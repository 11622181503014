import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "@modules/alert";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ExpatsService } from "../../../../_services/expats.service";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { OrderPopupComponent } from "../../orders/order-popup/order-popup.component";
import { finalize, take } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { UserDetails } from "@models/user-details";

@Component({
  selector: "app-expat-details-page",
  templateUrl: "./expat-details.component.html",
  styleUrls: ["./expat-details.component.scss"],
})
export class ExpatDetailsComponent {
  myControl = new UntypedFormControl();
  options: string[] = ["One", "Two", "Three"];
  @Input() set entity(value) {
    if (value) {
      this.id = value.id;
      this.entityID = value.entity_id;
      this.getExpatById();
    }
  }

  isLoadingGeneral = false;
  isLoadingStatus = false;
  id;
  // user;
  expat;
  statuses;
  user = new UserDetails();
  entityID;
  profilePic;
  docId;
  constructor(
    private route: ActivatedRoute,
    private expatService: ExpatsService,
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    private translate: TranslateService,
    public dictionariesService: DictionariesService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getExpatById();
      }
    });

    this.route.queryParams.subscribe((query) => {
      if (query) {
        this.entityID = query.entity_id;
      }
    });
  }

  getProfilePicture(id) {
    this.expatService.getProfilePicture(id).subscribe((res) => {
      if (res && res["result"] && res["result"]["file"]) {
        this.profilePic = res["result"]["file"];
        this.docId = res["result"]["id"];
      } else {
        this.profilePic = "../../../assets/images/footer-img.jpg";
      }
    });
  }

  setExpatDetails(data) {
    this.user.name = data.user.name;
    this.user.created_at = data.user.created_at;
    this.user.status_id = data.status_id;
    this.user.status_name = data.status_name;
    this.user.email = data.user.email;
    this.user.phone_prefix = this.dictionariesService.getPhonePrefix(
      data.user.phone_prefix
    );
    this.user.phone = data.user.phone;
    this.user.user_id = data.user_id;
    this.user.details = [];
    let arr = [
      { value: data.company_position, label: "" },
      { value: data.department_name, label: "" },
      { value: data.country_code.toUpperCase(), label: "" },
    ];

    this.user.details.push({
      title: "PORTAL.ACCOUNT-CONTACT-INFO",
      value: arr,
    });

    const obj = {
      "PORTAL.ADULTS": data.adults_number,
      "PORTAL.CHILDREN": data.children_number,
    };
    arr = [];
    arr = Object.keys(obj).map((k) => ({ label: k, value: obj[k] }));

    this.user.details.push({
      title: "PORTAL.ACCOMPANYING-FAMILY",
      value: arr,
    });
  }

  public getStatusTransitions() {
    this.isLoadingStatus = true;

    this.expatService
      .getStatusTransitions()
      .pipe(
        take(1),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe((data) => {
        if (data.success) {
          this.statuses = data.result[this.expat.status_id];
          this.user.statuses = data.result[this.expat.status_id];
        }
      });
  }
  handleEvent(e) {
    this.getExpatById();
  }

  getExpatById() {
    this.isLoadingGeneral = true;

    this.expatService
      .getExpatById(this.id)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe((data) => {
        this.expat = data.result;
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setForExpat(data.result, "details")
        );
        this.entityID = data.result.id;
        this.getStatusTransitions();
        this.getProfilePicture(this.id);
        this.setExpatDetails(data.result);
      });
  }

  editDetails() {
    this.expat["expat_id"] = this.id;
    this.expat["edit"] = true;

    const dialogRef = this.dialog.open(OrderPopupComponent, {
      data: {
        type: "newExpat",
        openedFrom: "expats",
        expat: { data: this.expat },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "save") {
        this.getExpatById();
      }
    });
  }

  resetPassword() {
    this.authService.sendResetPasswordEmail(this.user.email).subscribe(
      (res) => {
        if (res && res.message) {
          this.alertService.success(res.message);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  resendConfirmationMail() {
    this.authService.resendConfirmationMail(this.user.user_id).subscribe(
      (data) => {
        this.translate
          .get("GENERALS.MAIL-WAS-SENT-SUCCESS")
          .subscribe((data) => {
            this.alertService.success(data);
          });
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  openDocModal() {
    this.ngxSmartModalService.getModal("addDoc").open();
  }

  openPicModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").open();
  }

  openConfirmAction(action) {
    const ids = [this.id];
    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.expatService.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.getExpatById();
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.expatService
        .updateStatusBulk(action.id, ids, null)
        .subscribe((data) => {
          this.getExpatById();
        });
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
