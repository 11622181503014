<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div *ngIf="hideTitle" class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "LEFT-NAVIGATION.NAV5" | translate }}</div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <div class="btn white-bg" (click)="exportOrders()">
      <i class="icon-download"></i> {{ "GENERALS.EXPORT" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="openConfirmAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <ng-container matColumnDef="select">
              <th>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th mat-sort-header="identity_number" class="text-right">
              {{ "GENERALS.ORDER-NO" | translate }}
            </th>
            <th mat-sort-header="client">
              {{ "GENERALS.CLIENT" | translate }}
            </th>
            <th mat-sort-header="provider">
              {{ "GENERALS.PROVIDER" | translate }}
            </th>
            <th mat-sort-header="amount" class="text-right">
              {{ "GENERALS.ORDER-VALUE" | translate }}
            </th>
            <th mat-sort-header="created_at" class="text-right">
              {{ "GENERALS.PLACED" | translate }}
            </th>
            <th class="text-right">
              {{ "PORTAL.ASSIGNED-SERVICES" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let order of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? changeSelection(order) : null"
                [checked]="selection.isSelected(order)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>
            <td
              (click)="navigateByUrl(filters.page, order.identity_number)"
              class="text-right"
            >
              <a [href]="'/orders/' + order.identity_number" class="underline">
                {{ order.identity_number }}
              </a>
            </td>
            <td (click)="navigateByUrl(filters.page, order.identity_number)">
              {{ order.client.company_name }}
            </td>
            <td (click)="navigateByUrl(filters.page, order.identity_number)">
              {{ order.provider.company_name }}
            </td>
            <td
              (click)="navigateByUrl(filters.page, order.identity_number)"
              class="text-right"
            >
              {{ order.amount | currency : order.currency_code }}
            </td>
            <td
              (click)="navigateByUrl(filters.page, order.identity_number)"
              class="text-right"
            >
              {{ order.created_at | date : "dd/MM/yyyy" }}
            </td>

            <td
              (click)="navigateByUrl(filters.page, order.identity_number)"
              [ngClass]="
                remainingExpatsToAssign(order) > 0 ? 'orange' : 'light-green'
              "
              class="text-right"
            >
              {{ assignedExpatsOnOrder(order) }} /
              {{ expatsOnOrder(order) }}
            </td>
            <td (click)="navigateByUrl(filters.page, order.identity_number)">
              <div class="w-100 text-center status-label-{{ order.status_id }}">
                <a>{{ order.status_name }}</a>
              </div>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action btn -->
              <!-- <div class="table-action-btn">
                                  <i class="icon-list-add"></i>
                              </div> -->
              <!-- end table action btn -->

              <!-- table action with overlay -->
              <div
                toggle-action-table
                class="table-action-wrapper"
                *ngIf="actions && order.status_id !== OrderStatus.refund"
              >
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li
                      *ngFor="let status of actions[order.status_id]"
                      (click)="openConfirmAction(status, order)"
                    >
                      {{ status.text }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="8" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let order of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(order) : null"
              [checked]="selection.isSelected(order)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            (click)="navigateByUrl(filters.page, order.identity_number)"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ORDER-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.group_number }} - {{ order.identity_number }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CLIENT" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.company_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.PROVIDER" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.provider.company_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ORDER-VALUE" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ order.amount }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.PLACED" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.created_at | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.MAX-DUE-DATE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.created_at | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "PORTAL.ASSIGNED-SERVICES" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ assignedExpatsOnOrder(order) }} /
            {{ expatsOnOrder(order) }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ order.status_id }}">{{
              order.status_name
            }}</span>
          </div>
        </div>

        <div *ngIf="actions" class="responsive-table-action">
          <span
            *ngFor="let status of actions[order.status_id]"
            (click)="openConfirmAction(status, order)"
          >
            {{ status.text }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
