import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ServiceCategoriesService } from "src/app/_services/service-categories.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ItemsListComponent } from "../../dashboard/items-list/items-list.component";
import { RFQSService } from "src/app/_services/rfqs.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-rfq-service-offers",
  templateUrl: "./rfq-service-offers.component.html",
})
export class RFQServiceOffersComponent
  extends ItemsListComponent
  implements OnInit
{
  @Input() item;
  @Input() edit = false;
  @Output() disabledNextAction: EventEmitter<any> = new EventEmitter();

  constructor(
    private serviceCategoriesService: ServiceCategoriesService,
    private rfqService: RFQSService
  ) {
    super();
  }

  categories;
  private _onDestroy = new Subject<void>();
  request_call;
  selectionCount = {};
  page;
  inputNumberEmpty = false;

  updateCategorySelection(data) {
    // set RFQ/Bid categories
    if (
      !this.item.categories ||
      (this.item.categories && !this.item.categories.length)
    ) {
      this.item.categories = [];
    }

    const categIdx =
      this.item.categories && this.item.categories.length
        ? this.item.categories.findIndex(
            (f) => f.service_category_id === data.selection.id
          )
        : -1;
    if (categIdx > -1) {
      this.item.categories.splice(categIdx, 1);
    } else {
      this.item.categories.push({
        service_category_id: data.selection.id,
        edit_flag: this.edit,
        label: data.selection.label,
      });
    }
    // }

    // set service description
    if (data && data.id) {
      data.selection.quantity = 1;
      data.selection.service_category_id = data.selection.id;

      if (this.item.offers_request.length) {
        const idx = this.item.offers_request.findIndex(
          (f) =>
            f.id === data.selection.id ||
            f.service_category_id === data.selection.service_category_id
        );
        if (idx > -1 && !data.update) {
          this.item.offers_request.splice(idx, 1);
        } else if (data.update) {
          this.item.offers_request[idx] = data.selection;
        } else {
          this.item.offers_request.push(data.selection);
        }
      } else {
        this.item.offers_request = [];
        this.item.offers_request.push(data.selection);
      }
    }
  }

  qtyChanges(idx, unit) {
    if ((unit < 0 && this.item.offers_request[idx].quantity > 1) || unit >= 0) {
      // && < max.qty

      if (!(unit === 0)) {
        this.item.offers_request[idx].quantity =
          this.item.offers_request[idx].quantity + unit;
      }

      if (
        this.item.offers_request[idx].quantity === undefined ||
        this.item.offers_request[idx].quantity === null ||
        this.item.offers_request[idx].quantity === 0
      ) {
        this.inputNumberEmpty = true;
      } else {
        this.inputNumberEmpty = false;
      }

      this.disabledNextAction.emit(this.inputNumberEmpty);
    }
  }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    const params = { status: "active" };
    this.isLoadingGeneral = true;

    this.serviceCategoriesService
      .getServiceCategories(params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.categories = data.result.items;
        this.isLoadingGeneral = false;
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
