import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { SharedModule } from "../shared/shared.module";
import { CompanyCardComponent } from "./company-card/company-card.component";
import { CompanyDetailsComponent } from "./company-details/company-details.component";
import { CompanyPrimaryAccountComponent } from "./company-primary-account/company-primary-account.component";
import { CompanyInfoComponent } from "./company-info/company-info.component";
import { CompanyFormComponent } from "./company-form/company-form.component";
import { CompanyImpersonateComponent } from "./company-impersonate/company-impersonate.component";
import { SubscriptionsSharedModule } from "../subscriptions/subscriptions-shared/subscriptions-shared.module";

@NgModule({
  declarations: [
    CompanyCardComponent,
    CompanyDetailsComponent,
    CompanyPrimaryAccountComponent,
    CompanyInfoComponent,
    CompanyFormComponent,
    CompanyImpersonateComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    NgxSmartModalModule,
    SubscriptionsSharedModule,
  ],
  exports: [CompanyDetailsComponent],
})
export class CompanyModule {}
