import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { ProvidersService } from "../../../../_services/providers.service";
import { Provider } from "../../../../_models/provider";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AddProvidersComponent } from "../add-provider/add-provider.component";
import { ProviderInviteComponent } from "../provider-invite/provider-invite.component";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import cloneDeep from "lodash-es/cloneDeep";
import cloneDeepWith from "lodash-es/cloneDeepWith";
import deepCastToInt from "../../../../_helpers/deepCastToInt";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@modules/alert";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { finalize, take } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { SortDirection } from "@angular/material/sort";

@Component({
  selector: "app-providers",
  templateUrl: "./providers.component.html",
  styleUrls: ["./providers.component.css"],
})
export class ProvidersComponent extends ItemsListComponent implements OnInit {
  items: Provider[];
  request_call;
  active = "";
  direction: SortDirection = "";
  isXs: boolean;
  isFiltersLoaded: boolean = false;
  switchID;

  @ViewChild(ProviderInviteComponent, { static: true })
  private _providerInviteModal: ProviderInviteComponent;
  @ViewChild("tableHead", { static: true }) tableHead: ElementRef;
  @ViewChild("tableWrapper", { static: true }) tableWrapper: ElementRef;
  @ViewChild("tableBodyTr", { static: true }) tableBodyTr: ElementRef;
  @ViewChild("invisibleThead", { static: true }) invisibleThead: ElementRef;

  constructor(
    private service: ProvidersService,
    public dictionariesService: DictionariesService,
    public ngxSmartModalService: NgxSmartModalService, // private compareHelper: CompareHelper
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.route.queryParams.subscribe((query) => {
      if (query && Object.keys(query).length > 0) {
        this.filters = cloneDeepWith(query, deepCastToInt);
      } else {
        this.clearFilters();
        const params = this.getParams(1);
        this.filters = params;
      }
      if (this.filters["search_text"]) {
        this.search_text = this.filters["search_text"];
      }
      if (this.filters["per_page"]) {
        this.perPage = this.filters["per_page"];
      }
      if (this.filters["order"]) {
        this.active = this.filters["order"];
        this.direction = this.filters["order_direction"];
      } else {
        this.active = "";
        this.direction = "";
      }

      this.getItems();
    });
    this.showBulkActions = false;
    this.getStatusTransitions();
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/providers", "providers")
    );

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    let url = "";
    if (id) {
      url = "/providers/" + id;
    } else {
      url = "/providers";
    }
    this.router.navigated = false;
    this.router.navigate([url], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoadingGeneral = true;

    let params = {};
    params = cloneDeep(this.filters);
    this.request_call = this.service
      .getProviders(params)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public reloadTableData(page: number = 1) {
    const routerReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const url = "/providers";
    const queryParams = this.getParams(page);
    this.router.navigateByUrl(url).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.router.url], { queryParams });
    });

    this.router.routeReuseStrategy.shouldReuseRoute = routerReuseRoute;
  }

  protected getService() {
    return this.service;
  }

  public getStatusTransitions() {
    this.dictionariesService
      .getStatusTransitions("provider")
      .subscribe((data) => {
        if (data.success) {
          this.actions = data.result;
        }
      });
  }

  openModal() {
    const dialogRef = this.dialog.open(AddProvidersComponent);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.reloadTableData();
        },
      });
  }

  openInviteModal() {
    this._providerInviteModal.initEmpty();
    this.ngxSmartModalService.getModal("inviteProvider").open();
  }

  openSwitchModal(id) {
    this.switchID = id;
    this.ngxSmartModalService.getModal("switchAccount").open();
  }

  switchToAccount(e) {
    this.ngxSmartModalService.getModal("switchAccount").close();

    this.service
      .switchToAccount({
        provider_id: this.switchID,
        password: e,
      })
      .subscribe(
        (res) => {
          this.ngxSmartModalService.getModal("switchAccount").close();
        },
        (error) => {
          this.alertService.errors(error.error.errors);
        }
      );
  }

  onResize(e) {
    // ***** table header fix when scroll
    // const getInitialTh = this.tableHead.nativeElement.getElementsByTagName(
    //   'th'
    // );
    // const getInitialTr = this.tableBodyTr.nativeElement.getElementsByTagName(
    //   'td'
    // );
    // this.renderer.setStyle(
    //   this.invisibleThead.nativeElement,
    //   'height',
    //   getInitialTh[0].offsetHeight + 'px'
    // );
    // for (let i = 0; i < getInitialTh.length; i++) {
    //   this.renderer.setStyle(
    //     getInitialTh[i],
    //     'width',
    //     getInitialTh[i].offsetWidth + 'px'
    //   );
    //   this.renderer.setStyle(
    //     getInitialTr[i],
    //     'width',
    //     getInitialTh[i].offsetWidth + 'px'
    //   );
    // }
    // const getInitialTHeadPosition = this.tableWrapper.nativeElement.offsetTop;
    // const scrolled =
    //   window.pageYOffset !== undefined
    //     ? window.pageYOffset
    //     : (
    //         document.documentElement ||
    //         document.body.parentElement ||
    //         document.body
    //       ).scrollTop;
    // if (scrolled >= getInitialTHeadPosition - 51) {
    //   this.renderer.addClass(this.tableHead.nativeElement, 'fixedTableHead');
    //   this.renderer.setStyle(
    //     this.invisibleThead.nativeElement,
    //     'display',
    //     'block'
    //   );
    // } else {
    //   this.renderer.removeClass(this.tableHead.nativeElement, 'fixedTableHead');
    //   this.renderer.setStyle(
    //     this.invisibleThead.nativeElement,
    //     'display',
    //     'none'
    //   );
    // }
    // ***** end table header fix when scroll
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
