import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class DocumentsService {
  private docsUrl = "files";
  private ordersUploadUrl = "billing/orders";
  private clientsUploadUrl = "entities/clients";
  private providersUploadUrl = "entities/providers";
  private expatsUploadUrl = "entities/expats";
  public docTypeInvoice = "invoices";

  constructor(private http: HttpClient) {}

  // GET docs by entity and ID
  getDocuments(entityType, entityId, docType, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        entityType +
        "/" +
        entityId +
        "/" +
        this.docsUrl +
        "/" +
        docType,
      {
        params: params,
      }
    );
  }

  getOrderInvoiceStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "invoice/order-invoice-statuses"
    );
  }

  getCurrencies(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/currencies"
    );
  }

  // GET statuses for invoice upload
  getFilterOrderInvoicesStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "invoice/filter-order-invoices-statuses"
    );
  }

  // GET statuses for doc upload
  getStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "files/statuses");
  }

  // Upload by doc type
  uploadFiles(
    id,
    fileType,
    obj,
    entityType,
    invoiceExtraData?
  ): Observable<any> {
    let url;
    const formData: FormData = new FormData();

    if (obj.file && obj.file.length > 0) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    if (invoiceExtraData) {
      if (invoiceExtraData.invoice_amount) {
        formData.append(
          "invoice_amount",
          invoiceExtraData.invoice_amount.toString()
        );
      }
      if (invoiceExtraData.invoice_currency) {
        formData.append("invoice_currency", invoiceExtraData.invoice_currency);
      }
      if (invoiceExtraData.invoice_number) {
        formData.append("invoice_number", invoiceExtraData.invoice_number);
      }
      if (invoiceExtraData.invoice_due_date) {
        formData.append("invoice_due_date", invoiceExtraData.invoice_due_date);
      }
      if (invoiceExtraData.invoice_issue_date) {
        formData.append(
          "invoice_issue_date",
          invoiceExtraData.invoice_issue_date
        );
      }
      if (invoiceExtraData.invoice_status) {
        formData.append("invoice_status", invoiceExtraData.invoice_status);
      }
    }

    switch (entityType) {
      case "clients":
        url = this.clientsUploadUrl;
        break;
      case "providers":
        url = this.providersUploadUrl;
        break;
      case "orders":
        url = this.ordersUploadUrl;
        break;
      case "expats":
        url = this.expatsUploadUrl;
        break;
    }

    return this.http
      .post<any>(
        environment.gateway_endpoint + url + "/" + id + "/" + fileType,
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Upload by doc type
  uploadImage(id, fileType, obj, entityType, docId): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    if (docId) {
      formData.append("file_id", docId);
      formData.append("status", "updated");
    }

    const url = entityType + "/" + id + "/update-profile-picture";

    return this.http
      .post<any>(environment.gateway_endpoint + url, formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getAgreementsList(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        "/entities/providers/" +
        id +
        "/agreements/list"
    );
  }

  downloadAgreementPDF(provider_id, agreement_id) {
    const headers = new HttpHeaders({
      Accept: "application/pdf",
    });
    return this.http.get(
      environment.gateway_endpoint +
        "/entities/providers/" +
        provider_id +
        "/agreements/" +
        agreement_id +
        "/export-pdf",
      {
        headers: headers,
        responseType: "blob",
      }
    );
  }

  // Upload by doc type
  uploadProfilePicture(entityType, entityId, files): Observable<any> {
    const formData: FormData = new FormData();

    if (files.file) {
      formData.append("file", files.file[0], files.file[0].name);
    }
    if (files.status) {
      formData.append("status", files.status);
    }
    if (files.name) {
      formData.append("name", files.name);
    }

    const url = entityType + "/" + entityId + "/update-profile-picture";

    return this.http
      .post<any>(environment.gateway_endpoint + url, formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Get Provider User Agreements

  getUserAgreements(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.providersUploadUrl +
        "/" +
        id +
        "/user-agreements",
      {
        params: params,
      }
    );
  }

  // Delete doc

  deleteDocument(id): Observable<any> {
    return this.http
      .delete<any>(environment.gateway_endpoint + "files/" + id, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
