import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
  selector: "[appHoverHelper]",
  standalone: false,
})
export class HoverHelperDirective {
  @HostBinding("class.visible-helper") isOpen = false;

  @HostListener("mouseenter") mouseEnter($event) {
    this.isOpen = true;
  }
  @HostListener("mouseleave") mouseLeave($event) {
    this.isOpen = false;
  }
}
