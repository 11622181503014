import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import cloneDeep from "lodash-es/cloneDeep";
import { Subject, combineLatest } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { ServiceCategoriesService } from "src/app/_services/service-categories.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { Provider } from "../../../../_models/provider";
import { ProvidersCommissions } from "../../../../_models/providerscommissions";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { AlertService } from "@modules/alert";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ProvidersService } from "../../../../_services/providers.service";

@Component({
  selector: "app-add-provider",
  templateUrl: "./add-provider.component.html",
})
export class AddProvidersComponent implements OnInit, OnDestroy {
  provider: Provider;
  providerId: number;

  setModalOpen = true;
  isCanceled = false;
  isLoading = {
    general: false,
    categories: false,
    provider: false,
    company: false,
  };
  currentStep = 1;

  commissions: ProvidersCommissions;
  categories: ServiceCategory[];

  categorySelection = {};

  countries;
  selectedCountries;

  private _onDestroy = new Subject<void>();

  // hoveredDate: NgbDate;
  // fromDate: NgbDate;
  // toDate: NgbDate;
  // dateRange: Date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddProvidersComponent>,
    private service: ProvidersService,
    private alertService: AlertService,
    calendar: NgbCalendar,
    private dictionaryService: DictionariesService,
    private categoryService: ServiceCategoriesService,
    private router: Router
  ) {
    this.setModalOpen = false;
    this.initEmpty();
  }

  ngOnInit() {
    this.getServiceCategories();
    this.getCountryList();
  }

  initEmpty(): void {
    this.provider = new Provider();
    this.currentStep = 1;
  }

  getCountryList() {
    this.dictionaryService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  getServiceCategories() {
    const params = { status: "active" };
    this.isLoading.categories = true;

    this.categoryService
      .getServiceCategories(params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.categories = data.result.items;
        this.isLoading.categories = false;
      });
  }

  createProviderAccount() {
    this.provider.name = this.provider.first_name + this.provider.last_name;
    this.isLoading.provider = true;

    this.service
      .createProviderStep1(this.prepareDataToSave())
      .pipe(
        take(1),
        finalize(() => (this.isLoading.provider = false))
      )
      .subscribe(
        (data) => {
          this.currentStep = 2;
          this.providerId = data.result.id;
        },
        (error) => {
          this.alertService.stringError(error.error.errors);
          if (error?.error?.errors) {
            if (error.error.errors["company_name"]) {
              this.alertService.stringError(
                error.error.errors["company_name"][0]
              );
            } else {
              this.alertService.errors(error.error.errors);
            }
          }
        }
      );
  }

  prepareDataToSave() {
    const dataToSave: any = { ...this.provider };

    dataToSave.user = dataToSave.user || {};
    dataToSave.user.first_name = this.provider.first_name;
    dataToSave.user.last_name = this.provider.last_name;
    dataToSave.user.email = this.provider.email;
    dataToSave.user.phone_number = this.provider.phone;
    dataToSave.user.phone_prefix = this.provider.phone_prefix;

    return dataToSave;
  }

  createProviderCompany() {
    this.isLoading.company = true;
    this.service.createProviderStep2(this.provider, this.providerId).subscribe(
      (data) => {
        this.currentStep = 3;
        this.isLoading.company = false;
      },
      (error) => {
        const errors = [];

        Object.keys(error.error.errors).forEach((key) => {
          errors.push({
            message: error.error.errors[key][0],
          });
        });

        this.alertService.errors(errors);
        this.isLoading.company = false;
      }
    );
  }

  updateCategorySelection(data) {
    if (data.id) {
      if (this.categorySelection[data.id]) {
        const idx = this.categorySelection[data.id].findIndex(
          (f) => f.id === data.selection.id
        );
        if (idx > -1 && !data.update) {
          this.categorySelection[data.id].splice(idx, 1);
        } else if (data.update) {
          this.categorySelection[data.id][idx] = data.selection;
        } else {
          this.categorySelection[data.id].push(data.selection);
        }
      } else {
        this.categorySelection[data.id] = [];
        this.categorySelection[data.id].push(data.selection);
      }
      if (
        this.categorySelection[data.id] &&
        this.categorySelection[data.id].length === 0
      ) {
        delete this.categorySelection[data.id];
      }
    }
  }

  setCountriesAndCategories(param?) {
    this.isLoading.categories = true;
    this.commissions = new ProvidersCommissions();
    this.commissions.provider_id = this.providerId;
    this.commissions.categories = [];
    const selection = cloneDeep(this.categorySelection);
    const keys = Object.keys(selection);
    keys.forEach((item) => {
      selection[item].forEach((categ) => {
        if (!categ.commissions) {
          categ.commissions = "";
        }
        const categKeys = Object.keys(categ);
        categKeys.forEach((subkey) => {
          if (subkey !== "commissions" && subkey !== "id") {
            delete categ[subkey];
          }
        });

        delete categ.breadcrumbs;
        delete categ.children;
        this.commissions.categories.push(categ);
      });
    });

    let countryCodes;

    if (this.selectedCountries) {
      countryCodes = this.countries.filter((f) =>
        this.selectedCountries.find((name) => name === f.name)
      );
      countryCodes = countryCodes.map((f) => f.country_code);
    } else {
      countryCodes = [];
    }

    const commission$ = this.service.createProviderStep3(
      this.providerId,
      this.commissions
    );
    const countries$ = this.service.setCountryProviderStep3(
      {
        countries: countryCodes,
      },
      this.providerId
    );
    combineLatest(commission$, countries$).subscribe(
      ([comm, country]) => {
        if (param === "add_another") {
          this.initEmpty();
        } else {
          this.dialogRef.close();
          this.router.navigate(["/providers/" + this.providerId]);
        }
        this.isLoading.categories = false;
      },
      (error) => {
        if (error.error) {
          this.alertService.errors(error.error.errors);
          this.isLoading.categories = false;
        }
      }
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
