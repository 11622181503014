import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ActionTypes, UnsafeAction } from "../app.actions";
import { AppState } from "../app.state";

@Injectable()
export class WidgetsEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  setNotification$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.SET_NOTIFICATION),
      map((action: UnsafeAction) => {
        return {
          type: ActionTypes.SET_NOTIFICATION_SUCCESS,
          payload: action.payload,
        };
      })
    )
  );

  setMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.SET_MESSAGES),
      map((action: UnsafeAction) => {
        return {
          type: ActionTypes.SET_MESSAGES_SUCCESS,
          payload: action.payload,
        };
      })
    )
  );
}
