import { ClientUser } from "./client-user";
import { LegalEntityData } from "./legal-entity.data";

export class Client {
  id: number;
  company_name: string;
  department_id: number;
  department_name: string;
  users: Array<ClientUser>;
  status_id: number;
  status_name: string;
  company_type_id: number;
  company_type_name: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  phone_prefix: string;
  company_position: string;
  industry_id: number;
  number_of_employees_id: number;
  entity_type_id = 2;
  default_legal_entity?: LegalEntityData;
}
