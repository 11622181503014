import { Component, EventEmitter, Input, Output } from "@angular/core";

import { DictionariesService } from "../../../../../_services/dictionaries.service";

@Component({
  selector: "app-filter-status",
  templateUrl: "./filter-status.component.html",
})
export class FilterStatusComponent {
  @Input() statusId: number;

  @Output() selectedStatus: EventEmitter<any> = new EventEmitter();

  options: any[];
  loading = true;
  selectedStatusId: number;

  constructor(private dictionariesService: DictionariesService) {}
}
