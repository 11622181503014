import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

@Component({
  selector: "app-edit-unit-price-modal",
  templateUrl: "./edit-unit-price-modal.component.html",
  styleUrls: ["./edit-unit-price-modal.component.css"],
})
export class EditUnitPriceModalComponent {
  @Input() item;
  @Output() saveEditData: EventEmitter<any> = new EventEmitter();
  @Output() getItem: EventEmitter<any> = new EventEmitter();

  constructor(public ngxSmartModalService: NgxSmartModalService) {}

  closeEditUnitPrice() {
    this.ngxSmartModalService.getModal("editUnitPrice").close();
    this.getItem.emit();
  }

  save(e) {
    this.saveEditData.emit(e);
  }
}
