<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div *ngIf="!hideFilters" class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "SUB-NAVIGATION.FEES" | translate }}</div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!--    todo add commissions btn modal-->
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div *ngIf="!hideFilters" class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [showCalendar]="true"
      [searchPlaceholder]="'GENERALS.SEARCH'"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      (date)="setFilteringDate($event); navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>

  <div *ngIf="hideFilters" class="fl-l big-title">
    {{ "LEFT-NAVIGATION.COMMISSIONS" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    *ngIf="!hideFilters"
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="!isLoadingGeneral && showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <th>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </th>
            <th mat-sort-header="transaction_date">
              {{ "GENERALS.DATE" | translate }}
            </th>
            <th mat-sort-header="company_name">
              {{ "GENERALS.PROVIDER" | translate }}
            </th>
            <th mat-sort-header="invoice_id">
              {{ "INVOICES.INVOICE-NO" | translate }}
            </th>
            <th mat-sort-header="identity_number">
              {{ "GENERALS.ORDER-NO" | translate }}
            </th>
            <th mat-sort-header="offer_id">
              {{ "OFFERS.OFFER-NO" | translate }}
            </th>
            <th mat-sort-header="service_category_name">
              {{ "OFFERS.CATEGORY-LABEL" | translate }}
            </th>
            <th mat-sort-header="amount">
              {{ "GENERALS.AMOUNT" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>

        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let commission of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? changeSelection(commission) : null"
                [checked]="selection.isSelected(commission)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>
            <td class="gray-pointer">
              {{ commission.transaction_date | date : "dd/MM/yyyy HH:mm:ss" }}
            </td>
            <td>
              <a
                href="/providers/{{ commission.provider.id }}"
                [routerLink]="['/providers/' + commission.provider.id]"
                class="action-link-gray"
                >{{ commission.provider.company_name }}</a
              >
            </td>
            <td class="gray-pointer">
              <a
                *ngIf="commission.invoice_id"
                href="/invoices/{{ commission.invoice_id }}"
                [routerLink]="['/invoices/' + commission.invoice_id]"
                >#{{ commission.invoice_id }}</a
              >
              <span *ngIf="!commission.invoice_id">n/a</span>
            </td>
            <td>
              <a
                href="/orders/{{ commission.order_identity_number }}"
                [routerLink]="['/orders/' + commission.order_identity_number]"
                class="action-link-orange"
                >#{{ commission.order_identity_number }}</a
              >
            </td>
            <td>
              <a
                href="/service-offers/{{ commission.offer_id }}"
                [routerLink]="['/service-offers/' + commission.offer_id]"
                class="action-link-gray"
                >#{{ commission.offer_id }}</a
              >
            </td>
            <td class="gray-pointer">
              {{ commission.category.name }}
            </td>
            <td class="gray-pointer">
              {{ commission.amount | currency : commission.currency_code }}
            </td>

            <td class="gray-pointer">
              <div class="status-label-{{ commission.status_id }}">
                {{
                  "STATUSES.COMMISSION-BILLED-" + commission.status_id
                    | translate
                }}
              </div>
              <div *ngIf="commission.is_refunded === true">
                {{ "STATUSES.REFUNDED" | translate }}
              </div>
            </td>
            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action with overlay -->
              <div
                toggle-action-table
                class="table-action-wrapper"
                *ngIf="actions && actions[commission.status_id]"
              >
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li
                      *ngFor="let status of actions[commission.status_id]"
                      (click)="changeStatus(status, commission)"
                    >
                      {{ status.text }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>

    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let commission of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(commission) : null"
              [checked]="selection.isSelected(commission)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.DATE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.transaction_date | date : "dd/MM/yyyy HH:mm:ss" }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COMPANY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.provider?.company_name }}
            <span
              [routerLink]="['/providers/' + commission.provider.id]"
              class="action-link-orange-sm"
            >
              - {{ "GENERALS.DETAILS" | translate }}</span
            >
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.INVOICE-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.invoice_id ? "#" + commission.invoice_id : "n/a" }}
            <span
              *ngIf="commission.invoice_id"
              [routerLink]="['/invoices/' + commission.invoice_id]"
              class="action-link-orange-sm"
            >
              - {{ "GENERALS.DETAILS" | translate }}
            </span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ORDER-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            #{{ commission.order_identity_number }}
            <span
              [routerLink]="['/orders/' + commission.order_identity_number]"
              class="action-link-orange-sm"
            >
              - {{ "GENERALS.DETAILS" | translate }}</span
            >
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "OFFERS.OFFER-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            #{{ commission.offer_id }}
            <span
              [routerLink]="['/service-offers/' + commission.offer_id]"
              class="action-link-orange-sm"
            >
              - {{ "GENERALS.DETAILS" | translate }}</span
            >
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "OFFERS.CATEGORY-LABEL" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.category.name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "OFFERS.COMMISSION" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.amount | currency : commission.currency_code }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ commission.status_id }}">
              {{
                "STATUSES.COMMISSION-BILLED-" + commission.status_id | translate
              }}
            </span>
            <span *ngIf="commission.is_refunded === true">
              {{ "STATUSES.REFUNDED" | translate }}
            </span>
          </div>
        </div>

        <div *ngIf="commission.status_id" class="responsive-table-action">
          <span *ngIf="commission.status_id === 1">
            {{ "GENERALS.CANCEL" | translate }}
          </span>
          <span *ngIf="commission.status_id === 2">
            {{ "GENERALS.REFUND" | translate }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
