import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { finalize, switchMap, takeUntil } from "rxjs/operators";
import { ServiceOffer } from "src/app/_models/service-offer";
import { ServiceOffersService } from "src/app/_services/service-offers.service";

@Component({
  selector: "app-availability-form",
  templateUrl: "./availability-form.component.html",
  styleUrls: ["./availability-form.component.css"],
})
export class AvailabilityFormComponent implements OnInit, OnDestroy {
  @Input()
  offer: any;

  offerForm = new UntypedFormGroup({
    for_individual: new UntypedFormControl(false, [Validators.required]),
    for_business: new UntypedFormControl(false, [Validators.required]),
  });

  unsubscribe$: Subject<void>;

  constructor(private readonly offerService: ServiceOffersService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.unsubscribe$ = new Subject();

    if (!this.offer) {
      this.offerForm.disable();
      return false;
    }
    this.offerForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((value) => this.updateOffer(value))
      )
      .subscribe();
    this.offerForm.patchValue(this.offer, { emitEvent: false });
  }

  updateOffer(newData: Partial<ServiceOffer>) {
    const data: any = { ...this.offer, ...newData };

    this.offerForm.disable({ emitEvent: false });
    return this.offerService
      .updateServiceOffers(this.prepare(data), this.offer.id)
      .pipe(finalize(() => this.offerForm.enable({ emitEvent: false })));
  }

  prepare(data: any) {
    const characteristics = {};
    (data.characteristics ? data.characteristics : []).forEach((ch) => {
      characteristics[ch.characteristic_id] = ch.data;
    });

    return {
      ...data,
      sale_price: data.sale_price.price,
      default_price: data.default_price.price,
      default_currency_id: data.default_price.currency_id,
      default_quantity: data.default_price.quantity,
      characteristics,
    } as ServiceOffer;
  }
}
