import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { finalize, map, take } from "rxjs/operators";
import { Plan } from "src/app/_models/plan";
import { AlertService } from "@modules/alert";
import { IntervalEnum } from "src/app/api/subscriptions/enums/subscription-interval.enum";
import { PlanService } from "src/app/api/subscriptions/services/plan.service";
import { EntityTypeEnum } from "src/app/modules/shared/_enums/entity-type.enum";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-assign-plan-form",
  templateUrl: "./assign-plan-form.component.html",
  styleUrls: ["./assign-plan-form.component.scss"],
  standalone: false,
})
export class AssignPlanFormComponent implements OnInit {
  @Input()
  entityType: EntityTypeEnum;

  readonly INTERVALS = [IntervalEnum.MONTH, IntervalEnum.YEAR];

  isLoadingGeneral = false;
  form: UntypedFormGroup;
  plans$: Observable<Plan[]>;
  recurring$: Observable<any>;

  constructor(
    private planService: PlanService,
    @Inject(MAT_DIALOG_DATA)
    public data: { plan: Plan; entityId: number; entityType: EntityTypeEnum },
    private dialogRef: MatDialogRef<AssignPlanFormComponent>,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.setupForm();

    this.getPlans();
  }

  private setupForm(): void {
    this.form = new UntypedFormGroup({
      plan: new UntypedFormControl(
        this.data.plan ? this.data.plan : "",
        Validators.required
      ),
      recurring: new UntypedFormControl(null, Validators.required),
      days_until_due: new UntypedFormControl("", Validators.required),
      trial_period_days: new UntypedFormControl("", Validators.required),
    });

    this.recurring$ = this.form.get("plan").valueChanges.pipe(
      map((plan) => {
        if (!plan.product || !plan.product.prices) {
          return [];
        }

        return plan.product.prices
          .filter((price) => price.type === "recurring")
          .map((price) => {
            return {
              price: price,
              interval: price.recurring.interval,
              interval_count: price.recurring.interval_count,
            };
          });
      })
    );
  }

  private getPlans(): void {
    this.isLoadingGeneral = true;

    this.plans$ = this.planService
      .getPlans({ entity_type: this.data.entityType })
      .pipe(
        finalize(() => (this.isLoadingGeneral = false)),
        map((pageData) => pageData.items)
      );
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }
    this.isLoadingGeneral = true;
    const data = this.form.getRawValue();
    const requestData = {
      plan_id: data.plan.id,
      entity_id: this.data.entityId,
      entity_type: this.data.entityType,
      days_until_due: data.days_until_due,
      trial_period_days: data.trial_period_days,
      recurring: data.recurring,
      collection_method: "send_invoice",
    };
    this.planService
      .addPlanToUser(requestData)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe(
        (result) => this.dialogRef.close({ success: true, result }),
        (err) => {
          if (err && err.error && err.error.message) {
            this.alertService.stringError(err.error.message);
          }
        }
      );
  }

  compareWith(obj1: Plan, obj2: Plan): boolean {
    return obj1.id === obj2.id;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
