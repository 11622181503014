<ng-template #loading>
  <app-loader-spinner-general
    [loadingType]="LoadingType.INPUT"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    [loadingType]="LoadingType.INFO"
  ></app-loader-spinner-general>
</ng-template>
<div class="white-box-row" *ngIf="planSubject$ | async as plan; else loading">
  <div class="white-box-header">
    <div class="medium-title fl-l">
      {{ "GENERALS.SUBSCRIPTION-INFO" | translate }}
    </div>
    <div class="fl-r" *ngIf="subscriptionSubject$ | async as sub">
      <div
        *ngIf="!sub.subscription; else removeSubscription"
        class="white-box-header-action orange fl-r edit-info"
        (click)="openEdit(plan)"
      >
        <i class="icon-edit"></i>
        <span class="fw-700">
          {{ "GENERALS.EDIT" | translate }}
        </span>
      </div>
      <ng-template #removeSubscription>
        <div
          class="white-box-header-action orange fl-r edit-info"
          (click)="removeUserSubscription()"
        >
          <i class="icon-edit"></i>
          <span class="fw-700">
            {{ "SUBSCRIPTIONS.CANCEL-SUBSCRIPTION" | translate }}
          </span>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="clear"></div>
  <div class="white-box-body">
    <div class="w-100 p-0 fields-wrapper">
      <div class="w-50 p-0">
        <section>
          <span class="font-weight-bold">
            {{ "LABEL.PLAN-NAME" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{ plan.name }}
            <span class="fw-700">
              {{
                plan.default === PlanType.DEFAULT
                  ? ("SUBSCRIPTIONS.DEFAULT" | translate)
                  : ""
              }}
            </span>
          </p>
        </section>
        <section *ngIf="plan.product">
          <span class="font-weight-bold">
            {{ "GENERALS.PRICE" | translate }}
          </span>
          <p class="case-label m-t-10">
            {{
              plan.product.default_price.unit_amount
                | currency : (plan.product.default_price.currency | uppercase)
            }}
          </p>
        </section>
      </div>
      <ng-container *ngIf="subscriptionSubject$ | async as sub">
        <div class="w-50 p-0" *ngIf="sub.subscription">
          <section>
            <span class="font-weight-bold">
              {{ "LABEL.RENEW-DATE" | translate }}
            </span>
            <p class="case-label m-t-10">
              {{ sub.subscription.current_period_end | date | notAvailable }}
            </p>
          </section>
          <section class="status-wrapper">
            <span class="font-weight-bold">
              {{ "GENERALS.STATUS" | translate }}
            </span>
            <p [ngClass]="'status-label-' + $any(sub).status" class="m-t-10">
              {{ $any(sub).status }}
            </p>
          </section>
        </div>
      </ng-container>
    </div>

    <div class="w-100 p-0" *ngIf="plan.product">
      <section>
        <a
          [href]="$any(plan.product).stripe_url"
          target="_blank"
          class="orange pointer fw-700"
          >{{ "LABEL.VIEW-SUBSCRIPTION" | translate }}</a
        >
      </section>
    </div>
  </div>
</div>
