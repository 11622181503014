import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "@modules/alert";
import { EntityTypeEnum } from "../../shared/_enums/entity-type.enum";

@Component({
  selector: "app-company-impersonate",
  templateUrl: "./company-impersonate.component.html",
  styleUrls: ["./company-impersonate.component.css"],
})
export class CompanyImpersonateComponent implements OnInit {
  @Input() entityId: number;
  @Input() service;

  public password: string;
  private entityType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CompanyImpersonateComponent>,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.entityId = this.data.entityId;
    this.service = this.data.service;
    this.entityType = this.data.entityType;
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const data = {
      ...(this.entityType === EntityTypeEnum.CLIENT && {
        client_id: this.entityId,
      }),
      ...(this.entityType === EntityTypeEnum.PROVIDER && {
        provider_id: this.entityId,
      }),
      password: this.password,
    };

    this.service.switchToAccount(data).subscribe(
      (res) => {
        this.dialogRef.close({ res });
      },
      (error) => {
        if (error && error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }
}
