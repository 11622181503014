import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ServiceOffersService } from "src/app/_services/service-offers.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "offer-activity",
  templateUrl: "./offer-activity.component.html",
  styleUrls: ["./offer-activity.component.css"],
})
export class OfferActivityComponent implements OnInit {
  public id;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private serviceOffers: ServiceOffersService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    this.serviceOffers.getServiceOfferById(this.id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForOffer(data.result, "history")
      );
    });
  }
}
