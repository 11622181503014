import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "@modules/alert";
import { ClientsService } from "../../../_services/clients.service";
import { ProvidersService } from "../../../_services/providers.service";
import { EntityTypeEnum } from "../../shared/_enums/entity-type.enum";
import { EntityData } from "../models/entity.data";

@Component({
  selector: "app-company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: ["./company-form.component.css"],
})
export class CompanyFormComponent implements OnInit {
  public entity: EntityData;
  public entityType: string;

  public entityTypes = EntityTypeEnum;
  private service;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CompanyFormComponent>,
    private clientsService: ClientsService,
    private providersService: ProvidersService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.entity = { ...this.data.entity };
    this.entityType = this.data.entityType;
    if (this.entityType === EntityTypeEnum.PROVIDER) {
      this.service = this.providersService;
    } else if (this.entityType === EntityTypeEnum.CLIENT) {
      this.service = this.clientsService;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const dataToSave = this.setDataFromDefaultLegalEntity({ ...this.entity });
    this.service.updateCompanyDetails(dataToSave).subscribe(
      (data) => {
        this.alertService.success("Saved successfully");
        this.dialogRef.close({ data });
      },
      (error) => {
        if (error && error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  setDataFromDefaultLegalEntity(data: any) {
    if (this.entityType === EntityTypeEnum.CLIENT) {
      return data;
    }

    data.address = data.default_legal_entity.address;
    data.city = data.default_legal_entity.city;
    data.country_code = data.default_legal_entity.country_code;
    data.zip_code = data.default_legal_entity.zip_code;
    data.tax_number = data.default_legal_entity.tax_number;
    data.region = data.default_legal_entity.region;

    return data;
  }
}
