import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ValueList } from "../modules/shared/models/value-list.model";
import { DictionaryResponse } from "../_models/dictionary";

const CACHE_SIZE = 1;

@Injectable({
  providedIn: "root",
})
export class DictionariesService {
  private departmentsUrl = "entities/enum/departments";
  private companyTypesUrl = "entities/enum/company_types";
  private industriesListUrl = "entities/enum/industries";
  private employeesNumberUrl = "entities/enum/number_of_employees";
  private professionalAssociationUrl = "entities/enum/professional_association";
  private cache$: Observable<any>;
  private countriesMap: Map<string, string>;

  constructor(private http: HttpClient) {}

  getCountryMap(): Observable<Map<string, string>> {
    if (this.countriesMap) {
      return of(this.countriesMap);
    }

    return this.getCountryList().pipe(
      map((response) => response.result),
      map((countries) => {
        return countries.reduce(
          (countriesMap: Map<string, string>, country) =>
            countriesMap.set(country.country_code, country.name),
          new Map<string, string>()
        );
      }),
      tap((countries) => (this.countriesMap = countries))
    );
  }

  // GET Departments
  getDepartments(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.departmentsUrl,
      { params: { "low-priority": "low-priority" } }
    );
  }

  // GET Company Types
  getCompanyTypes(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.companyTypesUrl,
      { params: { "low-priority": "low-priority" } }
    );
  }

  // GET Industries list
  getIndustriesList(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.industriesListUrl,
      { params: { "low-priority": "low-priority" } }
    );
  }

  // GET Industries list
  getprofessionalAssociation(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.professionalAssociationUrl,
      { params: { "low-priority": "low-priority" } }
    );
  }

  // GET Industries list
  getEmployeesNumber(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.employeesNumberUrl,
      { params: { "low-priority": "low-priority" } }
    );
  }

  // GET entities Statuses
  getEntityStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/enum/entity_statuses",
      { params: { "low-priority": "low-priority" } }
    );
  }

  getCountryList() {
    if (!this.cache$) {
      this.cache$ = this.requestCountries().pipe(shareReplay(CACHE_SIZE));
    }

    return this.cache$;
  }

  private requestCountries(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "countries");
  }

  getStatusTransitions(entityType): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        "entities/" +
        entityType +
        "/status-transitions",
      { params: { "low-priority": "low-priority" } }
    );
  }

  // GET entities Statuses
  getUserStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "users/statuses", {
      params: { "low-priority": "low-priority" },
    });
  }

  intCountryPhonePhone(code) {
    const numberPattern = /\d+/g;

    if (code.match(numberPattern)) {
      return code.match(numberPattern).join("");
    }

    return null;
  }

  getPhonePrefix(code) {
    if (!code) {
      return null;
    }

    return "(+" + this.intCountryPhonePhone(code) + ")";
  }

  /**
   * Get cities
   */
  public cities(
    search: string,
    countryCode: string
  ): Observable<DictionaryResponse> {
    return this.http.get<DictionaryResponse>(
      environment.gateway_endpoint + "cities",
      { params: { q: search, country_code: countryCode } }
    );
  }

  public filter(
    endpoint: string,
    params: ValueList
  ): Observable<DictionaryResponse> {
    return this.http.get<DictionaryResponse>(
      environment.gateway_endpoint + endpoint,
      { params }
    );
  }
}
