import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, exhaustMap, map, switchMap, tap } from "rxjs/operators";
import * as AccountActions from "../actions/account.actions";
import { AccountService } from "../services";
import { AccountStoreService } from "../services/account-store.service";
import { UserData } from "@models/user.model";

@Injectable()
export class AccountEffects {
  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadAccount),
      switchMap(() => this.accountStore.loadUser()),
      exhaustMap((user) =>
        user
          ? from([
              AccountActions.loadAccountSuccess({ user }),
              AccountActions.reloadAccount(),
            ])
          : this.account.fetchData().pipe(
              map((data) => AccountActions.loadAccountSuccess({ user: data })),
              catchError((error) =>
                of(AccountActions.loadAccountFailure({ error }))
              )
            )
      )
    )
  );
  reloadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.reloadAccount),
      exhaustMap(() =>
        this.account.fetchData().pipe(
          map((data) => AccountActions.reloadAccountSuccess({ user: data })),
          catchError((error) =>
            of(AccountActions.reloadAccountFailure({ error }))
          )
        )
      )
    )
  );
  saveInStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AccountActions.loadAccountSuccess,
          AccountActions.reloadAccountSuccess,
          AccountActions.updateAccountDataSuccess
        ),
        tap(({ user }) => {
          this.accountStore.setUser(user as UserData);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private account: AccountService,
    public accountStore: AccountStoreService
  ) {}
}
