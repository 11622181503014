import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AuthGuard } from "./guards/auth.guard";
import { AuthEffects } from "./effects/auth.effects";
import { authFeatureKey, reducer } from "./reducers/auth.reducer";
import { NoAuthGuard } from "./guards/no-auth.guard";

@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(authFeatureKey, reducer),
  ],
})
export class AuthenticationModule {
  public static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [AuthGuard, NoAuthGuard],
    };
  }
}
