import { finalize, take } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Dictionary } from "../../../_models/dictionary";
import { AlertService } from "@modules/alert";
import { ConfirmActionModalComponent } from "../../../components/dashboard/confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { AddProfileImageComponent } from "../../shared/_components/add-profile-image/add-profile-image.component";
import { EntityTypeEnum } from "../../shared/_enums/entity-type.enum";
import { CompanyImpersonateComponent } from "../company-impersonate/company-impersonate.component";
import { EntityData } from "../models/entity.data";
import { LoadingTypeEnum } from "../../shared/_enums/loading-type.enum";

@Component({
  selector: "app-company-card",
  templateUrl: "./company-card.component.html",
  styleUrls: ["./company-card.component.scss"],
})
export class CompanyCardComponent implements OnChanges {
  @Input() entity: EntityData;
  @Input() entityType: string;
  @Input() service;
  @Output() changeCompany: EventEmitter<any> = new EventEmitter<any>();

  public entityTypes = EntityTypeEnum;
  public rating: number;
  public statusOptions: Dictionary[];
  isLoadingStatus: boolean = false;

  constructor(
    private matDialog: MatDialog,
    private alertService: AlertService
  ) {}

  ngOnChanges() {
    this.calculateRating();
    this.getStatusTransitions();
  }

  public calculateRating() {
    if (this.entity.rating && this.entity.rating.length) {
      let calculatedRating: number[] = [];
      calculatedRating = this.entity.rating.map((f) => f["rating"]);
      this.rating =
        calculatedRating.reduce((a, b) => a + b) / this.entity.rating.length;
      this.rating = +this.rating.toFixed(2);
    }
  }

  public getStatusTransitions() {
    this.isLoadingStatus = true;

    this.service
      .getStatusTransitions()
      .pipe(
        take(1),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe((data) => {
        if (data.success) {
          this.statusOptions = data.result[this.entity.status_id];
        }
      });
  }

  openUploadModal() {
    const dialogRef = this.matDialog.open(AddProfileImageComponent, {
      data: {
        entityId: this.entity.id,
        entityType: this.entityType,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.data) {
        this.changeCompany.emit();
      }
    });
  }

  openStatusChangeAction(action) {
    const ids = [this.entity.id];
    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.service.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.changeCompany.emit();
            },
            (error) => {
              if (error && error.error && error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.service.updateStatusBulk(action.id, ids, null).subscribe(
        (data) => {
          this.changeCompany.emit();
        },
        (error) => {
          if (error && error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
    }
  }

  openImpersonateModal() {
    this.matDialog.open(CompanyImpersonateComponent, {
      data: {
        entityId: this.entity.id,
        service: this.service,
        entityType: this.entityType,
      },
    });
  }

  changeManagedState() {
    this.service
      .updateManagedState(this.entity.id, this.entity.is_managed)
      .subscribe((data) => {
        this.changeCompany.emit();
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
