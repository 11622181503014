import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserData } from "src/app/_models/user.model";
import { UsersService } from "src/app/_services/users.service";
import { Dictionary } from "../../../../_models/dictionary";
import { DictionariesService } from "../../../../_services/dictionaries.service";

@Component({
  selector: "app-add-user-step1",
  templateUrl: "./add-user-step1.component.html",
})
export class AddUserStep1Component implements OnInit, OnChanges {
  userFormGroup = new FormGroup({
    id: new FormControl<number>(null, Validators.required),
    email: new FormControl("", Validators.required),
    first_name: new FormControl("", Validators.required),
    last_name: new FormControl("", Validators.required),
    phone_number: new FormControl("", Validators.required),
    phone_prefix: new FormControl("", Validators.required),
    company_position: new FormControl("", Validators.required),
    role_id: new FormControl(null, Validators.required),
  });

  @Input() user: UserData;
  @Input() edit = false;
  @Input() isCanceled: boolean;
  @Input() loaderAnim: boolean;
  @Input() userType: string;

  isLoadingGeneral = {};
  phoneCode;
  phoneNumber;
  prefix;
  countries;
  departments: Dictionary[];
  phone_prefix;
  roles;
  constructor(
    private dictionariesService: DictionariesService,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.getDepartments();

    if (this.userType !== "expat") {
      this.getUserRoles();
    }
    this.patchForm();
    this.userFormGroup.valueChanges.subscribe((value) => {
      Object.assign(this.user, value);
    });
  }

  patchForm() {
    if (!this.user) {
      return;
    }

    this.userFormGroup.patchValue({
      id: this.user.id,
      email: this.user.email,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      phone_prefix: this.user.phone_prefix,
      phone_number: this.user.phone_prefix + this.user.phone_number,
      company_position: this.user.company_position,
      role_id: this.user.role_id,
    });
  }

  getUserRoles() {
    this.userService.getUserRoles(this.userType).subscribe((res) => {
      this.roles = res.result;
    });
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
        this.prefix = data.result;
        this.prefix.map((p) => {
          if (
            this.edit &&
            this.user.phone_prefix &&
            this.dictionariesService.getPhonePrefix(this.user.phone_prefix) ===
              this.dictionariesService.getPhonePrefix(p.phone_code)
          ) {
            p.phone_code =
              this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
            this.setPrefix(p);
            this.phone_prefix = p.phone_code;
          } else {
            p.phone_code =
              this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
          }
        });
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.isCanceled && changes.isCanceled.currentValue) {
      this.phoneCode = null;
      this.phoneNumber = null;
      this.phone_prefix = null;
    }
  }

  getDepartments() {
    this.dictionariesService.getDepartments().subscribe((data) => {
      this.departments = data.result;
    });
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setPrefix(e) {
    if (e) {
      const code = e.phone_code.match(/\((.*?)\)/);
      this.user.phone_prefix = code[1];
    }
  }

  onCountryChanged(event: any) {
    this.user.phone_prefix = `+${event.dialCode}`;
    this.userFormGroup.patchValue({
      phone_prefix: `+${event.dialCode}`,
    });
  }
}
