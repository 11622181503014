import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Client } from "../_models/client";
import { map } from "rxjs/operators";
import { UserData } from "../_models/user.model";
import { ClientInvite } from "../_models/client-invite";
import { EntityData } from "../modules/company/models/entity.data";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Directive()
@Injectable()
export class ClientsService {
  private clientsUrl = "entities/clients";

  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {}

  // GET Clients
  getClients(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.clientsUrl, {
      params: params,
    });
  }

  // GET Clients without pagination
  getAllClients(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/clients-without-pagination"
    );
  }

  // GET Client
  getClient(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.clientsUrl + "/" + id
    );
  }

  // GET Client history
  getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "history/client/" + id,
      { params: params }
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/client/status-transitions"
    );
  }

  // POST Create Client step 1
  createClientStep1(client: Partial<Client>): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.clientsUrl,
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Create Client step 2
  createClientStep2(client: Client): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.clientsUrl + "/" + client.id,
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // PUT Edit Account info
  updateAccountDetails(client): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          "entities/clients/user/" +
          client.entity_id,
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // PUT Edit Company info
  updateCompanyDetails(client: EntityData): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}${this.clientsUrl}/${client.id}`,
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Create User for client
  createUser(id, user: UserData): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.clientsUrl + `/${id}/addUser`,
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Change client status
  updateStatusBulk(status_id, client_ids, reason = null): Observable<any> {
    const body = { status_id: status_id, entity_ids: client_ids };
    if (reason) {
      body["reason"] = reason;
    }
    const response = this.http.patch<any>(
      environment.gateway_endpoint + "entities/clients",
      body,
      httpOptions
    );

    this.statusChange.emit();
    return response;
  }

  // POST provider sent invite
  inviteClient(clientInvite: ClientInvite): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "entities/clients/invite",
      clientInvite,
      httpOptions
    );
  }

  // Log in as specified client
  switchToAccount(account_info) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "switch-to-client",
        account_info,
        httpOptions
      )
      .pipe(
        map((data) => {
          if (data && data.result.token) {
            // serialize and encode session data to use on portal
            const marketplaceUrlPathArray = environment.marketplace.split("/");
            const protocol = marketplaceUrlPathArray[0];
            const host = marketplaceUrlPathArray[2];
            const goToMarketplaceUrl = protocol + "//" + host;
            const response = btoa(JSON.stringify(data.result));
            window.open(
              goToMarketplaceUrl + "/sso/?token=" + response,
              "_blank"
            );
            // window.location.href =
            //   goToMarketplaceUrl + '/sso/?token=' + response;
          }
        })
      );
  }

  /**
   * Get number of clients pending approval
   */
  getPendingCount(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.clientsUrl + "/pending/count",
      { params: { "low-priority": "low-priority" } }
    );
  }
}
