<app-loader-spinner-general
  [isLoadingGeneral]="isLoadingGeneral"
></app-loader-spinner-general>

<div class="dashboard-statistics-wrapper">
  <!-- orders overview -->
  <div class="sts-section">
    <div class="row sts-title-m-b">
      <div class="w-100">
        <div class="fl-l big-title">
          {{ "GENERALS.ORDERS-OVERVIEW" | translate }}
        </div>
      </div>
    </div>
    <div class="row sts-content-row-m-b">
      <div class="w-70 w-m-100 sts-w-m-m-b">
        <div class="dashboard-statistics-box">
          <div class="dashboard-statistics-box-header">
            <div class="statistics-tab-wrapper fl-l tab-l-t-r">
              <div
                (click)="
                  getOrdersPerMonthChart(
                    ordersPerMonthCurrency,
                    1,
                    ordersPerMonthInterval
                  )
                "
                class="tab-item"
                [ngClass]="{
                  'active-sts-tab': ordersPerMonthStatus === 1
                }"
              >
                {{ "GENERALS.PLACED-ORDERS" | translate }}
              </div>
              <div
                (click)="
                  getOrdersPerMonthChart(
                    ordersPerMonthCurrency,
                    7,
                    ordersPerMonthInterval
                  )
                "
                class="tab-item"
                [ngClass]="{
                  'active-sts-tab': ordersPerMonthStatus === 7
                }"
              >
                {{ "GENERALS.FINISHED-ORDERS" | translate }}
              </div>
            </div>

            <div class="dashboard-statistics-box-header-action-multi">
              <div class="statistics-tab-wrapper fl-l tab-r-t-l">
                <div
                  (click)="ordersPerMonthCurrency = 'EUR'"
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': ordersPerMonthCurrency === 'EUR'
                  }"
                >
                  &#8364;
                </div>
                <div
                  (click)="ordersPerMonthCurrency = 'USD'"
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': ordersPerMonthCurrency === 'USD'
                  }"
                >
                  &#36;
                </div>
                <div
                  (click)="ordersPerMonthCurrency = 'GBP'"
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': ordersPerMonthCurrency === 'GBP'
                  }"
                >
                  &#163;
                </div>
              </div>
              <div
                sts-calendar-helper
                class="relativ-v-align fl-l sts-calendar-wrapper"
              >
                <app-date-range-calendar
                  [showLabel]="false"
                  [placeholder]="'GENERALS.SELECT-DATE' | translate"
                  (change)="
                    getOrdersPerMonthChart(
                      ordersPerMonthCurrency,
                      ordersPerMonthStatus,
                      $event
                    )
                  "
                >
                </app-date-range-calendar>
              </div>
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div
              class="dashboard-statistics-chart-wrapper"
              *ngIf="ordersPerMonthData"
            >
              <ngx-charts-line-chart
                [scheme]="colorSchemeLineOrange"
                [results]="
                  ordersPerMonthData['currencies'][ordersPerMonthCurrency]
                "
                [xAxis]="true"
                [yAxis]="true"
                [legend]="false"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [curve]="curve"
              >
              </ngx-charts-line-chart>
            </div>
          </div>
        </div>
      </div>

      <!-- processing orders  + proccessing comissions -->
      <div class="w-30 w-m-100">
        <div class="row-m">
          <div class="w-m-50">
            <!-- processing orders -->
            <div
              class="dashboard-statistics-box sts-box-h-50 m-b-10 yellow-bg-gradient white"
            >
              <div class="dashboard-statistics-box-header yellow-bg-gradient">
                <div class="dashboard-statistics-box-title white">
                  {{ "GENERALS.PROCESSING-ORDERS" | translate }}
                </div>
              </div>

              <div class="clear"></div>

              <div class="dashboard-statistics-box-body sts-box-body-h-50">
                <ng-container *ngIf="ordersStatisticsStatus">
                  <ng-container
                    *ngFor="
                      let processingOrder of ordersStatisticsStatus.orders_by_status
                        | keyvalue;
                      let i = index;
                      let last = last
                    "
                  >
                    <div
                      *ngIf="$any(processingOrder.value).status_id === 2"
                      class="statistics-inner-box-wrapper sts-last-child"
                    >
                      <!-- status_id = 2 (In progress) -->
                      <div class="statistics-box-row">
                        <span class="ta-l fw-600 fs-20 fl-l">{{
                          $any(processingOrder.value).total
                        }}</span>
                        <span class="ta-r fl-r fw-500">
                          {{ "GENERALS.PROCESSING-ORDERS" | translate }}
                        </span>
                      </div>
                      <div class="statistics-box-row no-bord">
                        <div class="fl-l">
                          <ng-container
                            *ngFor="
                              let currencies of $any(processingOrder.value)
                                .currencies | keyvalue
                            "
                          >
                            <span class="ta-l fw-600">
                              <span class="fs-16">{{
                                $any(currencies.value).total
                              }}</span>
                              /
                              {{
                                $any(currencies.value).amount
                                  | currency : $any(currencies).key
                              }}</span
                            >
                          </ng-container>
                        </div>
                        <span class="ta-r fl-r fw-500">
                          {{ "GENERALS.TOTAL-VALUE" | translate }}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <!-- end processing orders -->
          </div>

          <div class="w-m-50">
            <!-- proccessing comissions -->
            <div
              class="dashboard-statistics-box sts-box-h-50 yellow-bg-gradient white"
            >
              <div class="dashboard-statistics-box-header yellow-bg-gradient">
                <div class="dashboard-statistics-box-title white">
                  {{ "GENERALS.PROGESSING-COMISSIONS" | translate }}
                </div>
              </div>

              <div class="clear"></div>

              <div class="dashboard-statistics-box-body sts-box-body-h-50">
                <div class="statistics-inner-box-wrapper">
                  <div class="statistics-box-row no-bord">
                    <span class="ta-l fw-600 fs-18 fl-l">0</span>
                    <span class="ta-r fl-r fw-500">
                      {{ "GENERALS.COMISSIONS-WAITING-APPROVAL" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- end proccessing comissions -->
          </div>
        </div>
      </div>
      <!-- end processing orders + proccessing comissions -->
    </div>

    <div class="row sts-content-row-m-b">
      <!-- Orders last 7 days -->
      <div class="w-25 w-m-50 sts-w-m-m-b">
        <div class="dashboard-statistics-box">
          <div
            *ngIf="boxMaskLoader"
            [ngClass]="{ 'statistics-box-mask': boxMaskLoader }"
          ></div>
          <app-loader-spinner-general
            [isLoadingGeneral]="boxMaskLoader"
          ></app-loader-spinner-general>
          <div class="dashboard-statistics-box-header">
            <div class="dashboard-statistics-box-title">
              {{ "GENERALS.ORDERS-LAST-SEVEN-DAYS" | translate }}
            </div>
            <div class="dashboard-statistics-box-header-action">
              <div class="statistics-tab-wrapper fl-r tab-r-t-l">
                <div
                  (click)="getOrdersByDay('EUR')"
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': selectedCurr === 'EUR'
                  }"
                >
                  &#8364;
                </div>
                <div
                  (click)="getOrdersByDay('USD')"
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': selectedCurr === 'USD'
                  }"
                >
                  &#36;
                </div>
                <div
                  (click)="getOrdersByDay('GBP')"
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': selectedCurr === 'GBP'
                  }"
                >
                  &#163;
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div class="statistics-inner-box-wrapper">
              <div class="statistics-box-row no-bord">
                <span class="ta-l fw-600 orange fl-l">
                  <span class="fs-20">{{ totalOrdersSumByDay }}</span>
                  /
                  {{
                    totalOrdersCurrencySumByDay | currency : selectedCurr
                  }}</span
                >
                <span class="ta-r gray fl-r fw-500">
                  {{ "GENERALS.TOT" | translate }}
                </span>
              </div>
            </div>

            <div class="statistics-inner-box-list-wrapper">
              <ng-container
                *ngFor="let item of ordersByDay | keyvalue; let i = index"
              >
                <ng-container
                  *ngFor="
                    let currencies of $any(item.value).currencies | keyvalue
                  "
                >
                  <ng-container *ngIf="currencies.key === selectedCurr">
                    <div class="statistics-box-row">
                      <span class="ta-l fw-600 fl-l">
                        <span class="fs-20">{{
                          $any(currencies.value).total
                        }}</span>
                        /
                        {{
                          $any(currencies.value).amount
                            | currency : $any(currencies).key
                        }}</span
                      >
                      <span class="ta-r gray fl-r fw-500">
                        {{ "GENERALS." + dayOfWekk[i] | uppercase | translate }}
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- End Orders last 7 days -->

      <!-- order value -->
      <div class="w-25 w-m-50 sts-w-m-m-b">
        <div class="dashboard-statistics-box">
          <div
            *ngIf="boxMaskLoaderOrderValue"
            [ngClass]="{
              'statistics-box-mask': boxMaskLoaderOrderValue
            }"
          ></div>
          <app-loader-spinner-general
            [isLoadingGeneral]="boxMaskLoaderOrderValue"
          ></app-loader-spinner-general>
          <div class="dashboard-statistics-box-header">
            <div class="dashboard-statistics-box-title">
              {{ "GENERALS.RECENT-ORDERS" | translate }}
            </div>

            <div class="dashboard-statistics-box-header-action">
              <div class="statistics-tab-wrapper fl-r tab-r-t-l">
                <div
                  (click)="
                    [getNoOrdersAccepted('EUR'), getNoOrdersWaiting('EUR')]
                  "
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': selectedCurrencyOrderValue === 'EUR'
                  }"
                >
                  &#8364;
                </div>
                <div
                  (click)="
                    [getNoOrdersAccepted('USD'), getNoOrdersWaiting('USD')]
                  "
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': selectedCurrencyOrderValue === 'USD'
                  }"
                >
                  &#36;
                </div>
                <div
                  (click)="
                    [getNoOrdersAccepted('GBP'), getNoOrdersWaiting('GBP')]
                  "
                  class="tab-item fs-16 l-h-no"
                  [ngClass]="{
                    'active-sts-tab': selectedCurrencyOrderValue === 'GBP'
                  }"
                >
                  &#163;
                </div>
              </div>
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div class="statistics-inner-box-wrapper">
              <ng-container
                *ngFor="let item of noOrdersAccepted | keyvalue; let i = index"
              >
                <ng-container *ngIf="item.key === selectedCurrencyOrderValue">
                  <div class="statistics-box-row">
                    <span class="ta-l fw-600 fs-20 fl-l">{{
                      $any(item.value).total_orders
                    }}</span>
                    <span class="ta-r orange fl-r fw-500">
                      {{ "GENERALS.ACCEPTED-ORDERS" | translate }}
                    </span>
                  </div>
                  <div class="statistics-box-row">
                    <span class="ta-l fw-600 fs-16 fl-l"
                      >{{ $any(item.value).total_percentage }} %</span
                    >
                    <span class="ta-r gray fl-r fw-500">
                      %
                      {{ "GENERALS.FROM-TOTAL" | translate }}
                    </span>
                  </div>
                  <div class="statistics-box-row no-bord">
                    <span class="ta-l fw-600 fs-16 fl-l"
                      >{{
                        $any(item.value).total_amount
                          | currency : $any(item.value).currency
                      }}
                    </span>
                    <span class="ta-r gray fl-r fw-500">
                      {{ "GENERALS.ORDER-VALUE" | translate }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <div class="statistics-inner-box-wrapper no-bord">
              <ng-container
                *ngFor="let item of noOrdersWaiting | keyvalue; let i = index"
              >
                <ng-container *ngIf="item.key === selectedCurrencyOrderValue">
                  <div class="statistics-box-row">
                    <span class="ta-l fw-600 fs-20 fl-l">{{
                      $any(item.value).total_orders
                    }}</span>
                    <span class="ta-r orange fl-r fw-500">
                      {{ "GENERALS.STILL-IN-TERM" | translate }}
                    </span>
                  </div>
                  <div class="statistics-box-row">
                    <span class="ta-l fw-600 fs-16 fl-l"
                      >{{ $any(item.value).total_percentage }} %</span
                    >
                    <span class="ta-r gray fl-r fw-500">
                      %
                      {{ "GENERALS.FROM-TOTAL" | translate }}
                    </span>
                  </div>
                  <div class="statistics-box-row no-bord">
                    <span class="ta-l fw-600 fs-16 fl-l"
                      >{{
                        $any(item.value).total_amount
                          | currency : $any(item.value).currency
                      }}
                    </span>
                    <span class="ta-r gray fl-r fw-500">
                      {{ "GENERALS.ORDER-VALUE" | translate }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- end order value -->

      <!-- canceled -->
      <div class="w-25 w-m-50">
        <div class="dashboard-statistics-box">
          <div class="dashboard-statistics-box-header">
            <div class="dashboard-statistics-box-title">
              {{ "GENERALS.CANCELED-ORDERS" | translate }}
            </div>
            <div class="dashboard-statistics-box-header-action">
              <div sts-calendar-helper class="sts-calendar-wrapper">
                <app-date-range-calendar
                  [showLabel]="false"
                  [placeholder]="'GENERALS.SELECT-DATE' | translate"
                  (change)="getCanceledOrdersComparison($event)"
                >
                </app-date-range-calendar>
              </div>
            </div>
          </div>

          <div class="clear"></div>

          <div
            class="dashboard-statistics-box-body"
            *ngIf="canceledOrdersComparison"
          >
            <ng-container
              *ngFor="
                let canceledOrder of canceledOrdersComparison
                  | keyvalue : keyDescOrder;
                let i = index;
                let last = last
              "
            >
              <div
                [ngClass]="{ 'sts-last-child': last }"
                class="statistics-inner-box-wrapper"
              >
                <div class="statistics-box-row">
                  <span class="ta-l fw-600 fs-20 fl-l">{{
                    $any(canceledOrder.value).total
                  }}</span>
                  <span class="ta-r orange fl-r fw-500">
                    {{ "GENERALS.CANCELED-ORDERS" | translate }}
                  </span>
                </div>
                <div class="statistics-box-row no-border">
                  <span class="ta-l fw-600 fs-16 fl-l"
                    >{{ $any(canceledOrder.value).total_percent }} %</span
                  >
                  <span class="ta-r gray fl-r fw-500">
                    %
                    {{ "GENERALS.FROM-TOTAL" | translate }}
                  </span>
                </div>
              </div>

              <div
                *ngIf="$any(canceledOrder.value).total"
                [ngClass]="{ 'sts-last-child': last }"
                class="statistics-compare-label-wrapper"
              >
                <div class="statistics-compare-label">
                  {{ "GENERALS.COMPARED-TO-LAST-YEAR" | translate }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- end canceled -->

      <!-- completed -->
      <div class="w-25 w-m-50">
        <div class="dashboard-statistics-box yellow-bg-gradient white">
          <div class="dashboard-statistics-box-header yellow-bg-gradient">
            <div class="dashboard-statistics-box-title white">
              {{ "GENERALS.COMPLETED-ORDERS" | translate }}
            </div>
            <div class="dashboard-statistics-box-header-action">
              <div
                sts-calendar-helper
                class="sts-calendar-wrapper calendar-white-icon"
              >
                <app-date-range-calendar
                  [showLabel]="false"
                  [placeholder]="'GENERALS.SELECT-DATE' | translate"
                  (change)="getOrdersStatisticsStatus($event)"
                >
                </app-date-range-calendar>
              </div>
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div class="dashboard-statistics-chart-wrapper">
              <ngx-charts-pie-chart
                [scheme]="colorSchemeDoughnutYellow"
                [results]="ordersFinalized"
                [legend]="false"
                [explodeSlices]="false"
                [labels]="false"
                [doughnut]="true"
                [arcWidth]="0.1"
              >
                <ng-template #tooltipTemplate let-model="model">
                  <p *ngIf="model.name === 'progress'">
                    {{ "GENERALS.COMPLETED" | translate }}:
                    {{ model.value }}
                  </p>
                  <p *ngIf="model.name === 'total'">
                    {{ "GENERALS.TOTAL" | translate }}:
                    {{ model.value }}
                  </p>
                </ng-template>
              </ngx-charts-pie-chart>

              <ng-container *ngIf="ordersStatisticsStatus">
                <ng-container
                  *ngFor="
                    let processingOrder of ordersStatisticsStatus.orders_by_status
                      | keyvalue;
                    let i = index
                  "
                >
                  <div
                    *ngIf="$any(processingOrder.value).status_id === 7"
                    class="statistics-inner-box-wrapper sts-last-child"
                  >
                    <!-- status_id = 7 (Finalized) -->
                    <div class="label-inside-doughnut-chart">
                      <span
                        >{{ $any(processingOrder.value).total_percent }}
                        %
                      </span>
                      <div>
                        {{ "GENERALS.NUMBER-OF-ORDERS" | translate }}
                      </div>
                      <span
                        >{{ $any(processingOrder.value).total }}
                        /
                        {{ ordersStatisticsStatus.total }}</span
                      >
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- end completed -->
    </div>
  </div>
  <!-- end orders overview -->

  <!-- services overview -->
  <div class="sts-section">
    <div class="row sts-title-m-b">
      <div class="w-100">
        <div class="fl-l big-title">
          {{ "GENERALS.SERVICES-OVERVIEW" | translate }}
        </div>
      </div>
    </div>

    <div class="row sts-content-row-m-b">
      <!-- services nr. -->
      <div class="w-25 w-m-50 sts-w-m-m-b">
        <div class="dashboard-statistics-box">
          <div class="dashboard-statistics-box-header">
            <div class="dashboard-statistics-box-title">
              {{ "GENERALS.SERVICES" | translate }}
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <ng-container *ngIf="offersByStatus">
              <ng-container
                *ngFor="let item of offersByStatus | keyvalue; let i = index"
              >
                <div
                  *ngIf="$any(item.value).status_id === 7"
                  class="statistics-inner-box-wrapper"
                >
                  <!-- status_id = 7 (Published & Inactive) -->
                  <div class="statistics-box-row no-bord">
                    <span class="ta-l fw-600 fs-20 orange fl-l">{{
                      $any(item.value).total
                    }}</span>
                    <span class="ta-r fl-r gray fw-500">
                      {{ "GENERALS.PUBLISHED" | translate }}
                    </span>
                  </div>
                </div>
              </ng-container>

              <div class="statistics-inner-box-list-wrapper">
                <ng-container
                  *ngFor="let item of offersByStatus | keyvalue; let i = index"
                >
                  <div
                    *ngIf="$any(item.value).status_id === 6"
                    class="statistics-box-row"
                  >
                    <!-- status_id = 6 (Published & Active) -->
                    <span class="ta-l fw-600 fs-16 fl-l">{{
                      $any(item.value).total
                    }}</span>
                    <span class="ta-r fl-r gray fw-500">
                      {{ "GENERALS.VISIBLE" | translate }}
                    </span>
                  </div>
                </ng-container>

                <div class="statistics-box-row">
                  <span class="ta-l fw-600 fs-16 fl-l">{{
                    inValidateServices
                  }}</span>
                  <span class="ta-r fl-r gray fw-500">
                    {{ "GENERALS.IN-VALIDATION" | translate }}
                  </span>
                </div>

                <ng-container
                  *ngFor="let item of offersByStatus | keyvalue; let i = index"
                >
                  <div
                    *ngIf="$any(item.value).status_id === 5"
                    class="statistics-box-row"
                  >
                    <!-- status_id = 5 (Rejected) -->
                    <span class="ta-l fw-600 fs-16 fl-l">{{
                      $any(item.value).total
                    }}</span>
                    <span class="ta-r fl-r gray fw-500">
                      {{ "GENERALS.REJECTED" | translate }}
                    </span>
                  </div>
                </ng-container>
                <div class="statistics-box-row">
                  <ng-container
                    *ngFor="let item of soldOffers | keyvalue; let i = index"
                  >
                    <ng-container *ngIf="item.key === 'total_sold_offers'">
                      <span class="ta-l fw-600 fs-16 fl-l">{{
                        $any(item.value)
                      }}</span>
                      <span class="ta-r fl-r gray fw-500">
                        {{ "GENERALS.SELLING" | translate }}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- end services nr. -->

      <!-- selling -->
      <div class="w-25 w-m-50 sts-w-m-m-b">
        <div class="dashboard-statistics-box">
          <div class="dashboard-statistics-box-header">
            <div class="dashboard-statistics-box-title">
              {{ "GENERALS.SOLD-SERVICES" | translate }}
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div *ngIf="soldOffers" class="statistics-inner-box-wrapper">
              <div class="statistics-box-row">
                <ng-container
                  *ngFor="let item of soldOffers | keyvalue; let i = index"
                >
                  <ng-container *ngIf="item.key === 'total_inactive'">
                    <span class="ta-l fw-600 fs-20 fl-l">{{
                      $any(item.value)
                    }}</span>
                    <span class="ta-r orange fl-r fw-500">
                      {{ "GENERALS.ACTIVE" | translate }}
                    </span>
                  </ng-container>
                </ng-container>
              </div>

              <div class="statistics-box-row no-bord">
                <ng-container
                  *ngFor="let item of soldOffers | keyvalue; let i = index"
                >
                  <ng-container *ngIf="item.key === 'inactive_sold_percent'">
                    <span class="ta-l fw-600 fs-16 fl-l"
                      >{{ $any(item.value) }} %</span
                    >
                    <span class="ta-r gray fl-r fw-500">
                      %
                      {{ "GENERALS.FROM-ACTIVE" | translate }}
                    </span>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div
              *ngIf="soldOffers"
              class="statistics-inner-box-wrapper sts-last-child"
            >
              <div class="statistics-box-row">
                <ng-container
                  *ngFor="let item of soldOffers | keyvalue; let i = index"
                >
                  <ng-container *ngIf="item.key === 'total_published'">
                    <span class="ta-l fw-600 fs-20 fl-l">{{
                      $any(item.value)
                    }}</span>
                    <span class="ta-r orange fl-r fw-500">
                      {{ "GENERALS.PUBLISHED" | translate }}
                    </span>
                  </ng-container>
                </ng-container>
              </div>

              <div class="statistics-box-row no-bord">
                <ng-container
                  *ngFor="let item of soldOffers | keyvalue; let i = index"
                >
                  <ng-container *ngIf="item.key === 'published_sold_percent'">
                    <span class="ta-l fw-600 fs-16 fl-l"
                      >{{ $any(item.value) }} %</span
                    >
                    <span class="ta-r gray fl-r fw-500">
                      %
                      {{ "GENERALS.FROM-PUBLISHED" | translate }}
                    </span>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end selling -->
    </div>
  </div>
  <!-- end services overview -->

  <!-- Provider overview -->
  <div class="sts-section">
    <div class="row sts-title-m-b">
      <div class="w-100">
        <div class="fl-l big-title">
          {{ "GENERALS.ACCOUNTS-OVERVIEW" | translate }}
        </div>
      </div>
    </div>

    <div class="row sts-content-row-m-b">
      <!-- top provider -->
      <div class="w-70 w-m-100 sts-w-m-m-b">
        <div class="statistics-table">
          <div class="table-header-action-wrapper">
            <div class="dashboard-statistics-box-header">
              <div class="dashboard-statistics-box-title">
                {{ "GENERALS.TOP-PROVIDERS" | translate }}
              </div>
              <div class="dashboard-statistics-box-header-action-multi">
                <div class="statistics-tab-wrapper fl-l tab-r-t-l">
                  <div
                    (click)="
                      getTopFiveProviders('1', selectedDateOrderOverview)
                    "
                    class="tab-item"
                    [ngClass]="{
                      'active-sts-tab': selectedStatusTopProviders === '1'
                    }"
                  >
                    {{ "GENERALS.PLACED-ORDERS" | translate }}
                  </div>
                  <div
                    (click)="
                      getTopFiveProviders('7', selectedDateOrderOverview)
                    "
                    class="tab-item"
                    [ngClass]="{
                      'active-sts-tab': selectedStatusTopProviders === '7'
                    }"
                  >
                    {{ "GENERALS.FINISHED-ORDERS" | translate }}
                  </div>
                </div>
                <div
                  sts-calendar-helper
                  class="relativ-v-align fl-l sts-calendar-wrapper"
                >
                  <!-- <app-statistics-date-filter>

                                </app-statistics-date-filter> -->

                  <app-date-range-calendar
                    [showLabel]="false"
                    [placeholder]="'GENERALS.SELECT-DATE' | translate"
                    (change)="
                      getTopFiveProviders(selectedStatusTopProviders, $event)
                    "
                  >
                  </app-date-range-calendar>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive sts-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>{{ "GENERALS.ID" | translate }}</th>
                  <th>
                    {{ "GENERALS.PROVIDER-NAME" | translate }}
                  </th>
                  <th>
                    {{ "GENERALS.NR-OF-ORDERS" | translate }}
                  </th>
                  <th>
                    {{ "GENERALS.TOTAL-REVENUE" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of topFiveProviders; let i = index">
                  <td class="orange fw-500">{{ item.id }}</td>
                  <td class="gray">
                    {{ item.provider_name }}
                  </td>
                  <td class="gray">
                    {{ item.no_of_orders }}
                  </td>
                  <td class="gray">
                    {{ item.total_revenue | currency : item.currency }}
                  </td>
                </tr>

                <tr *ngIf="topFiveProviders?.length === 0">
                  <td colspan="4" class="gray">
                    {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end top provider -->

      <!-- provider leads + client leads -->
      <div class="w-30 w-m-100">
        <div class="row-m">
          <div class="w-m-50">
            <!-- provider leads -->
            <div class="dashboard-statistics-box sts-box-h-50 m-b-10">
              <div class="dashboard-statistics-box-header">
                <div class="dashboard-statistics-box-title">
                  {{ "GENERALS.PROVIDER-LEADS" | translate }}
                </div>
              </div>

              <div class="clear"></div>

              <div class="dashboard-statistics-box-body sts-box-body-h-50">
                <div class="statistics-inner-box-wrapper">
                  <div class="statistics-box-row no-bord">
                    <span class="ta-l fw-600 fs-20 orange fl-l">{{
                      providerLeads?.totals
                    }}</span>
                    <span class="ta-r gray fl-r fw-500">
                      {{ "GENERALS.TOTAL" | translate }}
                    </span>
                  </div>
                </div>

                <div
                  *ngIf="providerLeads && providerLeads.series"
                  class="statistics-inner-box-list-wrapper"
                >
                  <ng-container *ngFor="let item of providerLeads.series">
                    <div class="statistics-box-row">
                      <span class="ta-l fw-600 fs-16 fl-l">{{
                        $any(item.value)
                      }}</span>
                      <span class="ta-r gray fl-r fw-500">
                        {{ item.month | translate }}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <!-- end provider leads -->
          </div>

          <div class="w-m-50">
            <!-- client leads -->

            <div class="dashboard-statistics-box sts-box-h-50">
              <div class="dashboard-statistics-box-header">
                <div class="dashboard-statistics-box-title">
                  {{ "GENERALS.CLIENT-LEADS" | translate }}
                </div>
              </div>

              <div class="clear"></div>

              <div class="dashboard-statistics-box-body sts-box-body-h-50">
                <div class="statistics-inner-box-wrapper">
                  <div class="statistics-box-row no-bord">
                    <span class="ta-l orange fw-600 fs-20 fl-l">{{
                      clientLeads?.totals
                    }}</span>
                    <span class="ta-r gray fl-r fw-500">
                      {{ "GENERALS.TOTAL" | translate }}
                    </span>
                  </div>
                </div>

                <div
                  *ngIf="clientLeads && clientLeads.series"
                  class="statistics-inner-box-list-wrapper"
                >
                  <ng-container *ngFor="let item of clientLeads.series">
                    <div class="statistics-box-row">
                      <span class="ta-l fw-600 fs-16 fl-l">{{
                        $any(item.value)
                      }}</span>
                      <span class="ta-r gray fl-r fw-500">
                        {{ item.month | translate }}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <!-- end client leads -->
          </div>
        </div>
      </div>
      <!-- end provider leads + client leads -->
    </div>

    <div class="row sts-content-row-m-b">
      <!-- Provider nr. -->
      <div class="w-25 w-m-50 sts-w-m-m-b">
        <div class="dashboard-statistics-box">
          <div class="dashboard-statistics-box-header">
            <div class="dashboard-statistics-box-title">
              {{ "GENERALS.PROVIDERS" | translate }}
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div class="statistics-inner-box-wrapper">
              <div class="statistics-box-row no-bord">
                <span class="ta-l fw-600 orange fs-20 fl-l">{{
                  totalProviderByStatus
                }}</span>
                <span class="ta-r gray fl-r fw-500">
                  {{ "GENERALS.TOTAL" | translate }}
                </span>
              </div>
            </div>

            <div class="statistics-inner-box-list-wrapper">
              <ng-container
                *ngFor="
                  let item of providerByStatus | keyvalue;
                  let i = index;
                  let last = last
                "
              >
                <div class="statistics-box-row">
                  <span class="ta-l fw-600 fs-16 fl-l">{{
                    $any(item.value).total
                  }}</span>
                  <span class="ta-r gray fl-r fw-500">
                    {{ $any(item.value).status_label }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- end Provider nr. -->

      <!-- Client nr. -->
      <div class="w-25 w-m-50 sts-w-m-m-b">
        <div class="dashboard-statistics-box">
          <div class="dashboard-statistics-box-header">
            <div class="dashboard-statistics-box-title">
              {{ "GENERALS.CLIENTS" | translate }}
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div class="statistics-inner-box-wrapper">
              <div class="statistics-box-row no-bord">
                <span class="ta-l fw-600 fs-20 orange fl-l">{{
                  totalClientByStatus
                }}</span>
                <span class="ta-r gray fl-r fw-500">
                  {{ "GENERALS.TOTAL" | translate }}
                </span>
              </div>
            </div>

            <div class="statistics-inner-box-list-wrapper">
              <ng-container
                *ngFor="
                  let item of clientByStatus | keyvalue;
                  let i = index;
                  let last = last
                "
              >
                <div class="statistics-box-row">
                  <span class="ta-l fw-600 fs-16 fl-l">{{
                    $any(item.value).total
                  }}</span>
                  <span class="ta-r gray fl-r fw-500">
                    {{ $any(item.value).status_label }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- end Client nr. -->

      <!-- Pending commissions -->

      <div class="w-25 w-m-50">
        <div class="dashboard-statistics-box orange-bg-gradient white">
          <div class="dashboard-statistics-box-header orange-bg-gradient">
            <div class="dashboard-statistics-box-title white">
              {{ "GENERALS.PENDING-COMMISSIONS" | translate }}
            </div>
          </div>

          <div class="clear"></div>

          <div class="dashboard-statistics-box-body">
            <div class="dashboard-statistics-chart-wrapper">
              <ngx-charts-pie-chart
                [scheme]="colorSchemeDoughnutOrange"
                [results]="single"
                [legend]="false"
                [explodeSlices]="false"
                [labels]="false"
                [doughnut]="true"
                [arcWidth]="0.1"
              >
                <ng-template #tooltipTemplate let-model="model">
                  <p *ngIf="model.name === 'name1'">
                    {{ "GENERALS.IN-PROGRESS" | translate }}: 0
                  </p>
                  <p *ngIf="model.name === 'name2'">
                    {{ "GENERALS.TOTAL-ORDERS-CHART" | translate }}: 0
                  </p>
                </ng-template>
              </ngx-charts-pie-chart>

              <div class="label-inside-doughnut-chart">
                <span>0</span>
                <div>out of</div>
                <span>0</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Pending commissions -->
    </div>
  </div>
  <!-- end Provider overview -->
</div>
