import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import cloneDeep from "lodash-es/cloneDeep";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  Commission,
  ProviderInvoiceDueDays,
  ProvidersCommissions,
} from "src/app/_models/providerscommissions";
import { ServiceCategory } from "src/app/_models/servicecategory";
import { AlertService } from "@modules/alert";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { ServiceCategoriesService } from "src/app/_services/service-categories.service";
import { Provider } from "../../../../_models/provider";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-provider-categories",
  templateUrl: "./provider-categories.component.html",
  styleUrls: ["./provider-categories.component.css"],
})
export class ProviderCategoriesComponent implements OnInit, OnDestroy {
  id: number;
  categories: ServiceCategory[];
  selectedCountries;
  allCategories: ServiceCategory[];
  isLoadingGeneral: boolean;
  public provider: Provider;
  countries;
  categorySelection = {};
  private _onDestroy = new Subject<void>();

  selectedCommission;
  selectedCategory;
  edit = false;
  type;
  commission$;
  countries$;

  constructor(
    private providersService: ProvidersService,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private dictionaryService: DictionariesService,
    private categoryService: ServiceCategoriesService,
    private alertService: AlertService,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.id = this.route.snapshot.parent.params.id;
    this.selectedCommission = new Commission();
  }

  ngOnInit() {
    this.getProviderCategories();
    this.getProvider();
    this.getServiceCategories();
    this.getCountryList();
  }

  getCountryList() {
    this.dictionaryService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  getServiceCategories() {
    const params = { status: "active" };
    this.isLoadingGeneral = true;

    this.categoryService
      .getServiceCategories(params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.allCategories = data.result.items;
        this.isLoadingGeneral = false;
      });
  }

  getProviderCategories() {
    this.isLoadingGeneral = true;
    this.providersService.getProviderCategories(this.id).subscribe((data) => {
      this.categories = data.result;
      this.isLoadingGeneral = false;
    });
  }

  getProvider() {
    this.providersService.getProvider(this.id).subscribe((data) => {
      this.provider = data.result;
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForProvider(
          this.provider,
          "Service categories & commissions"
        )
      );
      if (this.provider.countries.length) {
        this.selectedCountries = [];
        this.provider.countries.forEach((c) => {
          this.selectedCountries.push(c.name.toString().toUpperCase());
        });
      } else {
        this.selectedCountries = [];
      }
    });
  }

  setEditOptions(comm) {
    this.initEmpty();
    this.selectedCommission = cloneDeep(comm);
    this.selectedCommission.commission_type_id = comm.commission_type.id;
  }

  initEmpty() {
    this.selectedCommission = new Commission();
  }

  showCommission(_commission) {
    const path = this.selectedCategory.path;
    const segments = path.split("/");
    let lastCategory = this.categories[segments[0]];
    segments.splice(0, 1);
    segments.forEach((p) => {
      lastCategory = lastCategory.children[p];
    });

    if (Array.isArray(_commission)) {
      //set commission for each child
      _commission = _commission[0];
      this.setTreeCategoriesCommission(lastCategory, _commission);
    } else if (_commission.id) {
      lastCategory["provider_commissions"].push(_commission);
    } else if (this.selectedCommission.id) {
      const idx = lastCategory["provider_commissions"].findIndex(
        (f) => f.id === this.selectedCommission.id
      );
      if (idx > -1) {
        lastCategory["provider_commissions"][idx] = this.selectedCommission;
      }
    } else {
      this.alertService.success(
        this.translate.instant("COMMISSION.COMMISSION_REQUEST_SENT")
      );
    }

    this.initEmpty();
    this.ngxSmartModalService.close("editCommissions");
  }

  setTreeCategoriesCommission(lastCategory, _commission) {
    for (const child_name of Object.keys(lastCategory.children)) {
      if (lastCategory.children[child_name].children_count == 0) {
        lastCategory.children[child_name]["provider_commissions"].push(
          _commission
        );
      } else {
        this.setTreeCategoriesCommission(
          lastCategory.children[child_name],
          _commission
        );
      }
    }
  }

  deleteCommission(id, path) {
    this.isLoadingGeneral = true;

    this.providersService.deleteCommission(id).subscribe(
      (res) => {
        const segments = path.split("/");
        let lastCategory = this.categories[segments[0]];
        segments.splice(0, 1);
        segments.forEach((p) => {
          lastCategory = lastCategory.children[p];
        });
        const idx = lastCategory["provider_commissions"].findIndex(
          (f) => f.id === id
        );
        if (idx > -1) {
          lastCategory["provider_commissions"].splice(idx, 1);
        }
        this.initEmpty();

        this.isLoadingGeneral = false;
      },
      (error) => {
        if (error.error) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoadingGeneral = false;
      }
    );
  }

  removeCategory(category) {
    if (!confirm(this.translate.instant("CONFIRMATIONS.REMOVE_CATEGORY"))) {
      return;
    }

    this.isLoadingGeneral = true;
    this.providersService
      .removeCategory(category.id, this.provider.id)
      .subscribe(
        (res) => {
          this.categories = this.deleteCategoryFromTree(
            this.categories,
            category.id
          );
          this.isLoadingGeneral = false;
        },
        (error) => {
          if (error.error) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoadingGeneral = false;
        }
      );
  }

  private deleteCategoryFromTree(categories, categoryId) {
    for (const [key, category] of Object.entries(categories)) {
      if (category["id"] === categoryId) {
        delete categories[key];

        return categories;
      }

      if (category["children_count"] > 0) {
        categories[key]["children"] = this.deleteCategoryFromTree(
          categories[key]["children"],
          categoryId
        );
      }
    }

    return categories;
  }

  handleEvent(e) {
    this.selectedCategory = e.category;
    this.type = e.type;
    switch (e.type) {
      case "update":
        this.setEditOptions(e.commission);
        this.selectedCategory = e.category;
        this.ngxSmartModalService.getModal("editCommissions").open();
        break;
      case "add":
        this.selectedCategory = e.category;
        this.ngxSmartModalService.getModal("editCommissions").open();
        break;
      case "delete":
        this.deleteCommission(e.commission.id, e.path);
        break;
      case "remove-category":
        this.removeCategory(e.category);
        break;
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  updateCategorySelection(data) {
    if (data.id) {
      if (this.categorySelection[data.id]) {
        const idx = this.categorySelection[data.id].findIndex(
          (f) => f.id === data.selection.id
        );
        if (idx > -1 && !data.update) {
          this.categorySelection[data.id].splice(idx, 1);
        } else if (data.update) {
          this.categorySelection[data.id][idx] = data.selection;
        } else {
          this.categorySelection[data.id].push(data.selection);
        }
      } else {
        this.categorySelection[data.id] = [];
        this.categorySelection[data.id].push(data.selection);
      }
      if (
        this.categorySelection[data.id] &&
        this.categorySelection[data.id].length === 0
      ) {
        delete this.categorySelection[data.id];
      }
    }
  }

  setCountriesAndCategories(param?) {
    const commissions = new ProvidersCommissions();
    const invoiceDueDays = new ProviderInvoiceDueDays();
    commissions.provider_id = this.id;
    commissions.categories = [];

    invoiceDueDays.invoice_due_days = this.provider.invoice_due_days;
    invoiceDueDays.provider_id = this.id;

    this.providersService
      .setProviderInvoiceDueDays(invoiceDueDays, this.id)
      .subscribe({
        next: (res) => {
          this.isLoadingGeneral = false;
        },
        error: (error) => {
          if (error.error) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoadingGeneral = false;
        },
      });

    const keys = Object.keys(this.categorySelection);
    keys.forEach((item) => {
      this.categorySelection[item].forEach((categ) => {
        commissions.categories.push({
          id: categ.id,
          commissions: "",
        });
      });
    });

    let countryCodes;

    if (this.selectedCountries) {
      countryCodes = this.countries.filter((f) =>
        this.selectedCountries.find((name) => name === f.name)
      );
      countryCodes = countryCodes.map((f) => f.country_code);
    } else {
      countryCodes = [];
    }

    if (Object.keys(this.categorySelection).length > 0) {
      this.commission$ = this.providersService.updateCategory(
        commissions,
        this.id
      );
      this.countries$ = this.providersService.setCountryProviderStep3(
        { countries: countryCodes },
        this.id
      );

      combineLatest(this.commission$, this.countries$).subscribe(
        ([comm, country]) => {
          this.edit = false;
          this.initEmpty();
          this.getProviderCategories();
          this.getProvider();
        },
        (error) => {
          if (error.error) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
    } else {
      this.providersService
        .setCountryProviderStep3({ countries: countryCodes }, this.id)
        .subscribe();
      this.edit = false;
      this.initEmpty();
      this.getProviderCategories();
      this.getProvider();
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
