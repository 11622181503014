import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { UserData } from "../../../_models/user.model";
import { AuthenticationService } from "../../../_services/authentication.service";
import { MenuBadgesService } from "../../../_services/menu-badges.service";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "../../../modules/shared/_enums/permission-resource.enum";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AccountService } from "@api/account";

@Component({
  selector: "app-left-navigation",
  templateUrl: "./left-navigation.component.html",
  styleUrls: ["./left-navigation.component.css"],
})
export class LeftNavigationComponent implements OnInit {
  public resource = PermissionResourceEnum;
  public subResource = PermissionSecondaryResourceEnum;
  public action = PermissionActionEnum;
  public user$: Observable<UserData>;
  public providersBadge: 0;
  public clientsBadge: 0;
  public expatsBadge: 0;
  public offersBadge: 0;
  public commissionsBadge: 0;
  urlMarketplace: string;

  constructor(
    public router: Router,
    protected store: Store,
    public authService: AuthenticationService,
    protected accountService: AccountService,
    private badgesService: MenuBadgesService
  ) {
    this.urlMarketplace = environment.marketplace;
  }

  ngOnInit() {
    this.user$ = this.accountService.account$;

    this.badgesService.providersChanged.subscribe((badge) => {
      this.providersBadge = badge;
    });
    this.badgesService.clientsChanged.subscribe((badge) => {
      this.clientsBadge = badge;
    });
    this.badgesService.expatsChanged.subscribe((badge) => {
      this.expatsBadge = badge;
    });
    this.badgesService.offersChanged.subscribe((badge) => {
      this.offersBadge = badge;
    });
    this.badgesService.commissionsChanged.subscribe((badge) => {
      this.commissionsBadge = badge;
    });

    this.badgesService.getAllCounters();
  }

  goToBlog() {
    // window.open(environment.marketplace_url + 'blog');
    window.open("https://xpath.zendesk.com/hc/en-us");
  }

  goToPlans() {
    window.open(environment.stripe_plans_url);
  }

  goToPayments() {
    window.open(environment.stripe_payments_url);
  }
}
