<div class="details-2c-left" *ngIf="user">
  <div class="small-box">
    <div
      *ngIf="userType !== 'expat'"
      class="small-box-header-img"
      (click)="openDocModal()"
    >
      <img
        [src]="
          user.primary_image_path
            ? user.primary_image_path
            : '../assets/images/profile-pics-placeholder.jpg'
        "
        alt="img"
        class="profile-pic"
      />
      <div class="edit-trigger">
        {{ "GENERALS.CHANGE-PIC" | translate }}
      </div>
    </div>
    <div
      *ngIf="userType === 'expat'"
      class="small-box-header-img"
      (click)="openDocModal()"
    >
      <img
        [src]="
          user.user.primary_image_path
            ? user.user.primary_image_path
            : '../assets/images/profile-pics-placeholder.jpg'
        "
        alt="img"
        class="profile-pic"
      />
      <div class="edit-trigger">
        {{ "GENERALS.CHANGE-PIC" | translate }}
      </div>
    </div>

    <div class="small-box-details">
      <div *ngIf="userType !== 'expat' && user.users" class="medium-title">
        {{ user.users[0].name }}
      </div>

      <div *ngIf="userType === 'expat'" class="medium-title">
        {{ user.user.name }}
      </div>

      <div class="rating" *ngIf="userType.toLowerCase() === 'provider'">
        <app-rating [currentRate]="user.calculatedRating"></app-rating>
        <span class="review-nr"
          >({{ user.calculatedRating ? user.calculatedRating : "0" }})
          {{ "GENERALS.RATING" | translate }}</span
        >
      </div>

      <div *ngIf="userType !== 'expat'" class="small-box-status">
        <div class="entity_status-label-{{ user.status_id }}">
          {{ user.status_name }}
        </div>
      </div>

      <div *ngIf="userType === 'expat'" class="small-box-status">
        <div class="entity_status-label-{{ user.status_id }}">
          {{ user.user.status_name }}
        </div>
      </div>

      <div
        class="fw-500"
        *ngIf="(user.status_reason || '') && userType !== 'expat'"
      >
        <span class="gray block-span"
          >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
        >
        {{ user.status_reason }}
      </div>

      <div
        class="fw-500"
        *ngIf="(user.status_reason || '') && userType === 'expat'"
      >
        <span class="gray block-span"
          >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
        >
        {{ user.user.status_reason }}
      </div>

      <div *ngIf="userType !== 'expat'" class="fw-500">
        <span class="gray block-span"
          >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
        >
        {{ user.created_at | date : "dd/MM/yyyy" }}
      </div>

      <div *ngIf="userType === 'expat'" class="fw-500">
        <span class="gray block-span"
          >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
        >
        {{ user.user.created_at | date : "dd/MM/yyyy" }}
      </div>

      <div *ngIf="userType === 'provider'" class="fw-500 inline">
        <label for="managed" class="pr-10">{{
          "GENERALS.MANAGED_BY_XPATH" | translate
        }}</label>
        <input
          id="managed"
          class="checkbox-switch"
          [(ngModel)]="user.is_managed"
          (change)="changeManagedState()"
          type="checkbox"
          name="enabled"
        />
      </div>
    </div>
    <div class="small-box-list">
      <ul>
        <ng-container *ngFor="let option of statusOptions">
          <li>
            <div
              (click)="openConfirmAction(option)"
              class="btn-small orange-bg"
            >
              {{ option.text }}
            </div>
          </li>
        </ng-container>
        <li class="action-link-gray" (click)="openModal()">
          <i class="icon-right-dir"></i>
          {{ "GENERALS.LOG-IN-AS" | translate }}
          {{ userType }}
        </li>
      </ul>
    </div>
  </div>
</div>

<ngx-smart-modal id="switchAccount" #switchAccount identifier="switchAccount">
  <div class="custom-modal-body">
    <div class="modal-form">
      <div class="row">
        <div class="form-group w-100">
          <label>{{ "LABEL.ENTER-ACCOUNT-PASS" | translate }}:</label>
          <input
            type="password"
            name="password"
            class="form-control"
            placeholder="{{ 'LABEL.PASSWORD' | translate }}"
            [(ngModel)]="password"
            required
          />
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="modal-footer">
    <button type="button" class="btn gray-bg" (click)="cancelModal()">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button class="btn orange-bg" (click)="switchToAccount()">
      {{ "GENERALS.PROCEED" | translate }}
    </button>
  </div>
</ngx-smart-modal>

<app-add-profile-picture
  [entityType]="userType"
  [entityId]="user?.id"
  [title]="'GENERALS.UPLOAD-PIC'"
  (changePicture)="handleEvent($event)"
></app-add-profile-picture>
