import { Component, OnInit } from "@angular/core";
import { ServiceOffersService } from "src/app/_services/service-offers.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@modules/alert";
import { environment } from "src/environments/environment";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-reviews-offers",
  templateUrl: "./reviews-offers.component.html",
  styleUrls: ["./reviews-offers.component.css"],
})
export class ReviewsOffersComponent implements OnInit {
  id;
  offersReviews;
  isLoadingGeneral;
  showCommentId = [];
  numOfReviews = 0;
  itemsPage: number;
  p: number;
  offer;
  characteristics = [];
  keys;
  bundle;
  active = 6; // offer status_id
  inactive = 7;
  actions;
  constructor(
    private serviceOffers: ServiceOffersService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private alertService: AlertService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getServiceOfferById(params.id);
        this.getStatusTransitions();
      }
    });
  }

  ngOnInit() {
    this.getOffersReviews(1);
  }

  public getStatusTransitions() {
    this.serviceOffers.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }
  copyOffer(offerId) {
    this.serviceOffers.copyOffer(offerId).subscribe(
      (data) => {
        this.isLoadingGeneral = false;
        let url = "";
        url = "/service-offers/" + data.result.id + "/edit";
        this.router.navigate([url], {
          queryParams: { ...{ copy: 1 } },
        });
      },
      (error) => {
        this.isLoadingGeneral = false;
      }
    );
  }

  getOffersReviews(page: number) {
    const params = {};

    params["page"] = page;

    this.serviceOffers.getOffersReviews(this.id, params).subscribe(
      (data) => {
        this.offersReviews = data.result;
        // this.showCommentId = [];
        this.numOfReviews = data.result.total;
        this.itemsPage = data.result.perPage;
        this.p = data.result.currentPage;
      },
      (error) => {
        this.numOfReviews = 0;
      }
    );
  }

  approveOfferReview(id) {
    this.serviceOffers.approveOfferReview(id).subscribe((data) => {
      this.getOffersReviews(1);
    });
  }

  deleteOfferReview(id) {
    this.serviceOffers.deleteOfferReview(id).subscribe((data) => {
      this.getOffersReviews(1);
    });
  }

  setCharacteristics() {
    this.offer.characteristics.forEach((ch) => {
      const objFields = JSON.parse(ch.data);
      this.characteristics = Object.assign({}, this.characteristics, objFields);
    });
    this.keys = Object.keys(this.characteristics);
    this.isLoadingGeneral = false;
  }

  getServiceOfferById(id) {
    this.isLoadingGeneral = true;
    this.serviceOffers.getServiceOfferById(id).subscribe((data) => {
      this.offer = data.result;
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForOffer(this.offer, "reviews")
      );
      if (data.result.bundle_id) {
        this.serviceOffers
          .getBundleServiceOffersById(data.result.bundle_id)
          .subscribe((res) => {
            this.bundle = res.result;
            this.isLoadingGeneral = false;
          });
      }

      if (data.result.characteristics && data.result.characteristics.length) {
        this.setCharacteristics();
      } else {
        this.isLoadingGeneral = false;
      }
      this.isLoadingGeneral = false;
    });
  }
  goToMarketplaceOffer(id) {
    const marketplaceUrlPathArray = environment.marketplace.split("/");
    const protocol = marketplaceUrlPathArray[0];
    const host = marketplaceUrlPathArray[2];
    const goToMarketplaceUrl = protocol + "//" + host;
    const goToOfferUrl = goToMarketplaceUrl + "/offers/" + id;

    window.open(goToOfferUrl, "_blank");
  }

  updateStatus() {
    if (this.offer.active === true) {
      this.openConfirmAction({ id: 6 }); // published and active
    } else {
      this.openConfirmAction({ id: 7 }); // published and inactive
    }
  }

  openConfirmAction(action) {
    const ids = [this.id];

    if (!action.needs_confirmation && !action.needs_reason && action.text) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.serviceOffers.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.getServiceOfferById(this.id);
              this.translate
                .get("GENERALS.STATUS-CHANGED")
                .subscribe((statusChangedTranslation) => {
                  this.alertService.success(statusChangedTranslation);
                });
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.serviceOffers.updateStatusBulk(action.id, ids, null).subscribe(
        (data) => {
          this.getServiceOfferById(this.id);
          this.translate
            .get("GENERALS.STATUS-CHANGED")
            .subscribe((statusChangedTranslation) => {
              this.alertService.success(statusChangedTranslation);
            });
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
