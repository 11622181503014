import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Price } from "src/app/_models/service-offer";
import { AlertService } from "@modules/alert";
import { ServiceOffersService } from "../../../../../_services/service-offers.service";
import { DateRangeInterface } from "src/app/_interfaces/date-range.interface";

@Component({
  selector: "app-add-price",
  templateUrl: "./add-price.component.html",
})
export class AddPriceComponent {
  setModal: boolean;
  price: Price;
  clear = false;
  dateRangeMultipleMonths: DateRangeInterface = {
    start_date: null,
    end_date: null,
  };
  @Input() currency_code;
  @Input() salePrice;
  @Output() saved: EventEmitter<any> = new EventEmitter();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public serviceOffersService: ServiceOffersService,
    private alertService: AlertService
  ) {
    this.setModal = false;
    this.price = new Price();
  }

  add() {
    this.saved.emit(this.price);
    this.cancelModal();
  }

  setDate(e) {
    if (e.start_date && e.end_date) {
      this.price.starts_on = e.start_date;
      this.price.ends_on = e.end_date;
      this.dateRangeMultipleMonths = {
        start_date: e.start_date,
        end_date: e.end_date,
      };
    }
  }

  isModalOpen() {
    this.setModal = true;
  }

  isModalClose() {
    this.initEmpty();
    this.setModal = false;
  }
  cancelModal() {
    this.initEmpty();
    this.ngxSmartModalService.getModal("addPrice").close();
  }

  initEmpty(addAnother = false): void {
    this.price = new Price();
    this.clear = true;
    this.dateRangeMultipleMonths = null;
  }

  enableSaveButton() {
    return Object.keys(this.price).length < 4;
  }
}
