<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div *ngIf="hideTitle" class="fl-l big-title">
    {{ "LEFT-NAVIGATION.NAV3" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r" *ngIf="auth.$canCreate(resources.SERVICE)">
    <div class="btn white-bg" (click)="export()">
      <i class="icon-download"></i> {{ "GENERALS.EXPORT" | translate }}
    </div>
    <div class="btn white-bg" *ngIf="provider_id" (click)="downloadExcel()">
      <i class="icon-download"></i>
      {{ "GENERALS.DOWNLOAD-EXCEL" | translate }}
    </div>

    <div
      class="btn white-bg"
      [routerLink]="['/service-offers/new']"
      [queryParams]="{ provider_id: provider_id }"
    >
      <i class="icon-plus-1"></i> {{ "GENERALS.ADD" | translate }}
    </div>
    <div
      class="btn white-bg"
      [routerLink]="['/service-offers/bundle']"
      [queryParams]="{ provider_id: provider_id }"
    >
      <i class="icon-plus-1"></i>
      {{ "GENERALS.ADD-BUNDLE" | translate }}
    </div>

    <div class="btn white-bg" (click)="openUploadModal()">
      <i class="icon-upload-cloud"></i>
      {{ "GENERALS.BULK-UPLOAD-OFFERS" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    >
    </app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="openConfirmAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <ng-container matColumnDef="select">
              <th>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th>{{ "GENERALS.ID" | translate }}</th>
            <th mat-sort-header="title">
              {{ "GENERALS.SERVICE-TITLE" | translate }}
            </th>
            <th>{{ "GENERALS.COMPANY" | translate }}</th>
            <th>{{ "GENERALS.SALEPRICE" | translate }}</th>
            <th mat-sort-header="country">
              {{ "GENERALS.COUNTRY" | translate }}
            </th>
            <th mat-sort-header="city">
              {{ "GENERALS.CITY" | translate }}
            </th>
            <th>{{ "GENERALS.WORKFLOW" | translate }}</th>
            <th>{{ "GENERALS.RATING" | translate }}</th>
            <th>{{ "GENERALS.STATUS" | translate }}</th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let serviceOffer of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? changeSelection(serviceOffer) : null"
                [checked]="selection.isSelected(serviceOffer)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>
            <td class="gray-pointer">
              <a
                [routerLink]="['/service-offers', serviceOffer.id]"
                target="_blank"
                class="action-link-gray"
                [href]="'/service-offers' + serviceOffer.id"
                >{{ serviceOffer.id }}</a
              >
            </td>
            <td class="gray-pointer">
              <a
                [routerLink]="['/service-offers', serviceOffer.id]"
                target="_blank"
                class="action-link-gray"
                >{{ serviceOffer.title }}</a
              >
            </td>
            <td class="gray-pointer">
              <a
                [routerLink]="['/providers', serviceOffer.provider_id]"
                target="_blank"
                class="action-link-gray"
                >{{ serviceOffer.company_name }}</a
              >
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/service-offers', serviceOffer.id]"
            >
              {{
                serviceOffer.sale_price.price
                  | currency : serviceOffer.sale_price.currency
              }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/service-offers', serviceOffer.id]"
            >
              {{ serviceOffer.country }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/service-offers', serviceOffer.id]"
            >
              {{ serviceOffer.city }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/service-offers', serviceOffer.id]"
            >
              {{ serviceOffer.workflow_status | getWorkflowStatus }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/service-offers', serviceOffer.id]"
            >
              <app-rating [currentRate]="serviceOffer.rating"></app-rating>
            </td>
            <td>
              <div class="services-status-label-{{ serviceOffer.status_id }}">
                <a [routerLink]="['/service-offers', serviceOffer.id]">{{
                  serviceOffer.status_label.toLowerCase()
                }}</a>
              </div>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action btn -->
              <!-- <div class="table-action-btn">
                                  <i class="icon-list-add"></i>
                              </div> -->
              <!-- end table action btn -->

              <!-- table action with overlay -->
              <div
                toggle-action-table
                class="table-action-wrapper"
                *ngIf="actions"
              >
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li>
                      <a
                        href="/service-offers/{{ serviceOffer.id }}/edit"
                        target="_blank"
                        >{{ "GENERALS.EDIT" | translate }}</a
                      >
                    </li>
                    <li (click)="copyOffer(serviceOffer.id)">
                      {{ "GENERALS.COPY-OFFER" | translate }}
                    </li>
                    <li
                      *ngFor="let status of actions[serviceOffer.status_id]"
                      (click)="openConfirmAction(status, serviceOffer)"
                    >
                      {{ status.text }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="10" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>

    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let serviceOffer of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(serviceOffer) : null"
              [checked]="selection.isSelected(serviceOffer)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            [routerLink]="['/service-offers', serviceOffer.id]"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.id }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.SERVICE-TITLE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.title }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COMPANY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.company_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.SALEPRICE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.sale_price.price }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COUNTRY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.country }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CITY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.city }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.POSITION" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ serviceOffer.workflow_status | getWorkflowStatus }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.RATING" | translate }}
          </div>
          <div class="responsive-table-data-item resposive-table-rating">
            <app-rating [currentRate]="serviceOffer.rating"></app-rating>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="services-status-label-{{ serviceOffer.status_id }}">{{
              serviceOffer.status_label.toLowerCase()
            }}</span>
          </div>
        </div>

        <div *ngIf="actions" class="responsive-table-action">
          <span
            *ngFor="let status of actions[serviceOffer.status_id]"
            (click)="openConfirmAction(status, serviceOffer)"
          >
            {{ status.text }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

<!-- add doc modal -->
<app-bulk-upload-offers
  (changeOffers)="getItems()"
  [provider]="provider_id"
></app-bulk-upload-offers>
<!-- add doc modal -->
