<div class="mobile-header-middle-wrapper">
  <div class="fl-l w-30">
    <div (click)="openMobileNav()" class="mobile-menu-btn btn">
      <i class="icon-menu"></i> {{ "GENERALS.MENU" | translate }}
    </div>
    <div class="mobile-navigation-wrapper">
      <div (click)="closeMobileNav()" class="close-mobile-menu-btn">
        <i class="icon-cancel-2"></i>
      </div>
      <div class="mobile-navigation-items">
        <ul *ngIf="user$ | async as user">
          >
          <!--            Dashboard -->
          <li
            (click)="closeMobileNav()"
            [routerLink]="['/dashboard']"
            [ngClass]="{
              'active-nav': router.url.indexOf('/dashboard') > -1
            }"
            *ngIf="
              this.authService.$hasSecondaryResource(
                resource.REPORTS,
                subResource.FINANCIAL
              )
            "
          >
            <a>
              <i class="icon-dashboard_comp"></i>
              {{ "LEFT-NAVIGATION.NAV1" | translate }}
            </a>
          </li>

          <!--            Admins -->
          <li
            [routerLink]="['/admins']"
            [ngClass]="{
              'active-nav': router.url.indexOf('/admins') > -1
            }"
            *ngIf="this.authService.$hasResource(resource.ADMIN)"
          >
            <a>
              <i class="icon-clients_comp"></i>

              {{ "LEFT-NAVIGATION.NAV12" | translate }}
            </a>
          </li>

          <!--            Providers -->
          <li
            (click)="closeMobileNav()"
            [routerLink]="['/providers']"
            [ngClass]="{
              'active-nav': router.url.indexOf('/providers') > -1
            }"
            *ngIf="this.authService.$hasResource(resource.PROVIDER)"
          >
            <a>
              <i class="icon-providers_compound"></i>
              {{ "LEFT-NAVIGATION.NAV2" | translate }}
            </a>
          </li>

          <!--            Services -->
          <li
            (click)="closeMobileNav()"
            [routerLink]="['/service-offers/list']"
            [ngClass]="{
              'active-nav': router.url.indexOf('/service-offers') > -1
            }"
            *ngIf="this.authService.$hasResource(resource.SERVICE)"
          >
            <a>
              <i class="icon-services-comp"></i>
              {{ "LEFT-NAVIGATION.NAV3" | translate }}
            </a>
          </li>

          <!--            Clients -->
          <li
            (click)="closeMobileNav()"
            [routerLink]="['/clients']"
            [ngClass]="{
              'active-nav': router.url.indexOf('/clients') > -1
            }"
            *ngIf="this.authService.$hasResource(resource.CLIENT)"
          >
            <a>
              <i class="icon-clients_comp"></i>
              {{ "SIDE_MENU.CLIENTS" | translate }}
            </a>
          </li>

          <!--            Expats -->
          <li
            (click)="closeMobileNav()"
            [routerLink]="['/expats']"
            [ngClass]="{
              'active-nav': router.url.indexOf('/expats') > -1
            }"
            *ngIf="this.authService.$hasResource(resource.EXPAT)"
          >
            <a>
              <i class="icon-clients_comp"></i>
              {{ "LEFT-NAVIGATION.EXPATS" | translate }}
            </a>
          </li>

          <!--            Orders -->
          <li
            (click)="closeMobileNav()"
            [routerLink]="['/orders']"
            *ngIf="this.authService.$hasResource(resource.ORDER)"
            [ngClass]="{
              'active-nav':
                router.url.indexOf('/orders') > -1 &&
                router.url.indexOf('/clients') === -1 &&
                router.url.indexOf('/providers') === -1
            }"
          >
            <a>
              <i class="icon-orders_comp"></i>
              {{ "LEFT-NAVIGATION.NAV5" | translate }}
            </a>
          </li>

          <!--            RFQs -->
          <li
            (click)="closeMobileNav()"
            [routerLink]="['/rfqs-bids']"
            [ngClass]="{
              'active-nav': router.url.indexOf('/rfqs-bids') > -1
            }"
            *ngIf="this.authService.$hasResource(resource.RFQ)"
          >
            <a>
              <i class="icon-rfqs"></i>
              {{ "LEFT-NAVIGATION.NAV6" | translate }}
            </a>
          </li>

          <!--          Commissions -->
          <li
            class="has-sub-nav"
            expand-menu
            *ngIf="
              this.authService.$hasSecondaryResource(
                resource.CATEGORY,
                subResource.FINANCIAL
              )
            "
            [ngClass]="{
              'active-nav': router.url.indexOf('/commissions') > -1
            }"
          >
            <a>
              <i class="icon-commissions_comp"></i>
              {{ "LEFT-NAVIGATION.COMMISSIONS" | translate }}
            </a>
          </li>

          <div
            *ngIf="
              this.authService.$hasSecondaryResource(
                resource.CATEGORY,
                subResource.FINANCIAL
              )
            "
            class="sub-navigation open-sub-navigation"
          >
            <ul>
              <!--                    Custom Commissions -->
              <li
                (click)="closeMobileNav()"
                [routerLink]="['/commissions/custom']"
              >
                <a
                  [ngClass]="{
                    'active-sub-nav':
                      router.url.indexOf('/commissions/custom') > -1
                  }"
                >
                  {{ "SUB-NAVIGATION.CUSTOM_COMMISSIONS" | translate }}
                </a>
              </li>

              <!--                    Approve Commissions -->
              <li
                (click)="closeMobileNav()"
                [routerLink]="['/commissions/approve']"
              >
                <a
                  [ngClass]="{
                    'active-sub-nav':
                      router.url.indexOf('/commissions/approve') > -1
                  }"
                >
                  {{ "SUB-NAVIGATION.APPROVE_COMMISSIONS" | translate }}
                </a>
              </li>
            </ul>
          </div>

          <!--          Financial -->
          <li
            class="has-sub-nav"
            expand-menu
            [ngClass]="{
              'active-nav': router.url.indexOf('/financial') > -1
            }"
            *ngIf="this.authService.$hasResource(resource.ORDER)"
          >
            <a>
              <i class="icon-financial_comp"></i>
              {{ "LEFT-NAVIGATION.FINANCIAL" | translate }}
            </a>
          </li>

          <div
            *ngIf="this.authService.$hasResource(resource.ORDER)"
            class="sub-navigation open-sub-navigation"
          >
            <ul>
              <!--              Invoices -->
              <li
                (click)="closeMobileNav()"
                [routerLink]="['/financial/invoices']"
              >
                <a
                  [ngClass]="{
                    'active-sub-nav':
                      router.url.indexOf('/financial/invoices') > -1
                  }"
                >
                  {{ "SUB-NAVIGATION.INVOICES" | translate }}
                </a>
              </li>

              <!--              Order Commissions -->
              <li (click)="closeMobileNav()" [routerLink]="['/financial/fees']">
                <a
                  [ngClass]="{
                    'active-sub-nav': router.url.indexOf('/financial/fees') > -1
                  }"
                >
                  {{ "SUB-NAVIGATION.FEES" | translate }}
                </a>
              </li>
            </ul>
          </div>

          <!--          CMS -->
          <li
            class="has-sub-nav"
            expand-menu
            [ngClass]="{
              'active-nav': router.url.indexOf('/cms') > -1
            }"
          >
            <a>
              <i class="icon-cms_comp"></i>
              {{ "LEFT-NAVIGATION.NAV10" | translate }}
            </a>
          </li>

          <div class="sub-navigation open-sub-navigation">
            <ul>
              <!--              Categories -->
              <li
                (click)="closeMobileNav()"
                [routerLink]="['/cms/service-categories']"
                *ngIf="this.authService.$hasResource(resource.CATEGORY)"
              >
                <a
                  [ngClass]="{
                    'active-sub-nav':
                      router.url.indexOf('/cms/service-categories') > -1
                  }"
                >
                  {{ "SUB-NAVIGATION.CATEGORISATION" | translate }}
                </a>
              </li>

              <!--              Ads -->
              <li
                (click)="closeMobileNav()"
                [routerLink]="['/cms/ads/pricing']"
              >
                <a
                  [ngClass]="{
                    'active-sub-nav':
                      router.url.indexOf('/cms/ads/priecing') > -1
                  }"
                >
                  {{ "SUB-NAVIGATION.ADS-PRICING" | translate }}
                </a>
              </li>
            </ul>
          </div>

          <!--          Help -->
          <li (click)="goToBlog()">
            <a>
              <i class="icon-help_comp"></i>
              {{ "LEFT-NAVIGATION.NAV11" | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="fl-l w-40">
    <div class="btn-wrapper">
      <a
        class="btn orange-bg mobile-middle-header-btn"
        target="_blank"
        href="{{ urlMarketplace }}"
        ><i class="icon-right"></i> {{ "GENERALS.MARKETPLACE" | translate }}</a
      >
    </div>
  </div>

  <div class="fl-l w-30">
    <!-- language selection -->
    <div class="header-top-lang fl-l">
      <app-translate></app-translate>
    </div>
  </div>
</div>
