<div class="dialog">
  <app-dialog-header>
    {{ "GENERALS.HUBSPOT" | translate }}
  </app-dialog-header>

  <div class="dialog-body">
    <form [formGroup]="hubspotForm">
      <div class="form-group w-50">
        <label>{{ "LABEL.HUBSPOT_ID" | translate }}</label>
        <input
          type="text"
          name="hubspot_id"
          formControlName="hubspot_id"
          class="form-control"
        />
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button (click)="cancel()" class="btn gray-bg fl-l">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button (click)="confirm()" class="btn orange-bg fl-r">
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>
