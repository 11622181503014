import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { AccountService } from "@api/account";
import { TranslateService } from "@ngx-translate/core";
import cloneDeep from "lodash-es/cloneDeep";
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { RFQ } from "src/app/_models/rfq";
import { AlertService } from "@modules/alert";
import { ClientsService } from "src/app/_services/clients.service";
import { RFQSService } from "src/app/_services/rfqs.service";
import { ConfirmActionModalComponent } from "src/app/components/dashboard/confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { RfqHelper } from "../../../../_helpers/_models/rfq.helper";

@Component({
  selector: "app-rfq-details-actions-panel",
  templateUrl: "./rfq-details-actions-panel.component.html",
  styleUrls: ["../rfq-details.component.scss"],
})
export class RFQDetailsActionsPanelComponent implements OnInit, OnChanges {
  @Output() oncreate: EventEmitter<any> = new EventEmitter();
  @Input() item;

  isLoadingGeneral;
  statusOptions;
  password;
  timeLeft;
  RFQ: RFQ;
  id;
  edit;
  itemChanges;
  currency = [
    { id: 1, label: "USD" },
    { id: 2, label: "EUR" },
    { id: 3, label: "GBP" },
  ];
  user;
  isOpen = false;
  maxExtensionDays = 0;
  canExtend = false;
  extended_period;
  reason;
  selectedProvider;
  minStartDate: moment.Moment;
  maxStartDate: moment.Moment;
  minEndDate: moment.Moment;
  maxEndDate: moment.Moment;
  minDeliveryDate: moment.Moment;
  numberOfDaysToAdd = 30;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private rfqService: RFQSService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private clientService: ClientsService,
    public dialog: MatDialog,
    public accountService: AccountService,
    public helper: RfqHelper
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getRFQDetails();
      }
    });

    if (!this.id && this.route.snapshot.parent.params.id) {
      this.id = this.route.snapshot.parent.params.id;
      this.getRFQDetails();
    }
    this.itemChanges = new RFQ();
  }

  ngOnInit() {
    this.minStartDate = moment().add(1, "d");
    this.minEndDate = moment().add(2, "d");
    this.minDeliveryDate = moment().add(3, "d");
  }

  get user$() {
    return this.accountService.account$;
  }

  ngOnChanges(changes) {
    if (changes && changes.item) {
      this.getRFQDetails();
    }
  }

  getRFQDetails() {
    this.isLoadingGeneral = true;
    this.rfqService.getRFQById(this.id).subscribe(
      (res) => {
        this.isLoadingGeneral = false;
        this.RFQ = res.result;

        if (
          this.RFQ.status_name.toLowerCase() === "draft" ||
          this.RFQ.status_name.toLowerCase() === "published"
        ) {
          this.initIfDraft();
        }

        if (this.RFQ.status_name.toLowerCase() === "open") {
          const endDate = new Date(this.RFQ.end_date);
          const nowDate = new Date();
          const now = moment(nowDate);
          const end = moment(endDate);
          const duration = moment.duration(now.diff(end));
          if (duration.days() < 14) {
            this.maxExtensionDays =
              duration.days() > 0 ? 14 - duration.days() : 14;
          }
        }
        if (this.RFQ.status_name.toLowerCase() !== "closed") {
          this.getStatusTransitions();
        } else {
          this.statusOptions = [];
        }
      },
      (error) => {
        this.isLoadingGeneral = false;
      }
    );
  }

  initIfDraft() {
    if (this.RFQ.start_date) {
      this.itemChanges.start_time = this.setTimeFormat(
        new Date(this.RFQ.start_date)
      );
      this.itemChanges.start_date = this.RFQ.start_date;
      // // max start date // substract one day to the StartDate
      this.maxStartDate = moment(this.RFQ.delivery_date).subtract(2, "d");
      // // max end date // substract one day to the EndDate
      this.maxEndDate = moment(this.RFQ.delivery_date).subtract(1, "d");
    }

    if (this.RFQ.end_date) {
      this.itemChanges.end_time = this.setTimeFormat(
        new Date(this.RFQ.end_date)
      );
      this.itemChanges.end_date = this.RFQ.end_date;
    }

    if (this.RFQ.delivery_date) {
      this.itemChanges.delivery_date = this.RFQ.delivery_date;
    }

    if (this.RFQ.request_type.toLowerCase() === "bid") {
      this.itemChanges.target_price = this.RFQ.target_price;
      this.itemChanges.target_currency_id = this.RFQ.target_currency_id;
    }
  }

  public getStatusTransitions() {
    this.rfqService.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.statusOptions = data.result[this.RFQ.status];
      }
    });
  }

  openConfirmAction(action) {
    if (action !== 2) {
      if (action.needs_confirmation || action.needs_reason) {
        const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
          data: {
            action: action,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result && result !== undefined) {
            this.rfqService
              .updateStatusBulk(
                action.id,
                this.RFQ.client_id,
                this.RFQ.id,
                result
              )
              .subscribe(
                (data) => {
                  this.oncreate.emit();
                  this.isLoadingGeneral = false;
                  this.getRFQDetails();
                },
                (error) => {
                  this.isLoadingGeneral = false;
                  this.alertService.errors(error.error.errors);
                }
              );
          }
        });
      } else {
        this.rfqService
          .updateStatusBulk(action.id, this.RFQ.client_id, this.RFQ.id, null)
          .subscribe((data) => {
            this.oncreate.emit();
            this.isLoadingGeneral = false;
            this.getRFQDetails();
          });
      }
    } else {
      this.isOpen = true;
      this.ngxSmartModalService.getModal("finalizeRFQ").open();
    }
  }

  openModal() {
    this.ngxSmartModalService.getModal("switchAccount").open();
  }

  switchToAccount(e) {
    this.ngxSmartModalService.getModal("switchAccount").close();

    this.clientService
      .switchToAccount({
        client_id: this.id,
        password: e,
      })
      .subscribe(
        (res) => {
          this.ngxSmartModalService.getModal("switchAccount").close();
        },
        (error) => {
          this.alertService.errors(error.error.errors);
        }
      );
  }

  setFormDate(e, dateType) {
    if (!e) {
      return;
    }

    if (typeof e == "string") {
      switch (dateType) {
        case "start":
          this.itemChanges.start_date = e;
          break;
        case "end":
          this.itemChanges.end_date = e;
          break;
        case "delivery":
          this.itemChanges.delivery_date = e;
          break;
      }
    }
  }

  initEmpty() {
    this.edit = null;
    this.itemChanges = new RFQ();
    this.canExtend = false;
    this.extended_period = null;
    if (
      this.RFQ.status_name.toLowerCase() === "draft" ||
      this.RFQ.status_name.toLowerCase() === "published"
    ) {
      this.initIfDraft();
    }
  }

  update(e) {
    switch (this.RFQ.status_name.toLowerCase()) {
      case "draft":
        this.updateDraftRFQ(e);
        break;
      case "published":
        this.updateRFQ(e);
        break;
    }
  }

  updateDraftRFQ(e) {
    const params = cloneDeep(this.RFQ);
    const keys = Object.keys(this.itemChanges);
    keys.forEach((key) => {
      if (key !== "offers_request") {
        params[key] = this.itemChanges[key];
      }
    });

    params.rfq_id = params.id;

    if (e === "receiving-quotes") {
      params.start_date = this.addTimeToDate(
        this.itemChanges.start_date,
        this.itemChanges.start_time
      );
      params.end_date = this.addTimeToDate(
        this.itemChanges.end_date,
        this.itemChanges.end_time
      );
    }

    this.isLoadingGeneral = true;
    this.rfqService.updateDraftRFQDetails(params).subscribe(
      (res) => {
        this.RFQ = res.result;
        this.isLoadingGeneral = false;

        this.initEmpty();
        if (
          this.RFQ.status_name.toLowerCase() === "draft" ||
          this.RFQ.status_name.toLowerCase() === "published"
        ) {
          this.initIfDraft();
        }
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  updateRFQ(e) {
    const params = cloneDeep(this.RFQ);

    const keys = Object.keys(this.itemChanges);
    keys.forEach((key) => {
      if (key !== "offers_request") {
        params[key] = this.itemChanges[key];
      }
    });

    params.rfq_id = params.id;
    this.isLoadingGeneral = true;

    this.rfqService.updateRFQDetails(params).subscribe(
      (res) => {
        this.oncreate.emit();
        this.RFQ = res.result;

        this.initEmpty();
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  extendAvailability() {
    if (!this.extended_period) {
      return;
    }
    this.isLoadingGeneral = true;
    this.rfqService.extendAvailability(this.extended_period, this.id).subscribe(
      (res) => {
        this.initEmpty();
        this.getRFQDetails();
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  refresh() {
    this.oncreate.emit();
    this.getRFQDetails();
  }

  openDeclineModal() {
    this.ngxSmartModalService.getModal("declineRFQ").open();
  }

  decline() {
    this.isLoadingGeneral = true;
    const params = {};
    params["provider_id"] = this.selectedProvider;
    params["status"] = "decline";
    params["message"] = this.reason;
    this.rfqService.declineRFQInvitation(params, this.id).subscribe(
      (res) => {
        this.getRFQDetails();
        this.oncreate.emit();
        this.isLoadingGeneral = false;
        this.ngxSmartModalService.getModal("declineRFQ").close();
      },
      (error) => {
        this.alertService.errors(error.error.errors);

        this.isLoadingGeneral = false;
      }
    );
  }

  setTimeFormat(time) {
    return {
      second: 0,
      minute: time.getUTCMinutes(),
      hour: time.getUTCHours(),
    };
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }

  addTimeToDate(date, timeObj) {
    const formatDate = moment(date).startOf("day").toISOString();
    return moment(formatDate)
      .add(timeObj.hour, "hours")
      .add(timeObj.minute, "minutes")
      .toISOString();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
