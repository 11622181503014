import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ExpatsService } from "../../../../_services/expats.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "app-expat-documents",
  templateUrl: "./expat-documents.component.html",
})
export class ExpatDocumentsComponent {
  public id: number;

  constructor(
    private route: ActivatedRoute,
    private expatService: ExpatsService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.expatService.getExpatById(this.id).subscribe((data) => {
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForExpat(data.result, "documents")
          );
        });
      }
    });
  }
}
