import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { AuthenticationModule } from "@api/authentication";
import { environment } from "@environment/environment";
import { BASE_API_URL } from "./http/base-api-url.token";
import { AuthInterceptor } from "./http/interceptors/auth.interceptor";
import { BaseUrlInterceptor } from "./http/interceptors/base-url.interceptor";
import { DefaultHeadersInterceptor } from "./http/interceptors/default-headers.interceptor";

import {
  BACKEND_API_HTTP_INTERCEPTORS,
  BackendHttpClient,
} from "./http/backend-http-client";
import { AccountModule } from "./account/account.module";
import { ErrorInterceptor } from "./http/interceptors/error.interceptor";

@NgModule({
  declarations: [],
  imports: [CommonModule, AuthenticationModule, AccountModule],
  providers: [
    { provide: BASE_API_URL, useValue: environment.gateway_endpoint },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    BackendHttpClient,
  ],
})
export class ApiModule {
  public static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [],
    };
  }
}
