import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-add-client-step-1",
  templateUrl: "./add-client-step-1.component.html",
})
export class AddClientStep1Component implements OnInit, OnChanges {
  @Input() user;
  @Input() isCanceled;
  @Input() loaderAnim;

  phoneCode;
  phoneNumber;
  prefix;
  countries;
  phone_prefix;
  constructor(private dictionariesService: DictionariesService) {}

  form: UntypedFormGroup;

  ngOnInit() {
    this.getCountryList();

    this.form = new UntypedFormGroup({
      phone_prefix: new UntypedFormControl(""),
    });

    this.form
      .get("phone_prefix")
      .valueChanges.pipe(filter((value) => value))
      .subscribe((value) => {
        this.user.phone_prefix = value;
      });
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
        this.prefix = data.result;
        this.prefix.map((p) => {
          p.phone_code =
            this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
        });
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.isCanceled && changes.isCanceled.currentValue) {
      this.phoneCode = null;
      this.phoneNumber = null;
      this.phone_prefix = null;
    }
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setPrefix(e) {
    if (e) {
      const code = e.phone_code.match(/\((.*?)\)/);
      this.user.phone_prefix = code[1];
    }
  }
}
