import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserData } from "src/app/_models/user.model";
import { DictionariesService } from "src/app/_services/dictionaries.service";

@Component({
  selector: "app-user-form",
  templateUrl: "./add-user-form.component.html",
  styleUrls: ["./add-user-form.component.css"],
})
export class AddUserFormComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  countries;
  companyTypes;
  isNewAdmin = false;
  constructor(
    private dictionariesService: DictionariesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: UserData;
      about: string;
      edit: boolean;
      isCanceled: boolean;
      userType: string;
    }
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.getCountryList();
    this.getCompanyTypes();
  }

  getCompanyTypes() {
    this.dictionariesService
      .getCompanyTypes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.companyTypes = data.result;
      });
  }

  getCountryList() {
    this.dictionariesService
      .getCountryList()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data && data.result) {
          this.countries = data.result;
        }
      });
  }
}
