<ng-container *ngIf="isLoadingGeneral">
  <div class="d-flex loading-wrapper m-0">
    <div class="w-profile">
      <app-loader-spinner-general
        [loadingType]="LoadingType.PROFILE"
      ></app-loader-spinner-general>
    </div>
  </div>
</ng-container>

<div class="details-2c-left m-r-20" *ngIf="!isLoadingGeneral && RFQ">
  <div class="small-box">
    <div class="small-box-header-img">
      <img src="../../../assets/images/footer-img.jpg" alt="img" />
    </div>
    <div class="small-box-details">
      <div class="medium-title">
        {{ RFQ.is_bid ? ("RFQS.BID" | translate) : ("RFQS.RFQ" | translate) }}
        ID {{ RFQ.id }}
      </div>
      <div class="small-box-status">
        <div class="rfq-status-label-{{ RFQ.status }}">
          {{ "STATUSES." + RFQ.status_name | translate }}
        </div>
      </div>

      <div class="fw-500" *ngIf="RFQ.declined_reason || ''">
        <span class="gray block-span"
          >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
        >
        {{ RFQ.declined_reason }}
      </div>

      <!-- time left -->
      <div class="fw-500">
        <span class="small-box-subtitle">
          {{ "RFQS.TIME-LEFT" | translate }}:
        </span>
        <span class="orange">
          {{ helper.timeLeft(RFQ) }}
        </span>
      </div>
      <!-- end time left -->

      <!--  requested by -->
      <div class="fw-500">
        <span class="small-box-subtitle">
          {{ "RFQS.REQUESTED-BY" | translate }}:
        </span>
        {{ RFQ.company_name }}
      </div>
      <!-- end requested by -->

      <!-- availability -->
      <div class="fw-500">
        <span class="small-box-subtitle">
          {{ "RFQS.AVAILABILITY-PERIOD" | translate }}:
        </span>
        <span *ngIf="edit !== 'availability'" class="fx-space-center">
          {{ RFQ.start_date | date : "dd/MM/yyyy HH:mm zzzz" }} <br />
          {{ RFQ.end_date | date : "dd/MM/yyyy HH:mm zzzz" }}
          <span
            class="editable-input-action"
            *ngIf="helper.isDraft(RFQ)"
            (click)="edit = 'availability'"
          >
            <i class="icon-edit"> </i>
          </span>
        </span>

        <span
          *ngIf="helper.canExtend(RFQ) && maxExtensionDays > 0 && !canExtend"
          class="action-link-orange"
          (click)="canExtend = !canExtend"
        >
          {{ "RFQS.EXTEND-RFQ" | translate }}
        </span>

        <ng-container *ngIf="canExtend">
          <div>{{ "RFQS.EXTENSION-HEADER" | translate }}:</div>

          <div class="fw-500">
            <span class="small-box-subtitle">
              {{ "RFQS.EXTENSION-REMAINING" | translate }}:
            </span>
            {{ maxExtensionDays }}
          </div>

          <div class="fw-500">
            <span class="small-box-subtitle">
              {{ "RFQS.EXTENSION-DAYS" | translate }}:
            </span>
            <input
              type="number"
              name="extension"
              class="form-control"
              [(ngModel)]="extended_period"
            />
            <span class="rfq-edit-actions-btn">
              <span class="fl-r">
                <span class="btn-small gray-bg" (click)="initEmpty()">
                  {{ "GENERALS.CANCEL" | translate }}
                </span>

                <span
                  class="btn-small orange-bg"
                  (click)="extendAvailability()"
                >
                  {{ "GENERALS.SAVE" | translate }}
                </span>
              </span>
            </span>
          </div>
        </ng-container>

        <span class="rfq-edit-actions" *ngIf="edit === 'availability'">
          <span class="form-group">
            <app-date-range-calendar
              [singleDate]="itemChanges.start_date"
              [placeholder]="'GENERALS.SELECT-DATE' | translate"
              [minDate]="minStartDate"
              [maxDate]="maxStartDate"
              (change)="setFormDate($event, 'start')"
            >
            </app-date-range-calendar>
          </span>
          <span class="form-group timepicker">
            <ngb-timepicker
              [(ngModel)]="itemChanges.start_time"
            ></ngb-timepicker>
          </span>

          <div class="w-50">
            <span class="form-group">
              <app-date-range-calendar
                [singleDate]="itemChanges.end_date"
                [placeholder]="'GENERALS.SELECT-DATE' | translate"
                [minDate]="minEndDate"
                [maxDate]="maxEndDate"
                (change)="setFormDate($event, 'end')"
              >
              </app-date-range-calendar>
            </span>
            <span class="form-group timepicker">
              <ngb-timepicker
                [(ngModel)]="itemChanges.end_time"
              ></ngb-timepicker>
            </span>
          </div>

          <span class="rfq-edit-actions-btn">
            <span class="fl-r">
              <span class="btn-small gray-bg" (click)="initEmpty()">
                {{ "GENERALS.CANCEL" | translate }}
              </span>
              <span
                class="btn-small orange-bg"
                (click)="update('receiving-quotes')"
              >
                {{ "GENERALS.SAVE" | translate }}
              </span>
            </span>
          </span>
        </span>
      </div>
      <!-- end availability -->

      <!--  delivery deadline -->
      <div class="fw-500">
        <span class="small-box-subtitle">
          {{ "RFQS.DELIVERY-DEADLINE" | translate }}:
        </span>
        <span *ngIf="edit !== 'delivery'" class="fx-space-center">
          {{ RFQ.delivery_date | date : "dd/MM/yyyy" }}
          <span
            class="editable-input-action"
            *ngIf="
              RFQ.status_name.toLowerCase() === 'draft' ||
              RFQ.status_name.toLowerCase() === 'published'
            "
            (click)="edit = 'delivery'"
          >
            <i class="icon-edit"> </i>
          </span>
        </span>

        <span class="rfq-edit-actions" *ngIf="edit === 'delivery'">
          <span class="form-group">
            <app-date-range-calendar
              [singleDate]="itemChanges.delivery_date"
              [showLabel]="false"
              [placeholder]="'GENERALS.SELECT-DATE' | translate"
              [minDate]="minDeliveryDate"
              (change)="setFormDate($event, 'delivery')"
            >
            </app-date-range-calendar>
          </span>

          <span class="rfq-edit-actions-btn">
            <span class="fl-r">
              <span class="btn-small gray-bg" (click)="initEmpty()">
                {{ "GENERALS.CANCEL" | translate }}
              </span>
              <span
                class="btn-small orange-bg"
                (click)="update('delivery-deadline')"
              >
                {{ "GENERALS.SAVE" | translate }}
              </span>
            </span>
          </span>
        </span>
      </div>
      <!-- end delivery deadline -->

      <!-- taget price -->
      <div class="fw-500" *ngIf="RFQ.request_type.toUpperCase() === 'BID'">
        <span class="small-box-subtitle">
          {{ "RFQS.TARGET-PRICE" | translate }}:
        </span>
        <span *ngIf="edit !== 'target'" class="fx-space-center">
          {{ RFQ.target_price | currency : RFQ.target_currency_code }}
          <span
            class="editable-input-action"
            *ngIf="RFQ.status_name.toLowerCase() === 'draft'"
            (click)="edit = 'target'"
          >
            <i class="icon-edit"> </i>
          </span>
        </span>
        <div class="rfq-edit-actions" *ngIf="edit === 'target'">
          <div class="fx-space-center">
            <div class="form-group w-50">
              <input
                type="number"
                name="target_price"
                class="form-control"
                [(ngModel)]="itemChanges.target_price"
              />
            </div>
            <div class="form-group w-50">
              <ng-select
                id="rfqDetailsSelect"
                class="customiz-select chips-select"
                [items]="currency"
                bindLabel="label"
                bindValue="id"
                placeholder="{{ 'GENERALS.SELECT-CURRENCY' | translate }}"
                notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                [(ngModel)]="itemChanges.target_currency_id"
              >
              </ng-select>
            </div>
          </div>

          <span class="rfq-edit-actions-btn">
            <span class="fl-r">
              <span class="btn-small gray-bg" (click)="initEmpty()">
                {{ "GENERALS.CANCEL" | translate }}
              </span>
              <button
                class="btn-small orange-bg"
                (click)="updateDraftRFQ($event)"
                [disabled]="
                  !itemChanges.target_price || itemChanges.target_price < 0
                "
              >
                {{ "GENERALS.SAVE" | translate }}
              </button>
            </span>
          </span>
        </div>
      </div>
      <!-- end taget price -->

      <!-- create rfq/bid from draft -->
      <div
        class="btn-small orange-bg"
        *ngIf="RFQ.status_name.toLowerCase() === 'draft'"
        (click)="updateRFQ($event)"
      >
        {{ "RFQS.CREATE-&-INVITATIONS" | translate }}
      </div>
      <!-- end create rfq/bid from draft -->

      <!-- place order -->
      <div
        class="btn-small orange-bg"
        *ngIf="RFQ.status_name.toLowerCase() === 'closed'"
        (click)="openConfirmAction(2)"
      >
        {{ "RFQS.PLACE-ORDER" | translate }}
      </div>
      <!-- end place order -->

      <!-- see order details  -->
      <div
        class="btn-small orange-bg"
        *ngIf="RFQ.status_name.toLowerCase() === 'finalized' && RFQ.order_id"
      >
        <div [routerLink]="['/orders/' + RFQ.order_identity_number]">
          {{ "PORTAL.ORDER-DETAILS" | translate }}
        </div>
      </div>
      <!-- end see order details  -->
    </div>

    <div class="small-box-list">
      <div *ngIf="!statusOptions" class="p-10">
        <app-loader-spinner-general
          [repeat]="1"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <ul>
        <ng-container *ngFor="let option of statusOptions">
          <li>
            <div
              (click)="openConfirmAction(option)"
              class="btn-small orange-bg"
            >
              {{ option.text | translate }}
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<app-user-details-actions-panel
  (switchAccount)="switchToAccount($event)"
></app-user-details-actions-panel>

<app-finalize-rfq
  *ngIf="RFQ"
  [client_id]="RFQ.client_id"
  [rfq_id]="RFQ.id"
  [type]="RFQ.request_type"
  [status]="RFQ.status"
  [status_name]="RFQ.status_name"
  [isOpen]="isOpen"
  (onfinalize)="refresh()"
></app-finalize-rfq>

<ngx-smart-modal
  id="declineRFQ"
  #declineRFQ
  identifier="declineRFQ"
  class="fx-column"
  [dismissable]="false"
>
  <app-loader-spinner-general
    [isLoadingGeneral]="isLoadingGeneral"
  ></app-loader-spinner-general>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "RFQS.DECLINE-RFQ" | translate }}
    </div>
  </div>
  <div class="clear"></div>
  <!-- Modal body -->
  <div class="custom-modal-body" *ngIf="RFQ">
    <div class="row">
      <div class="form-group w-100">
        <label
          >{{ "GENERALS.PROVIDER" | translate }} *
          <!-- <span appHoverHelper class="input-helper"
            ><i class="icon-help_comp"></i>
            <span class="helper-description">test text helper</span>
          </span> -->
        </label>
        <ng-select
          class="customiz-select"
          [items]="RFQ.providers"
          bindLabel="company_name"
          bindValue="provider_id"
          placeholder="{{ 'GENERALS.SELECT-PROVIDER' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [(ngModel)]="selectedProvider"
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group w-100">
        <label class="fw-600 fsz-15">
          {{ "RFQS.DECLINE-RFQ-REASON" | translate }}
        </label>
        <textarea
          name="reason"
          class="form-control decline"
          [(ngModel)]="reason"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button
      type="button"
      class="btn gray-bg"
      (click)="declineRFQ.close(); reason = null; selectedProvider = null"
    >
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button type="button" class="btn orange-bg" (click)="decline()">
      {{ "GENERALS.DECLINE" | translate }}
    </button>
  </div>
</ngx-smart-modal>
