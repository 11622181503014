<div class="modal-form">
  <div class="row">
    <div class="w-100">
      <div class="form-group-title">
        {{ "GENERALS.COMPANY-INFORMATION" | translate }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="this.edit; else noEdit">
    <div class="row">
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.COMPANY-NAME" | translate }}</mat-label>
          <input [(ngModel)]="client.company_name" matInput />
        </mat-form-field>
      </div>

      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.COMPANY-TYPE" | translate }} *</mat-label>
          <mat-select [(ngModel)]="client.company_type_id">
            <mat-option *ngFor="let type of companyTypes" [value]="type.id">
              {{ type.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.NUMBER-OF-EMPLOYEES" | translate }} *</mat-label>
          <mat-select [(ngModel)]="client.number_of_employees_id">
            <mat-option
              *ngFor="let employee of employeesNumber"
              [value]="employee.id"
            >
              {{ employee.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.INDUSTRY" | translate }}</mat-label>
          <mat-select [(ngModel)]="client.industry_id">
            <mat-option
              *ngFor="let industry of industriesList"
              [value]="industry.id"
            >
              {{ industry.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <ng-template #noEdit>
    <div class="row">
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.COMPANY-TYPE" | translate }} *</mat-label>
          <mat-select [(ngModel)]="client.company_type_id">
            <mat-option *ngFor="let type of companyTypes" [value]="type.id">
              {{ type.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.NUMBER-OF-EMPLOYEES" | translate }} *</mat-label>
          <mat-select [(ngModel)]="client.number_of_employees_id">
            <mat-option
              *ngFor="let employee of employeesNumber"
              [value]="employee.id"
            >
              {{ employee.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="w-50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.INDUSTRY" | translate }}</mat-label>
          <mat-select [(ngModel)]="client.industry_id">
            <mat-option
              *ngFor="let industry of industriesList"
              [value]="industry.id"
            >
              {{ industry.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-template>
</div>
