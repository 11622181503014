<div class="dialog">
  <!-- Modal header -->
  <app-dialog-header>
    <div *ngIf="!data.edit && data.user && data.userType === 'admin'">
      {{ "GENERALS.ADD-ADMIN" | translate }}
    </div>
    <div *ngIf="data.edit && data.user && data.userType === 'admin'">
      {{ "GENERALS.EDIT-ADMIN" | translate }}
    </div>
    <div *ngIf="!data.edit && data.user && data.userType !== 'admin'">
      {{ "GENERALS.ADD-USER" | translate }}
    </div>
    <div
      *ngIf="
        data.about === 'user' &&
        data.user &&
        data.userType === 'user' &&
        data.edit
      "
      class="modal-title"
    >
      {{ "GENERALS.EDIT-USER" | translate }}
    </div>
    <div
      *ngIf="
        data.about === 'account' &&
        data.user &&
        (data.userType === 'provider' || data.userType === 'client')
      "
      class="modal-title"
    >
      {{ "GENERALS.EDIT-COMPANY-INFO" | translate }}
    </div>
    <div
      *ngIf="data.about === 'account' && data.user && data.userType === 'user'"
      class="modal-title"
    >
      {{ "GENERALS.EDIT-USER" | translate }}
    </div>
    <div
      *ngIf="
        data.about === 'company' && data.user && data.userType === 'provider'
      "
      class="modal-title"
    >
      {{ "GENERALS.EDIT-PROVIDER-DETAILS" | translate }}
    </div>
    <div
      *ngIf="
        data.about === 'company' && data.user && data.userType === 'client'
      "
      class="modal-title"
    >
      {{ "GENERALS.EDIT-COMPANY-INFO" | translate }}
    </div>
  </app-dialog-header>
  <!-- Modal body -->
  <div mat-dialog-content class="dialog-body">
    <div *ngIf="data.about === 'account'">
      <app-edit-primary-user
        [user]="data.user"
        [isCanceled]="data.isCanceled"
        [edit]="data.edit"
        [userType]="data.userType"
      ></app-edit-primary-user>
    </div>
    <div *ngIf="data.about === 'user'">
      <app-add-user-step1
        [user]="data.user"
        [isCanceled]="data.isCanceled"
        [edit]="data.edit"
        [userType]="data.userType"
      ></app-add-user-step1>
    </div>
    <div
      *ngIf="
        data.about === 'company' && data.user && data.userType === 'provider'
      "
    >
      <app-add-provider-step2
        [provider]="$any(data.user)"
        [edit]="data.edit"
      ></app-add-provider-step2>
    </div>
    <div
      *ngIf="
        data.about === 'company' && data.user && data.userType === 'client'
      "
    >
      <app-add-client-step2
        [client]="$any(data.user)"
        [edit]="data.edit"
      ></app-add-client-step2>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="dialog-footer">
    <button
      *ngIf="!data.edit && data.userType !== 'admin'"
      type="button"
      class="btn orange-bg"
      mat-button
      [mat-dialog-close]="data.user"
    >
      {{ "GENERALS.NEXT" | translate }}
    </button>
    <button
      *ngIf="data.edit"
      type="button"
      class="btn orange-bg"
      mat-button
      [mat-dialog-close]="data.user"
    >
      {{ "GENERALS.SAVE" | translate }}
    </button>
    <button
      *ngIf="!data.edit && data.userType === 'admin'"
      type="button"
      class="btn orange-bg"
      mat-button
      [mat-dialog-close]="data.user"
    >
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
