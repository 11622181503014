import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { ActionEnum } from "src/app/_enums/action-enum";
import { AlertService } from "@modules/alert";
import { ClientsService } from "src/app/_services/clients.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { environment } from "src/environments/environment";
import { UserData } from "../../../../_models/user.model";
import { AuthenticationService } from "../../../../_services/authentication.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { UsersService } from "../../../../_services/users.service";
import { PermissionResourceEnum } from "../../../../modules/shared/_enums/permission-resource.enum";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { AddUserFormComponent } from "../add-user-form/add-user-form.component";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
  standalone: false,
})
export class UsersListComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy
{
  public resource = PermissionResourceEnum;
  user: UserData;
  items: UserData[];
  isCanceled;
  entity_id;
  private entityService;
  isXs: boolean;
  roles;
  isFiltersLoaded: boolean = false;
  private destroyed$: Subject<void> = new Subject();

  @Input() filters: any = { page: 1 };
  @Input() entityType;
  @Input() addPermission = null;

  constructor(
    private service: UsersService,
    private providerService: ProvidersService,
    private clientService: ClientsService,
    public ngxSmartModalService: NgxSmartModalService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    public authService: AuthenticationService,
    private translate: TranslateService,
    public dictionariesService: DictionariesService
  ) {
    super();
    this.showBulkActions = false;
    this.getStatusTransitions();
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.entity_id = parseInt(this.filters["entity_id"], 10);

    this.getItems(1);
    this.setEntityService();

    this.windowResizeHelper
      .getData()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.isXs = data.responsiveStatus;
      });
  }

  navigateTo(id, entity_id) {
    const host = environment.app_host;
    const expatsUrl = "/users/" + id;
    const query = "?entity_id=" + entity_id;
    const url = host + expatsUrl + query;
    window.open(url, "_blank");
  }

  public getItems(page: number = 1) {
    const params = this.getParams(page);
    params["entity_id"] = this.entity_id;

    this.isLoadingGeneral = true;
    this.service
      .list(params)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoadingGeneral = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        error: (message) => {
          if (message.error && message.error.errors) {
            this.alertService.errors(message.error.errors);
          }
        },
      });
  }

  public getStatusTransitions() {
    this.service
      .getStatusTransitions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          if (data.success) {
            this.actions = data.result;
          }
        },
      });
  }

  protected getService() {
    return this.service;
  }
  public setEntityService() {
    switch (this.entityType) {
      case "provider":
        this.entityService = this.providerService;
        break;
      case "client":
        this.entityService = this.clientService;
        break;
    }

    this.getUserRoles();
  }

  getUserRoles() {
    this.service
      .getUserRoles(this.entityType)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.roles = res.result;
      });
  }

  openModal() {
    const request =
      this.entityType === "provider"
        ? this.entityService.getProvider(this.filters["entity_id"])
        : this.entityService.getClient(this.filters["entity_id"]);
    request.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (res) => {
        this.dialog
          .open(AddUserFormComponent, {
            data: {
              user: {
                company_name: res.result.company_name,
              },
              isCanceled: this.isCanceled,
              about: "user",
              userType: this.entityType,
            },
          })
          .afterClosed()
          .pipe(takeUntil(this.destroyed$))
          .subscribe((user: UserData) => {
            if (user) {
              this.user = user;
              this.addEntityUser();
            } else {
              this.isCanceled = true;
            }
          });
      },
    });
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("addUser").close();
  }

  addEntityUser() {
    this.user.name = this.user.first_name + " " + this.user.last_name;

    this.entityService
      .createUser(this.entity_id, this.user)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.getItems(1);
          this.cancelModal();
        },
        error: (message) => {
          this.alertService.errors(message.error.errors);
        },
      });
  }

  resetPassword(_email) {
    this.authService
      .sendResetPasswordEmail(_email)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
        },
        error: (error) => {
          if (error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  resendConfirmationMail(id) {
    this.authService
      .resendConfirmationMail(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.translate
            .get("GENERALS.MAIL-WAS-SENT-SUCCESS")
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
              this.alertService.success(data);
            });
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  confirmEmail(id) {
    this.authService
      .confirmEmail(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.translate
            .get("GENERALS.EMAIL_CONFIRMED_MESSAGE")
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
              this.alertService.success(data);
              this.getItems();
            });
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  makePrimry(id) {
    this.authService
      .makePrimry(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.getItems(1);
        },
        error: (error) => {
          if (error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  showAction(actionId: number, user: UserData): boolean {
    if (
      !(actionId === ActionEnum.SUSPENDED || actionId === ActionEnum.DEACTIVATE)
    ) {
      return true;
    }

    if (!user.is_primary) {
      return true;
    }

    return false;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
