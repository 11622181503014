import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SortDirection } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import cloneDeep from "lodash-es/cloneDeep";
import unionBy from "lodash-es/unionBy";
import { NgxSmartModalService } from "ngx-smart-modal";
import { finalize } from "rxjs";
import { AlertService } from "@modules/alert";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { ServiceOffer } from "../../../../_models/service-offer";
import { AuthenticationService } from "../../../../_services/authentication.service";
import { ServiceOffersService } from "../../../../_services/service-offers.service";
import { PermissionResourceEnum } from "../../../../modules/shared/_enums/permission-resource.enum";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { ItemsListComponent } from "../../items-list/items-list.component";

@Component({
  selector: "app-service-offers-list",
  templateUrl: "./service-offers-list.component.html",
  styleUrls: ["./service-offers-list.component.css"],
})
export class ServiceOffersListComponent
  extends ItemsListComponent
  implements OnInit
{
  public items: ServiceOffer[];
  active = "";
  direction: SortDirection = "";
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;

  @Input() provider_id;
  @Input() hideTitle = true;

  public resources = PermissionResourceEnum;

  constructor(
    public service: ServiceOffersService,
    private dictionariesService: DictionariesService,
    private route: ActivatedRoute,
    private router: Router,
    private windowResizeHelper: WindowResizeHelperService,
    public ngxSmartModalService: NgxSmartModalService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    public translate: TranslateService,
    public breadcrumbService: BreadcrumbService,
    public auth: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((query) => {
      this.isLoadingGeneral = true;
      this.watchQueryParams(query);
    });

    this.getStatusTransitions();

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });

    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList(
        "/service-offers/list",
        "Service Offers"
      )
    );
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);

    this.router
      .navigate([location.pathname], {
        queryParams: params,
      })
      .then();
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoadingGeneral = true;

    let params = {};
    params = cloneDeep(this.filters);

    if (this.provider_id) {
      params["provider_id"] = this.provider_id;
    }

    this.request_call = this.service
      .getServiceOffers(params)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          // merge status filter -- useful when archiving all offers
          // and wanting to keep the status label in the dropdown
          const oldStatusFilterValues = this.getOldStatusFilterValues();
          this.processResponse(data);
          this.mergeNewStatusFilters(oldStatusFilterValues);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  copyOffer(offerId) {
    this.service.copyOffer(offerId).subscribe((data) => {
      this.isLoadingGeneral = false;
      const params = this.getParams(this.filters.page);
      const url = "/service-offers/" + data.result.id + "/edit";
      this.router
        .navigate([url], {
          queryParams: { ...params, ...{ copy: 1 } },
        })
        .then();
    });
  }

  openConfirmAction(action, offer?) {
    const ids = [];
    if (offer && offer.id) {
      ids.push(offer.id);
    } else {
      this.selection.selected.forEach((row) => ids.push(row.id));
    }

    if (!action.needs_confirmation && !action.needs_reason && action.text) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.isLoadingGeneral = true;
          this.service
            .updateStatusBulk(action.id, ids, result)
            .subscribe(() => {
              this.getItems(1);
              this.isLoadingGeneral = false;
            });
        }
      });
    } else {
      this.isLoadingGeneral = true;
      this.service.updateStatusBulk(action.id, ids, null).subscribe(() => {
        this.getItems(1);
        this.isLoadingGeneral = false;
      });
    }
  }

  openUploadModal() {
    this.ngxSmartModalService.getModal("addDoc").open();
  }

  downloadExcel() {
    this.service.downloadExcel(this.provider_id).subscribe(
      (data) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = "Offers_" + this.provider_id + ".zip";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      },
      () => {
        this.isLoadingGeneral = false;
      }
    );
  }

  private getOldStatusFilterValues() {
    let oldStatusFilters = [];
    let oldStatusFilterIndex = -1;
    if (typeof this.availableFilters === "object") {
      oldStatusFilterIndex = this.availableFilters.findIndex(
        (filter) => filter.id === "status_id"
      );
      if (oldStatusFilterIndex > -1) {
        oldStatusFilters = this.availableFilters[oldStatusFilterIndex].data;
      }
    }

    return oldStatusFilters;
  }

  private mergeNewStatusFilters(oldStatusFilterValues: any[]) {
    const newStatusFilterIdx = this.availableFilters.findIndex(
      (filter) => filter.id === "status_id"
    );
    if (newStatusFilterIdx > -1) {
      this.availableFilters[newStatusFilterIdx].data = unionBy(
        this.availableFilters[newStatusFilterIdx].data,
        oldStatusFilterValues,
        "id"
      );
    }
  }

  /**
   * Export offers to CSV
   */
  export() {
    this.isLoadingGeneral = true;

    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = cloneDeep(this.filters);
    if (this.provider_id) {
      params["provider_id"] = this.provider_id;
    }
    this.request_call = this.service.export(params).subscribe(
      (data: Blob) => {
        const blob = new Blob([data], {
          type: "application/vnd.ms-excel",
        });
        saveAs(blob, "offers.xlsx");
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
