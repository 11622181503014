import { Injectable } from "@angular/core";
import { UserData } from "@models/user.model";
import { BehaviorSubject, Observable, of } from "rxjs";

export const USER_DATA_STORAGE_KEY = "user_data";
export const USER_PERMISSIONS_STORAGE_KEY = "permissions_data";

@Injectable({
  providedIn: "root",
})
export class AccountStoreService {
  user$: BehaviorSubject<UserData | null> = new BehaviorSubject(null);

  constructor() {}

  setUser(user: UserData): void {
    try {
      this.user$.next(user);
      this.saveUser(user);
    } catch (e) {
      return;
    }
  }

  clearStore(): void {
    try {
      this.user$.next(null);
      return localStorage.removeItem(USER_DATA_STORAGE_KEY);
    } catch (e) {
      return;
    }
  }

  saveUser(user: UserData | null): void {
    if (!user) {
      this.clearStore();
      return;
    }
    return localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(user));
  }

  loadUser(): Observable<UserData | null> {
    try {
      const data = localStorage.getItem(USER_DATA_STORAGE_KEY);
      if (!data) {
        throw new Error("No data found");
      }
      return of(JSON.parse(data));
    } catch (e) {
      return of(null);
    }
  }

  getUserSync(): UserData | null {
    return this.user$.getValue();
  }

  getUser(): Observable<UserData | null> {
    return this.user$.asObservable();
  }
}
