import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { merge } from "rxjs";
import { startWith } from "rxjs/operators";

@Component({
  selector: "app-form-errors",
  templateUrl: "./form-errors.component.html",
  styleUrls: ["./form-errors.component.scss"],
  standalone: false,
})
export class FormErrorsComponent implements OnInit {
  public supportedRuleMessage = {
    required: "VALIDATIONS.REQUIRED",
    email: "VALIDATIONS.EMAIL",
    phone: "VALIDATIONS.PHONE",
    min: "VALIDATIONS.MIN",
    fileExtension: "VALIDATIONS.FILE_EXTENSION",
    fileTypeExtension: "VALIDATIONS.FILE_EXTENSION",
    fileSizeExtension: "VALIDATIONS.FILE_MAXIMUM_SIZE",
    minimumServices: "VALIDATIONS.MINIMUM_SERVICES",
    futureDate: "GENERALS.FUTURE-DATE",
  };

  @Input()
  control: AbstractControl;

  message = "";

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (!this.control) {
      return;
    }
    merge(this.control.valueChanges, this.control.statusChanges)
      .pipe(startWith(""))
      .subscribe((data) => {
        try {
          this.updateMessage();
        } catch (e) {
          /* empty */
        }
      });
  }

  updateMessage() {
    try {
      const errors = { ...this.control.errors };
      if (!errors) {
        this.message = "";
      }
      const keys = Object.keys(errors);
      const key = keys.find((k) => !!this.supportedRuleMessage[k]);
      if (!key) {
        this.message = "";
      }
      const message = this.supportedRuleMessage[key];
      this.message = this.translateService.instant(message);
    } catch (e) {
      this.message = "";
    }
  }
}
