import { Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import { UserData } from "@models/user.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
export interface RoleData {
  id: number;
  name: string;
}
@Injectable()
export class AdminService {
  constructor(private http: BackendHttpClient) {}

  // GET Admins
  getAdmins(params = {}): Observable<any> {
    return this.http.get<any>("admins", {
      params: params,
    });
  }

  // GET current user details
  getCurrentUser(id): Observable<any> {
    return this.http.get<any>("users/" + id);
  }

  /**
   *  Update current user
   */
  updateCurrentUser(user): Observable<any> {
    return this.http.patch<any>("users/update-current-user", user).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // Add Admins
  addAdminUser(user: Partial<UserData>): Observable<any> {
    return this.http.post<any>("admins", user);
  }

  /**
   *  Update admin user
   */
  updateAdminUser(user: Partial<UserData>): Observable<any> {
    return this.http.put<any>("users/" + user.id, user).pipe(
      map((model) => {
        return model;
      })
    );
  }

  /**
   *  Update current user password
   */
  updatePassword(params = {}): Observable<any> {
    return this.http.patch<any>("users/update-password", params).pipe(
      map((model) => {
        return model;
      })
    );
  }

  getRoles(): Observable<RoleData[]> {
    return this.http
      .get<DefaultResponseData<RoleData[]>>("admin/roles")
      .pipe(map((data) => data.result));
  }
}
