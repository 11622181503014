import { Component, Input, OnInit } from "@angular/core";
import { Dictionary } from "../../../../_models/dictionary";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { EntityData } from "../../../company/models/entity.data";

@Component({
  selector: "app-add-client-step2",
  templateUrl: "./add-client-step2.component.html",
  styleUrls: ["./add-client-step2.component.css"],
  standalone: false,
})
export class AddClientStep2Component implements OnInit {
  @Input() client: EntityData;
  @Input() edit = false;
  @Input() loaderAnim;
  companyTypes: Dictionary[];
  industriesList: Dictionary[];
  employeesNumber: Dictionary[];
  departments: Dictionary[];
  request_call;
  cities;
  public countries;

  constructor(private dictionariesService: DictionariesService) {}

  ngOnInit() {
    this.getCompanyTypes();
    this.getIndustriesList();
    this.getEmployeesNumber();
    this.getDepartments();
    this.getCountryList();

    if (this.client.default_legal_entity.country_code) {
      this.loadCities();
    }

    if (!this.client.company_type_id) {
      this.client.company_type_id = null;
    }

    if (!this.client.default_legal_entity.country_code.length) {
      this.client.default_legal_entity.country_code = null;
    }

    if (!this.client.default_legal_entity.city.length) {
      this.client.default_legal_entity.city = null;
    }
  }

  getIndustriesList() {
    this.dictionariesService.getIndustriesList().subscribe((data) => {
      this.industriesList = data.result;
    });
  }

  getCompanyTypes() {
    this.dictionariesService.getCompanyTypes().subscribe((data) => {
      this.companyTypes = data.result;
    });
  }

  getEmployeesNumber() {
    this.dictionariesService.getEmployeesNumber().subscribe((data) => {
      this.employeesNumber = data.result;
    });
  }

  getDepartments() {
    this.dictionariesService.getDepartments().subscribe((data) => {
      this.departments = data.result;
    });
  }

  loadCities(search?) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.request_call = this.dictionariesService
      .cities(search || "", this.client.default_legal_entity.country_code)
      .subscribe((response) => {
        if (response.success) {
          this.cities = response.result;
        }
      });
  }

  countryChanged() {
    this.cities = [];

    this.loadCities();
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
      }
    });
  }
}
