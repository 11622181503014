<div>
  <ng-template #t let-fill="fill">
    <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
    </span>
  </ng-template>
  <ngb-rating
    [(rate)]="currentRate"
    [starTemplate]="t"
    [readonly]="true"
    [max]="5"
  ></ngb-rating>
</div>
