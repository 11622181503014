import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "@modules/alert";
import { ClientsService } from "../../../../_services/clients.service";
import { Client } from "../../../../_models/client";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { Router } from "@angular/router";
import { finalize, take } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-add-client",
  templateUrl: "./add-client.component.html",
})
export class AddClientComponent implements OnInit {
  setModal: boolean;
  stept1Done: boolean;
  client: Client;
  countries;
  isCanceled = false;
  clientId;
  isLoadingGeneral = false;

  @Output() getClients: EventEmitter<any> = new EventEmitter();

  constructor(
    private clientsService: ClientsService,
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private dictionariesService: DictionariesService,
    private router: Router
  ) {
    this.setModal = false;
    this.client = new Client();
  }

  ngOnInit() {
    this.getCountryList();
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  createClientStep1() {
    this.isLoadingGeneral = true;
    this.client.name = this.client.first_name + " " + this.client.last_name;

    this.clientsService
      .createClientStep1(this.prepareDataToSave())
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe(
        (data) => {
          this.getClients.emit("");
          this.stept1Done = true;
          this.client = data.result;
          this.clientId = data.result.id;
        },
        (error) => {
          const errors = [];

          Object.keys(error.error.errors).forEach((key) => {
            errors.push({
              message: error.error.errors[key][0],
            });
          });

          this.alertService.errors(errors);
        }
      );
  }

  prepareDataToSave() {
    const dataToSave: any = { ...this.client };

    dataToSave.user = dataToSave.user || {};
    dataToSave.user.first_name = this.client.first_name;
    dataToSave.user.last_name = this.client.last_name;
    dataToSave.user.email = this.client.email;
    dataToSave.user.phone_number = this.client.phone;
    dataToSave.user.phone_prefix = this.client.phone_prefix;

    return dataToSave;
  }

  createClientStep2() {
    this.isLoadingGeneral = true;
    this.clientsService
      .createClientStep2(this.client)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe(
        (data) => {
          this.ngxSmartModalService.getModal("addClient").close();
          this.stept1Done = false;
          this.router.navigate(["/clients/" + this.clientId]);
        },
        (error) => {
          const errors = [];

          Object.keys(error.error.errors).forEach((key) => {
            errors.push({
              message: error.error.errors[key][0],
            });
          });

          this.alertService.errors(errors);
        }
      );
  }

  isModalOpen() {
    this.setModal = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModal = false;
    this.isCanceled = true;
  }
  cancelModal() {
    this.ngxSmartModalService.getModal("addClient").close();
    this.stept1Done = false;
  }

  initEmpty(): void {
    this.client = new Client();
    this.stept1Done = false;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
