<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModal }">
  <ngx-smart-modal
    id="addClient"
    #addClient
    identifier="addClient"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">
        {{ "GENERALS.ADD-CLIENT" | translate }}
      </div>

      <div class="modal-step-wrapper">
        <ul>
          <li [ngClass]="{ 'active-step': !stept1Done }">
            {{ "GENERALS.ACCOUNT" | translate }}
          </li>
          <li [ngClass]="{ 'active-step': stept1Done }">
            {{ "GENERALS.COMPANY" | translate }}
          </li>
        </ul>
      </div>
    </div>
    <div class="clear"></div>
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
    <div class="custom-modal-body" *ngIf="!isLoadingGeneral">
      <!-- add client step 1 -->
      <ng-container *ngIf="!stept1Done">
        <app-add-client-step-1
          [user]="client"
          [isCanceled]="isCanceled"
          [loaderAnim]="isLoadingGeneral"
        ></app-add-client-step-1>
      </ng-container>
      <!-- end add client step 1 -->

      <!-- add client step 2 -->

      <ng-container *ngIf="stept1Done">
        <app-add-client-step2
          [client]="$any(client)"
          [countries]="countries"
          [loaderAnim]="isLoadingGeneral"
        ></app-add-client-step2>
      </ng-container>
      <!-- end add client step 2 -->
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button type="button" class="btn gray-bg" (click)="cancelModal()">
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button
        *ngIf="!stept1Done"
        type="button"
        class="btn orange-bg"
        (click)="createClientStep1()"
      >
        {{ "GENERALS.NEXT" | translate }}
      </button>
      <button
        *ngIf="stept1Done"
        type="button"
        class="btn orange-bg"
        (click)="createClientStep2()"
      >
        {{ "GENERALS.FINISH" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->
