import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { RFQ } from "src/app/_models/rfq";
import { AlertService } from "@modules/alert";
import { ClientsService } from "src/app/_services/clients.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";

@Component({
  selector: "app-rfq-general-info",
  templateUrl: "./rfq-general-info.component.html",
  styleUrls: ["./rfq-general-info.component.css"],
})
export class RFQGeneralInfoComponent implements OnInit, OnChanges {
  @Input() type;
  @Input() set rfq(value) {
    if (value) {
      this.item = value;
    } else {
      this.item = new RFQ();
    }
  }
  search_text: string;
  item;
  countries;
  clients;
  user;
  request_call;
  minStartDate: moment.Moment;
  maxStartDate: moment.Moment;
  minEndDate: moment.Moment;
  maxEndDate: moment.Moment;
  minDeliveryDate: moment.Moment;
  decisionCriteria = [
    { id: "price", label: "RFQS.LOWEST-PRICE" },
    { id: "quality", label: "RFQS.MIX-QUALITY" },
  ];
  currency = [
    { id: 1, label: "USD" },
    { id: 2, label: "EUR" },
    { id: 3, label: "GBP" },
  ];
  numberOfDaysToAdd = 30;
  cities;

  constructor(
    private dictionariesService: DictionariesService,
    private clientService: ClientsService,
    private alertService: AlertService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.minStartDate = moment().add(1, "d");
    this.minEndDate = moment().add(2, "d");
    this.minDeliveryDate = moment().add(3, "d");

    this.getCountryList();
    this.getClients(this.search_text);
  }

  ngOnChanges(changes) {
    if (changes.rfq) {
      this.maxStartDate = null;
      this.maxEndDate = null;
      this.minEndDate = moment().add(1, "d");
    }
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
      }
    });
  }

  getClients(search_text) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = {
      status_id: 3,
    };
    if (search_text) {
      params["search_text"] = search_text.term;
    }
    this.clientService.getClients(params).subscribe((data) => {
      this.clients = data.result.items;
    });
  }

  setFormDate(e, dateType) {
    if (moment.isMoment(e)) {
      switch (dateType) {
        case "delivery":
          this.item.delivery_date = e;

          this.item.start_date = null;
          this.item.end_date = null;

          // // max start date // substract one day to the StartDate
          this.maxStartDate = moment(e).subtract(2, "d");

          // // max end date // substract one day to the EndDate
          this.maxEndDate = moment(e).subtract(1, "d");

          break;

        case "start":
          this.item.start_date = e;
          // add a day to the startDate
          this.minEndDate = moment(e).add(1, "d");

          break;

        case "end":
          this.item.end_date = e;
          // minus one day to the EndDate
          this.maxStartDate = moment(e).subtract(1, "d");

          break;
      }
    }
  }

  setDecisionCriteria() {
    if (this.item["decision_criteria"]) {
      if (this.item["decision_criteria"] === "price") {
        this.item.lowest_price = 1;
      } else {
        this.item.lowest_price = 0;
      }
      this.item.mix_quality_price = +!this.item.lowest_price;
    }
  }

  loadCities(event?) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.request_call = this.dictionariesService
      .cities((event && event.term) || "", this.item.country)
      .subscribe((response) => {
        if (response.success) {
          this.cities = response.result;
        }
      });
  }

  countryChanged() {
    this.cities = [];
    this.item.city = null;
    // this.translate.get('GENERALS.SELECT-CITY').subscribe(data => {
    //   this.item.city = data;
    // });
  }
}
