import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { Commission } from "../../../../_models/providerscommissions";
import { CommissionType } from "../../../../_models/provider-commission";
import { Provider } from "../../../../_models/provider";
import { CommissionsService } from "../../../../_services/commissions.service";
import { LoadingService } from "../../../../_services/loading.service";
import { AlertService } from "@modules/alert";
import { DateService } from "../../../../_services/date.service";
import * as moment from "moment";

@Component({
  selector: "app-add-custom-commission",
  templateUrl: "./add-custom-commission.component.html",
  styleUrls: ["./add-custom-commission.component.css"],
})
export class AddCustomCommissionComponent implements OnInit, OnChanges {
  @Input() category: ServiceCategory;
  @Input() commission: Commission;
  @Input() provider: Provider;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() closeCommission: EventEmitter<any> = new EventEmitter();

  commissionTypes;

  dateOptions = [
    { id: "singleMonth", label: "Only start date" },
    { id: "multipleMonths", label: "Period" },
  ];
  selectedDateOption;
  dateRange;
  dateRangeMultipleMonths;
  minDate;

  action = "add";

  constructor(
    private service: CommissionsService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.commissionTypes = CommissionType.elements;
    this.minDate = moment().add(1, "d");
    this.initEmpty();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.commission.id) {
      this.action = "edit";

      this.commission.provider_commission_id = this.commission.id;

      if (this.commission.end_date) {
        this.dateRangeMultipleMonths = {
          start_date: this.commission.start_date,
          end_date: this.commission.end_date,
        };
        this.selectedDateOption = "multipleMonths";
      } else {
        this.dateRange = this.commission.start_date;
        this.selectedDateOption = "singleMonth";
      }
    }
  }

  initEmpty() {
    this.dateRange = null;
    this.dateRangeMultipleMonths = null;
    this.selectedDateOption = null;
    this.commission = new Commission();
    this.action = "add";
  }

  setDate(e) {
    if (this.selectedDateOption === "multipleMonths") {
      if (!e.start_date || !e.end_date) {
        return;
      }
      this.dateRangeMultipleMonths = {
        start_date: e.start_date,
        end_date: e.end_date,
      };
      this.commission.start_date = e.start_date;
      this.commission.end_date = e.end_date;
    } else if (this.selectedDateOption === "singleMonth") {
      if (!e) {
        return;
      }
      this.commission.start_date = e;
      this.dateRange = e;
    }
  }

  addCommission() {
    this.commission.provider_id = this.provider.id;
    this.commission.service_category_id = this.category.id;

    this.loadingService.loadingStarted.emit();

    this.service.addCommission(this.commission).subscribe(
      (res) => {
        this.save.emit(res.result);
        this.initEmpty();
        this.loadingService.loadingFinished.emit();
      },
      (error) => {
        if (error.error) {
          this.alertService.errors(error.error.errors);
          this.loadingService.loadingFinished.emit();
        }
      }
    );
  }

  updateCommission() {
    this.commission.provider_id = this.provider.id;

    this.service.updateCommission(this.commission).subscribe(
      (res) => {
        this.save.emit(res.result);
        this.initEmpty();
        this.loadingService.loadingFinished.emit();
      },
      (error) => {
        this.alertService.errors(error.error.errors);
        this.loadingService.loadingFinished.emit();
      }
    );
  }
}
