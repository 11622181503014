import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import { AlertService } from "@modules/alert";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { InvoicesService } from "../../../../_services/invoices.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { SortDirection } from "@angular/material/sort";

@Component({
  selector: "app-invoices-log",
  templateUrl: "./invoices-log.component.html",
})
export class InvoicesLogComponent extends ItemsListComponent {
  @Output() shouldUpdateCommissionsRequestsCount: EventEmitter<any> =
    new EventEmitter();

  items: any[];
  request_call;
  active = "";
  direction: SortDirection = "";

  constructor(
    private service: InvoicesService,
    private dictionariesService: DictionariesService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/invoices/log", "invoices log")
    );
    this.route.queryParams.subscribe((query) => {
      this.isLoadingGeneral = true;

      if (query && Object.keys(query).length > 0) {
        this.filters = cloneDeep(query);
      } else {
        this.clearFilters();
        const params = this.getParams(1);
        this.filters = params;
      }
      if (this.filters["per_page"]) {
        this.perPage = this.filters["per_page"];
      }
      if (this.filters["order"]) {
        this.active = this.filters["order"];
        this.direction = this.filters["order_direction"];
      } else {
        this.active = "";
        this.direction = "";
      }

      this.getItems();
    });

    this.showBulkActions = false;
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    const url = "/invoices/log";
    this.router.navigate([url], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = {};
    params = cloneDeep(this.filters);
    this.request_call = this.service.getInvoicesLog(params).subscribe(
      (data) => {
        this.processResponse(data);
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }
}
