<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <ng-container matColumnDef="select">
              <th>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th>
              {{ "TAX.COUNTRY" | translate }}
            </th>
            <th *ngFor="let type of taxTypes">
              {{ "TAX." + type.value | translate }}
            </th>
            <!-- <th mat-sort-header="last_update">
                            {{ 'TAX.LAST_UPDATE' | translate }}
                        </th> -->
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let tax of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? changeSelection(tax) : null"
                [checked]="selection.isSelected(tax)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>
            <td class="gray-pointer">
              {{ tax.name }}
            </td>
            <td class="gray-pointer" *ngFor="let type of tax.taxes">
              {{ type.value }}%
            </td>
            <!-- <td
                          class="gray-pointer"
                        >
                          {{ tax.last_update }}
                          NEEDS UPDATE
                        </td> -->
            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action with overlay -->
              <div toggle-action-table class="table-action-wrapper">
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li (click)="openDialog(tax)">
                      {{ "GENERALS.EDIT" | translate }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>

    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let tax of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(tax) : null"
              [checked]="selection.isSelected(tax)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "TAX.COUNTRY" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ tax.name }}</div>
        </div>
        <div class="responsive-table-row" *ngFor="let type of tax.taxes">
          <div class="responsive-table-header-item">
            {{ "TAX." + type.tax_type | translate }}
          </div>
          <div class="responsive-table-data-item">{{ type.value }}%</div>
        </div>

        <div class="responsive-table-action">
          <span (click)="openDialog(tax)">
            {{ "GENERALS.EDIT" | translate }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
