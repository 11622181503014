<!-- add expat form -->
<app-add-expat-form
  *ngIf="stage === 'newExpat'"
  [expat]="expat.data"
  (emitter)="addedExpat($event)"
></app-add-expat-form>
<!-- end add expat form -->
<!-- contact client/provider modal -->

<div class="dialog" *ngIf="stage === 'contact'">
  <app-dialog-header>
    {{ "GENERALS.ABOUT-THE" | translate }} {{ role }}
  </app-dialog-header>
  <div class="dialog-body">
    <!-- contact provider modal -->
    <app-user-details-page
      *ngIf="role === 'provider'"
      [entity]="entity"
    ></app-user-details-page>
    <!--  end contact provider modal -->

    <!-- contact expat modal -->
    <app-expat-details-page
      *ngIf="role === 'expat'"
      [entity]="entity"
    ></app-expat-details-page>
    <!--  end contact expat modal -->
  </div>
  <div class="dialog-footer">
    <button class="btn gray-bg fl-l" (click)="onNoClick()">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button class="btn orange-bg fl-r" matDialogClose (click)="navigateTo()">
      {{ "GENERALS.SEE-MORE" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>

<!-- end contact client/provider modal -->
