import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "../../../../modules/shared/_enums/permission-resource.enum";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@modules/alert";
import { CommissionsService } from "../../../../_services/commissions.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { AuthenticationService } from "../../../../_services/authentication.service";
import { CommissionRequest } from "../../../../_models/commission-request";
import { MatDialog } from "@angular/material/dialog";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { SortDirection } from "@angular/material/sort";

@Component({
  selector: "app-commissions-requests",
  templateUrl: "./commissions-requests.component.html",
})
export class CommissionsRequestsComponent extends ItemsListComponent {
  public resource = PermissionResourceEnum;
  public subResource = PermissionSecondaryResourceEnum;
  public action = PermissionActionEnum;

  @Input() hideFilters: boolean;
  @Output() shouldUpdateCommissionsRequestsCount: EventEmitter<any> =
    new EventEmitter();

  items: CommissionRequest[];
  isFiltersLoaded: boolean = false;
  request_call;
  active = "";
  direction: SortDirection = "";

  constructor(
    private service: CommissionsService,
    private dictionariesService: DictionariesService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private breadcrumbService: BreadcrumbService,
    public authService: AuthenticationService
  ) {
    super();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForCommissions(
        "/commissions/approve",
        "approve"
      )
    );

    this.route.queryParams.subscribe((query) => {
      this.watchQueryParams(query);
    });

    this.showBulkActions = true;
  }

  ngOnInit(): void {
    this.getStatusTransitions();
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    const url = "/commissions/approve";
    this.router.navigate([url], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoadingGeneral = true;

    this.request_call = this.service
      .getRequests(this.filters)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  protected getService() {
    return this.service;
  }

  public getStatusTransitions() {
    this.service.getRequestsStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
