import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { EntityTypeEnum } from "src/app/modules/shared/_enums/entity-type.enum";
import { Subscription } from "src/app/_models/subscription";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  readonly ROOT_ROUTE = "subscriptions";

  constructor(private http: HttpClient) {}

  getSubscriptions(): Observable<any> {
    return this.http
      .get<{ result: any }>(environment.gateway_endpoint + this.ROOT_ROUTE)
      .pipe(map((data) => data.result));
  }

  cancelSubscriptions(
    entityId: number,
    entityType: EntityTypeEnum
  ): Observable<any> {
    return this.http.post<{ result: any }>(
      environment.gateway_endpoint + this.ROOT_ROUTE + "/cancel",
      { entity_id: entityId, entity_type: entityType }
    );
  }

  getUserSubscription(
    entityId: string,
    entityType: EntityTypeEnum
  ): Observable<{ subscription?: Subscription }> {
    return this.http.get<{ subscription: Subscription }>(
      environment.gateway_endpoint + this.ROOT_ROUTE + "/current",
      {
        params: {
          entity_type: entityType,
          entity_id: entityId,
        },
      }
    );
  }
}
