export class ServiceCategory {
  id: number;
  parent_id: number;
  name: string;
  label: string;
  details: string;
  children: Array<ServiceCategory>;
  status: string;
  priority: number;
  commission: number;
  start_date: string;
  end_date: string;
  children_count: number;
  level: number;
  category_id: number;
  drupal_url: string;
  service_types: string[];
  data: any;
  icon: string;
  path: string;
  page_uri: string;
  seo_title: string;
  commission_type_id: number;
  seo_description: string;
  commission_type: any;
}
