<ng-container *ngIf="user$ | async as user">
  <!-- top icons -->
  <!-- user details -->
  <div class="header-top-user fl-l">
    <div class="header-top-user-info fl-l">
      <div class="fw-500">{{ user?.name }}</div>
      <div class="gray">{{ user?.role }}</div>
    </div>
    <div class="header-top-user-img fl-l">
      <img
        [src]="
          userPic?.primary_image_path
            ? userPic.primary_image_path
            : '../assets/images/profile-pics-placeholder.jpg'
        "
        alt="{{ user?.name }}"
        class="user-img"
      />
    </div>
    <div class="header-top-menu fl-l">
      <i
        (click)="userMenuIsOpen = !userMenuIsOpen"
        [matMenuTriggerFor]="userMenu"
        class="icon-angle-down"
      ></i>
    </div>
  </div>

  <!-- marketplace-header top overlay -->

  <!-- log out overlay -->
  <mat-menu
    class="custom-mat-menu-wrapper"
    #userMenu
    [overlapTrigger]="false"
    (closed)="userMenuIsOpen = false"
  >
    <div *ngIf="xs" class="header-top-user-details-responsive">
      <div class="header-top-user-img-responsive">
        <img
          [src]="
            userPic?.primary_image_path
              ? userPic.primary_image_path
              : '../assets/images/profile-pics-placeholder.jpg'
          "
          alt="{{ user?.name }}"
          class="user-img"
        />
      </div>
      <div class="header-top-user-info-responsive">
        {{ user?.name }}
      </div>
    </div>
    <div class="custom-mat-menu-item" mat-menu-item [routerLink]="['/account']">
      {{ "LABEL.ACCOUNT-SETTINGS" | translate }}
    </div>
    <div class="custom-mat-menu-item" mat-menu-item (click)="logout()">
      {{ "AUTH.LOGOUT" | translate }}
    </div>
  </mat-menu>
  <!-- end log out overlay -->

  <!-- end marketplace-header top overlay -->
</ng-container>
