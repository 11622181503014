import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AccountService } from "@api/account";
import { CookieService } from "ngx-cookie-service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ResetPassword } from "../_models/reset-password";
import { UserData } from "../_models/user.model";
import {
  PermissionActionEnum,
  PermissionSecondaryResourceEnum,
} from "../modules/shared/_enums/permission-resource.enum";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class AuthenticationService {
  public currentUser: Observable<UserData>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    public ngxSmartModalService: NgxSmartModalService,
    public dialog: MatDialog,
    private accountService: AccountService
  ) {
    this.currentUser = this.accountService.account$;
  }

  public setOptions() {
    const x_client_secret = btoa(
      environment.client_secret +
        ":" +
        environment.client_id +
        ":" +
        environment.role
    );

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Client-Secret": x_client_secret,
      }),
    };
  }

  /**
   * Clear Storage
   */
  removeLocalStorage() {
    localStorage.removeItem(environment.session_local_storage_key);
  }

  /**
   * Clear Cookies
   */
  removeCookies() {
    this.cookieService.delete(
      environment.session_local_storage_key,
      "/",
      environment.domain
    );
  }

  abort404() {
    this.router.navigate(["/page/not-found"]);
  }

  abort403() {
    this.router.navigate(["/page/forbidden"]);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "/resetPassword",
        resetPassword,
        this.setOptions()
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  sendResetPasswordEmail(email) {
    return this.http
      .post<any>(environment.gateway_endpoint + "users/password/request", {
        username: email,
      })
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  resendConfirmationMail(id) {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "users/" +
          id +
          "/send-confirmation-mail",
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Confirm a user's email address as an admin
   */
  confirmEmail(id) {
    return this.http
      .get<any>(
        environment.gateway_endpoint + "users/" + id + "/confirm",
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  makePrimry(id) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "users/" + id + "/make-primary-user",
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Check if the user has a specific permission
   */
  public $can(
    action: string,
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    const permission = `${action} ${resource} ${secondaryResource}`;
    return this.currentUser.pipe(
      map((user) => user.permissions.indexOf(permission) > -1)
    );
  }

  /**
   * Check if the user has view access on a resource
   */
  public $hasResource(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.VIEW,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user has view access on a secondary resource
   */
  public $hasSecondaryResource(
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    return this.$can(PermissionActionEnum.VIEW, resource, secondaryResource);
  }

  /**
   * Check if the user is allowed to create a resource
   */
  public $canCreate(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.CREATE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user is allowed to update a resource
   */
  public $canUpdate(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.UPDATE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user is allowed to manage a resource
   */
  public $canManage(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.MANAGE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }
}
