import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserData } from "../_models/user.model";

export interface UpdateUserPasswordRequest {
  user_id: number;
  password: string;
  password_confirmation: string;
}

@Injectable()
export class UsersService {
  private url = "users";

  constructor(private http: BackendHttpClient) {}

  /**
   * List users
   * with search, filters and pagination
   */
  list(params = {}): Observable<any> {
    return this.http.get<any>(this.url, {
      params: params,
    });
  }

  /**
   * Get user details
   * with search, filters and pagination
   */
  getUserDetails(user_id): Observable<any> {
    return this.http.get<any>(this.url + "/" + user_id);
  }

  /**
   * Get user search fields
   */
  getSearchFields(): Observable<any> {
    return this.http.get("entities/expats/search-fields");
  }

  // Update user details
  updateUserDetails(user: UserData): Observable<any> {
    return this.http.put<any>("users/" + +user.id, user).pipe(
      map((model) => {
        return model;
      })
    );
  }

  /**
   * PUT Update User for client
   */
  updateUserAccount(user: UserData): Observable<any> {
    return this.http.put<any>("users/" + user.id, user).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // Update user Status
  updateStatusBulk(status_id, user_id, reason = null): Observable<any> {
    const body = { status_id: status_id, user_id: user_id };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch<any>("users/status-update", body);
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get("users/statuses-transition");
  }

  getUserRoles(type): Observable<any> {
    return this.http.get(type + "/roles");
  }

  updateUserPassword(data: UpdateUserPasswordRequest): Observable<void> {
    return this.http.patch<void>("users/update-password", data);
  }
}
