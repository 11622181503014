<div
  class="category-tree-group"
  *ngFor="let item of categories | keyvalue"
  (window:resize)="onResize()"
>
  <div
    toggle-row
    class="toggle-row-action"
    *ngIf="$any(item.value).active_children_count"
  >
    <i class="icon-plus-1"></i>
  </div>
  <div class="tree-row">
    <div
      class="tree-check fl-l"
      *ngIf="
        (!edit && !$any(item.value).active_children_count) ||
        (!readonlySelection.isSelected($any(item.value)) &&
          !$any(item.value).active_children_count)
      "
    >
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? changeSelection($any(item.value)) : null"
        [checked]="selection.isSelected($any(item.value))"
        [disableRipple]="true"
      >
      </mat-checkbox>
    </div>
    <div
      class="tree-check dark-green fl-l"
      *ngIf="
        edit &&
        readonlySelection.isSelected($any(item.value)) &&
        !$any(item.value).active_children_count
      "
    >
      <i class="icon-ok"></i>
    </div>

    <span
      class="tree-label fl-l"
      [ngClass]="{ 'fw-500': $any(item.value).level === 0, 'fs-15': isRFQ }"
      >{{ $any(item.value).label }}</span
    >

    <ng-container *ngFor="let selected of previouslySelectedCategory">
      <ng-container
        *ngIf="$any(item.value).id === selected.service_category_id"
      >
        <ng-container
          class="fs-16"
          *ngTemplateOutlet="
            templateRef;
            context: $any({ id: selected.service_category_id })
          "
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="toggle-tree" *ngIf="$any(item.value).active_children_count">
    <app-categories-tree
      [serviceCategories]="$any(item.value).children"
      [selectedServiceCategories]="previouslySelectedCategory"
      [isRFQ]="isRFQ"
      [edit]="edit"
      (onselect)="onselect.emit($event)"
      [templateRef]="templateRef"
    ></app-categories-tree>
  </div>
</div>
