import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { saveAs } from "file-saver";
import { AlertService } from "@modules/alert";
import { DocumentsService } from "src/app/_services/documents.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ProvidersService } from "../../../../_services/providers.service";

@Component({
  selector: "app-provider-documents",
  templateUrl: "./provider-documents.component.html",
  styleUrls: ["./provider-documents.component.css"],
})
export class ProviderDocumentsComponent implements OnInit {
  public id: number;
  agreements;
  isLoadingGeneral = false;
  selectedAgreement;
  isXs: boolean;

  constructor(
    private route: ActivatedRoute,
    private windowResizeHelper: WindowResizeHelperService,
    private alertService: AlertService,
    protected service: DocumentsService,
    private serviceProvider: ProvidersService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.serviceProvider.getProvider(this.id).subscribe((data) => {
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForProvider(data.result, "Agreements")
          );
        });
        this.getAgreements();
      }
    });
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  getAgreements() {
    this.isLoadingGeneral = true;
    this.service.getAgreementsList(this.id).subscribe(
      (res) => {
        this.agreements = res.result;
        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  downloadAgreement(agreement_id, name) {
    this.service.downloadAgreementPDF(this.id, agreement_id).subscribe(
      (res) => {
        const blob = new Blob([res], { type: "text/csv" });
        saveAs(blob, name + ".pdf");
        // console.warn(res, 'download');
      },
      (error) => {
        console.warn(error, " errordownload");
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
