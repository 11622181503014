import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class AdsService {
  private bannerType = "ads/banner-types";
  private adsUrl = "ads/banners";

  constructor(private http: HttpClient) {}

  // GET List Banner Types
  getListBannerType(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.bannerType);
  }

  // Update Banner
  updateBanner(item): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.bannerType + "/" + item.id,
        item,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // GET List Ad Banners
  getListAdBanner(params): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.adsUrl, {
      params: params,
    });
  }

  // Update Ad Banner Status

  updateAdBanner(status, id): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + this.adsUrl + "/" + id,
        status,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // GET List Ad-Banners Status Transitions

  getListAdBannersStatusTransitions(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.adsUrl + "/status-transitions"
    );
  }
}
