import { DragDropModule } from "@angular/cdk/drag-drop";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  isDevMode,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

// ########## LIBRARY ##########
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from "ngx-cookie-service";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// ########## COMPONENTS ##########
import { ChipsSelectComponent } from "./components/chips-select/chips-select.component";
import { CookieConsentComponent } from "./components/cookie-consent/cookie-consent.component";
import { ActivityComponent } from "./components/dashboard/activity/activity.component";
import { AddDocComponent } from "./components/dashboard/add-doc/add-doc.component";
import { AddProfilePictureComponent } from "./components/dashboard/add-profile-picture/add-profile-picture.component";
import { BreadcrumbComponent } from "./components/dashboard/breadcrumbs/breadcrumbs.component";
import { AddClientStep1Component } from "./components/dashboard/clients/add-client-step-1/add-client-step-1.component";
import { AddClientComponent } from "./components/dashboard/clients/add-client/add-client.component";
import { ClientActivityComponent } from "./components/dashboard/clients/client-activity/client-activity.component";
import { ClientDocumentsComponent } from "./components/dashboard/clients/client-documents/client-documents.component";
import { ClientInviteComponent } from "./components/dashboard/clients/client-invite/client-invite.component";
import { ClientOrdersComponent } from "./components/dashboard/clients/client-orders/client-orders.component";
import { ClientUsersComponent } from "./components/dashboard/clients/client-users/client-users.component";
import { ClientComponent } from "./components/dashboard/clients/client/client.component";
import { ClientsComponent } from "./components/dashboard/clients/index/clients.component";
import { AdCampaignsComponent } from "./components/dashboard/cms/ad-campaigns/ad-campaigns.component";
import { AddCategoryComponent } from "./components/dashboard/cms/add-category/add-category.component";
import { AddSubCategoryComponent } from "./components/dashboard/cms/add-subcategory/add-subcategory.component";
import { AdsWrapperComponent } from "./components/dashboard/cms/ads-wrapper/ads-wrapper.component";
import { AdsComponent } from "./components/dashboard/cms/ads/ads.component";
import { EditUnitPriceModalComponent } from "./components/dashboard/cms/ads/edit-unit-price-modal/edit-unit-price-modal.component";
import { CategoriesTreeComponent } from "./components/dashboard/cms/categories/categories-tree/categories-tree.component";
import { CategoriesComponent } from "./components/dashboard/cms/categories/categories.component";
import { CategoryItemComponent } from "./components/dashboard/cms/categories/category-item/category-item.component";
import { DetailsCampaignComponent } from "./components/dashboard/cms/details-campaign/details-campaign.component";
import { DocumentationStandardsComponent } from "./components/dashboard/cms/documentation-standards/documentation-standards.component";
import { CmsComponent } from "./components/dashboard/cms/index/cms.component";
import { PreviewBannerComponent } from "./components/dashboard/cms/preview-banner/preview-banner.component";
import { AddCustomCommissionComponent } from "./components/dashboard/commissions/add-custom-commission/add-custom-commission.component";
import { CommissionsBilledComponent } from "./components/dashboard/commissions/billed/commissions-billed.component";
import { CommissionsWrapperComponent } from "./components/dashboard/commissions/commissions-wrapper.component";
import { CommissionsListComponent } from "./components/dashboard/commissions/list/commissions-list.component";
import { CommissionsRequestsComponent } from "./components/dashboard/commissions/requests/commissions-requests.component";
import { DashboardComponent } from "./components/dashboard/dashboard/dashboard.component";
import { DetailsNavigationComponent } from "./components/dashboard/details-navigation/details-navigation.component";
import { DocumentsComponent } from "./components/dashboard/documents/documents.component";
import { AddExpatForm } from "./components/dashboard/expats/add-expat-form/add-expat-form.component";
import { ExpatActivityComponent } from "./components/dashboard/expats/expat-activity/expat-activity.component";
import { ExpatDetailsComponent } from "./components/dashboard/expats/expat-details/expat-details.component";
import { ExpatDocumentsComponent } from "./components/dashboard/expats/expat-documents/expat-documents.component";
import { ExpatComponent } from "./components/dashboard/expats/expat/expat.component";
import { ExpatsComponent } from "./components/dashboard/expats/expats.component";
import { HeaderXsComponent } from "./components/dashboard/header/header-xs/header-xs.component";
import { HeaderComponent } from "./components/dashboard/header/header.component";
import { TopMenuComponent } from "./components/dashboard/header/top-menu/top-menu.component";
import { InvoiceComponent } from "./components/dashboard/invoices/invoice/invoice.component";
import { InvoicesListComponent } from "./components/dashboard/invoices/list/invoices-list.component";
import { InvoicesLogComponent } from "./components/dashboard/invoices/log/invoices-log.component";
import { FilterProviderNameComponent } from "./components/dashboard/items-list/filters/provider-name/filter-provider-name.component";
import { FilterStatusComponent } from "./components/dashboard/items-list/filters/statuses/filter-status.component";
import { ItemsListComponent } from "./components/dashboard/items-list/items-list.component";
import { LeftNavigationXsComponent } from "./components/dashboard/left-navigation-xs/left-navigation-xs.component";
import { LeftNavigationComponent } from "./components/dashboard/left-navigation/left-navigation.component";
import { OrderActivityComponent } from "./components/dashboard/orders/order-activity/order-activity.component";
import { OrderDetailsComponent } from "./components/dashboard/orders/order-details/order-details.component";
import { OrderDocumentsComponent } from "./components/dashboard/orders/order-documents/order-documents.component";
import { OrderInvoicesComponent } from "./components/dashboard/orders/order-invoices/order-invoices.component";
import { OrderPopupComponent } from "./components/dashboard/orders/order-popup/order-popup.component";
import { OrderComponent } from "./components/dashboard/orders/order/order.component";
import { OrdersComponent } from "./components/dashboard/orders/orders.component";
import { AddCategoriesComponent } from "./components/dashboard/providers/add-categories/add-categories.component";
import { AddCommissionsComponent } from "./components/dashboard/providers/add-commissions/add-commissions.component";
import { AddProviderStep1Component } from "./components/dashboard/providers/add-provider-step-1/add-provider-step-1.component";
import { AddProvidersComponent } from "./components/dashboard/providers/add-provider/add-provider.component";
import { CommissionComponent } from "./components/dashboard/providers/commission/commission.component";
import { ProvidersComponent } from "./components/dashboard/providers/index/providers.component";
import { ProviderActivityComponent } from "./components/dashboard/providers/provider-activity/provider-activity.component";
import { ProviderCategoriesTreeComponent } from "./components/dashboard/providers/provider-categories-tree/provider-categories-tree.component";
import { ProviderCategoriesComponent } from "./components/dashboard/providers/provider-categories/provider-categories.component";
import { ProviderDocumentsComponent } from "./components/dashboard/providers/provider-documents/provider-documents.component";
import { ProviderInviteComponent } from "./components/dashboard/providers/provider-invite/provider-invite.component";
import { ProviderOffersComponent } from "./components/dashboard/providers/provider-offers/provider-offers.component";
import { ProviderOrdersComponent } from "./components/dashboard/providers/provider-orders/provider-orders.component";
import { ProviderUsersComponent } from "./components/dashboard/providers/provider-users/provider-users.component";
import { ProviderComponent } from "./components/dashboard/providers/provider/provider.component";
import { UserAgreementsComponent } from "./components/dashboard/providers/user-agreements/user-agreements.component";
import { ResetPassModalComponent } from "./components/dashboard/reset-pass-modal/reset-pass-modal.component";
import { AddPriceComponent } from "./components/dashboard/service-offers/add-service-offer/add-price/add-price.component";
import { AddServiceOfferComponent } from "./components/dashboard/service-offers/add-service-offer/add-service-offer.component";
import { CategorySelectionComponent } from "./components/dashboard/service-offers/add-service-offer/category-selection/category-selection.component";
import { CharacteristicFormComponent } from "./components/dashboard/service-offers/add-service-offer/characteristic-form/characteristic-form.component";
import { AssociatedOffersComponent } from "./components/dashboard/service-offers/associated-offers/associated-offers.component";
import { BulkUploadErrorsModalComponent } from "./components/dashboard/service-offers/bulk-upload-errors-modal/bulk-upload-errors-modal.component";
import { BulkUploadOffersComponent } from "./components/dashboard/service-offers/bulk-upload-offers/bulk-upload-offers.component";
import { AddBundleComponent } from "./components/dashboard/service-offers/bundles/add-bundle.component";
import { OfferActivityComponent } from "./components/dashboard/service-offers/offer-activity/offer-activity.component";
import { OfferHistoryComponent } from "./components/dashboard/service-offers/offer-history/offer-history.component";
import { ReviewsOffersComponent } from "./components/dashboard/service-offers/reviews-offers/reviews-offers.component";
import { ServiceOfferComponent } from "./components/dashboard/service-offers/service-offer/service-offer.component";
import { AvailabilityFormComponent } from "./components/dashboard/service-offers/service-offers-details/availability-form/availability-form.component";
import { ServiceOfferDetailsComponent } from "./components/dashboard/service-offers/service-offers-details/service-offers-details.component";
import { ServiceOffersListComponent } from "./components/dashboard/service-offers/service-offers-list/service-offers-list.component";
import { ServiceOffersComponent } from "./components/dashboard/service-offers/service-offers.component";
import { reducers } from "./components/dashboard/state";
import { WidgetsEffects } from "./components/dashboard/state/effects/widget.effects";
import { StatisticsDateFilterComponent } from "./components/dashboard/statistics-date-filter/statistics-date-filter.component";
import { AddUserFormComponent } from "./components/dashboard/users/add-user-form/add-user-form.component";
import { AddUserStep1Component } from "./components/dashboard/users/add-user-step1/add-user-step1.component";
import { CompanyUserDetailsComponent } from "./components/dashboard/users/company-user-details/company-user-details.component";
import { EditPrimaryUserComponent } from "./components/dashboard/users/edit-primary-user/edit-primary-user.component";
import { UserDetailsActionsPanelComponent } from "./components/dashboard/users/user-details/user-details-actions-panel/user-details-actions-panel.component";
import { UserDetailsComponent } from "./components/dashboard/users/user-details/user-details.component";
import { UserComponent } from "./components/dashboard/users/user/user.component";
import { UsersListComponent } from "./components/dashboard/users/users-list/users-list.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AddRFQOfferComponent } from "./components/RFQS/add-rfq-offer/add-rfq-offer.component";
import { AddRFQBidsComponent } from "./components/RFQS/add-rfqs-bids/add-rfqs-bids.component";
import { FinalizeRFQComponent } from "./components/RFQS/finalize-rfq/finalize-rfq.component";
import { RFQSComponent } from "./components/RFQS/index/rfqs.component";
import { InviteProvidersComponent } from "./components/RFQS/invite-providers/invite-providers.component";
import { QuotationsComponent } from "./components/RFQS/quotations/quotations.component";
import { RFQDetailsActionsPanelComponent } from "./components/RFQS/rfq-details/rfq-details-actions-panel/rfq-details-actions-panel.component";
import { RFQDetailsComponent } from "./components/RFQS/rfq-details/rfq-details.component";
import { RFQGeneralInfoComponent } from "./components/RFQS/rfq-general-info/rfq-general-info.component";
import { RFQHistoryComponent } from "./components/RFQS/rfq-history/rfq-history.component";
import { RFQServiceOffersComponent } from "./components/RFQS/rfq-service-offers/rfq-service-offers.component";
import { RFQComponent } from "./components/RFQS/rfq/rfq.component";
// ########## LAYOUT ##########
import { CdkDetailRowDirective } from "./_directives/cdk-detail-row.directive";
import { ExpandMenuDirective } from "./_directives/expand-menu";
import { DashboardLayoutComponent } from "./layout/dashboard-layout/dashboard-layout.component";
import { CompanyModule } from "./modules/company/company.module";
import { SharedModule } from "./modules/shared/shared.module";
// ########## DIRECTIVES ##########
import { LoaderSpinerComponent } from "./_directives/loader-spinner/loader-spinner.component";
import { TableWidthDirective } from "./_directives/table-width";
import { ToggleActionInnerDirective } from "./_directives/toggle-action-inner";
import { ToggleRowDirective } from "./_directives/toggle-row";
import { TranslateComponent } from "./_directives/translate/translate.component";
import { WindowResizeDirective } from "./_directives/window-resize";
import { WindowScrollAppNavDirective } from "./_directives/window-scroll-app-nav";
import { WindowScrollDashboardBodyActionDirective } from "./_directives/window-scroll-dashboardBodyAction";
import { WindowScrollDashboardBodyTopDirective } from "./_directives/window-scroll-dashboardBodyTop";
import { WindowScrollTableBulkDirective } from "./_directives/window-scroll-table-bulk";
// ########## HELPERS ##########
import { RfqHelper } from "./_helpers/_models/rfq.helper";
import { CompareHelper } from "./_helpers/compare.helper";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { QueueInterceptor } from "./_helpers/queue.interceptor";
import { GetWorkflowStatusPipe } from "./_pipes/get-workflow-status.pipe";
import { KeysPipe } from "./_pipes/keys.pipe";
// ########## PIPES ##########
import { FilterPipe } from "./_pipes/search.pipe";
import { SortDatePipe } from "./_pipes/sortDate.pipe";
import { ActivityService } from "./_services/activity.service";
import { AdminService } from "./_services/admins.service";
import { AdsService } from "./_services/ads.service";
// ########## SERVICES ##########
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { TranslateHttpClient } from "@api/http";
import { AuthInterceptor } from "@api/http/interceptors/auth.interceptor";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { environment } from "src/environments/environment";
import { SortByPipe } from "./_pipes/sorty-by.pipe";
import { AuthenticationService } from "./_services/authentication.service";
import { BreadcrumbService } from "./_services/breadcrumb.service";
import { ClientsService } from "./_services/clients.service";
import { CommissionsService } from "./_services/commissions.service";
import { CrmService } from "./_services/crm.service";
import { DateService } from "./_services/date.service";
import { DictionariesService } from "./_services/dictionaries.service";
import { DocumentsService } from "./_services/documents.service";
import { ExpatsService } from "./_services/expats.service";
import { GoogleAnalyticsService } from "./_services/google-analytics.service";
import { InvoicesService } from "./_services/invoices.service";
import { LoadingService } from "./_services/loading.service";
import { MenuBadgesService } from "./_services/menu-badges.service";
import { OrderCommissionsService } from "./_services/order-commissions.service";
import { OrdersService } from "./_services/orders.service";
import { ProvidersService } from "./_services/providers.service";
import { RFQSService } from "./_services/rfqs.service";
import { ServiceCategoriesService } from "./_services/service-categories.service";
import { ServiceOffersService } from "./_services/service-offers.service";
import { ShareDataService } from "./_services/share-data.service";
import { StatisticsService } from "./_services/statistics.service";
import { UsersService } from "./_services/users.service";
import { WindowResizeHelperService } from "./_services/window-resize-helper.service";
import { EditTaxRatesComponent } from "./components/dashboard/cms/edit-tax-rates/edit-tax-rates.component";
import { TaxRatesComponent } from "./components/dashboard/cms/tax-rates/tax-rates.component";
import { ClientPlansComponent } from "./components/dashboard/pricing/client-plans/client-plans.component";
import { PricingComponent } from "./components/dashboard/pricing/pricing/pricing.component";
import { ProviderPlansComponent } from "./components/dashboard/pricing/provider-plans/provider-plans.component";
import { HubspotDialogComponent } from "./components/dashboard/users/hubspot-dialog/hubspot-dialog.component";
import { ApiModule } from "@api/api.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AppEffects } from "./app.effects";
import { DialogModule } from "./modules/shared/modules/dialog/dialog.module";
import { AlertModule } from "@modules/alert";
import { ErrorInterceptor } from "@api/http/interceptors/error.interceptor";

export function HttpLoaderFactory(http: TranslateHttpClient) {
  return new TranslateHttpLoader(http);
}
const initializeKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: {
      realm: "xpath",
      url: environment.realm_url,
      clientId: "admin-frontend",
    },
    initOptions: {
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/assets/silent-check-sso.html",
    },
  });

@NgModule({
  declarations: [
    AppComponent,

    // ########## LAYOUT ##########
    DashboardLayoutComponent,
    // ########## COMPONENTS ##########
    DashboardComponent,
    TranslateComponent,
    HeaderComponent,
    HeaderXsComponent,
    LeftNavigationComponent,
    LeftNavigationXsComponent,
    ProvidersComponent,
    ProviderComponent,
    AddProvidersComponent,
    AddPriceComponent,
    AddDocComponent,
    ClientsComponent,
    ClientComponent,
    AddClientComponent,
    CmsComponent,
    AddCategoryComponent,
    AddSubCategoryComponent,
    DocumentationStandardsComponent,
    AddCommissionsComponent,
    AddUserStep1Component,
    ServiceOffersComponent,
    ServiceOffersListComponent,
    ServiceOfferComponent,
    AvailabilityFormComponent,
    AddServiceOfferComponent,
    CharacteristicFormComponent,
    ServiceOfferDetailsComponent,
    OrdersComponent,
    OrderDetailsComponent,
    OrderPopupComponent,
    UserDetailsComponent,
    HubspotDialogComponent,
    UsersListComponent,
    ProviderOffersComponent,
    ExpatsComponent,
    ActivityComponent,
    ExpatActivityComponent,
    ClientActivityComponent,
    OfferActivityComponent,
    OrderInvoicesComponent,
    ClientDocumentsComponent,
    ProviderDocumentsComponent,
    AddUserFormComponent,
    CommissionComponent,
    ChipsSelectComponent,
    AddExpatForm,
    UserDetailsActionsPanelComponent,
    ProviderCategoriesComponent,
    ProviderCategoriesTreeComponent,
    BreadcrumbComponent,
    TopMenuComponent,
    CompanyUserDetailsComponent,
    ExpatComponent,
    ExpatDetailsComponent,
    UserComponent,
    ProviderInviteComponent,
    AddCategoriesComponent,
    StatisticsDateFilterComponent,
    RFQComponent,
    RFQSComponent,
    AddRFQBidsComponent,
    RFQDetailsComponent,
    RFQDetailsActionsPanelComponent,
    CategoriesTreeComponent,
    InviteProvidersComponent,
    RFQGeneralInfoComponent,
    RFQServiceOffersComponent,
    QuotationsComponent,
    AddRFQOfferComponent,
    RFQHistoryComponent,
    BulkUploadOffersComponent,
    FinalizeRFQComponent,
    ExpatDocumentsComponent,
    AddBundleComponent,
    AssociatedOffersComponent,
    ResetPassModalComponent,
    AddProviderStep1Component,
    AddClientStep1Component,
    EditPrimaryUserComponent,
    // ########## PIPES ##########
    FilterPipe,
    SortDatePipe,
    KeysPipe,

    // ########## DIRECTIVES ##########
    LoaderSpinerComponent,
    ExpandMenuDirective,
    ToggleRowDirective,
    WindowScrollDashboardBodyTopDirective,
    WindowScrollDashboardBodyActionDirective,
    WindowScrollTableBulkDirective,
    WindowResizeDirective,
    TableWidthDirective,
    ToggleActionInnerDirective,
    CdkDetailRowDirective,
    DetailsNavigationComponent,
    OrderComponent,
    DocumentsComponent,
    ItemsListComponent,
    OrderDocumentsComponent,
    CategorySelectionComponent,
    ProviderUsersComponent,
    ClientUsersComponent,
    ClientOrdersComponent,
    ProviderOrdersComponent,
    CategoriesComponent,
    CategoryItemComponent,
    FilterProviderNameComponent,
    FilterStatusComponent,
    CommissionsWrapperComponent,
    CommissionsListComponent,
    CommissionsRequestsComponent,
    CommissionsBilledComponent,
    ClientInviteComponent,
    InvoicesListComponent,
    InvoiceComponent,
    InvoicesLogComponent,
    WindowScrollAppNavDirective,
    NotFoundComponent,
    AddProfilePictureComponent,
    CookieConsentComponent,
    ReviewsOffersComponent,
    AdsComponent,
    EditUnitPriceModalComponent,
    AdsWrapperComponent,
    AdCampaignsComponent,
    OfferHistoryComponent,
    DetailsCampaignComponent,
    PreviewBannerComponent,
    UserAgreementsComponent,
    BulkUploadErrorsModalComponent,
    AddCustomCommissionComponent,
    OrderActivityComponent,
    ProviderActivityComponent,
    GetWorkflowStatusPipe,
    SortByPipe,
    HubspotDialogComponent,
    PricingComponent,
    ClientPlansComponent,
    ProviderPlansComponent,
    TaxRatesComponent,
    EditTaxRatesComponent,
  ],
  imports: [
    NgxMatSelectSearchModule,
    NgbModule,
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    DragDropModule,
    ApiModule,
    KeycloakAngularModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([WidgetsEffects, AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    // ########## LIBRARY ##########
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TranslateHttpClient],
      },
    }),
    NgxSmartModalModule.forRoot(),
    FileUploadModule,
    SharedModule,
    CompanyModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    DialogModule,
    AlertModule.forRoot(),
  ],
  providers: [
    AuthenticationService,
    ProvidersService,
    DictionariesService,
    ServiceCategoriesService,
    ServiceOffersService,
    ClientsService,
    AdminService,
    OrdersService,
    OrderCommissionsService,
    DocumentsService,
    CompareHelper,
    ExpatsService,
    UsersService,
    CookieService,
    BreadcrumbService,
    WindowResizeHelperService,
    CommissionsService,
    StatisticsService,
    ShareDataService,
    InvoicesService,
    RFQSService,
    GoogleAnalyticsService,
    AdsService,
    LoadingService,
    MenuBadgesService,
    ActivityService,
    DateService,
    CrmService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueueInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: RfqHelper,
      useClass: RfqHelper,
      deps: [TranslateService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "outline",
        floatLabel: "always",
        subscriptSizing: "fixed",
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
