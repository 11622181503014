<div class="details-body-wrapper">
  <ng-container *ngIf="isLoadingGeneral">
    <app-loader-spinner-general
      [repeat]="3"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </ng-container>
  <div class="white-box-wrapper" *ngIf="!isLoadingGeneral && invoice">
    <div class="white-box-row">
      <div class="white-box-header">
        <div class="medium-title fl-l">
          {{ "GENERALS.INVOICE-DETAILS" | translate }}
        </div>
        <div class="small-box-status fl-r">
          <div class="btn download-invoice-btn m-l-r-15" (click)="download()">
            <i class="icon-download"></i>
            {{ "GENERALS.EXPORT-FILE" | translate }}
          </div>
          <div class="status-label-{{ invoice.status_id }} status-label">
            {{ invoice.status }}
          </div>
        </div>
      </div>

      <div class="clear"></div>

      <div class="white-box-body">
        <div class="row">
          <div class="w-33">
            <p>
              <span class="inner-label">
                {{ "INVOICES.INVOICE-NO" | translate }}:
              </span>
              {{ invoice.id }}
            </p>
            <p>
              <span class="inner-label">
                {{ "INVOICES.AMOUNT" | translate }}:
              </span>
              {{ invoice.amount | currency : invoice.currency_code }}
            </p>
            <p>
              <span class="inner-label">
                {{ "GENERALS.BILLING_PERIOD" | translate }}:
              </span>
              {{ invoice.start_date | date : "dd/MM/yyyy" }} -
              {{ invoice.end_date | date : "dd/MM/yyyy" }}
            </p>
          </div>
          <div class="w-33">
            <p>
              <span class="inner-label">
                {{ "INVOICES.ISSUED-ON" | translate }}:
              </span>
              {{ invoice.issued_on | date : "dd/MM/yyyy" }}
            </p>
            <p>
              <span class="inner-label">
                {{ "INVOICES.DUE-ON" | translate }}:
              </span>
              {{ invoice.due_on | date : "dd/MM/yyyy" }}
            </p>
            <p>
              <span class="inner-label">
                {{ "INVOICES.PAID-ON" | translate }}:
              </span>
              {{ invoice.paid_on | date : "dd/MM/yyyy" }}
            </p>
          </div>
          <div class="w-33" *ngIf="invoice.provider">
            <p>
              <span class="inner-label">
                {{ "INVOICES.ISSUED-TO" | translate }}:
              </span>
              {{ invoice.provider.company_name }}
            </p>
            <p>
              <span class="inner-label">
                {{ "LABEL.TAX-NUMBER" | translate }}:
              </span>
              {{ invoice.provider?.default_legal_entity?.tax_number }}
            </p>
            <p>
              <span class="inner-label">
                {{ "GENERALS.COMPANY-ADDRESS" | translate }}:
              </span>
              {{ invoice.provider?.default_legal_entity?.address }}
            </p>
            <p>
              <span class="inner-label">
                {{ "GENERALS.PROVIDER" | translate }}:
              </span>
              {{ invoice.provider.first_name }}
              {{ invoice.provider.last_name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-commissions-billed
  [hideFilters]="true"
  [invoiceId]="invoiceId"
></app-commissions-billed>
