import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceOffersService } from "src/app/_services/service-offers.service";
import { environment } from "../../../../../environments/environment";
import { AlertService } from "@modules/alert";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { WorkflowStatus } from "../../../../_models/workflow-status";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-service-offer-details-page",
  templateUrl: "./service-offers-details.component.html",
  styleUrls: ["./service-offers-details.component.scss"],
})
export class ServiceOfferDetailsComponent implements OnDestroy {
  id;
  offer;
  characteristics = [];
  keys;
  isLoadingGeneral;
  active = 6; // offer status_id
  inactive = 7;
  actions = [];
  private _onDestroy = new Subject<void>();
  bundle;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    public serviceOffers: ServiceOffersService,
    public dialog: MatDialog
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.isLoadingGeneral = true;

        this.getServiceOfferById(params.id);
        this.getStatusTransitions();
      }
    });
  }

  public getStatusLabel(status: WorkflowStatus) {
    switch (status) {
      case WorkflowStatus.DRAFT:
        return "1";
      case WorkflowStatus.PUBLISHED:
        return "2";
      case WorkflowStatus.MISSING:
        return "3";
    }
  }

  public getStatusTransitions() {
    this.serviceOffers.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  copyOffer(offerId) {
    this.serviceOffers.copyOffer(offerId).subscribe((data) => {
      this.isLoadingGeneral = false;
      const url = "/service-offers/" + data.result.id + "/edit";
      this.router.navigate([url], {
        queryParams: { ...{ copy: 1 } },
      });
    });
  }

  openConfirmAction(action) {
    const ids = [this.id];

    if (!action.needs_confirmation && !action.needs_reason && action.text) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.serviceOffers
            .updateStatusBulk(action.id, ids, result)
            .subscribe(() => {
              this.getServiceOfferById(this.id);
              this.translate
                .get("GENERALS.STATUS-CHANGED")
                .subscribe((statusChangedTranslation) => {
                  this.alertService.success(statusChangedTranslation);
                });
            });
        }
      });
    } else {
      this.serviceOffers
        .updateStatusBulk(action.id, ids, null)
        .subscribe(() => {
          this.getServiceOfferById(this.id);
          this.translate
            .get("GENERALS.STATUS-CHANGED")
            .subscribe((statusChangedTranslation) => {
              this.alertService.success(statusChangedTranslation);
            });
        });
    }
  }

  setCharacteristics() {
    this.offer.characteristics.forEach((ch) => {
      const objFields = JSON.parse(ch.data);
      this.characteristics = Object.assign({}, this.characteristics, objFields);
    });
    this.keys = Object.keys(this.characteristics);
    this.isLoadingGeneral = false;
  }

  getServiceOfferById(id) {
    this.isLoadingGeneral = true;
    this.serviceOffers.getServiceOfferById(id).subscribe((data) => {
      this.offer = data.result;
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForOffer(this.offer, "details")
      );

      if (data.result.bundle_id) {
        this.serviceOffers
          .getBundleServiceOffersById(data.result.bundle_id)
          .subscribe((res) => {
            this.bundle = res.result;
            this.isLoadingGeneral = false;
          });
      }

      if (data.result.characteristics && data.result.characteristics.length) {
        this.setCharacteristics();
      } else {
        this.isLoadingGeneral = false;
      }
      this.isLoadingGeneral = false;
    });
  }

  goToMarketplaceOffer(id) {
    const marketplaceUrlPathArray = environment.marketplace.split("/");
    const protocol = marketplaceUrlPathArray[0];
    const host = marketplaceUrlPathArray[2];
    const goToMarketplaceUrl = protocol + "//" + host;
    const goToOfferUrl = goToMarketplaceUrl + "/offers/" + id;

    window.open(goToOfferUrl, "_blank");
  }

  updateStatus() {
    if (this.offer.active === true) {
      this.openConfirmAction({ id: 6 }); // published and active
    } else {
      this.openConfirmAction({ id: 7 }); // published and inactive
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  navigateToEditPage() {
    const type = this.bundle ? this.bundle.id + "/bundle" : this.id + "/edit";
    this.router.navigate(["/service-offers/" + type]);
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
