<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "INVOICES.LIST" | translate }}</div>
  <!-- end left -->
</div>

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<div *ngIf="isFiltersLoaded" class="dashboard-body-action">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [showCalendar]="false"
      [issueDate]="true"
      [dueDate]="true"
      [paymentDate]="true"
      [searchPlaceholder]="'GENERALS.SEARCH'"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      (invoiceIssueDate)="
        invoiceIssueDate = $event; filters = setFilters($event); navigateByUrl()
      "
      (invoiceDueDate)="
        invoiceDueDate = $event; filters = setFilters($event); navigateByUrl()
      "
      (invoicePaymentDate)="
        invoicePaymentDate = $event;
        filters = setFilters($event);
        navigateByUrl()
      "
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- calendar -->
    <!--<div class="fl-l">-->
    <!--<app-date-range-calendar (change)="setDate($event)"></app-date-range-calendar>-->
    <!--</div>-->
    <!-- end calendar -->
    <!-- elem on page -->
    <div class="fl-l">
      <app-elements-on-page
        *ngIf="!isXs"
        [perPage]="perPage"
        (getItem)="perPage = $event; navigateByUrl()"
      ></app-elements-on-page>
    </div>
    <!-- end elem on page -->
  </div>
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <th>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </th>
            <th mat-sort-header="id">
              {{ "INVOICES.INVOICE-NO" | translate }}
            </th>
            <th mat-sort-header="company_name">
              {{ "GENERALS.PROVIDER" | translate }}
            </th>
            <th mat-sort-header="amount">
              {{ "INVOICES.AMOUNT" | translate }}
            </th>
            <th mat-sort-header="issued_on">
              {{ "INVOICES.ISSUED-ON" | translate }}
            </th>
            <th mat-sort-header="due_on">
              {{ "INVOICES.DUE-ON" | translate }}
            </th>
            <th mat-sort-header="paid_on">
              {{ "INVOICES.PAID-ON" | translate }}
            </th>
            <th>
              {{ "GENERALS.DOWNLOAD" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th mat-sort-header="payment_info">
              {{ "DOCUMENTS.PAYMENT-INFO" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let invoice of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? changeSelection(invoice) : null"
                [checked]="selection.isSelected(invoice)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/financial/invoices/' + invoice.id]"
            >
              <a
                [href]="'/financial/invoices/' + invoice.id"
                class="orange underline"
              >
                {{ invoice.id }}
              </a>
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/financial/invoices/' + invoice.id]"
            >
              {{ invoice.provider?.company_name }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/financial/invoices/' + invoice.id]"
            >
              {{ invoice.amount | currency : invoice.currency_code }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/financial/invoices/' + invoice.id]"
            >
              {{ invoice.issued_on | date : "dd/MM/yyyy" }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/financial/invoices/' + invoice.id]"
            >
              {{ invoice.due_on | date : "dd/MM/yyyy" }}
            </td>
            <td
              class="gray-pointer"
              [routerLink]="['/financial/invoices/' + invoice.id]"
            >
              <span *ngIf="invoice.paid_on === '--'">{{
                invoice.paid_on
              }}</span>
              <span *ngIf="invoice.paid_on !== '--'">{{
                invoice.paid_on | date : "dd/MM/yyyy"
              }}</span>
            </td>
            <td>
              <div
                (click)="exportCsv(invoice)"
                class="inner-table-btn"
                title="{{ 'GENERALS.DOWNLOAD-CSV' | translate }}"
              >
                <i class="icon-csv-icon"></i>
              </div>
              <div
                (click)="exportPdf(invoice)"
                class="inner-table-btn"
                title="{{ 'GENERALS.DOWNLOAD-PDF' | translate }}"
              >
                <i class="icon-file-pdf"></i>
              </div>
            </td>
            <td>
              <div class="status-label-{{ invoice.status_id }}">
                {{ "INVOICES.STATUS-" + invoice.status_id | translate }}
              </div>
            </td>
            <td class="ta-l">
              <span *ngIf="invoice.paid_on">
                <b>{{ "INVOICES.TRANSACTION-ID" | translate }}</b
                >:
                {{
                  $any(invoice).transaction_id
                    ? $any(invoice).transaction_id
                    : null
                }}
                <br />
                <b>{{ "INVOICES.PAYMENT-METHOD" | translate }}</b
                >:
                {{
                  $any(invoice).payment_method
                    ? $any(invoice).payment_method
                    : null
                }}
              </span>
            </td>
            <td class="ta-r">
              <div
                *ngIf="actions && actions[invoice.status_id]"
                toggle-action-table
                class="table-action-wrapper"
              >
                <i class="icon-dot-3"></i>
                <div class="table-action">
                  <ul>
                    <li
                      *ngFor="let status of actions[invoice.status_id]"
                      (click)="changeStatus(status, invoice)"
                    >
                      {{ status.text }}
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let invoice of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(invoice) : null"
              [checked]="selection.isSelected(invoice)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            [routerLink]="['/invoices/view']"
            [queryParams]="{ invoice_id: invoice.id }"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.INVOICE-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ invoice.id }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COMPANY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.provider?.company_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.AMOUNT" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.amount | currency : invoice.currency_code }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.ISSUED-ON" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.issued_on }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.DUE-ON" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.due_on }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.PAID-ON" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.paid_on }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.DOWNLOAD" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <div
              (click)="exportCsv(invoice)"
              class="inner-table-btn"
              title="{{ 'GENERALS.DOWNLOAD-CSV' | translate }}"
            >
              <i class="icon-csv-icon"></i>
            </div>
            <div
              (click)="exportPdf(invoice)"
              class="inner-table-btn"
              title="{{ 'GENERALS.DOWNLOAD-PDF' | translate }}"
            >
              <i class="icon-file-pdf"></i>
            </div>
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ invoice.status_id }}">
              {{ "INVOICES.STATUS-" + invoice.status_id | translate }}
            </span>
          </div>
        </div>

        <div
          *ngIf="invoice.status_id !== invoicesStatusEnum.paid"
          class="responsive-table-action"
        >
          <span
            *ngIf="
              [
                invoicesStatusEnum.issued,
                invoicesStatusEnum.overdue,
                invoicesStatusEnum.written_off
              ].includes(invoice.status_id)
            "
            (click)="
              updateInvoiceStatus(invoice.id, invoicesStatusEnum.canceled)
            "
          >
            {{ "INVOICES.CANCEL" | translate }}
          </span>
          <span
            *ngIf="
              [invoicesStatusEnum.issued, invoicesStatusEnum.overdue].includes(
                invoice.status_id
              )
            "
            (click)="
              updateInvoiceStatus(invoice.id, invoicesStatusEnum.written_off)
            "
          >
            {{ "INVOICES.WRITE-OFF" | translate }}
          </span>
          <span
            *ngIf="
              [invoicesStatusEnum.issued, invoicesStatusEnum.overdue].includes(
                invoice.status_id
              )
            "
            (click)="updateInvoiceStatus(invoice.id, invoicesStatusEnum.paid)"
          >
            {{ "INVOICES.MARK-PAID" | translate }}
          </span>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "DOCUMENTS.PAYMENT-INFO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span *ngIf="invoice.paid_on">
              <b>{{ "INVOICES.TRANSACTION-ID" | translate }}</b
              >:
              {{
                $any(invoice).transaction_id
                  ? $any(invoice).transaction_id
                  : null
              }}
              <br />
              <b>{{ "INVOICES.PAYMENT-METHOD" | translate }}</b
              >:
              {{
                $any(invoice).payment_method
                  ? $any(invoice).payment_method
                  : null
              }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
