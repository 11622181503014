import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "@modules/alert";
import { DocumentsService } from "src/app/_services/documents.service";
import { ShareDataService } from "src/app/_services/share-data.service";

@Component({
  selector: "app-add-profile-picture",
  templateUrl: "./add-profile-picture.component.html",
})
export class AddProfilePictureComponent {
  @Input() entityType;
  @Input() entityId;
  @Input() title;

  @Output() changePicture: EventEmitter<any> = new EventEmitter();

  public fileUploadControl = new FileUploadControl();

  setModalOpen = true;
  isCanceled = false;
  isLoading = false;
  uploadedFiles = { file: null, name: null, status: null };
  statuses;

  sts = [{ id: 1, text: "signed" }];
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    protected service: DocumentsService,
    private alertService: AlertService,
    private shareDataService: ShareDataService
  ) {
    this.setModalOpen = false;
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModalOpen = false;
    this.fileUploadControl.clear();
  }
  cancelModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").close();
    this.isCanceled = true;
    this.uploadedFiles = { file: null, name: null, status: null };
    this.fileUploadControl.clear();
  }

  uploadImage() {
    this.isLoading = true;

    this.service
      .uploadProfilePicture(this.entityType, this.entityId, this.uploadedFiles)
      .subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.changePicture.emit("newProfilePicture");
          this.shareDataService.sendData("newProfilePicture");
          this.cancelModal();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;

          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }
}
