import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class StatisticsService {
  private url = "statistics/orders";
  private urlOffers = "statistics/offers";
  private urlEntities = "statistics/entities";
  private urlProviders = "statistics/providers";
  private urlProvider = "statistics/provider";
  private urlClient = "statistics/client";

  constructor(private http: HttpClient) {}

  // get statistics by status
  getOrdersStatisticsStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/by-status",
      {
        params: params,
      }
    );
  }

  // get statistics by month
  getOrdersStatisticsMonth(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/by-month",
      {
        params: params,
      }
    );
  }

  // get recent documents
  getRecentDocuments(id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.url +
        "/documents?" +
        "entity_id=" +
        id
    );
  }

  // get SLA (Service Level)
  getServiceLevel(id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.url +
        "/by-service?" +
        "main_category_id=" +
        id
    );
  }

  // Get Overdue Orders
  getOverdueOrders(params = {}): Observable<any> {
    return this.http.get(environment.gateway_endpoint + this.url + "/overdue", {
      params: params,
    });
  }

  // Get Orders by Day
  getOrdersByDay(): Observable<any> {
    return this.http.get(environment.gateway_endpoint + this.url + "/by-day");
  }

  // Get Canceled Orders Comparison
  getCanceledOrdersComparison(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/canceled-year-comparison",
      {
        params: params,
      }
    );
  }

  // get statistics by status
  getOffersByStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlOffers + "/by-status",
      {
        params: params,
      }
    );
  }

  // get entities by status
  getEntitiesByStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlEntities + "/by-status",
      {
        params: params,
      }
    );
  }

  // get Sold Offers
  getSoldOffers(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlOffers + "/sold"
    );
  }

  // get Finalized Orders Comparison
  getFinalizedOrdersComp(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.url +
        "/accepted-year-comparison-graph",
      {
        params: params,
      }
    );
  }

  // get Top 5 Providers Stats
  getTopFiveProviders(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlProviders + "/top-five",
      {
        params: params,
      }
    );
  }

  // get No of Orders By status ID
  getNoOrdersByStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/actepted-orders",
      {
        params: params,
      }
    );
  }

  // Get Client Leads
  getClientLeads(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlClient + "/leads",
      {
        params: params,
      }
    );
  }

  // Get Provider Leads
  getProviderLeads(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlProvider + "/leads",
      {
        params: params,
      }
    );
  }
}
