import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "@modules/alert";
import { InvoicesService } from "../../../../_services/invoices.service";
import { Invoice } from "../../../../_models/invoice";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { finalize, take } from "rxjs";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent {
  isLoadingGeneral = false;
  invoiceId;
  invoice = new Invoice();

  constructor(
    private service: InvoicesService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.invoiceId = params.id;
        this.getInvoice(this.invoiceId);
      }
    });
  }

  public getInvoice(invoiceId) {
    this.isLoadingGeneral = true;

    this.service
      .getInvoiceDetails(invoiceId)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe(
        (data) => {
          this.invoice = data.result;
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForInvoice(this.invoice)
          );
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public download() {
    const issuedOn = moment(this.invoice.issued_on).format("DD-MM-Y");

    this.service.exportPdf(this.invoiceId).subscribe(
      (data) => {
        const blob = new Blob([data], { type: "text/csv" });
        saveAs(
          blob,
          "Invoice_" + this.invoiceId + "_xpath_global_" + issuedOn + ".pdf"
        );
      },
      (error) => {
        if (error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoadingGeneral = false;
        return false;
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
