import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AlertComponent } from "./alert/alert.component";
import { LoaderSpinnerGeneralComponent } from "./loader-spinner-general/loader-spinner-general.component";
import { AlertService } from "./services";

@NgModule({
  declarations: [AlertComponent, LoaderSpinnerGeneralComponent],
  exports: [AlertComponent, LoaderSpinnerGeneralComponent],
  imports: [CommonModule, HttpClientModule, TranslateModule],
  providers: [],
})
export class AlertModule {
  public static forRoot(): ModuleWithProviders<AlertModule> {
    return {
      ngModule: AlertModule,
      providers: [AlertService],
    };
  }
}
