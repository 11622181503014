<div *ngIf="categories">
  <div class="form-group">
    <label *ngIf="level === 0"
      >{{ "GENERALS.SERVICE-CATEGORY" | translate }} *
      <span
        appHoverHelper
        class="input-helper"
        *ngIf="helper && helper.level === 0"
        ><i class="icon-help_comp"></i>
        <span class="helper-description">
          {{ helper.text | translate }}
        </span>
      </span></label
    >
    <label *ngIf="level > 0"
      >{{ "GENERALS.SUBCATEGORY" | translate }} *
      <span
        appHoverHelper
        *ngIf="helper && helper.level > 0"
        class="input-helper"
        ><i class="icon-help_comp"></i>
        <span class="helper-description">{{ helper.text | translate }}</span>
      </span>
    </label>

    <ng-select
      class="customiz-select"
      [(ngModel)]="selectedPath"
      (change)="selectCategory($event)"
      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      placeholder="{{ placeholder }}"
      [clearable]="false"
    >
      <ng-option
        *ngFor="let category of categories | keyvalue"
        [value]="category.key"
        >{{ $any(category.value).label }}</ng-option
      >
    </ng-select>
  </div>
</div>

<ng-container *ngIf="selectedCategory && selectedCategory.children_count">
  <app-category-selection
    [categories]="selectedCategory.children"
    [preselectedPath]="preselectedPath"
    [level]="selectedCategory.level + 1"
    (categorySelected)="forward($event)"
    (intermediaryCategory)="intermediaryCategory.emit()"
    [clear]="clear"
  ></app-category-selection>
</ng-container>
