import { Component, Input, OnInit, OnChanges, OnDestroy } from "@angular/core";
import { Dictionary } from "../../../../_models/dictionary";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { EntityData } from "../../../company/models/entity.data";
import { Subject } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { FormGroup, UntypedFormControl } from "@angular/forms";
import { LoadingTypeEnum } from "../../_enums/loading-type.enum";

@Component({
  selector: "app-add-provider-step2",
  templateUrl: "./add-provider-step2.component.html",
  styleUrls: ["./add-provider-step2.component.scss"],
  standalone: false,
})
export class AddProviderStep2Component implements OnInit, OnChanges, OnDestroy {
  @Input() provider: EntityData;
  @Input() edit = false;

  citySearchControl = new UntypedFormControl();
  isLoading = {
    company: false,
    department: false,
    professional: false,
    employees: false,
  };
  private destroyed$: Subject<void> = new Subject();

  form: FormGroup = new FormGroup({
    country_code: new UntypedFormControl(),
    city: new UntypedFormControl(),
  });

  companyTypes: Dictionary[];
  departments: Dictionary[];
  professionalAssociation: Dictionary[];
  employeesNumber: Dictionary[];
  request_call;
  cities;
  public countries;

  currency = [
    { id: 1, name: "USD" },
    { id: 2, name: "EUR" },
    { id: 3, name: "GBP" },
  ];

  preselectedCurrency;
  constructor(private dictionariesService: DictionariesService) {}

  ngOnInit() {
    if (this.provider && this.provider.currency_id) {
      this.preselectedCurrency = this.provider.currency_id;
    }
    this.getCompanyTypes();
    this.getDepartments();
    this.getProfessionalAssociation();
    this.getEmployeesNumber();

    this.form
      .get("country_code")
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.provider.default_legal_entity.country_code = value;
      });

    this.form
      .get("city")
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.provider.default_legal_entity.city = value;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnChanges(changes) {
    if (changes && changes.provider && changes.provider.currentValue) {
      if (this.provider && this.provider.currency_id) {
        this.preselectedCurrency = this.provider.currency_id;
      }
    }
  }

  getCompanyTypes() {
    this.isLoading.company = true;

    this.dictionariesService
      .getCompanyTypes()
      .pipe(
        take(1),
        finalize(() => (this.isLoading.company = false))
      )
      .subscribe((data) => {
        this.companyTypes = data.result;
      });
  }

  getDepartments() {
    this.isLoading.department = true;

    this.dictionariesService
      .getDepartments()
      .pipe(
        take(1),
        finalize(() => (this.isLoading.department = false))
      )
      .subscribe((data) => {
        this.departments = data.result;
      });
  }

  getProfessionalAssociation() {
    this.isLoading.professional = true;

    this.dictionariesService
      .getprofessionalAssociation()
      .pipe(
        take(1),
        finalize(() => (this.isLoading.professional = false))
      )
      .subscribe((data) => {
        this.professionalAssociation = data.result;
      });
  }

  getEmployeesNumber() {
    this.isLoading.employees = true;

    this.dictionariesService
      .getEmployeesNumber()
      .pipe(
        take(1),
        finalize(() => (this.isLoading.employees = false))
      )
      .subscribe((data) => {
        this.employeesNumber = data.result;
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
