import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { UserData } from "../../../../_models/user.model";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-add-provider-step-1",
  templateUrl: "./add-provider-step-1.component.html",
})
export class AddProviderStep1Component implements OnInit, OnChanges {
  @Input() user: UserData;
  @Input() isCanceled;

  phoneCode;
  phoneNumber;
  prefix;
  countries;
  phone_prefix;
  constructor(private dictionariesService: DictionariesService) {}

  form: UntypedFormGroup;

  ngOnInit() {
    this.form = new UntypedFormGroup({
      phone_prefix: new UntypedFormControl(""),
    });

    this.form
      .get("phone_prefix")
      .valueChanges.pipe(filter((value) => value))
      .subscribe((value) => {
        this.user.phone_prefix = value;
      });
  }

  ngOnChanges(changes) {
    if (changes.isCanceled && changes.isCanceled.currentValue) {
      this.phoneCode = null;
      this.phoneNumber = null;
      this.phone_prefix = null;
    }
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
