import { Component, OnInit } from "@angular/core";
import { Order, OrderRating } from "../../../../_models/order";
import { OrdersService } from "../../../../_services/orders.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { OrderPopupComponent } from "../order-popup/order-popup.component";
import { AlertService } from "@modules/alert";
import { environment } from "src/environments/environment";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { finalize, take } from "rxjs";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.css"],
})
export class OrderDetailsComponent implements OnInit {
  isLoading = false;
  isLoadingStatus = false;
  id;
  public order: Order;
  total = 0;
  statuses;
  public editArrivingDate;
  public editDeliveryDate;
  public initialQty = {};
  public editprice;
  public isEditable = false;
  minDate: NgbDate;
  public rating: OrderRating[];
  orderIdentityNo;
  providerId;

  constructor(
    private orderService: OrdersService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getOrderById(params.id);
      }
    });
  }
  ngOnInit() {
    this.minDate = this.setCalendarLimit(
      new Date(this.addDayToDate(new Date(), 1))
    );
  }

  setCalendarLimit(referenceDate) {
    return new NgbDate(
      referenceDate.getFullYear(),
      referenceDate.getMonth() + 1,
      referenceDate.getDate()
    );
  }

  getOrderById(id) {
    this.isLoading = true;

    this.orderService.getOrderByIdentityNumber({}, id).subscribe(
      (res) => {
        if (res) {
          this.order = res.result;
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForOrder(this.order, "details")
          );

          this.total = res.result.amount;
          this.editArrivingDate = null;
          this.editDeliveryDate = null;
          this.orderIdentityNo = this.order.details[0].order_identity_number;
          this.providerId = this.order.details[0].provider_id;
          if (
            this.order.status_id !== 4 &&
            this.order.status_id !== 5 &&
            this.order.status_id !== 6 &&
            this.order.status_id !== 7
          ) {
            this.isEditable = true;
          }
          this.getStatusTransitions();

          if (this.order.status_id === 7) {
            this.getRatingActivity();
          }
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  public getStatusTransitions() {
    this.isLoadingStatus = true;

    this.orderService
      .getStatusTransitions()
      .pipe(
        take(1),
        finalize(() => (this.isLoadingStatus = false))
      )
      .subscribe((data) => {
        if (data.success) {
          this.statuses = data.result[this.order.status_id];
        }
      });
  }

  openDialog(type, id?, data?): void {
    const dialogRef = this.dialog.open(OrderPopupComponent, {
      data: {
        type: type,
        openedFrom: "orders",
        id: id ? id : this.order.id,
        data: data,
        entity_id: this.order.entity_id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result) {
        this.getOrderById(this.id);
      }
    });
  }

  updateOrderDetails(offer) {
    this.orderService
      .updateOrderDetails(this.order.id, offer.id, offer)
      .subscribe(
        (res) => {
          this.getOrderById(this.id);
          if (res && res.message) {
            this.alertService.success(res.message);
          }
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  qtyChanges(idx, offer, unit) {
    if (!this.initialQty[idx]) {
      this.initialQty[idx] = offer.quantity;
    }
    if ((unit < 0 && offer.quantity > 1) || unit > 0) {
      // && < max.qty
      offer.quantity = offer.quantity + unit;
      offer.total_price = offer.total_price + unit * offer.unit_price;
      this.total = this.total + unit * offer.unit_price;
    }
  }

  navigateToMarketplace(id) {
    const marketplaceUrlPathArray = environment.marketplace.split("/");
    const protocol = marketplaceUrlPathArray[0];
    const host = marketplaceUrlPathArray[2];
    const goToMarketplaceUrl = protocol + "//" + host;
    const goToOfferUrl = goToMarketplaceUrl + "/offers/" + id;

    window.open(goToOfferUrl, "_blank");
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }

  openConfirmAction(action) {
    const ids = [this.order.id];
    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.orderService.updateStatusBulk(action.id, ids, result).subscribe(
            () => {
              this.getOrderById(this.id);
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.orderService.updateStatusBulk(action.id, ids, null).subscribe(() => {
        this.getOrderById(this.id);
      });
    }
  }

  getRatingActivity() {
    const params = {};
    params["order_identity_number"] = this.orderIdentityNo;
    this.orderService
      .getRatingActivity(this.providerId, params)
      .subscribe((res) => {
        if (res.result.length > 0) {
          this.rating = res.result;
        }
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
