import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RFQSService } from "src/app/_services/rfqs.service";
import { RFQOffer } from "src/app/_models/rfq";
import { AlertService } from "@modules/alert";
import { ServiceCategoriesService } from "src/app/_services/service-categories.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-add-rfq-offer",
  templateUrl: "./add-rfq-offer.component.html",
})
export class AddRFQOfferComponent implements OnInit {
  @Output() oncreate: EventEmitter<any> = new EventEmitter();
  @Input() providers;
  @Input() offer;

  @Input() set itemfilters(value) {
    if (value) {
      this.country = value.country;
    }
  }
  private _onDestroy = new Subject<void>();
  categories;
  item: RFQOffer;
  country;
  offers;
  isLoadingGeneral = false;
  clear = false;

  currency = [
    { id: 1, label: "USD" },
    { id: 2, label: "EUR" },
    { id: 3, label: "GBP" },
  ];
  constructor(
    private route: ActivatedRoute,
    private rfqService: RFQSService,
    private alertService: AlertService,
    private serviceCategoriesService: ServiceCategoriesService
  ) {
    this.item = new RFQOffer();
  }
  ngOnInit() {
    this.getCategories();
  }

  close() {
    this.item = new RFQOffer();
    this.clear = true;
    setTimeout(() => {
      this.clear = false;
    }, 1);
  }

  emitChanges() {
    this.item.currency_id = this.providers.filter(
      (f) => f.provider_id === this.item.provider_id
    )[0].currency;
    this.oncreate.emit(this.item);
  }

  getProviderOffers() {
    if (this.item["provider_id"] && this.item["category_id"]) {
      this.item["country"] = this.country;
      this.rfqService.getOffersByProvider(this.item).subscribe((res) => {
        this.offers = res.result;
      });
    }
  }

  getCategories() {
    const params = { status: "active" };
    this.isLoadingGeneral = true;

    this.serviceCategoriesService
      .getServiceCategories(params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.categories = data.result.items;
        this.isLoadingGeneral = false;
      });
  }
}
