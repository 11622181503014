import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, combineLatest } from "rxjs";
import { finalize, take, tap, takeUntil } from "rxjs/operators";
import { PlanTypeEnum } from "src/app/api/subscriptions/enums/plan-type.enum";
import { SubscriptionService } from "src/app/api/subscriptions/services/subscription.service";
import { EntityTypeEnum } from "src/app/modules/shared/_enums/entity-type.enum";
import { filterNullish } from "src/app/_helpers/filter-nullish";
import { Plan } from "src/app/_models/plan";
import { AlertService } from "@modules/alert";
import { AssignPlanFormComponent } from "../assign-plan-form/assign-plan-form.component";
import { PlanService } from "src/app/api/subscriptions/services/plan.service";
import { Subscription } from "src/app/_models/subscription";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-assign-plan",
  templateUrl: "./assign-plan.component.html",
  styleUrls: ["./assign-plan.component.scss"],
  standalone: false,
})
export class AssignPlanComponent implements OnInit, OnDestroy {
  @Input()
  entityType: EntityTypeEnum;
  @Input()
  entityId: number;

  planSubject$: Subject<Plan> = new Subject();
  subscriptionSubject$: Subject<{ subscription?: Subscription }> =
    new Subject();

  private destroyed$ = new Subject<void>();
  isLoadingGeneral = false;

  constructor(
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private planService: PlanService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  refreshData() {
    this.isLoadingGeneral = true;
    const userPlan$ = this.fetchPlan();
    const userSubscription$ = this.fetchSubscription();
    combineLatest([userPlan$, userSubscription$]).subscribe(
      ([plan, subscription]) => {
        this.isLoadingGeneral = false;
      }
    );
  }
  openEdit(plan: Plan): void {
    const dialogRef = this.dialog.open(AssignPlanFormComponent, {
      data: {
        plan: plan,
        entityType: this.entityType,
        entityId: this.entityId,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(filterNullish(), take(1))
      .subscribe((data) => {
        if (data.success) {
          this.refreshData();
        }
      });
  }

  removeUserSubscription(): void {
    this.isLoadingGeneral = true;

    this.subscriptionService
      .cancelSubscriptions(this.entityId, this.entityType)
      .pipe(
        finalize(() => (this.isLoadingGeneral = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (res) => {
          this.refreshData();
        },
        (err) => {
          if (err && err.error && err.error.message) {
            this.alertService.stringError(err.error.message);
          }
        }
      );
  }

  get PlanType() {
    return PlanTypeEnum;
  }

  private fetchPlan() {
    return this.planService
      .getUserPlan(this.entityId.toString(), this.entityType)
      .pipe(tap((plan) => this.planSubject$.next(plan)));
  }

  private fetchSubscription() {
    return this.subscriptionService
      .getUserSubscription(this.entityId.toString(), this.entityType)
      .pipe(tap((result) => this.subscriptionSubject$.next(result)));
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
