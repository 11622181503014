import { Pipe, PipeTransform } from "@angular/core";

/**
 * @usageNotes
 * Shows "N\A" where fields are null or undefined
 */
@Pipe({
  name: "na",
  standalone: false,
})
export class NAPipe implements PipeTransform {
  transform(value: string | number): string | number {
    if (value === undefined || value === null) {
      return "N/A";
    }
    return value;
  }
}
