import { Component, Input, OnInit } from "@angular/core";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import { AlertService } from "@modules/alert";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { OrderCommission } from "../../../../_models/order-commission";
import { OrderCommissionsService } from "../../../../_services/order-commissions.service";
import { MatDialog } from "@angular/material/dialog";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { SortDirection } from "@angular/material/sort";

@Component({
  selector: "app-commissions-billed",
  templateUrl: "./commissions-billed.component.html",
})
export class CommissionsBilledComponent
  extends ItemsListComponent
  implements OnInit
{
  @Input() hideFilters: boolean;
  @Input() invoiceId: number;

  items: OrderCommission[];
  request_call;
  active = "";
  direction: SortDirection = "";
  basePath = "";
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;

  constructor(
    private service: OrderCommissionsService,
    private dictionariesService: DictionariesService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/financial/fees", "order fees")
    );
    this.route.queryParams.subscribe((query) => {
      this.watchQueryParams(query);
    });

    this.showBulkActions = true;
  }

  ngOnInit(): void {
    this.basePath = window.location.pathname;

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });

    if (this.invoiceId) {
      this.filters["invoice_id"] = this.invoiceId;
      this.navigateByUrl();
    }

    this.getStatusTransitions();
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    this.router.navigate([this.basePath], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoadingGeneral = true;
    let params = {};
    params = cloneDeep(this.filters);
    this.request_call = this.service
      .getCommissions(params)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  getService() {
    return this.service;
  }

  setFilteringDate(e) {
    if (e) {
      this.startDate = e.start_date;
      this.endDate = e.end_date;
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
