<div class="dialog">
  <div class="modal-title-custom">
    {{ "GENERALS.LOG-IN-AS" | translate }}
  </div>

  <div class="modal-body">
    <div class="form-group w-100">
      <label>{{ "LABEL.ENTER-ACCOUNT-PASS" | translate }}:</label>
      <input
        type="password"
        name="password"
        class="form-control"
        placeholder="{{ 'LABEL.PASSWORD' | translate }}"
        [(ngModel)]="password"
        required
      />
    </div>
  </div>
  <div class="clear"></div>

  <div class="modal-footer-custom">
    <button (click)="cancel()" class="btn gray-bg fl-l">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button (click)="confirm()" class="btn orange-bg fl-r">
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>
