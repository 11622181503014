import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { ShareDataService } from "../_services/share-data.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Directive({
  selector: "[cdkDetailRow]",
})
export class CdkDetailRowDirective {
  private row: any;
  private tRef: TemplateRef<any>;
  private opened: boolean;
  private subscription: Subscription;

  @HostBinding("class.expanded")
  get expended(): boolean {
    return this.opened;
  }

  @Input()
  set cdkDetailRow(value: any) {
    if (value !== this.row) {
      this.row = value;
      // this.render();
    }
  }

  @Input()
  set template(value: TemplateRef<any>) {
    if (value !== this.tRef) {
      this.tRef = value;
      // this.render();
    }
  }

  constructor(
    public vcRef: ViewContainerRef,
    private shareDataServ: ShareDataService
  ) {}

  @HostListener("click")
  onClick(): void {
    this.shareDataServ._sendData.pipe(take(1)).subscribe((data) => {
      if (data === false) {
        this.toggle();
      }
    });
  }

  toggle(): void {
    if (this.opened) {
      this.vcRef.clear();
    } else {
      this.render();
    }
    this.opened = this.vcRef.length > 0;
  }

  private render(): void {
    this.vcRef.clear();
    if (this.tRef && this.row) {
      this.vcRef.createEmbeddedView(this.tRef, { $implicit: this.row });
    }
  }
}
