import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { IconsList } from "src/app/_models/icons-list";
import { ProviderCommissionEnum } from "../../../../_enums/provider-commission-enum";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { AlertService } from "@modules/alert";
import { ServiceCategoriesService } from "../../../../_services/service-categories.service";

@Component({
  selector: "app-add-category",
  templateUrl: "./add-category.component.html",
})
export class AddCategoryComponent implements OnChanges {
  setModal: boolean;
  commissionTypes = [
    { id: ProviderCommissionEnum.fixed, label: "Fixed (€)" },
    { id: ProviderCommissionEnum.percent, label: "Percent (%)" },
  ];

  allowedServiceTypes = ["offer", "internal_process"];

  @Input() parent: ServiceCategory;
  @Input() category: ServiceCategory;
  @Output() saved: EventEmitter<any> = new EventEmitter();
  iconsList = new IconsList();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private serviceCategoriesService: ServiceCategoriesService
  ) {
    this.setModal = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.parent && this.parent.id) {
      this.category.commission = this.parent.commission;
    }
  }

  add(addAnother = false) {
    if (parent) {
      this.category.parent_id = this.parent.id;
    }
    this.category.name = this.category.label;

    this.serviceCategoriesService
      .createServiceCategories(this.category)
      .subscribe(
        (data) => {
          this.saved.emit();

          if (addAnother) {
            this.initEmpty(addAnother);
          } else {
            this.ngxSmartModalService.getModal("addCategory").close();
          }
        },
        (error) => {
          this.alertService.errors(error.error.errors);
        }
      );
  }

  public update() {
    this.category.name = this.category.label;
    this.serviceCategoriesService.update(this.category).subscribe(
      (data) => {
        this.saved.emit();
        this.ngxSmartModalService.getModal("addCategory").close();
      },
      (error) => {
        this.alertService.errors(error.error.errors);
      }
    );
  }

  isModalOpen() {
    this.setModal = true;
  }

  isModalClose() {
    this.initEmpty();
    this.setModal = false;
  }
  cancelModal() {
    this.ngxSmartModalService.getModal("addCategory").close();
  }

  initEmpty(addAnother = false): void {
    this.category = new ServiceCategory();

    if (!addAnother) {
      this.parent = new ServiceCategory();
    }
  }
}
