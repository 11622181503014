import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { AlertService } from "@modules/alert";
import { ServiceOffersService } from "src/app/_services/service-offers.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "app-associated-offer",
  templateUrl: "./associated-offers.component.html",
})
export class AssociatedOffersComponent implements OnInit {
  isLoadingGeneral;
  associatedOffers;
  association_id;
  id;
  isXs: boolean;

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    public serviceOffers: ServiceOffersService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.isLoadingGeneral = true;
        this.id = params.id;
        this.getServiceOffer(params.id);
      }
    });
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }
  getServiceOffer(id) {
    this.serviceOffers.getServiceOfferById(id).subscribe(
      (data) => {
        this.association_id = data.result.association_id;
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setForOffer(data.result, "associated offers")
        );
        this.getAssociatedOffers();
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  getAssociatedOffers() {
    this.serviceOffers.getAssociatedOffers(this.association_id).subscribe(
      (res) => {
        if (res.result.items) {
          const index = res.result.items.findIndex(
            (f) => f.id.toString() === this.id.toString()
          );
          res.result.items.splice(index, 1);
          this.associatedOffers = res.result.items;
        } else {
          this.associatedOffers = 0;
        }

        this.isLoadingGeneral = false;
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
