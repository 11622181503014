import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import cloneDeep from "lodash-es/cloneDeep";
import isEmpty from "lodash/isEmpty";
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
import { environment } from "../../../../../environments/environment";
import { DocumentationStandard } from "../../../../_models/documentation-standard";
import { ServiceOffer } from "../../../../_models/service-offer";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { AlertService } from "@modules/alert";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ProvidersService } from "../../../../_services/providers.service";
import { ServiceCategoriesService } from "../../../../_services/service-categories.service";
import { ServiceOffersService } from "../../../../_services/service-offers.service";
import { DateRangeInterface } from "src/app/_interfaces/date-range.interface";

@Component({
  selector: "app-add-service-offer",
  templateUrl: "./add-service-offer.component.html",
  styleUrls: ["./add-service-offer.component.css"],
})
export class AddServiceOfferComponent implements OnInit {
  countries = null;
  cities;
  providers;
  newOffer: ServiceOffer;
  id;
  isLoadingGeneral = false;
  formdata;
  search_fields;
  search_text: string;
  request_call;
  documentationStandardsList: DocumentationStandard[];
  level = 0;
  imagePath;
  imgURL: any;
  message: string;
  edit;
  currency_code = null;
  providerId;
  readonly = false;
  helper = {
    level: 0,
    text: "HELPERS.SERVICE-ID",
  };
  public categories;
  public selectedCategory: ServiceCategory;
  public status = 3; // status_id = 3 (active)
  currentStatus;
  isCopyOffer = false;
  preselectedCategory = [];
  dateStartState = [];

  constructor(
    public serviceOffers: ServiceOffersService,
    private providersService: ProvidersService,
    private serviceCategoriesService: ServiceCategoriesService,
    private dictionariesService: DictionariesService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    private translate: TranslateService,
    private http: HttpClient,
    private breadcrumbService: BreadcrumbService
  ) {
    this.search_fields = ["company_name"];

    // this.getCountryList();

    this.newOffer = new ServiceOffer();
    this.newOffer.prices = [];
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (params.id) {
        this.edit = true;
        this.getServiceOfferById(params.id);
      } else {
        this.setDefaultValues();
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setForNewOffer()
        );
      }
    });

    this.route.queryParams.subscribe((query) => {
      if (query && query.provider_id) {
        this.providerId = query.provider_id;
        this.readonly = true;
        this.newOffer.provider_id = +query.provider_id;
      }
    });
  }
  setDefaultValues() {
    this.newOffer.for_business = true;
  }
  ngOnInit() {
    if (this.providerId) {
      if (this.newOffer.entity_type == "client") {
        this.getAllCountryList();
      } else {
        this.getCountryList(this.providerId);
      }
      this.getProviderById();

      this.getCategories(this.providerId);
    } else {
      this.getProviders(this.search_text, this.status);
    }

    this.route.queryParams.subscribe((params) => {
      if (params.copy) {
        this.isCopyOffer = true;
      }
    });
  }

  getProviders(search_text: string, status) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = {};

    params["status_id"] = status;
    if (search_text) {
      params["search_text"] = search_text;
      params["search_fields"] = this.search_fields;
    }
    this.providersService.getProviders(params).subscribe((data) => {
      this.providers = data.result.items;
    });
  }

  getProviderById() {
    this.providersService.getProvider(this.providerId).subscribe((data) => {
      this.providers = [data.result];
      this.newOffer.currency_code = data.result.currency_code;
      this.newOffer.default_currency_id = data.result.currency_id;
    });
  }
  setNewOfferProvider(val) {
    const idx = this.providers.findIndex((f) => f.company_name === val);
    this.newOffer.provider_id = this.providers[idx].id;
  }

  createServiceOffers(offer) {
    const params = cloneDeep(offer);
    this.setCurrency(params);
    this.isLoadingGeneral = true;
    this.serviceOffers.createServiceOffers(params).subscribe(
      (data) => {
        if (this.imagePath) {
          this.uploadImage(data.result.id);
        }
        this.isLoadingGeneral = false;
        this.router.navigate(["service-offers/" + data.result.id]);
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }

        return false;
      }
    );
  }

  getCountryList(providerId) {
    this.providersService.getProviderCountries(providerId).subscribe((data) => {
      this.countries = data.result;
    });
  }

  getAllCountryList() {
    this.dictionariesService.getCountryList().subscribe(
      (data) => {
        if (data.success) {
          this.countries = data.result;
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  getCategories(id) {
    if (!id) {
      this.selectedCategory = null;
      this.categories = null;
      return;
    }
    this.isLoadingGeneral = true;
    this.serviceCategoriesService.getCategoriesForProvider(id).subscribe(
      (data) => {
        this.selectedCategory = null;
        this.categories = data.result;
        this.isLoadingGeneral = false;
      },
      () => {
        this.isLoadingGeneral = false;
      }
    );
  }

  loadCharacteristics(category: ServiceCategory) {
    if (!category) {
      this.documentationStandardsList = null;
      this.formdata = null;
    } else {
      setTimeout(() => {
        this.selectedCategory = category;
        this.getDocumentationStandardsList(category.id);
      }, 0);
    }
  }

  clearCategory() {
    this.selectedCategory = null;
    delete this.newOffer.category_id;
    this.documentationStandardsList = null;
    this.formdata = null;
    delete this.newOffer.characteristics;
  }

  getDocumentationStandardsList(id) {
    this.formdata = "reset";

    this.serviceCategoriesService
      .getDocumentationStandardsList(id)
      .subscribe((data) => {
        this.documentationStandardsList = data.result;
        this.formdata = data.result;
      });
  }

  parseFormdata() {
    const characteristics = {};
    if (isEmpty(this.formdata)) {
      return {};
    }
    this.formdata
      .filter((characteristic) => characteristic.enabled)
      .forEach((characteristic) => {
        characteristic.fields.forEach((field) => {
          if (field.type === "checkbox") {
            characteristics[characteristic.characteristic_id] = {
              ...characteristics[characteristic.characteristic_id],
              [field.value]: field.data ? 1 : 0,
            };
          } else {
            if (field.data) {
              characteristics[characteristic.characteristic_id] = {
                ...characteristics[characteristic.characteristic_id],
                [field.value]: field.data,
              };
            }
          }
        });

        if (characteristics[characteristic.characteristic_id] !== undefined) {
          characteristics[characteristic.characteristic_id] = JSON.stringify(
            characteristics[characteristic.characteristic_id]
          );
        }
      });

    return characteristics;
  }

  validateNumericalInput(event: any, type) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      this.newOffer[type] = true;
    } else {
      this.newOffer[type] = false;
    }
  }

  addPrice(e) {
    this.newOffer.prices.push(e);
  }

  setCurrency(offer) {
    if (offer.prices && offer.prices.length) {
      offer.prices.forEach((price, i) => {
        if (!this.dateStartState[i]) {
          price.starts_on.add(1, "d");
          price.ends_on.add(1, "d");
        } else {
          if (
            !this.areSameDates(
              price.starts_on.toDate(),
              moment(this.dateStartState[i].starts_on).toDate()
            )
          ) {
            price.starts_on.add(1, "d").toISOString();
          }

          if (
            !this.areSameDates(
              price.ends_on.toDate(),
              moment(this.dateStartState[i].ends_on).toDate()
            )
          ) {
            price.ends_on.add(1, "d").toISOString();
          }
        }

        if (!price.currency_id) {
          price.currency_id = this.newOffer.default_currency_id;
        }
      });
    }
  }

  addOffer() {
    if (isEmpty(this.newOffer)) {
      return;
    }
    this.prepareOffer();
    this.createServiceOffers(this.newOffer);
  }

  saveDraft() {
    if (isEmpty(this.newOffer)) {
      return;
    }

    this.prepareOffer();

    this.newOffer.is_draft = true;

    this.createServiceOffers(this.newOffer);
  }

  private prepareOffer() {
    const formdata = this.parseFormdata();
    if (this.newOffer && !isEmpty(formdata)) {
      this.newOffer.characteristics = formdata;
    }
    if (this.selectedCategory) {
      this.newOffer.category_id = this.selectedCategory.id;
    }
    this.newOffer.unit_id = environment.unit_id;
  }

  // PUT - Edit offer methods

  setOffer(data) {
    this.newOffer.default_quantity = data.listing_price.quantity;
    this.newOffer.default_price = data.listing_price.price;
    this.newOffer.sale_price = data.sale_price.price;
    // this.newOffer.category_id = data.category.category_id;
    // this.level = data.category.level;
    this.formdata.forEach((ch) => {
      const idx = data.characteristics.findIndex(
        (item) => item.characteristic_id === ch.characteristic_id
      );
      if (idx > -1) {
        const objFields = JSON.parse(data.characteristics[idx].data);
        ch.fields.forEach((f, i) => {
          ch.fields[i].data = objFields[ch.fields[i].value];
        });
      }
    });
  }

  getServiceOfferById(id) {
    this.isLoadingGeneral = true;
    this.serviceOffers.getServiceOfferById(id).subscribe((data) => {
      this.newOffer = data.result;
      this.dateStartState = structuredClone(this.newOffer.prices);

      if (
        this.edit &&
        (this.newOffer.status_id === 1 ||
          this.newOffer.status_label === "Draft")
      ) {
        this.getCategories(this.newOffer.provider_id);
      }

      this.imgURL = data.result.primary_image_path;
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForOffer(data.result, "edit service offer")
      );

      if (this.newOffer.entity_type == "client") {
        this.getAllCountryList();
      } else {
        this.getCountryList(data.result.provider_id);
      }

      this.isLoadingGeneral = false;
      // TODO: set selected category on edit
      this.serviceCategoriesService
        .getDocumentationStandardsList(data.result.category_id)
        .subscribe((res) => {
          this.documentationStandardsList = res.result;
          this.formdata = res.result;
          this.setOffer(this.newOffer);

          this.newOffer.default_currency_id = data.result.provider.currency_id;
          this.newOffer.currency_code = data.result.provider.currency_code;
        });
      this.preselectedCategory = this.newOffer.category.path.split("/");
    });
  }

  updateOffer(draftState = null) {
    this.currentStatus = this.newOffer.status_id;
    if (draftState) {
      switch (draftState) {
        case "draft_update":
          this.newOffer.is_draft = true;
          break;
        case "draft_publish":
          this.newOffer.status_id = 2; // status_id = 2 (Pending & Active)
          break;
      }
    }

    this.newOffer.characteristics = this.parseFormdata();
    this.newOffer.unit_id = environment.unit_id;
    if (this.selectedCategory) {
      this.newOffer.category_id = this.selectedCategory.id;
    }

    this.updateServiceOffers(this.newOffer, this.id);
  }

  updateServiceOffers(offer, id) {
    // delete offer.current_price;
    this.isLoadingGeneral = true;
    const params = cloneDeep(offer);
    this.setCurrency(params);

    this.serviceOffers.updateServiceOffers(params, id).subscribe(
      () => {
        if (this.imagePath) {
          this.uploadImage(this.id);
        } else {
          this.router.navigate(["service-offers/" + this.id]);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.newOffer.status_id = this.currentStatus;
          this.alertService.errors(error.error.errors);
          this.isLoadingGeneral = false;
        } else if (error.error.message) {
          this.alertService.stringError(error.error.message);
          this.isLoadingGeneral = false;
        } else {
          this.alertService.stringError(error);
          this.isLoadingGeneral = false;
        }
        return false;
      }
    );
  }

  navigateBack() {
    this._location.back();
  }

  openPriceModal() {
    this.ngxSmartModalService.getModal("addPrice").open();
  }

  deletePrice(idx) {
    this.newOffer.prices.splice(idx, 1);
  }

  setDate(e, i) {
    if (e.start_date && e.end_date) {
      this.newOffer.prices[i].starts_on = e.start_date;
      this.newOffer.prices[i].ends_on = e.end_date;
    }
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.translate.get("GENERALS.ONLY-IMG").subscribe((data) => {
        this.message = data;
      });
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  removeUploaded() {
    this.imgURL = "";
    this.message = "";
  }

  uploadImage(id) {
    this.serviceOffers.uploadImage(id, this.imagePath).subscribe(
      () => {
        if (this.edit) {
          this.router.navigate(["service-offers/" + id]);
        }
      },
      (error) => {
        if (error.error.errors) {
          if (this.edit) {
            this.isLoadingGeneral = false;
            this.alertService.errors(error.error.errors);
          }
        }
        this.removeUploaded();
      }
    );
  }

  updateSelectedProvider(providerId) {
    this.providerId = providerId;
    this.getCategories(providerId);
    if (providerId) {
      this.getProviderById();
      this.getCountryList(providerId);
    }
  }

  loadCities(event?) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.request_call = this.dictionariesService
      .cities((event && event.term) || "", this.newOffer.country)
      .subscribe((response) => {
        if (response.success) {
          this.cities = response.result;
        }
      });
  }

  countryChanged() {
    this.cities = [];
    this.newOffer.city = null;
    // this.translate.get('GENERALS.SELECT-CITY').subscribe(data => {
    //   this.newOffer.city = data;
    // });
  }

  areSameDates(dateOne: Date, dateTwo: Date): boolean {
    if (moment(dateOne).year() !== moment(dateTwo).year()) {
      return false;
    }

    if (moment(dateOne).month() !== moment(dateTwo).month()) {
      return false;
    }

    if (moment(dateOne).date() !== moment(dateTwo).date()) {
      return false;
    }

    if (moment(dateOne).hour() !== moment(dateTwo).hour()) {
      return false;
    }

    if (moment(dateOne).minute() !== moment(dateTwo).minute()) {
      return false;
    }

    return moment(dateOne).second() === moment(dateTwo).second();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
  getPriceRange(price): DateRangeInterface {
    return {
      start_date: moment(price.starts_on).toDate(),
      end_date: moment(price.ends_on).toDate(),
    };
  }
}
