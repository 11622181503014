import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { AlertService } from "@modules/alert";

const MESSAGE_TIMEOUT = 300;
const TIMEOUT = 5000;

@Component({
  selector: "app-alert",
  templateUrl: "alert.component.html",
  styleUrls: ["alert.component.scss"],
})
export class AlertComponent implements OnInit {
  messages: any;
  messagesType: any;
  isErrValidation: boolean;
  isSuccessNotification: boolean;
  isErrorNotification: boolean;

  constructor(
    private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    this.alertService.getMessage().subscribe((message) => {
      if (message?.type && message?.text) {
        this.messages = message.text;
        this.messagesType = message.type;

        if (message.type === "error") {
          this.isErrValidation = true;
          setTimeout(() => {
            this.isErrValidation = false;
            setTimeout(() => {
              this.messages = [];
            }, MESSAGE_TIMEOUT);
          }, TIMEOUT);
        } else if (message.type === "success") {
          this.isSuccessNotification = true;
          setTimeout(() => {
            this.isSuccessNotification = false;
            setTimeout(() => {
              this.messages = [];
            }, MESSAGE_TIMEOUT);
          }, TIMEOUT);
        } else if (message.type === "string-error") {
          this.isErrorNotification = true;
          setTimeout(() => {
            this.isErrorNotification = false;
            setTimeout(() => {
              this.messages = [];
            }, MESSAGE_TIMEOUT);
          }, TIMEOUT);
        }
      }
    });
  }

  closeError(): void {
    setTimeout(() => {
      this.messages = [];
    }, 300);
    this.isErrValidation = false;
  }

  closeErrorNotification() {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    setTimeout(() => {
      this.messages = [];
      this.alertService.closeErrNotification(true);
    }, 300);
    this.isErrorNotification = false;
  }
}
