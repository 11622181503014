import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "@modules/alert";
import { ProvidersService } from "src/app/_services/providers.service";

@Component({
  selector: "app-hubspot-dialog",
  templateUrl: "./hubspot-dialog.component.html",
  styleUrls: ["./hubspot-dialog.component.css"],
})
export class HubspotDialogComponent implements OnInit {
  public entity: any;

  public hubspotForm = new UntypedFormGroup({
    hubspot_id: new UntypedFormControl("", Validators.required),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<HubspotDialogComponent>,
    private providerService: ProvidersService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.entity = { ...this.data.entity };
    this.hubspotForm.patchValue({
      hubspot_id: this.entity.hubspot_id,
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const data = this.hubspotForm.getRawValue();
    this.providerService
      .updateHubspot(data, this.entity.id)
      .subscribe((entity) => {
        this.alertService.success("Saved successfully");
        this.dialogRef.close({ entity });
      });
  }
}
