import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { AccountActions } from "../actions";
import { AccountSelectors } from "../selectors";
import { UserData } from "@models/user.model";

export interface ClientAccountSetupData {
  type: "client";
  company_name: string;
  industry: string;
  company_type_id: number;
  company_position: string;
}

export interface ExpatAccountSetupData {
  type: "expat";
  company_name: string;
  company_position: string;
}

@Injectable({
  providedIn: "root",
})
export class AccountService {
  data$ = combineLatest([
    this.store.select(AccountSelectors.selectUser),
    this.store.select(AccountSelectors.selectLoaded),
  ]).pipe(
    filter(([_user, loaded]) => loaded),
    map(([user, _loaded]) => user)
  );
  constructor(private api: BackendHttpClient, private store: Store) {}

  get account$(): Observable<UserData> {
    return this.store.select(AccountSelectors.selectAccountState).pipe(
      filter(({ loaded, user }) => loaded),
      map(({ user }) => {
        if (!user) {
          throw Error("no user");
        }
        return user;
      })
    );
  }

  private mapUserData(data: any): UserData {
    return {
      ...data,
    } as UserData;
  }

  fetchData(): Observable<UserData> {
    return this.api
      .get("users/current")
      .pipe(map((data: any) => this.mapUserData(data)));
  }

  /**
   * patch Update current user
   */
  updateCurrentUser(user: UserData): Observable<any> {
    return this.api.patch<any>("users/update-current-user", user).pipe(
      map((data) => this.mapUserData(data.result)),
      tap((user) =>
        this.store.dispatch(AccountActions.updateAccountDataSuccess({ user }))
      )
    );
  }

  setData(data: Partial<UserData>): void {
    this.store.dispatch(AccountActions.updateAccountData({ user: data }));
  }

  setupAccount(
    input: ClientAccountSetupData | ExpatAccountSetupData
  ): Observable<UserData> {
    return this.api.post<any>("users/setup", input).pipe(
      map((data) => this.mapUserData(data.result)),
      tap((user) =>
        this.store.dispatch(AccountActions.updateAccountDataSuccess({ user }))
      )
    );
  }
}
