import { Component, OnInit } from "@angular/core";
import { AccountService } from "@api/account/services";
import { AuthActions } from "@api/authentication";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { UserData } from "../../../../_models/user.model";

@Component({
  selector: "app-top-menu",
  templateUrl: "./top-menu.component.html",
})
export class TopMenuComponent implements OnInit {
  public user$: Observable<UserData>;
  public userMenuIsOpen = false;
  public isLoggedInUser = false;

  innerWidth;
  xs;
  userPic;

  constructor(private store: Store, private accountService: AccountService) {
    this.innerWidth = window.innerWidth;
    this.xs = this.innerWidth <= 992;
  }

  ngOnInit() {
    this.user$ = this.accountService.account$;
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  onResize() {
    this.innerWidth = window.innerWidth;

    this.xs = this.innerWidth <= 992;
  }
}
