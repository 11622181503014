<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">
    {{ "OFFERS.COMMISSIONS-LIST" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!--    todo add commissions btn modal-->
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div *ngIf="isFiltersLoaded" class="dashboard-body-action">
  <!-- left -->
  <div *ngIf="!hideFilters" class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [showCalendar]="true"
      [searchPlaceholder]="'GENERALS.SEARCH'"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      (date)="setFilteringDate($event); navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <div class="table-responsive">
    <table
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="{{ active }}"
      [matSortDirection]="direction"
    >
      <thead>
        <tr>
          <th>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </th>
          <th mat-sort-header="company_name">
            {{ "GENERALS.PROVIDER" | translate }}
          </th>
          <th mat-sort-header="service_category_name">
            {{ "OFFERS.CATEGORY-LABEL" | translate }}
          </th>
          <th mat-sort-header="start_date">
            {{ "GENERALS.ACTION" | translate }}
          </th>
          <th mat-sort-header="approver_full_name">
            {{ "FILTERS.APPROVER" | translate }}
          </th>
          <th mat-sort-header="request_status_id">
            {{ "GENERALS.STATUS" | translate }}
          </th>
          <th class="ta-r"></th>
        </tr>
      </thead>
      <tbody *ngIf="isLoadingGeneral">
        <tr>
          <td colspan="100">
            <app-loader-spinner-general
              [loadingType]="LoadingType.TABLE"
            ></app-loader-spinner-general>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoadingGeneral">
        <tr
          *ngFor="
            let commission of items
              | paginate
                : {
                    itemsPerPage: itemsPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <td>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(commission) : null"
              [checked]="selection.isSelected(commission)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </td>
          <td>
            {{ commission.provider.company_name }}
          </td>
          <td>
            <div>{{ commission.service_category.label }}</div>
            <div class="orange">
              <span
                *ngIf="
                  commission.service_category.commission_type &&
                  commission.service_category.commission_type.name ===
                    'COMMISSION_TYPE_PERCENT'
                "
              >
                {{ commission.service_category.commission }}%
              </span>
              <span
                *ngIf="
                  commission.service_category.commission_type &&
                  commission.service_category.commission_type.name ===
                    'COMMISSION_TYPE_FIXED'
                "
              >
                {{ commission.service_category.commission | currency : "EUR" }}
              </span>
            </div>
          </td>
          <td>
            <div>
              <span
                >{{ commission.author.name }}
                {{
                  "ACTIONS." + commission.action_type.name | translate
                }}:</span
              >
            </div>
            <div class="orange">
              <span
                *ngIf="
                  commission.commission_type.name === 'COMMISSION_TYPE_PERCENT'
                "
              >
                {{ commission.commission }}%
              </span>
              <span
                *ngIf="
                  commission.commission_type.name === 'COMMISSION_TYPE_FIXED'
                "
              >
                {{ commission.commission | currency : "EUR" }}
              </span>
              <span>
                for
                {{ commission.start_date | date : "dd/MM/yyyy" }}
                -
                {{ commission.end_date | date : "dd/MM/yyyy" }}</span
              >
            </div>
          </td>
          <td>{{ commission.approver.name }}</td>
          <td>
            <div
              class="status-label-{{ commission.status_name.toLowerCase() }}"
            >
              {{ commission.status_name }}
            </div>
          </td>
          <!-- table cel with action -->
          <td
            class="ta-r"
            *ngIf="
              authService.$can(
                action.MANAGE,
                resource.CATEGORY,
                subResource.FINANCIAL
              )
            "
          >
            <!-- table action with overlay -->
            <div
              toggle-action-table
              class="table-action-wrapper"
              *ngIf="actions && actions[commission.status_id]"
            >
              <i class="icon-dot-3"></i>

              <div class="table-action">
                <ul>
                  <li
                    *ngFor="let status of actions[commission.status_id]"
                    (click)="changeStatus(status, commission)"
                  >
                    {{ status.text }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- end table action with overlay -->
          </td>
          <!--end table cel with action -->
        </tr>
        <tr *ngIf="items?.length === 0">
          <td colspan="7" class="gray">
            {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
