<app-loader-spinner-general
  [isLoadingGeneral]="isLoadingGeneral"
></app-loader-spinner-general>

<div class="details-body-wrapper" *ngIf="user || !isLoadingGeneral">
  <!-- 2 col details -->
  <div class="details-2c">
    <!-- left -->
    <app-user-details-actions-panel
      [entity]="actionsDetails"
      (switchAccount)="switchToAccount($event)"
      [refresh]="user"
    >
    </app-user-details-actions-panel>

    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.ACCOUNT-INFO" | translate }}
            </div>
            <div
              class="white-box-header-action fl-r"
              (click)="openEditModal('account')"
            >
              {{ "GENERALS.EDIT" | translate }}
              <i class="icon-cog-outline"></i>
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <p>
              <span>{{ userInfo?.name }}</span>
            </p>
            <p>
              <i class="icon-visa_business"></i>
              {{ primaryAccount?.company_position }}
            </p>
            <p>
              <a href="mailto:{{ userInfo?.email }}">
                <i class="icon-mail"></i>
                {{ userInfo?.email }}
              </a>
            </p>
            <p>
              <i class="icon-phone"></i>
              {{ dictionariesService.getPhonePrefix(userInfo?.phone_prefix) }}
              {{ userInfo?.phone }}
            </p>
          </div>
        </div>

        <div class="white-box-row" *ngIf="customer && customer.subscription">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.SUBSCRIPTION-INFO" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <ng-container>
              <p>
                <span>{{ "LABEL.PLAN-NAME" | translate }}</span>
                {{ customer.subscription.plan.name }}
              </p>
              <p>
                <span>{{ "LABEL.RENEW-DATE" | translate }}</span>
                {{ customer.subscription.current_period_end | date }}
              </p>
              <a
                (click)="viewSubscription()"
                class="orange view-subscription"
                >{{ "LABEL.VIEW-SUBSCRIPTION" | translate }}</a
              >
            </ng-container>
          </div>
        </div>

        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.COMPANY-INFO" | translate }}
            </div>
            <div
              class="white-box-header-action fl-r"
              (click)="openEditModal('company')"
            >
              {{ "GENERALS.EDIT" | translate }}
              <i class="icon-cog-outline"></i>
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body" *ngIf="userType === 'provider'">
            <div class="row">
              <div class="w-50">
                <p>
                  <span>{{ "LABEL.COMPANY-NAME" | translate }}</span>
                  {{
                    user.company_name
                      ? user.company_name
                      : ("LABEL.COMPANY-NAME" | translate)
                  }}
                </p>

                <p>
                  <span>{{ "LABEL.COMPANY-LEGAL-NAME" | translate }}</span>

                  {{
                    user.company_legal_name && user.company_legal_name.length
                      ? user.company_legal_name
                      : ("LABEL.COMPANY-LEGAL-NAME" | translate)
                  }}
                </p>

                <p>
                  <span>{{ "LABEL.COMPANY-ADDRESS" | translate }}</span>

                  {{
                    user.address
                      ? user.address
                      : ("LABEL.COMPANY-ADDRESS" | translate)
                  }},

                  {{
                    user.default_legal_entity?.city
                      ? user.default_legal_entity?.city
                      : ("LABEL.COMPANY-CITY" | translate)
                  }},

                  {{
                    user.default_legal_entity?.country_code
                      ? user.default_legal_entity?.country_code
                      : ("LABEL.COMPANY-COUNTRY" | translate)
                  }},

                  {{
                    user.default_legal_entity?.zip_code
                      ? user.default_legal_entity?.zip_code
                      : ("LABEL.ZIP-CODE" | translate)
                  }}
                </p>
                <p>
                  <span>
                    {{
                      "LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME" | translate
                    }}
                  </span>

                  {{
                    user.legal_representative_name
                      ? user.legal_representative_name
                      : ("LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME"
                        | translate)
                  }}
                </p>
                <p>
                  <span>
                    {{ "LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate }}
                  </span>

                  {{
                    user.legal_representative_email
                      ? user.legal_representative_email
                      : ("LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate)
                  }}
                </p>
                <p>
                  <span>
                    {{ "LABEL.ONLINE-PAYMENTS-STATUS" | translate }}
                  </span>
                  <span
                    class="entity_status"
                    [className]="primaryAccount?.stripe_account?.status"
                  >
                    {{ primaryAccount?.stripe_account?.status }}
                  </span>
                </p>
              </div>
              <div class="w-50">
                <p>
                  <span> {{ "LABEL.TAX-NUMBER" | translate }}</span>

                  {{
                    user.default_legal_entity?.tax_number
                      ? user.default_legal_entity.tax_number
                      : ("LABEL.TAX-NUMBER" | translate)
                  }}
                </p>
                <p>
                  <span>
                    {{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }}
                  </span>

                  {{
                    user.bank_account_number
                      ? user.bank_account_number
                      : ("LABEL.BANK-ACCOUNT-NUMBER" | translate)
                  }}
                </p>
                <p>
                  <span>{{ "LABEL.BANK" | translate }}</span>

                  {{
                    user.bank_name ? user.bank_name : ("LABEL.BANK" | translate)
                  }}
                </p>
                <p>
                  <span>{{ "LABEL.SWIFT-CODE" | translate }}</span>

                  {{
                    user.swift_code
                      ? user.swift_code
                      : ("LABEL.SWIFT-CODE" | translate)
                  }}
                </p>
                <p>
                  <span>
                    {{ "LABEL.PROF-ASSOCIATION" | translate }}
                  </span>

                  {{
                    user.professional_association_name
                      ? user.professional_association_name
                      : ("LABEL.PROF-ASSOCIATION" | translate)
                  }}
                </p>
                <p>
                  <span>
                    {{ "LABEL.NUMBER-OF-EMPLOYEES" | translate }}
                  </span>

                  {{
                    user.number_of_employees_name
                      ? user.number_of_employees_name
                      : ("LABEL.NUMBER-OF-EMPLOYEES" | translate)
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="w-100 hubspot-field">
                <p class="flex-grow-1">
                  <span>{{ "LABEL.HUBSPOT_ID" | translate }}</span>
                  {{
                    user.hubspot_id
                      ? user.hubspot_id
                      : ("LABEL.HUBSPOT_ID" | translate)
                  }}
                </p>
                <div
                  class="white-box-header-action"
                  (click)="openHubspotModal()"
                >
                  {{ "GENERALS.EDIT" | translate }}
                  <i class="icon-cog-outline"></i>
                </div>
              </div>
              <div class="w-100">
                <p>
                  <span>{{
                    "LABEL.COMPANY-SHORT-DESCRIPTION" | translate
                  }}</span>

                  <span
                    *ngIf="user.short_description"
                    class="desc-content-innerHtml"
                    [innerHTML]="user.short_description"
                  ></span>
                </p>
              </div>
            </div>
          </div>

          <div class="white-box-body" *ngIf="userType === 'client'">
            <div class="row">
              <div class="w-50">
                <p>
                  <span>{{ "LABEL.COMPANY-NAME" | translate }}</span>

                  {{
                    user.company_name
                      ? user.company_name
                      : ("LABEL.COMPANY-NAME" | translate)
                  }}
                </p>
                <p>
                  <span>{{ "LABEL.COMPANY-ADDRESS" | translate }}</span>

                  {{
                    user.default_legal_entity?.address
                      ? user.default_legal_entity?.address
                      : ("LABEL.COMPANY-ADDRESS" | translate)
                  }},

                  {{
                    user.city ? user.city : ("LABEL.COMPANY-CITY" | translate)
                  }},

                  {{
                    user.default_legal_entity?.country_code
                      ? user.default_legal_entity?.country_code
                      : ("LABEL.COMPANY-COUNTRY" | translate)
                  }},

                  {{
                    user.zip_code
                      ? user.zip_code
                      : ("LABEL.ZIP-CODE" | translate)
                  }}
                </p>
                <p>
                  <span>{{ "LABEL.INDUSTRY" | translate }}</span>

                  {{
                    user.industry_name
                      ? user.industry_name
                      : ("LABEL.INDUSTRY" | translate)
                  }}
                </p>
              </div>
              <div class="w-50">
                <p>
                  <span>{{ "LABEL.COMPANY-TYPE" | translate }}</span>

                  {{
                    user.company_type_name
                      ? user.company_type_name
                      : ("LABEL.COMPANY-TYPE" | translate)
                  }}
                </p>

                <p>
                  <span>{{ "LABEL.TAX-NUMBER" | translate }}</span>

                  {{
                    user.default_legal_entity?.tax_number
                      ? user.default_legal_entity.tax_number
                      : ("LABEL.TAX-NUMBER" | translate)
                  }}
                </p>
                <p>
                  <span>{{ "LABEL.NUMBER-OF-EMPLOYEES" | translate }}</span>

                  {{
                    user.number_of_employees_name
                      ? user.number_of_employees_name
                      : ("LABEL.NUMBER-OF-EMPLOYEES" | translate)
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>
