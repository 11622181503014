import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ItemsListComponent } from "../../items-list/items-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import cloneDeep from "lodash-es/cloneDeep";
import { AlertService } from "@modules/alert";
import { InvoicesService } from "../../../../_services/invoices.service";
import { saveAs } from "file-saver";
import { WindowResizeHelperService } from "../../../../_services/window-resize-helper.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { Invoice } from "../../../../_models/invoice";
import { MatDialog } from "@angular/material/dialog";
import { InvoicesStatusEnum } from "../../../../_enums/invoices-status-enum";
import * as moment from "moment";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";
@Component({
  selector: "app-invoices-list",
  templateUrl: "./invoices-list.component.html",
})
export class InvoicesListComponent
  extends ItemsListComponent
  implements OnInit
{
  @Output() shouldUpdateCommissionsRequestsCount: EventEmitter<any> =
    new EventEmitter();

  items: Invoice[];
  request_call;
  basePath = "";
  isXs: boolean = false;
  isFiltersLoaded: boolean = false;
  invoicesStatusEnum = InvoicesStatusEnum;

  constructor(
    private service: InvoicesService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/financial/invoices", "invoices")
    );
    this.route.queryParams.subscribe((query) => {
      this.watchQueryParams(query);
    });

    this.showBulkActions = false;
  }

  ngOnInit() {
    this.basePath = window.location.pathname;
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });

    this.getStatusTransitions();
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    this.router.navigate([this.basePath], {
      queryParams: params,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoadingGeneral = true;
    let params = {};
    params = cloneDeep(this.filters);
    this.request_call = this.service
      .getInvoicesList(params)
      .pipe(
        finalize(() => {
          (this.isLoadingGeneral = false), (this.isFiltersLoaded = true);
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.isFiltersLoaded = true;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public updateInvoiceStatus(invoiceId, statusId) {
    this.service.updateInvoiceStatus(invoiceId, statusId).subscribe(
      () => {
        this.getItems();
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoadingGeneral = false;

        return false;
      }
    );
  }

  public exportCsv(invoice: Invoice) {
    const issuedOn = moment(invoice.issued_on).format("DD-MM-Y");

    this.service.exportCsv(invoice.id).subscribe(
      (data) => {
        const blob = new Blob([data], { type: "text/csv" });
        saveAs(
          blob,
          "Invoice_" + invoice.id + "_xpath_global_" + issuedOn + ".csv"
        );
      },
      (error) => {
        if (error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        return false;
      }
    );
  }

  public exportPdf(invoice: Invoice) {
    const issuedOn = moment(invoice.issued_on).format("DD-MM-Y");

    this.service.exportPdf(invoice.id).subscribe(
      (data) => {
        const blob = new Blob([data], { type: "text/csv" });
        saveAs(
          blob,
          "Invoice_" + invoice.id + "_xpath_global_" + issuedOn + ".pdf"
        );
      },
      (error) => {
        if (error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        return false;
      }
    );
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  getService() {
    return this.service;
  }

  public setFilters(e) {
    this.defaultFilters = {
      ...this.defaultFilters,
      ...e,
    };
    return this.cleanFilters(this.defaultFilters);
  }

  public cleanFilters(obj) {
    for (const propName in obj) {
      if (obj[propName] === null) {
        delete obj[propName];
      }
    }
    return obj;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
