import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ServiceOffersService } from "../../../../_services/service-offers.service";
import { ProvidersService } from "../../../../_services/providers.service";
import { ServiceOffer } from "../../../../_models/service-offer";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AlertService } from "@modules/alert";
import { NgxSmartModalService } from "ngx-smart-modal";
import { environment } from "src/environments/environment";
import cloneDeep from "lodash-es/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { ServiceCategoriesService } from "src/app/_services/service-categories.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { DateRangeInterface } from "src/app/_interfaces/date-range.interface";

@Component({
  selector: "app-add-bundle",
  templateUrl: "./add-bundle.component.html",
})
export class AddBundleComponent implements OnInit {
  providers;
  newOffer: ServiceOffer;
  id;
  isLoadingGeneral;
  search_fields;
  search_text: string;
  request_call;
  edit;
  offers: ServiceOffer[];
  public status = 3; // status_id = 3 (active)
  placeholders;
  formdata;
  city;
  providerId;
  readonly = false;
  searchFieldOffers;
  @ViewChild("chipsSelect", { static: true }) chipsSelect: ElementRef;
  disableSelect = false;
  constructor(
    public serviceOffers: ServiceOffersService,
    private serviceCategoriesService: ServiceCategoriesService,
    private providersService: ProvidersService,
    private route: ActivatedRoute,
    private _location: Location,
    private alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {
    this.search_fields = ["company_name"];
    this.initOffer();
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (params.id) {
        this.edit = true;
        this.getBundleById(params.id);
      } else {
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setForAddBundle()
        );
      }
    });

    this.route.queryParams.subscribe((query) => {
      if (query && query.provider_id) {
        this.providerId = query.provider_id;
        this.readonly = true;
        this.newOffer.provider_id = +query.provider_id;
      }
    });
  }

  ngOnInit() {
    if (!this.providerId) {
      this.getProviders(this.search_text, this.status);
    } else {
      this.getProviderById();
    }
  }

  getProviderById() {
    this.providersService.getProvider(this.providerId).subscribe((data) => {
      this.providers = [data.result];
      this.newOffer["company_name"] = data.result.company_name;
      this.newOffer.currency_code = data.result.currency_code;
      this.newOffer.default_currency_id = data.result.currency_id;
      this.getServiceOffers(this.providerId);
    });
  }

  getBundleById(id) {
    this.serviceOffers.getBundleServiceOffersById(id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForEditBundle(data.result.offer)
      );
      this.serviceCategoriesService
        .getDocumentationStandardsList(data.result.offer.category_id)
        .subscribe((res) => {
          this.formdata = res.result;
          this.setOffer(data.result);
        });
    });
  }

  setOffer(data) {
    this.newOffer = data.offer;
    this.newOffer = {
      ...this.newOffer,
      offers: data.parent_offers,
      default_price: data.offer.listing_price.price,
      sale_price: data.offer.sale_price.price,
      default_quantity: data.offer.listing_price.quantity,
      unit_id: environment.unit_id,
      default_currency_id: data.offer.default_price.currency_id,
    };

    this.newOffer.offers.forEach((of) => {
      this.placeholders = {
        default_price: this.placeholders.default_price + of.listing_price.price,
        sale_price: this.placeholders.sale_price + of.sale_price.price,
        min_price: this.placeholders.min_price + of.min_price,
        max_price: this.placeholders.max_price + of.max_price,
        duration: this.placeholders.duration + of.duration,
      };
    });

    this.formdata.forEach((ch) => {
      const idx = data.offer.characteristics.findIndex(
        (item) => item.characteristic_id === ch.characteristic_id
      );
      if (idx > -1) {
        const objFields = JSON.parse(data.offer.characteristics[idx].data);
        ch.fields.forEach((f, i) => {
          ch.fields[i].data = objFields[ch.fields[i].value];
        });
      }
    });
  }

  parseFormdata() {
    const characteristics = {};
    if (isEmpty(this.formdata)) {
      return {};
    }
    this.formdata
      .filter((characteristic) => characteristic.enabled)
      .forEach((characteristic) => {
        characteristic.fields.forEach((field, index) => {
          if (field.type === "checkbox") {
            characteristics[characteristic.characteristic_id] = {
              ...characteristics[characteristic.characteristic_id],
              [field.value]: field.data ? 1 : 0,
            };
          } else {
            if (field.data || false) {
              characteristics[characteristic.characteristic_id] = {
                ...characteristics[characteristic.characteristic_id],
                [field.value]: field.data,
              };
            }
          }
        });

        if (characteristics[characteristic.characteristic_id] !== undefined) {
          characteristics[characteristic.characteristic_id] = JSON.stringify(
            characteristics[characteristic.characteristic_id]
          );
        }
      });

    return characteristics;
  }

  initOffer() {
    this.newOffer = new ServiceOffer();
    this.newOffer.prices = [];
    this.newOffer.offers = [];

    this.newOffer.unit_id = environment.unit_id;
    this.placeholders = {
      default_price: 0,
      sale_price: 0,
      min_price: 0,
      max_price: 0,
      duration: 0,
      offers: "FILTERS.SELECT-OFFER",
    };
    this.city = null;
  }

  getProviders(search_text, status) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = {};
    params["status_id"] = status;
    if (search_text && search_text.term) {
      params["search_text"] = search_text.term;
      params["search_fields"] = this.search_fields;
    }

    this.providersService.getProviders(params).subscribe((data) => {
      this.providers = data.result.items;
    });
  }

  getServiceOffers(id, e?) {
    const params = {
      provider_id: id,
      status_id: 6, // Published & Active,
      is_bundle_hidden: 1,
    };
    if (this.city) {
      params["city"] = this.city;
    }
    if (e) {
      params["search_text"] = e;
    }
    this.serviceOffers.getServiceOffers(params).subscribe((res) => {
      this.offers = res.result.items;
      // if (!this.offers.length) {
      //   this.placeholders.offers = 'GENERALS.NO-ITEMS-FOUND';
      // }
    });
  }

  navigateBack() {
    this._location.back();
  }

  searchServiceOffers(e) {
    this.getServiceOffers(this.newOffer.provider_id, e);
  }

  updateSelectedOffer(e, unit) {
    const item = e.length ? e[e.length - 1] : e;
    this.placeholders.default_price =
      this.placeholders.default_price + unit * item.listing_price.price;
    this.placeholders.sale_price =
      this.placeholders.sale_price + unit * item.sale_price.price;
    this.placeholders.min_price =
      this.placeholders.min_price + unit * item.min_price;
    this.placeholders.max_price =
      this.placeholders.max_price + unit * item.max_price;
    this.placeholders.duration =
      this.placeholders.duration + unit * item.duration;

    if (!this.city) {
      this.city = item.city;
    }
    const params = {
      provider_id: this.newOffer.provider_id,
      status_id: 6, // Published & Active,
      is_bundle_hidden: 1,
    };
    this.serviceOffers.getServiceOffers(params).subscribe((res) => {
      this.offers = res.result.items;

      if (e) {
        if (e.length >= 3 || e.length >= this.offers.length) {
          this.disableSelect = true;
        } else if (e.length === undefined) {
          this.disableSelect = false;
        }
      }
    });
  }

  addPrice(price) {
    this.newOffer.prices.push(price);
  }

  openPriceModal() {
    this.ngxSmartModalService.getModal("addPrice").open();
  }

  deletePrice(idx) {
    this.newOffer.prices.splice(idx, 1);
  }

  removeOffer(item, idx) {
    this.newOffer.offers.splice(idx, 1);
    this.newOffer.offers = Object.assign([], this.newOffer.offers);
    this.updateSelectedOffer(item, -1);
  }

  validateNumericalInput(event: any, type) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setDate(e, i) {
    if (e.start_date && e.end_date) {
      this.newOffer.prices[i].starts_on = e.start_date;
      this.newOffer.prices[i].ends_on = e.end_date;
    }
  }

  setCurrency(offer) {
    if (offer.prices && offer.prices.length) {
      offer.prices.forEach((e) => {
        if (!e.currency_id) {
          e.currency_id = this.newOffer.default_currency_id;
        }
      });
    }
  }

  updateSelectedProvider(providerId) {
    const selectedProvider = this.providers.find(
      (provider) => provider.id === providerId
    );
    this.newOffer.currency_code = selectedProvider.currency_code;
    this.newOffer.default_currency_id = selectedProvider.currency_id;
    this.initOffer();
    this.getServiceOffers(selectedProvider.id);
    this.disableSelect = false;
  }

  addOffer() {
    const params = this.newOffer;
    params["offer_ids"] = this.newOffer.offers.map((item) => item.id);

    this.isLoadingGeneral = true;
    this.serviceOffers.createBundleServiceOffers(params).subscribe(
      (data) => {
        this.isLoadingGeneral = false;
        this.router.navigate(["service-offers/" + data.result.offer.id]);
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        return false;
      }
    );
  }

  updateOffer() {
    this.isLoadingGeneral = true;
    let params = this.newOffer;
    const formdata = this.parseFormdata();
    if (this.newOffer && !isEmpty(formdata)) {
      this.newOffer.characteristics = formdata;
    }
    if (this.newOffer.prices && this.newOffer.prices.length) {
      params = cloneDeep(this.newOffer);
      this.setCurrency(params);
    }

    this.serviceOffers.updateServiceOffers(params, this.newOffer.id).subscribe(
      (data) => {
        this.isLoadingGeneral = false;
        this.router.navigate(["service-offers/" + data.result.id]);
      },
      (error) => {
        this.isLoadingGeneral = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        return false;
      }
    );
  }

  hidePlaceholder() {
    this.getServiceOffers(this.newOffer.provider_id);

    const getPlaceholder =
      this.chipsSelect.nativeElement.querySelector(".ng-placeholder");
    // this.renderer.setStyle(getPlaceholder, 'display',  'none');
    getPlaceholder.classList.add("hide-placeholder");

    // getPlaceholder.innerHTML = '';
  }
  getPriceDateRange(price): DateRangeInterface {
    return {
      start_date: price.starts_on,
      end_date: price.ends_on,
    };
  }
}
