import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import {
  NgbDatepickerModule,
  NgbModule,
  NgbTimepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { OnlyNumberDirective } from "src/app/_directives/only-numbers.directive";
import { BulkActionsComponent } from "src/app/components/dashboard/items-list/bulk-actions/bulk-actions.component";
import { FiltersComponent } from "src/app/components/dashboard/items-list/filters/filters.component";
import { ElementsOnPageComponent } from "src/app/components/elements-on-page/elements-on-page.component";
import { CitySelectorComponent } from "src/app/standalone/components/city-selector/city-selector.component";
import { CountrySelectorComponent } from "src/app/standalone/components/country-selector/country-selector.component";
import { PhonePrefixSelectorComponent } from "src/app/standalone/components/phone-prefix-selector/phone-prefix-selector.component";
import { ConfirmActionModalComponent } from "../../components/dashboard/confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { AddClientStep2Component } from "./_components/add-client-step2/add-client-step2.component";
import { AddProfileImageComponent } from "./_components/add-profile-image/add-profile-image.component";
import { AddProviderStep2Component } from "./_components/add-provider-step2/add-provider-step2.component";
import { FormErrorsComponent } from "./_components/form-errors/form-errors.component";
import { RatingComponent } from "./_components/rating/rating.component";
import { DateRangeCalendarComponent } from "./_directives/date-range-calendar/date-range-calendar.component";
import { FormErrorDirective } from "./_directives/form-error.directive";
import { HoverHelperDirective } from "./_directives/HoverHelperDirective";
import { LocalizedNumericInputDirective } from "./_directives/localized-numeric-input.directive";
import { ToggleCalendarInputDirective } from "./_directives/toggle-calendar-input";
import { ToggleActionTableDirective } from "./_directives/toggle-table-action";
import { StsCalendarHelperDirective } from "./_directives/z-index-helper";
import { CountryPipe } from "./_pipes/country.pipe";
import { NAPipe } from "./_pipes/na.pipe";
import { NotAvailablePipe } from "./_pipes/not-available.pipe";
import { DialogModule } from "./modules/dialog/dialog.module";
import { NgxMatInputTelComponent } from "ngx-mat-input-tel";
import { AlertModule } from "@modules/alert/alert.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatInputTelComponent,
    MatCheckboxModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatSortModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatRadioModule,
    MatFormFieldModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgxChartsModule,
    NgSelectModule,
    NgbDatepickerModule,
    MatSlideToggleModule,
    NgbTimepickerModule,
    NgxSmartModalModule.forChild(),
    TranslateModule.forChild({
      extend: true,
    }),
    HttpClientModule,
    NgxMatSelectSearchModule,
    FileUploadModule,
    NgbModule,
    NgxPaginationModule,
    CountrySelectorComponent,
    CitySelectorComponent,
    DialogModule,
    AlertModule,
  ],
  declarations: [
    StsCalendarHelperDirective,
    DateRangeCalendarComponent,
    ToggleCalendarInputDirective,
    HoverHelperDirective,
    ToggleActionTableDirective,
    ConfirmActionModalComponent,
    AddProviderStep2Component,
    AddClientStep2Component,
    NotAvailablePipe,
    AddProfileImageComponent,
    RatingComponent,
    PhonePrefixSelectorComponent,
    FiltersComponent,
    ElementsOnPageComponent,
    BulkActionsComponent,
    FormErrorsComponent,
    OnlyNumberDirective,
    FormErrorDirective,
    CountryPipe,
    NAPipe,
    LocalizedNumericInputDirective,
  ],
  exports: [
    NgxMatInputTelComponent,
    DialogModule,
    StsCalendarHelperDirective,
    DateRangeCalendarComponent,
    ToggleCalendarInputDirective,
    FormErrorDirective,
    HoverHelperDirective,
    ToggleActionTableDirective,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatSortModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatRadioModule,
    MatFormFieldModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgxChartsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    HttpClientModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    AddProviderStep2Component,
    AddClientStep2Component,
    NotAvailablePipe,
    CountryPipe,
    NAPipe,
    RatingComponent,
    PhonePrefixSelectorComponent,
    CountrySelectorComponent,
    CitySelectorComponent,
    FiltersComponent,
    ElementsOnPageComponent,
    BulkActionsComponent,
    NgxPaginationModule,
    FormErrorsComponent,
    OnlyNumberDirective,
    LocalizedNumericInputDirective,
    AlertModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        display: {
          dateInput: "DD/MM/YYYY",
          monthYearLabel: "MMM YYYY",
        },
      },
    },
  ],
})
export class SharedModule {}
