import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Dictionary } from "../../../../_models/dictionary";
import { ServiceCategory } from "../../../../_models/servicecategory";
import { AlertService } from "@modules/alert";
import { ServiceCategoriesService } from "../../../../_services/service-categories.service";

@Component({
  selector: "app-add-subcategory",
  templateUrl: "./add-subcategory.component.html",
})
export class AddSubCategoryComponent {
  setModal: boolean;
  departments: Dictionary;
  employeesNumber: Dictionary;
  newServiceSubCategories: ServiceCategory;

  @Input() parentId;
  @Output() getServiceCategories: EventEmitter<any> = new EventEmitter();

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private serviceCategoriesService: ServiceCategoriesService
  ) {
    this.setModal = false;
    this.newServiceSubCategories = new ServiceCategory();
  }

  createServiceSubCategories() {
    this.newServiceSubCategories.parent_id = this.parentId;
    this.serviceCategoriesService
      .createServiceCategories(this.newServiceSubCategories)
      .subscribe(
        (data) => {
          this.getServiceCategories.emit("");
          this.ngxSmartModalService.getModal("addSubCategory").close();
        },
        (error) => {
          this.alertService.errors(error.error.errors);
        }
      );
  }

  createOtherServiceSubCategories() {
    this.newServiceSubCategories.parent_id = this.parentId;
    this.serviceCategoriesService
      .createServiceCategories(this.newServiceSubCategories)
      .subscribe(
        (data) => {
          this.getServiceCategories.emit("");
          this.initEmpty();
        },
        (error) => {
          this.alertService.errors(error.error.errors);
        }
      );
  }

  isModalOpen() {
    this.setModal = true;
  }

  isModalClose() {
    this.setModal = false;
  }
  cancelModal() {
    this.ngxSmartModalService.getModal("addSubCategory").close();
  }

  initEmpty(): void {
    this.newServiceSubCategories = new ServiceCategory();
  }
}
