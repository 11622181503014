<div class="dialog">
  <app-dialog-header>
    {{ "GENERALS.EDIT-COMPANY-INFO" | translate }}
  </app-dialog-header>
  <div class="dialog-body">
    <div *ngIf="entityType === entityTypes.PROVIDER">
      <app-add-provider-step2
        [provider]="entity"
        [edit]="true"
      ></app-add-provider-step2>
    </div>

    <div *ngIf="entityType === entityTypes.CLIENT">
      <app-add-client-step2
        [client]="entity"
        [edit]="true"
      ></app-add-client-step2>
    </div>
  </div>
  <div class="clear"></div>

  <div class="dialog-footer">
    <button (click)="cancel()" class="btn gray-bg fl-l">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button (click)="confirm()" class="btn orange-bg fl-r">
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>
