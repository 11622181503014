<div class="country-wrapper">
  <mat-form-field class="country-field" appearance="outline">
    <mat-label>
      <ng-content select="[slot='label']"> </ng-content>
    </mat-label>

    <mat-select
      [formControl]="$any(control)"
      (click)="markAsTouched()"
      (selectionChange)="onSelectChange($event)"
      disableOptionCentering
      panelClass="country-panel"
      [compareWith]="preselectCountries"
      [multiple]="multiple"
      [placeholder]="placeholder | translate"
    >
      <mat-option>
        <ngx-mat-select-search
          [placeholderLabel]="'LABEL.COUNTRY' | translate"
          [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          [formControl]="countrySearchControl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let country of filteredCountries$ | async"
        [value]="onlyCode ? country.country_code : country"
      >
        {{ country.name }}
      </mat-option>
    </mat-select>
    <!-- <mat-error formControlError [control]="control"></mat-error> -->
  </mat-form-field>
</div>
