import { UserData } from "@models/user.model";
import { createAction, props } from "@ngrx/store";

export const loadAccount = createAction("[Account] Load Account Data");
export const reloadAccount = createAction("[Account] Reload Account Data");
export const reloadAccountSuccess = createAction(
  "[Account] Reload Account Success",
  props<{ user: UserData }>()
);
export const reloadAccountFailure = createAction(
  "[Account] Reload Account Failed",
  props<{ error: any }>()
);
export const loadAccountSuccess = createAction(
  "[Account] Load Account Success",
  props<{ user: UserData }>()
);

export const updateAccountData = createAction(
  "[Account] Update Account",
  props<{ user: Partial<UserData> }>()
);

export const updateAccountDataSuccess = createAction(
  "[Account] Update Account Success",
  props<{ user: Partial<UserData> }>()
);
export const loadAccountFailure = createAction(
  "[Account] Load Account Failed",
  props<{ error: any }>()
);

export const clear = createAction("[Account] Clear Account");
