export class Expat {
  id?: number;
  primary_image_path?: string;
  client_id?: number;
  company_name?: string;
  user_id?: number;
  name?: string;
  email?: string;
  payroll_id?: string;
  adults_number?: number;
  children_number?: number;
  status_id?: number;
  status_name?: string;
  address?: string;
  region?: string;
  city?: string;
  zip_code?: string;
  country_code?: string;
  bank_name?: string;
  swift_code?: string;
  bank_account_number?: string;
  declined_reason?: string;
  main_register?: number;
  phone_prefix?: string;
  phone_number?: string;
  expat_id?: number;
  first_name?: string;
  last_name?: string;
  created_at: string;
  details?: {
    marital_status_id?: number;
    education_form_id?: number;
    office_id?: number;
    manager_id?: number | null;
    manager_email?: string;
    manager_name?: string;
    department_name?: string;
    company_position?: string;
    birth_date?: string;
    birth_country?: string;
    birth_city?: string;
    primary_language?: string;
  };
  user?: {
    id?: number;
    first_name?: string;
    last_name?: string;
    name?: string;
    email?: string;
    company_position?: string;
    entity_id?: number;
    entity_type?: string;
    is_primary?: false;
    phone?: string;
    phone_number?: string;
    phone_prefix?: string;
    profile_image?: string;
    status_id?: number;
    admin_access?: number;
    auid?: number;
    primary_image_path?: string;
    status_name?: string;
    status_reason?: string;
    confirmed_account?: false;
    last_login?: string;
    created_at?: string;
    updated_at?: string;
    role?: string;
    role_name?: string;
    role_id?: number;
    stripe_account?: Array<object>;
  };
}
