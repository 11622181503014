<div class="outside-dropdown">
  <label *ngIf="hideLabel">
    {{ "GENERALS.PROVIDER" | translate }}
  </label>

  <ng-select
    class="customiz-select white-select"
    [items]="options"
    bindLabel="text"
    bindValue="id"
    placeholder="{{ 'GENERALS.PROVIDER' | translate }}"
    [loading]="loading"
    [(ngModel)]="selectedProviderId"
    notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
    (search)="searchProviders($event.term, null)"
    (change)="selectedProvider.emit(selectedProviderId)"
  >
  </ng-select>
</div>

<span *ngIf="total" class="gray m-l-r-15"
  >{{ total }} {{ "GENERALS.RESULTS" | translate | lowercase }}</span
>
