<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "LEFT-NAVIGATION.NAV2" | translate }}</div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <div class="btn white-bg" (click)="openModal()">
      <i class="icon-user-add-outline"></i>
      {{ "GENERALS.ADD" | translate }}
    </div>

    <div class="btn white-bg" (click)="openInviteModal()">
      <i class="icon-messaging_comp"></i>
      {{ "GENERALS.INVITE" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      [total]="total"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
  <!-- end right -->
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div
  #tableWrapper
  class="table-wrapper"
  (window:resize)="onResize($event)"
  (window:scroll)="onResize($event)"
>
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        [matSortDirection]="direction"
      >
        <div #invisibleThead></div>
        <thead #tableHead>
          <tr>
            <ng-container matColumnDef="select">
              <th>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th mat-sort-header="id">
              {{ "GENERALS.ID" | translate }}
            </th>
            <th mat-sort-header="company_name">
              {{ "GENERALS.COMPANY" | translate }}
            </th>
            <th mat-sort-header="country_code">
              {{ "GENERALS.COUNTRY" | translate }}
            </th>
            <th>{{ "GENERALS.SERVICEOFFERS" | translate }}</th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            #tableBodyTr
            *ngFor="
              let provider of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? changeSelection(provider) : null"
                [checked]="selection.isSelected(provider)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, provider.id)"
            >
              <a [href]="'/providers/' + provider.id" class="orange underline">
                {{ provider.id }}
              </a>
            </td>
            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, provider.id)"
            >
              {{ provider.company_name }}
            </td>
            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, provider.id)"
            >
              {{ provider.default_legal_entity?.country_code.toUpperCase() }}
            </td>
            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, provider.id)"
            >
              <div *ngFor="let offerCounts of $any(provider).offers">
                {{ offerCounts.status_label }}:
                {{ offerCounts.offers }}
              </div>
            </td>
            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, provider.id)"
            >
              <div class="entity_status-label-{{ provider.status_id }}">
                {{ provider.status_name }}
              </div>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action with overlay -->
              <div
                toggle-action-table
                class="table-action-wrapper"
                *ngIf="actions"
              >
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li
                      *ngFor="let action of actions[provider.status_id]"
                      (click)="changeStatus(action, provider)"
                    >
                      {{ action.text }}
                    </li>
                    <li (click)="openSwitchModal(provider.id)">
                      {{ "GENERALS.LOG-IN-AS-PROVIDER" | translate }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>

    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let provider of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(provider) : null"
              [checked]="selection.isSelected(provider)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            (click)="navigateByUrl(filters.page, provider.id)"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ provider.id }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COMPANY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ provider.company_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COUNTRY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ provider.default_legal_entity?.country_code.toUpperCase() }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.SERVICEOFFERS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ $any(provider).service_offers }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="entity_status-label-{{ provider.status_id }}">{{
              provider.status_name
            }}</span>
          </div>
        </div>

        <div *ngIf="actions" class="responsive-table-action">
          <span
            *ngFor="let action of actions[provider.status_id]"
            (click)="changeStatus(action, provider)"
          >
            {{ action.text }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

<!-- add provider modal -->
<app-provider-invite></app-provider-invite>
<app-user-details-actions-panel
  (switchAccount)="switchToAccount($event)"
></app-user-details-actions-panel>
<!-- end add provider modal -->
