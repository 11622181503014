import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";

import { environment } from "../../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-filter-provider-name",
  templateUrl: "./filter-provider-name.component.html",
})
export class FilterProviderNameComponent implements OnInit, OnChanges {
  @Input() providerId: number;
  @Input() hideLabel;
  @Input() total;

  @Output() selectedProvider: EventEmitter<any> = new EventEmitter();

  options: any[];
  providerName: string;
  loading = false;
  selectedProviderId;
  subscription: Subscription;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.searchProviders(this.providerName, this.providerId);
  }

  ngOnChanges(changes) {
    if (
      changes &&
      changes.providerId &&
      changes.providerId.previousValue &&
      !changes.currentValue
    ) {
      this.selectedProviderId = null;
    }
  }

  searchProviders(providerName: string, providerId) {
    // TODO: replace search provider url with the correct one
    this.loading = true;

    const searchProvidersUrl =
      environment.gateway_endpoint + "entities/providers/search";
    const queryParams = {
      company_name: providerName ? providerName : "",
      provider_id: providerId ? providerId : "",
      limit: "10",
    };

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.http
      .get<any>(searchProvidersUrl, { params: queryParams })
      .pipe(debounceTime(1000))
      .subscribe((response) => {
        this.options = response.result ? response.result : [];

        // auto selected option, comes from url one time
        // cast to number
        if (this.options.length > 0 && providerId) {
          this.selectedProviderId = +providerId;
        }
        this.loading = false;
      });
  }
}
