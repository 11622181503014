import { Component, OnInit } from "@angular/core";
import { AdsService } from "src/app/_services/ads.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { ConfirmActionModalComponent } from "../../confirm-action-modal/confirm-action-modal/confirm-action-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "@modules/alert";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-ad-campaigns",
  templateUrl: "./ad-campaigns.component.html",
  styleUrls: ["./ad-campaigns.component.css"],
})
export class AdCampaignsComponent implements OnInit {
  isLoadingGeneral: boolean;
  request_call;
  listAdBanner;
  itemsPage: number;
  p: number;
  total: number;
  isXs: boolean;
  listAdBannersStatusTransitions;
  listBannerType;
  campaignDetails;
  previewDetails;

  constructor(
    private adsService: AdsService,
    private windowResizeHelper: WindowResizeHelperService,
    public ngxSmartModalService: NgxSmartModalService,
    private breadcrumbService: BreadcrumbService,
    protected dialog: MatDialog,
    protected alertService: AlertService
  ) {}

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/cms/ads/ad-campaigns", "Ad Campaigns")
    );
    this.getListAdBannersStatusTransitions();
    this.getListAdBanner(1);
    this.getListBannerType();
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  getListAdBanner(page: number, param?) {
    const params = {};
    params["page"] = page;

    if (param) {
      params["key"] = param;
    }

    this.isLoadingGeneral = true;
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.request_call = this.adsService
      .getListAdBanner(params)
      .subscribe((data) => {
        this.listAdBanner = data.result.items;
        this.itemsPage = data.result.perPage;
        this.p = data.result.currentPage;
        this.total = data.result.total;
        this.isLoadingGeneral = false;
      });
  }

  getListAdBannersStatusTransitions() {
    this.adsService.getListAdBannersStatusTransitions().subscribe((data) => {
      this.listAdBannersStatusTransitions = data.result;
    });
  }

  getListBannerType() {
    this.adsService.getListBannerType().subscribe((data) => {
      this.listBannerType = data.result;
    });
  }

  openConfirmAction(action, id) {
    const status = {};

    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        status["status_reason"] = result;
        status["status"] = action.id;
        if (result && result !== undefined) {
          this.adsService.updateAdBanner(status, id).subscribe(
            (data) => {
              this.getListAdBanner(1);
            },
            (error) => {
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.adsService.updateAdBanner(status, id).subscribe((data) => {
        this.getListAdBanner(1);
      });
    }
  }

  openCampaignDetails(e) {
    this.campaignDetails = e;
    this.ngxSmartModalService.getModal("detailsCampaign").open();
  }

  previewBanner(e) {
    this.previewDetails = e;
    this.ngxSmartModalService.getModal("previewBanner").open();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
