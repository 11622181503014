<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">
    {{ "GENERALS.AD-CAMPAIGNS" | translate }}
  </div>
  <!-- end left -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="isLoadingGeneral"
  class="d-flex justify-content-end"
  [loadingType]="LoadingType.BUTTONS"
></app-loader-spinner-general>
<div
  windowScrollDashboardBodyAction
  class="dashboard-body-action"
  *ngIf="!isLoadingGeneral"
>
  <!-- right -->
  <div class="fl-r">
    <ng-select
      class="customiz-select white-select min-select-width"
      placeholder="{{ 'GENERALS.FILTER-BANNER-TYPE' | translate }}"
      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      [clearable]="true"
      [searchable]="false"
      (change)="getListAdBanner(1, $event)"
    >
      <ng-option *ngFor="let option of listBannerType" [value]="option.key">
        {{ "BANNER-TYPE." + option.key | translate }}
      </ng-option>
    </ng-select>
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>{{ "GENERALS.ID" | translate }}</th>
            <th>{{ "GENERALS.CREATIVE" | translate }}</th>
            <th>{{ "GENERALS.PLACEMENT" | translate }}</th>
            <!-- <th>{{ 'GENERALS.PERFORMANCE' | translate }}</th> -->
            <th>{{ "GENERALS.DURATION" | translate }}</th>

            <th>{{ "GENERALS.DAILY-BUDGET" | translate }}</th>
            <th>{{ "GENERALS.CLICKS" | translate }}</th>
            <th>{{ "GENERALS.VIEWS" | translate }}</th>
            <th>{{ "GENERALS.COST-THIS-MONTH" | translate }}</th>

            <!-- <th>{{ 'GENERALS.ACTION-URL' | translate }}</th> -->
            <th>{{ "GENERALS.STATUS" | translate }}</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let item of listAdBanner
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td class="gray">
              {{ item.id }}
            </td>

            <td class="gray">
              <div class="img-inside-table">
                <img
                  class="cursor-zoom"
                  (click)="previewBanner(item)"
                  src="{{ item.image_url }}"
                  alt="{{ item.key | translate }}"
                  title="{{ 'GENERALS.PREVIEW-BANNER' | translate }}"
                />
              </div>
            </td>

            <td class="gray">
              {{ "BANNER-TYPE." + item.key | translate }}
            </td>

            <!-- <td class="gray">
             {{item.performance_type | uppercase}}
            </td> -->

            <td class="gray">
              {{ item.starts_at | date : "dd/MM/yyyy" }} -
              {{ item.ends_at | date : "dd/MM/yyyy" }}
            </td>

            <td class="gray">
              {{ item.max_cost | currency : "EUR" }}
            </td>

            <td class="gray">
              {{ item.clicks }}
            </td>

            <td class="gray">
              {{ item.views }}
            </td>

            <td class="gray">
              {{ item.cost_this_month | currency : "EUR" }}
            </td>

            <!-- <td class="gray">
             <a class="action-link-orange" href="{{item.action_url}}" target="_blank">{{item.action_url}}</a>
            </td> -->

            <td>
              <div class="status-label-{{ item.status }}">
                {{ item.status }}
              </div>
            </td>

            <td>
              <i
                *ngIf="item.status_reason"
                class="info-icon-table fs-26 icon-attention-circled"
                title="{{ item.status_reason }}"
              ></i>
            </td>

            <td>
              <i
                (click)="openCampaignDetails(item)"
                class="info-icon-table fs-24 icon-view-more"
                title="{{ 'GENERALS.CAMPAIGN-DETAILS' | translate }}"
              ></i>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action btn -->
              <!-- <div class="table-action-btn">
                    <i class="icon-list-add"></i>
                </div> -->
              <!-- end table action btn -->

              <!-- table action with overlay -->

              <div toggle-action-table class="table-action-wrapper">
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul *ngIf="listAdBannersStatusTransitions">
                    <!-- <li (click)="updateAdBanner('inactive', item.id)" *ngIf="item.status == 'active'">
                      {{ 'GENERALS.DISABLE' | translate }}
                    </li>
                    <li (click)="updateAdBanner('active', item.id)" *ngIf="item.status == 'inactive'">
                      {{ 'GENERALS.PUBLISH' | translate }}
                    </li> -->

                    <li
                      *ngFor="
                        let action of listAdBannersStatusTransitions[
                          item.status
                        ]
                      "
                      (click)="openConfirmAction(action, item.id)"
                    >
                      {{ action.text }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="listAdBanner?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let item of listAdBanner
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.id }}</div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CREATIVE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <img src="{{ item.image_url }}" alt="{{ item.key | translate }}" />
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.PLACEMENT" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ "BANNER-TYPE." + item.key | translate }}
          </div>
        </div>
        <!--
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">{{ 'GENERALS.PERFORMANCE' | translate }}</div>
          <div class="responsive-table-data-item"> {{item.performance_type | uppercase}}</div>
        </div> -->

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.DURATION" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ item.starts_at | date : "dd/MM/yyyy" }} -
            {{ item.ends_at | date : "dd/MM/yyyy" }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.DAILY-BUDGET" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ item.max_cost | currency : "EUR" }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CLICKS" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.clicks }}</div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.VIEWS" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.views }}</div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COST-THIS-MONTH" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ item.cost_this_month | currency : "EUR" }}
          </div>
        </div>

        <!-- <div class="responsive-table-row">
          <div class="responsive-table-header-item">{{ 'GENERALS.ACTION-URL' | translate }}</div>
          <div class="responsive-table-data-item">
            <a class="action-link-orange" href="{{item.action_url}}" target="_blank">{{item.action_url}}</a>
          </div>
        </div> -->

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ item.status }}">
              {{ item.status }}
            </span>
          </div>
        </div>

        <div *ngIf="item.status_reason" class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.DECLINE-MESSAGE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ item.status_reason }}
          </div>
        </div>

        <div class="responsive-table-action">
          <span (click)="previewBanner(item)">{{
            "GENERALS.PREVIEW-BANNER" | translate
          }}</span>
          <span (click)="openCampaignDetails(item)">{{
            "GENERALS.CAMPAIGN-DETAILS" | translate
          }}</span>
          <span
            *ngFor="let action of listAdBannersStatusTransitions[item.status]"
          >
            {{ action.text }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="getListAdBanner($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

<app-details-campaign [item]="campaignDetails"></app-details-campaign>

<app-preview-banner [item]="previewDetails"></app-preview-banner>
