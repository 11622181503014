<div class="category-tree-group" *ngFor="let item of categories | keyvalue">
  <div
    toggle-row
    class="toggle-row-action"
    *ngIf="$any(item.value).children_count"
  >
    <i class="icon-plus-1"></i>
  </div>
  <div
    class="category-tree-row"
    *ngIf="
      !(
        $any(item.value).provider_commissions &&
        $any(item.value).provider_commissions.length
      ) && $any(item.value).children_count
    "
  >
    <span
      class="category-title"
      [ngClass]="{ 'fw-600': $any(item.value).level === 0 }"
      >{{ $any(item.value).label }}</span
    >
    <span class="fs-12">
      <span
        class="orange m-0"
        *ngIf="
          $any(item.value).commission_type &&
          $any(item.value).commission_type.id.toString() !==
            providerCommissionEnum.percent.toString()
        "
      >
        {{ $any(item.value).commission | currency : "EUR" }}
      </span>
      <span
        class="orange m-0"
        *ngIf="
          $any(item.value).commission_type &&
          $any(item.value).commission_type.id.toString() ===
            providerCommissionEnum.percent.toString()
        "
      >
        {{ $any(item.value).commission }}%
      </span>
      {{ "GENERALS.COMMISSION" | translate }}</span
    >
    <span *ngIf="$any(item.value).data" class="gray fs-12">
      ({{ $any(item.value).data }})
    </span>
    <span
      class="set-commission-btn fl-r"
      *ngIf="
        authService.$can(
          action.UPDATE,
          resource.PROVIDER,
          subResource.FINANCIAL
        )
      "
    >
      <span
        class="action-link-orange"
        *ngIf="
          authService.$can(
            action.CREATE,
            resource.CATEGORY,
            subResource.FINANCIAL
          )
        "
        (click)="
          onchange.emit({
            category: item.value,
            type: 'add'
          })
        "
        >{{ "LABEL.SET-COMMISSION" | translate }}
      </span>
      <span class="gray"> | </span>
      <span
        class="action-link-orange"
        (click)="
          onchange.emit({
            category: item.value,
            type: 'remove-category'
          })
        "
        >{{ "LABEL.REMOVE-PROVIDER-CATEGORY" | translate }}</span
      >
    </span>
  </div>

  <div
    class="category-tree-row"
    id="readonly-periods"
    *ngIf="$any(item.value).children_count === 0"
  >
    <div
      class="category-title"
      [ngClass]="{
        'fw-600': $any(item.value).level === 0,
        'leaf-category-title': $any(item.value).children_count === 0
      }"
    >
      <span>{{ $any(item.value).label }}</span>

      <span class="set-commission-btn fl-r">
        <ng-container
          *ngIf="
            authService.$can(
              action.UPDATE,
              resource.CATEGORY,
              subResource.FINANCIAL
            )
          "
        >
          <span
            class="action-link-orange"
            (click)="
              onchange.emit({
                category: item.value,
                type: 'remove-category'
              })
            "
            >{{ "LABEL.REMOVE-PROVIDER-CATEGORY" | translate }}
          </span>
          <span class="gray"> | </span>
        </ng-container>
        <span
          class="action-link-orange"
          *ngIf="
            authService.$can(
              action.CREATE,
              resource.CATEGORY,
              subResource.FINANCIAL
            )
          "
          (click)="
            onchange.emit({
              category: item.value,
              type: 'add'
            })
          "
          >{{ "LABEL.SET-COMMISSION" | translate }}
        </span>
      </span>
    </div>

    <ng-container
      *ngIf="
        $any(item.value).provider_commissions &&
        $any(item.value).provider_commissions.length
      "
    >
      <div class="tree-commissions-row-wrapper">
        <div
          class="tree-commissions-row"
          *ngFor="
            let commission of $any(item.value).provider_commissions
              | sortDate : 'start_date'
          "
        >
          <div class="fl-l">
            <span
              class="m-0 fw-500"
              *ngIf="
                commission.commission_type &&
                commission.commission_type.id.toString() !==
                  providerCommissionEnum.percent.toString()
              "
            >
              {{ commission.commission | currency : "EUR" }}</span
            >
            <span
              class="m-0 fw-500"
              *ngIf="
                commission.commission_type &&
                commission.commission_type.id.toString() ===
                  providerCommissionEnum.percent.toString()
              "
            >
              {{ commission.commission }} %</span
            >
            {{ "GENERALS.COMMISSION-LOWERCASE" | translate }}
          </div>

          <div class="fl-l">
            {{ "GENERALS.FROM" | translate }}
            <span class="m-0 fw-500">{{
              commission.start_date | date : "dd/MM/yyyy"
            }}</span>

            <ng-container *ngIf="commission.start_date !== commission.end_date">
              {{ "GENERALS.TO" | translate }}
              <span class="m-0 fw-500">{{
                commission.end_date | date : "dd/MM/yyyy"
              }}</span>
            </ng-container>
          </div>

          <div class="tree-commissions-action fl-l">
            <span
              *ngIf="
                authService.$can(
                  action.MANAGE,
                  resource.CATEGORY,
                  subResource.FINANCIAL
                )
              "
              (click)="
                onchange.emit({
                  commission: commission,
                  category: item.value,
                  type: 'update'
                })
              "
              title="{{ 'GENERALS.EDIT' | translate }}"
            >
              <i class="icon-edit"></i>
            </span>
            <span
              *ngIf="
                authService.$can(
                  action.MANAGE,
                  resource.CATEGORY,
                  subResource.FINANCIAL
                )
              "
              (click)="
                onchange.emit({
                  commission: commission,
                  path: $any(item.value).path,
                  type: 'delete'
                })
              "
              title="{{ 'GENERALS.DELETE' | translate }}"
            >
              <i class="icon-trash-empty"></i>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="toggle-tree" *ngIf="$any(item.value).children_count">
    <app-provider-categories-tree
      [categories]="$any(item.value).children"
      (onchange)="onchange.emit($event)"
    >
    </app-provider-categories-tree>
  </div>
</div>
