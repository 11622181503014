<div class="dialog">
  <app-dialog-header>
    {{ (edit ? "PORTAL.EDIT-EXPAT" : "PORTAL.ADD-EXPAT") | translate }}
  </app-dialog-header>
  <div class="dialog-body">
    <div *ngIf="showExpatAddedMessage" class="inner-notification-msg">
      {{
        "PORTAL.EXPAT-CREATED"
          | translate
            : {
                first_name: recentlyAddedExpat.user.first_name,
                last_name: recentlyAddedExpat.user.last_name
              }
      }}
    </div>
    <div class="loading-wrapper" *ngIf="isLoadingGeneral">
      <div class="w-100">
        <div class="w-50">
          <app-loader-spinner-general
            [repeat]="5"
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
        </div>
        <div class="w-50">
          <app-loader-spinner-general
            [repeat]="4"
            [loadingType]="LoadingType.INPUT"
          ></app-loader-spinner-general>
        </div>
      </div>
    </div>
    <form [formGroup]="form" *ngIf="!isLoadingGeneral && form && expat">
      <div class="field-grid">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.FIRST-NAME" | translate }}</mat-label>
          <input matInput formControlName="first_name" />
          <mat-error formControlError="first_name"> </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.LAST-NAME" | translate }}</mat-label>
          <input matInput formControlName="last_name" />
          <mat-error formControlError="last_name"> </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.EMAIL" | translate }}</mat-label>
          <input matInput formControlName="email" />
          <mat-error formControlError="email"> </mat-error>
        </mat-form-field>

        <mat-form-field class="phone-field w-full">
          <mat-label>
            {{ "LABEL.PHONE-NUMBER" | translate }}
          </mat-label>
          <ngx-mat-input-tel
            [enablePlaceholder]="true"
            [enableSearch]="true"
            formControlName="phone_number"
            name="phone_number"
            describedBy="phoneInput"
            [format]="'national'"
            [required]="true"
            #phone
            maxLength="20"
            (countryChanged)="
              form.controls.phone_prefix.setValue('+' + $event.dialCode)
            "
          ></ngx-mat-input-tel>
          <mat-error formControlError="phone_number"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.DEPARTMENT" | translate }}</mat-label>
          <input matInput formControlName="department_name" />
          <mat-error formControlError="department_name"> </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }}</mat-label>
          <input matInput formControlName="company_position" />
          <mat-error formControlError="company_position"> </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.RESIDENCE-COUNTRY" | translate }} *</mat-label>
          <mat-select
            formControlName="country_code"
            [placeholder]="'LABEL.COUNTRY' | translate"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.COUNTRY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="countryFilterControl"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let country of filteredCountries"
              [value]="country.country_code"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="form.controls.country_code.errors?.required">
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
          <mat-error
            class="m-b-10"
            *ngIf="form.controls.country_code.errors?.match"
          >
            {{ "EXPATS.COUNTRY-MATCH" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button class="btn orange-bg" (click)="emitChanges('save')">
      {{ "GENERALS.SAVE" | translate }}
    </button>
    <button
      *ngIf="!edit"
      type="button"
      class="btn orange-bg"
      (click)="emitChanges('another')"
    >
      {{ "MARKETPLACE.ADD-ANOTHER" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>
