<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- left -->
  <div *ngIf="!hideTitle" class="fl-l big-title">
    {{ "DOCUMENTS.PAGE_TITLE" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <div class="btn white-bg" (click)="openDocModal()">
      <i class="icon-upload-cloud"></i>
      {{ "DOCUMENTS.UPLOAD" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div
  windowScrollDashboardBodyAction
  class="dashboard-body-action"
  *ngIf="isFiltersLoaded"
>
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [showCalendar]="false"
      [issueDate]="true"
      [dueDate]="true"
      [paymentDate]="true"
      [searchPlaceholder]="'GENERALS.SEARCH'"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; getItems()"
      (filter)="filters = $event; getItems()"
      (invoiceIssueDate)="
        invoiceIssueDate = $event; filters = setFilters($event); getItems()
      "
      (invoiceDueDate)="
        invoiceDueDate = $event; filters = setFilters($event); getItems()
      "
      (invoicePaymentDate)="
        invoicePaymentDate = $event; filters = setFilters($event); getItems()
      "
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; getItems()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <div class="clear-btn btn white-bg fl-r" (click)="clearFilters(); getItems()">
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table matSort (matSortChange)="sortData($event)">
        <thead>
          <tr *ngIf="docType === service.docTypeInvoice">
            <th mat-sort-header="id">
              {{ "DOCUMENTS.ID" | translate }}
            </th>
            <th mat-sort-header="invoice-number">
              {{ "DOCUMENTS.INVOICE-NUMBER" | translate }}
            </th>
            <th mat-sort-header="invoice-amount">
              {{ "DOCUMENTS.INVOICE-AMOUNT" | translate }}
            </th>
            <th mat-sort-header="invoice-issue-date">
              {{ "DOCUMENTS.INVOICE-ISSUE-DATE" | translate }}
            </th>
            <th mat-sort-header="invoice-due-date">
              {{ "DOCUMENTS.INVOICE-DUE-DATE" | translate }}
            </th>
            <th mat-sort-header="payment-date">
              {{ "DOCUMENTS.PAYMENT-DATE" | translate }}
            </th>
            <th mat-sort-header="name">
              {{ "DOCUMENTS.PAYMENT-INFO" | translate }}
            </th>
            <th mat-sort-header="invoice-status">
              {{ "DOCUMENTS.INVOICE-STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
          <tr *ngIf="docType !== service.docTypeInvoice">
            <th mat-sort-header="id">
              {{ "DOCUMENTS.ID" | translate }}
            </th>
            <th mat-sort-header="name">
              {{ "DOCUMENTS.NAME" | translate }}
            </th>
            <th mat-sort-header="uploaded_by">
              {{ "DOCUMENTS.UPLOADED_BY" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let item of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    }
                | sortBy : 'desc' : 'created_at';
              let i = index
            "
          >
            <td class="gray-pointer">{{ item.id }}</td>
            <td *ngIf="docType !== service.docTypeInvoice" class="gray-pointer">
              {{ item.name }}
            </td>
            <td *ngIf="docType === service.docTypeInvoice" class="gray-pointer">
              {{
                item.order_invoice.invoice_number
                  ? item.order_invoice.invoice_number
                  : null
              }}
            </td>
            <td *ngIf="docType === service.docTypeInvoice" class="gray-pointer">
              {{
                item.order_invoice.invoice_amount &&
                item.order_invoice.invoice_currency
                  ? (item.order_invoice.invoice_amount
                    | currency : item.order_invoice.invoice_currency)
                  : null
              }}
            </td>
            <td *ngIf="docType === service.docTypeInvoice" class="gray-pointer">
              {{
                item.order_invoice.invoice_issue_date
                  ? (item.order_invoice.invoice_issue_date
                    | date : "dd/MM/yyyy")
                  : null
              }}
            </td>
            <td *ngIf="docType === service.docTypeInvoice" class="gray-pointer">
              {{
                item.order_invoice.invoice_due_date
                  ? (item.order_invoice.invoice_due_date | date : "dd/MM/yyyy")
                  : null
              }}
            </td>
            <td *ngIf="docType === service.docTypeInvoice" class="gray-pointer">
              {{
                item.order_invoice.payment_date
                  ? (item.order_invoice.payment_date | date : "dd/MM/yyyy")
                  : null
              }}
            </td>
            <td
              *ngIf="docType === service.docTypeInvoice && item.order_invoice"
              class="gray-pointer"
            >
              <span
                *ngIf="
                  item.order_invoice.transaction_id ||
                  item.order_invoice.payment_method
                "
              >
                <b>{{ "DOCUMENTS.TRANSACTION-ID" | translate }}</b
                >: {{ item.order_invoice.transaction_id }}
                <br />
                <b>{{ "DOCUMENTS.PAYMENT-METHOD" | translate }}</b
                >: {{ item.order_invoice.payment_method }}
              </span>
              <span
                class="deleted_reason"
                *ngIf="item.order_invoice.deleted_reason"
                >{{ item.order_invoice.deleted_reason }}</span
              >
            </td>
            <td *ngIf="docType === service.docTypeInvoice" class="gray-pointer">
              <div class="status-label-{{ item.order_invoice.invoice_status }}">
                {{
                  item.order_invoice.invoice_status
                    ? item.order_invoice.invoice_status
                    : null
                }}
              </div>
            </td>

            <td *ngIf="docType !== service.docTypeInvoice" class="gray-pointer">
              {{ item.user_name }}
            </td>
            <td *ngIf="docType !== service.docTypeInvoice">
              <div class="status-label-{{ item.status }}">
                {{ item.status }}
              </div>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action btn -->
              <!-- table action with overlay -->
              <div toggle-action-table class="table-action-wrapper">
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li
                      *ngIf="
                        downloadItems &&
                        downloadItems.length &&
                        downloadItems[i]
                      "
                    >
                      <a
                        href="{{ downloadItems[i].file }}"
                        target="_blank"
                        download=""
                      >
                        {{ "GENERALS.DOWNLOAD-DOCUMENT" | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
          </tr>

          <tr *ngIf="items?.length === 0">
            <td
              *ngIf="docType !== service.docTypeInvoice"
              colspan="6"
              class="gray"
            >
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
            <td
              *ngIf="docType === service.docTypeInvoice"
              colspan="11"
              class="gray"
            >
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let item of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(item) : null"
              [checked]="selection.isSelected(item)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            (click)="navigateByUrl(filters.page)"
            class="responsive-table-details"
          >
            <a
              href="{{ downloadItems[i] ? downloadItems[i].file : '' }}"
              target="_blank"
              download=""
              class="btn-small orange-bg"
              >{{ "GENERALS.DOWNLOAD" | translate }}</a
            >
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "DOCUMENTS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.id }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "DOCUMENTS.NAME" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.name }}</div>
        </div>
        <div *ngIf="docType === service.docTypeInvoice">
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.INVOICE-NUMBER" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{
                item.order_invoice.invoice_number
                  ? item.order_invoice.invoice_number
                  : null
              }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.INVOICE-AMOUNT" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{
                item.order_invoice.invoice_amount &&
                item.order_invoice.invoice_currency
                  ? (item.order_invoice.invoice_amount
                    | currency : item.order_invoice.invoice_currency)
                  : null
              }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.INVOICE-ISSUE-DATE" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{
                item.order_invoice.invoice_issue_date
                  ? item.order_invoice.invoice_issue_date
                  : null
              }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.INVOICE-DUE-DATE" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{
                item.order_invoice.invoice_due_date
                  ? item.order_invoice.invoice_due_date
                  : null
              }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.INVOICE-STATUS" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{
                item.order_invoice.invoice_status
                  ? item.order_invoice.invoice_status
                  : null
              }}
            </div>
          </div>

          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.PAYMENT-DATE" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{
                item.order_invoice.payment_date
                  ? item.order_invoice.payment_date
                  : null
              }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.PAYMENT-INFO" | translate }}
            </div>
            <div class="responsive-table-data-item">
              <span
                *ngIf="docType === service.docTypeInvoice && item.order_invoice"
                class="gray-pointer"
              >
                <p *ngIf="item.order_invoice.transaction_id">
                  {{ item.order_invoice.transaction_id }}
                </p>
                <p *ngIf="item.order_invoice.payment_method">
                  {{ item.order_invoice.payment_method }}
                </p>
                <p *ngIf="item.order_invoice.deleted_reason">
                  {{ item.order_invoice.deleted_reason }}
                </p>
              </span>
            </div>
          </div>
        </div>
        <div
          *ngIf="docType !== service.docTypeInvoice"
          class="responsive-table-row"
        >
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "DOCUMENTS.UPLOADED_BY" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{ item.user_name }}
            </div>
          </div>
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ item.status }}">{{
              item.status
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.VIEW" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span (click)="openInBrowser(item)" class="action-link-orange-sm">{{
              item.name
            }}</span>
          </div>
        </div>
        <div class="responsive-table-action">
          <span
            *ngIf="docType !== service.docTypeInvoice"
            (click)="deleteDocument(item.id)"
          >
            {{ "GENERALS.DELETE" | translate }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="getItems($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

<!-- add doc modal -->
<app-add-doc
  [entityType]="entityType"
  [entityId]="entityId"
  [docType]="docType"
  (changeDoc)="getItems()"
></app-add-doc>
<!-- add doc modal -->
