import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { DocumentsService } from "src/app/_services/documents.service";
import { AlertService } from "@modules/alert";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { FileTypeEnum } from "src/app/_enums/file-type-enum";
import { InvoiceExtraData } from "src/app/_models/invoice-extra-data";
import { OrdersService } from "src/app/_services/orders.service";
import * as moment from "moment";

export function fileExtension(allowedExtensions = []): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return;
    }
    const invalid = value.some((file) => {
      try {
        const name = file.name.toLowerCase();
        const exp = new RegExp(
          // eslint-disable-next-line no-useless-escape
          `^.*\.(${Object.values(FileTypeEnum).join("|")})$`
        );
        return !exp.test(name);
      } catch (e) {
        return true;
      }
    });
    return invalid ? { fileExtension: true } : null;
  };
}

@Component({
  selector: "app-add-doc",
  templateUrl: "./add-doc.component.html",
  styleUrls: ["./add-doc.component.scss"],
})
export class AddDocComponent implements OnInit {
  @Input() entityType;
  @Input() entityId;
  @Input() docType;
  @Input() docId;
  @Input() title;

  documentForm: UntypedFormGroup;

  @Output() changeDoc: EventEmitter<any> = new EventEmitter();

  public fileUploadControl = new FileUploadControl();

  setModalOpen = true;
  isCanceled = false;
  isLoadingGeneral = false;
  uploadedFiles = { file: null, name: null, status: null };
  statuses;
  public currencies = [];
  public orderInvoiceStatuses;
  invoiceExtraData = new InvoiceExtraData();

  sts = [{ id: 1, text: "signed" }];
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    protected service: DocumentsService,
    private alertService: AlertService,
    public ordersService: OrdersService
  ) {
    this.setModalOpen = false;
    this.documentForm = new UntypedFormGroup({
      files: new UntypedFormControl([], [Validators.required, fileExtension()]),
    });
  }

  ngOnInit() {
    this.getStatuses();
    this.getOrderInvoiceStatuses();
    this.getCurrencies();
  }

  limitFilesUpload(e) {
    if (e && e.length > 1) {
      this.uploadedFiles.file = [e[0]];
    }
  }
  getStatuses() {
    this.service.getStatuses().subscribe((res) => {
      this.statuses = res.result;
    });
  }

  getCurrencies() {
    this.service.getCurrencies().subscribe((res) => {
      this.currencies = res;
    });
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  getOrderInvoiceStatuses() {
    this.service.getOrderInvoiceStatuses().subscribe((res) => {
      this.orderInvoiceStatuses = res.result;
    });
  }

  isModalClose() {
    this.setModalOpen = false;
    this.fileUploadControl.clear();
    this.documentForm.reset();
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("addDoc").close();
    this.isCanceled = true;
    this.uploadedFiles = { file: null, name: null, status: null };
    this.fileUploadControl.clear();
    this.invoiceExtraData = new InvoiceExtraData();
  }

  uploadImage() {
    this.isLoadingGeneral = true;

    this.service
      .uploadImage(
        this.entityId,
        this.docType,
        this.uploadedFiles,
        this.entityType,
        this.docId
      )
      .subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.changeDoc.emit("newDoc");
          this.isLoadingGeneral = false;
          this.cancelModal();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoadingGeneral = false;
          this.uploadedFiles = {
            file: null,
            name: null,
            status: null,
          };
          this.fileUploadControl.clear();
        }
      );
  }

  uploadFile() {
    this.isLoadingGeneral = true;

    this.service
      .uploadFiles(
        this.entityId,
        this.docType,
        this.uploadedFiles,
        this.entityType,
        {
          ...this.invoiceExtraData,
          invoice_due_date: this.getDateString(
            this.invoiceExtraData.invoice_due_date
          ),
          invoice_issue_date: this.getDateString(
            this.invoiceExtraData.invoice_issue_date
          ),
        }
      )
      .subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.changeDoc.emit("newDoc");
          this.cancelModal();
          this.isLoadingGeneral = false;
        },
        (error) => {
          this.isLoadingGeneral = false;

          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  setInvoiceIssueDate(event) {
    if (moment.isMoment(event)) {
      this.invoiceExtraData.invoice_issue_date = event;
    }
  }

  setInvoiceDueDate(event) {
    if (moment.isMoment(event)) {
      this.invoiceExtraData.invoice_due_date = event;
    }
  }

  getDateString(event) {
    const d = new Date(event);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }
}
