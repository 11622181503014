<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">
    {{ "SUB-NAVIGATION.CUSTOM_COMMISSIONS" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!--    todo add commissions btn modal-->
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div *ngIf="!hideFilters" class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [showCalendar]="true"
      [searchPlaceholder]="'GENERALS.SEARCH'"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      (date)="setFilteringDate($event); navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <div class="table-responsive">
    <table
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="{{ active }}"
      [matSortDirection]="direction"
    >
      <thead>
        <tr>
          <th mat-sort-header="company_name">
            {{ "GENERALS.PROVIDER" | translate }}
          </th>
          <th mat-sort-header="service_category_name">
            {{ "OFFERS.CATEGORY-LABEL" | translate }}
          </th>
          <th mat-sort-header="service_category_commission">
            {{ "LABEL.DEFAULT-COMMISSION" | translate }}
          </th>
          <th mat-sort-header="commission">
            {{ "LABEL.CUSTOM_COMMISSION" | translate }}
          </th>
          <th mat-sort-header="start_date">
            {{ "GENERALS.START-DATE" | translate }}
          </th>
          <th mat-sort-header="end_date">
            {{ "GENERALS.END-DATE" | translate }}
          </th>
          <th class="ta-r"></th>
        </tr>
      </thead>
      <tbody *ngIf="isLoadingGeneral">
        <tr>
          <td colspan="100">
            <app-loader-spinner-general
              [loadingType]="LoadingType.TABLE"
            ></app-loader-spinner-general>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoadingGeneral">
        <tr
          *ngFor="
            let commission of items
              | paginate
                : {
                    itemsPerPage: itemsPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <td>
            {{ commission.provider.company_name }}
          </td>
          <td>
            <div>{{ commission.service_category.label }}</div>
          </td>
          <td>
            <span
              *ngIf="
                commission.service_category.commission_type &&
                commission.service_category.commission_type.name ===
                  'COMMISSION_TYPE_PERCENT'
              "
            >
              {{ commission.service_category.commission }}%
            </span>
            <span
              *ngIf="
                commission.service_category.commission_type &&
                commission.service_category.commission_type.name ===
                  'COMMISSION_TYPE_FIXED'
              "
            >
              {{ commission.service_category.commission | currency : "EUR" }}
            </span>
          </td>
          <td>
            <span
              *ngIf="
                commission.commission_type.name === 'COMMISSION_TYPE_PERCENT'
              "
            >
              {{ commission.commission }}%
            </span>
            <span
              *ngIf="
                commission.commission_type.name === 'COMMISSION_TYPE_FIXED'
              "
            >
              {{ commission.commission | currency : "EUR" }}
            </span>
          </td>
          <td>
            {{ commission.start_date | date : "dd/MM/yyyy" }}
          </td>
          <td>
            {{ commission.end_date | date : "dd/MM/yyyy" }}
          </td>
          <!-- table cel with action -->
          <td class="ta-r">
            <!-- table action with overlay -->
            <div
              toggle-action-table
              class="table-action-wrapper"
              *ngIf="
                authService.$can(
                  action.DELETE,
                  resource.CATEGORY,
                  subResource.FINANCIAL
                )
              "
            >
              <i class="icon-dot-3"></i>
              <div class="table-action">
                <ul>
                  <li (click)="deleteCommission(commission.id)">
                    {{ "GENERALS.DELETE" | translate }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- end table action with overlay -->
          </td>
          <!--end table cel with action -->
        </tr>
        <tr *ngIf="items?.length === 0">
          <td colspan="7" class="gray">
            {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
