/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { Country } from "src/app/_models/country";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-phone-prefix-selector",
  templateUrl: "./phone-prefix-selector.component.html",
  styleUrls: ["./phone-prefix-selector.component.scss"],
  standalone: false,
})
export class PhonePrefixSelectorComponent implements OnInit, OnDestroy {
  @Input()
  onlyCode: boolean = false;

  @Input() control?;

  isLoadingGeneral: boolean = false;
  selectedCountry: Country | string;
  onChange = (country: Country) => {};
  onTouched = () => {};
  touched = false;
  countries: Country[] = [];
  countrySearchControl = new UntypedFormControl();
  filteredCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(private dictionariesService: DictionariesService) {}

  ngOnInit(): void {
    this.getCountryList();

    this.countrySearchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
  }

  writeValue(selectCountry: MatSelectChange | Country | any): void {
    if (selectCountry && selectCountry.value) {
      this.selectedCountry = selectCountry.value;
    } else {
      this.selectedCountry = selectCountry;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onSelectChange(matSelect: MatSelectChange) {
    this.onChange(matSelect.value);
  }

  ngOnDestroy(): void {
    this._onDestroy.complete();
  }

  getCountryList(): void {
    this.isLoadingGeneral = true;

    this.dictionariesService
      .getCountryList()
      .pipe(
        take(1),
        finalize(() => (this.isLoadingGeneral = false))
      )
      .subscribe((data) => {
        if (data && data.result) {
          data.result.map((country) => {
            country.phone_prefix =
              "+" +
              this.dictionariesService.intCountryPhonePhone(country.phone_code);
            country.phone_prefix_label =
              this.dictionariesService.getPhonePrefix(country.phone_code) +
              country.name;
          });

          this.countries = data.result;

          this.filteredCountries.next(data.result);
        }
      });
  }

  protected filterCountries(): void {
    if (!this.countries) {
      return;
    }

    let search = this.countrySearchControl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countries.filter(
        (item) => item.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  preselectCountries(
    countryInList: Country | any,
    countryFrom: Country | any
  ): boolean {
    if (!countryInList || !countryFrom) {
      return;
    }
    // If we send Country object
    if (countryInList.iso && countryFrom.iso) {
      return countryInList.iso === countryFrom.iso;
    }

    // If we send only the country_code (this needs to be checked last always)
    if (countryInList && countryFrom) {
      return countryInList === countryFrom;
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
