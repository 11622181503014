import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@modules/alert";

@Component({
  selector: "app-confirm-action-modal",
  templateUrl: "./confirm-action-modal.component.html",
  styleUrls: ["./confirm-action-modal.component.css"],
  standalone: false,
})
export class ConfirmActionModalComponent {
  reason = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ConfirmActionModalComponent>,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    if (this.data.action.needs_reason) {
      if (this.reason !== "" && this.reason !== undefined) {
        this.dialogRef.close(this.reason);
      } else {
        this.alertService.stringError(
          this.translateService.instant("ERROR.REASON-REQUIRED")
        );
      }
    } else {
      this.dialogRef.close("no-reason");
    }
  }
}
