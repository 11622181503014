import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { ItemsListComponent } from "../../dashboard/items-list/items-list.component";
import { AlertService } from "@modules/alert";
import { RFQSService } from "src/app/_services/rfqs.service";
import { SelectionModel } from "@angular/cdk/collections";
import cloneDeep from "lodash-es/cloneDeep";
import { MatDialog } from "@angular/material/dialog";
import { LoadingTypeEnum } from "src/app/modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-invite-providers",
  templateUrl: "./invite-providers.component.html",
})
export class InviteProvidersComponent
  extends ItemsListComponent
  implements OnInit, OnChanges
{
  @Input() country;
  @Input() type;
  @Input() item;
  @Input() edit;
  @Input() set categoriesFilters(value) {
    if (value) {
      this.categories = value;
    }
  }
  @Input() set preinvitedProviders(value) {
    if (value) {
      this.preinvited = cloneDeep(value);
    } else {
      this.preinvited = [];
    }
  }
  preinvited = [];
  search_text;
  categories = [];
  page = 1;
  selectionCount = {};
  selectAll = false;

  constructor(
    private rfqService: RFQSService,
    protected dialog: MatDialog,
    protected alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    if ((Object.keys(this.categories).length && this.country) || !this.edit) {
      this.getItems();
    }
  }

  ngOnChanges(changes) {
    if ((Object.keys(this.categories).length && this.country) || !this.edit) {
      this.getItems(this.page);
    }
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoadingGeneral = true;
    const params = {};
    params["per_page"] = this.perPage;
    params["page"] = page;
    this.page = page;
    params["country_code"] = this.country;

    params["service_category_id"] = [];
    this.categories.forEach((categ) => {
      params["service_category_id"].push(categ.service_category_id);
    });
    if (this.search_text) {
      params["search_text"] = this.search_text;
    } else {
      delete params["search_text"];
    }
    this.request_call = this.rfqService
      .getProvidersInvitationList(params)
      .subscribe(
        (data) => {
          this.processResponse(data, true);
        },
        (error) => {
          this.isLoadingGeneral = false;
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  checkValue(id) {
    return this.selection.selected.findIndex((f) => f.id === id) > -1;
  }

  clearSelection() {
    this.selection = new SelectionModel(true, []);
    this.selectionCount = {};
    this.selectAll = false;
    this.handleChanges();
    this.getItems(1);
  }

  handleChanges() {
    const providerIds = [];
    this.selection.selected.forEach((offer) => {
      providerIds.push({
        provider_id: offer.id,
        edit_flag: this.edit,
      });
    });
    this.item.providers = providerIds;
    if (this.preinvited.length) {
      this.item.providers = this.item.providers.concat(this.preinvited);
    }
  }

  areAllSelected() {
    const numSelected = this.selectionCount[this.page];
    const numRows = this.items.length;
    return numSelected === numRows;
  }

  customChangeSelection(item) {
    if (this.selectionCount && this.selectionCount[this.page]) {
      this.selectionCount[this.page] = this.checkValue(item.id)
        ? this.selectionCount[this.page] - 1
        : this.selectionCount[this.page] + 1;
    } else {
      this.selectionCount[this.page] = 1;
    }

    if (this.selectionCount[this.page] === 0) {
      delete this.selectionCount[this.page];
    }
    const el = this.selection.selected.find((f) => f.id === item.id);
    if (el) {
      this.selection.deselect(el);
    } else {
      this.selection.select(item);
    }
    this.handleChanges();
  }

  checkPreinvited(id) {
    return (
      this.edit &&
      this.preinvited.length &&
      this.item.providers.findIndex(
        (f) => f.provider_id === id && !f.edit_flag
      ) > -1
    );
  }

  customMasterToggle() {
    const numSelected = this.selectionCount[this.page];
    const numRows = this.items.length;
    if (numRows > numSelected || !numSelected) {
      this.items.forEach((row) => {
        if (!this.checkPreinvited(row.id)) {
          this.selection.select(row);
        }
      });
      this.selectionCount[this.page] = numRows;
    } else {
      this.items.forEach((row) => {
        const el = this.selection.selected.find(
          (f) => f.id === row.id && !this.checkPreinvited(row.id)
        );
        if (el) {
          this.selection.deselect(el);
        }
      });
      delete this.selectionCount[this.page];
    }
    this.handleChanges();
  }

  getAllItems() {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoadingGeneral = true;
    const params = {};
    params["country_code"] = this.country;
    params["service_category_id"] = [];
    this.categories.forEach((categ) => {
      params["service_category_id"].push(categ.service_category_id);
    });
    this.request_call = this.rfqService
      .getAllProvidersInvitationList(params)
      .subscribe(
        (data) => {
          this.selectAll = true;
          this.items = data.result;
          this.selection = new SelectionModel(true, []);

          this.items.forEach((row) => {
            if (!this.checkPreinvited(row.id)) {
              this.selection.select(row);
            }
          });
          this.handleChanges();

          this.isLoadingGeneral = false;
        },
        (error) => {
          this.isLoadingGeneral = false;
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
