<ng-container *ngIf="isLoadingGeneral">
  <div class="top-wrapper">
    <app-loader-spinner-general
      customClasses="h-50"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
    <div class="d-flex justify-content-end">
      <app-loader-spinner-general
        [repeat]="3"
        [loadingType]="LoadingType.BUTTONS"
      ></app-loader-spinner-general>
    </div>
  </div>
  <app-loader-spinner-general
    [repeat]="11"
    [loadingType]="LoadingType.INFO"
  ></app-loader-spinner-general>
  <div class="d-flex justify-content-end">
    <app-loader-spinner-general
      [repeat]="1"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
  </div>
</ng-container>

<ng-container *ngIf="!isLoadingGeneral">
  <!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
  <div windowScrollDashboardBodyTop class="dashboard-body-top">
    <!-- left -->

    <!-- title -->
    <div *ngIf="!id" class="fl-l big-title">
      <i (click)="navigateBack()" class="icon-left-open back-arrow"></i>
      {{ "GENERALS.ADD-SERVICE-OFFER" | translate }}
    </div>
    <div *ngIf="id && !isCopyOffer" class="fl-l big-title">
      <i (click)="navigateBack()" class="icon-left-open back-arrow"></i>
      {{ "GENERALS.EDIT-SERVICE-OFFER" | translate }}
    </div>
    <div *ngIf="isCopyOffer" class="fl-l big-title">
      <i (click)="navigateBack()" class="icon-left-open back-arrow"></i>
      {{ "GENERALS.EDIT-NEW-OFFER" | translate }}
    </div>
    <!-- end title -->

    <!-- end left -->

    <!-- right -->
    <div class="fl-r">
      <div (click)="navigateBack()" class="btn white-bg">
        <i class="icon-cancel-2"></i> {{ "GENERALS.CANCEL" | translate }}
      </div>

      <ng-container *ngIf="!id">
        <div (click)="saveDraft()" class="btn white-bg">
          <i class="icon-floppy"></i>
          {{ "GENERALS.SAVE-DRAFT" | translate }}
        </div>
        <div (click)="addOffer()" class="btn orange-bg">
          <i class="icon-plus-1"></i> {{ "GENERALS.ADD" | translate }}
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          !isLoadingGeneral &&
          id &&
          (newOffer.status_id !== 1 || newOffer.status_label !== 'Draft')
        "
      >
        <div (click)="updateOffer()" class="btn orange-bg">
          <i class="icon-plus-1"></i> {{ "GENERALS.UPDATE" | translate }}
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          !isLoadingGeneral &&
          !isCopyOffer &&
          (newOffer.status_id === 1 || newOffer.status_label === 'Draft')
        "
      >
        <div (click)="updateOffer('draft_update')" class="btn orange-bg">
          <i class="icon-plus-1"></i>
          {{ "GENERALS.UPDATE-DRAFT" | translate }}
        </div>

        <div (click)="updateOffer('draft_publish')" class="btn orange-bg">
          <i class="icon-floppy"></i>
          {{ "GENERALS.SAVE-PUBLISH" | translate }}
        </div>
      </ng-container>
    </div>
    <!-- end right -->
  </div>
  <!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
  <div class="clear"></div>
  <!-- content -->

  <div class="white-box-wrapper">
    <div class="row">
      <!-- left -->
      <div class="w-50">
        <!-- Categorisation -->
        <div class="white-box-row">
          <div class="white-box-header no-border">
            <div class="medium-title fl-l">
              {{ "GENERALS.CATEGORISATION" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body p-t-0">
            <div class="row" *ngIf="!edit">
              <div class="form-group w-100">
                <label>{{ "GENERALS.PROVIDER" | translate }} * </label>
                <ng-select
                  [ngClass]="{
                    'customiz-select': true,
                    'customiz-select-disabled': edit
                  }"
                  [disabled]="edit || readonly"
                  [items]="providers"
                  bindLabel="company_name"
                  bindValue="id"
                  (search)="getProviders($event.term, status)"
                  (ngModelChange)="updateSelectedProvider($event)"
                  (clear)="getProviders('', status)"
                  placeholder="{{ 'GENERALS.SELECT-PROVIDER' | translate }}"
                  [(ngModel)]="newOffer.provider_id"
                  notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                >
                </ng-select>
              </div>
            </div>

            <div class="row" *ngIf="edit">
              <div class="w-100">
                <div class="form-group">
                  <label>{{ "GENERALS.PROVIDER" | translate }}* </label>

                  <input
                    class="form-control"
                    value="{{ newOffer?.provider?.company_name }}"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!edit">
              <div class="w-100">
                <app-category-selection
                  [categories]="categories"
                  [level]="level"
                  [helper]="helper"
                  (intermediaryCategory)="clearCategory()"
                  (categorySelected)="loadCharacteristics($event)"
                ></app-category-selection>
              </div>
            </div>

            <div
              class="row"
              *ngIf="
                edit &&
                (newOffer.status_id === 1 || newOffer.status_label === 'Draft')
              "
            >
              <div class="w-100">
                <app-category-selection
                  [categories]="categories"
                  [level]="0"
                  [preselectedPath]="preselectedCategory"
                  (categorySelected)="loadCharacteristics($event)"
                ></app-category-selection>
              </div>
            </div>

            <div
              class="row"
              *ngIf="
                edit &&
                newOffer &&
                newOffer.category &&
                (newOffer.status_id !== 1 || newOffer.status_label !== 'Draft')
              "
            >
              <div class="w-100">
                <div class="form-group">
                  <label *ngIf="level === 0"
                    >{{ "GENERALS.SERVICE-CATEGORY" | translate }}
                    *
                  </label>
                  <span class="inner-breadcrumbs-item-wrapper">
                    <ng-container
                      *ngFor="
                        let category of newOffer.category.breadcrumbs;
                        let last = last;
                        let first = first
                      "
                    >
                      <a
                        class="breadcrumbs-item"
                        [ngClass]="{ orange: last }"
                        >{{ category.label }}</a
                      >
                      <ng-container *ngIf="!last">
                        <span class="breadcrumbs-separator"
                          >/</span
                        ></ng-container
                      >
                    </ng-container>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end Categorisation -->

        <!-- Default price & availability -->
        <div *ngIf="newOffer.entity_type !== 'client'" class="white-box-row">
          <div class="white-box-header no-border">
            <div class="medium-title fl-l">
              {{ "GENERALS.DEFAULT-PRICE-AVAILABILITY" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body p-t-0">
            <div class="row">
              <div class="w-100">
                <p class="gray inner-small-text">
                  {{ "GENERALS.DEFAULT-PRICE-TEXT" | translate }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.LISTINGPRICE" | translate }}
                    <span *ngIf="newOffer.currency_code">
                      ({{ newOffer.currency_code | uppercase }}) </span
                    >*
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.LISTING-PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <input
                    type="number"
                    name="default_price"
                    class="form-control"
                    [(ngModel)]="newOffer.default_price"
                  />
                  <input
                    type="hidden"
                    name="default_currency_id"
                    [(ngModel)]="newOffer.default_currency_id"
                  />
                </div>
              </div>
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.SALEPRICE" | translate }}
                    <span *ngIf="newOffer.currency_code">
                      ({{ newOffer.currency_code | uppercase }}) </span
                    >*
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.SALE-PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <input
                    type="number"
                    name="sale_price"
                    class="form-control"
                    [(ngModel)]="newOffer.sale_price"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.MIN-PRICE" | translate }}
                    <span *ngIf="newOffer.currency_code">
                      ({{ newOffer.currency_code | uppercase }}) </span
                    >*
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.MIN-PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <input
                    type="number"
                    name="min_price"
                    (keypress)="
                      validateNumericalInput($event, 'min_price_format')
                    "
                    class="form-control"
                    [(ngModel)]="newOffer.min_price"
                  />
                  <div *ngIf="newOffer.min_price_format" class="field-message">
                    {{ "VALIDATIONS.NUMERIC-INPUT" | translate }}
                  </div>
                </div>
              </div>

              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.MAX-PRICE" | translate }}
                    <span *ngIf="newOffer.currency_code">
                      ({{ newOffer.currency_code | uppercase }}) </span
                    >*
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"> </i>
                      <span class="helper-description">
                        {{ "HELPERS.MAX-PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <input
                    type="number"
                    (keypress)="
                      validateNumericalInput($event, 'max_price_format')
                    "
                    name="max_price"
                    class="form-control"
                    [(ngModel)]="newOffer.max_price"
                  />
                  <div *ngIf="newOffer.max_price_format" class="field-message">
                    {{ "VALIDATIONS.NUMERIC-INPUT" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.STOCK-AVAILABILITY" | translate }}
                    *
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.MAX-AVAILABLE" | translate }}
                      </span>
                    </span></label
                  >
                  <input
                    type="number"
                    name="stock"
                    class="form-control"
                    [(ngModel)]="newOffer.default_quantity"
                  />
                </div>
              </div>
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.MARKETPLACE_PRICE" | translate }}
                    *
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.MARKETPLACE_PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <div class="card-price" *ngIf="newOffer.sale_price">
                    <span
                      *ngIf="
                        $any(newOffer.default_price) > $any(newOffer.sale_price)
                      "
                    >
                      <span class="old-price">{{
                        newOffer.default_price * 1
                          | currency : newOffer.currency_code
                      }}</span>

                      <span class="discount">
                        (-{{
                          serviceOffers.calculateDiscount(
                            newOffer.default_price,
                            newOffer.sale_price
                          )
                        }}%)</span
                      >
                    </span>
                    <span class="new-price"
                      >{{
                        $any(newOffer.sale_price) * 1
                          | currency : newOffer.currency_code
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="w-100">
                <div
                  (click)="openPriceModal()"
                  class="action-link-orange custom-price d-inline"
                >
                  <i class="icon-plus-1"></i>
                  {{ "GENERALS.ADD-PRICE" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end Default price & availability -->

        <!-- added price -->
        <div
          *ngIf="newOffer.entity_type !== 'client' && newOffer.prices.length"
          class="white-box-row"
        >
          <div class="white-box-header no-border">
            <div class="medium-title fl-l">
              {{ "GENERALS.ADDED-PRICE" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div
            class="white-box-body added-price-row"
            *ngFor="let price of newOffer.prices; let i = index"
          >
            <div class="row">
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.PRICE" | translate }}
                    <span *ngIf="newOffer.currency_code">
                      ({{ newOffer.currency_code | uppercase }}) </span
                    >*
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.CUSTOM-PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <input
                    type="number"
                    name="price"
                    class="form-control"
                    [(ngModel)]="price.price"
                  />
                </div>
              </div>
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.STOCK-AVAILABILITY" | translate }}
                    *
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.CUSTOM-MAX-AVAILABILITY" | translate }}
                      </span></span
                    ></label
                  >
                  <input
                    type="number"
                    name="stock"
                    class="form-control"
                    [(ngModel)]="price.quantity"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="w-50">
                <app-date-range-calendar
                  [placeholder]="'GENERALS.SELECT-DATE' | translate"
                  [multiple]="true"
                  [dateRange]="getPriceRange(price)"
                  (change)="setDate($event, i)"
                >
                </app-date-range-calendar>
              </div>
              <div class="w-50">
                <div class="form-group">
                  <label
                    >{{ "GENERALS.MARKETPLACE_PRICE" | translate }}
                    *
                    <span appHoverHelper class="input-helper"
                      ><i class="icon-help_comp"></i>
                      <span class="helper-description">
                        {{ "HELPERS.MARKETPLACE_PRICE" | translate }}
                      </span>
                    </span></label
                  >
                  <div class="card-price">
                    <span *ngIf="$any(newOffer.sale_price) > $any(price.price)">
                      <span class="old-price">{{
                        $any(newOffer.sale_price) * 1
                          | currency : newOffer.currency_code
                      }}</span>

                      <span class="discount">
                        (-{{
                          serviceOffers.calculateDiscount(
                            newOffer.sale_price,
                            price.price
                          )
                        }}%)</span
                      >
                    </span>
                    <span class="new-price">{{
                      price.price * 1 | currency : newOffer.currency_code
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- delete added price row -->
            <div
              class="delete-price-row"
              (click)="deletePrice(i)"
              title="remove added price"
            >
              <i class="icon-trash-empty"></i>
            </div>
            <!-- end delete added price row -->
          </div>
        </div>
        <!-- end added price -->
      </div>
      <!-- end left -->

      <!-- right -->
      <div class="w-50">
        <!-- Selected category criteria -->
        <div class="white-box-row">
          <div class="white-box-header no-border">
            <div class="medium-title fl-l">
              {{ "GENERALS.SELECTED-CATEGORY-CRITERIA" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body p-t-0">
            <div class="row">
              <div class="form-group w-100">
                <label
                  >{{ "GENERALS.SERVICE-TITLE" | translate }} *
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.TITLE" | translate }}
                    </span>
                  </span></label
                >
                <input
                  type="text"
                  name="title"
                  class="form-control"
                  [(ngModel)]="newOffer.title"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group w-100">
                <label
                  >{{ "GENERALS.SERVICE-DESCRIPTION" | translate }}
                  *
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.DESCRIPTION" | translate }}
                    </span>
                  </span></label
                >
                <textarea
                  name="service_description"
                  class="textarea-control-provider"
                  [(ngModel)]="newOffer.description"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="form-group-row w-100">
                <label class="characteristic-left">
                  {{ "SERVICES.FOR_BUSINESS" | translate }}
                </label>
                <div class="characteristic-right">
                  <input
                    class="checkbox-switch"
                    [(ngModel)]="newOffer.for_business"
                    type="checkbox"
                    name="field.data"
                  />
                </div>
              </div>
              <div class="form-group-row w-100">
                <label class="characteristic-left">
                  {{ "SERVICES.FOR_INDIVIDUAL" | translate }}
                </label>
                <div class="characteristic-right">
                  <input
                    class="checkbox-switch"
                    [(ngModel)]="newOffer.for_individual"
                    type="checkbox"
                    name="field.data"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group w-100">
                <label
                  >{{ "GENERALS.DURATION" | translate }} ({{
                    "GENERALS.DAYS" | translate
                  }}) *
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.DURATION" | translate }}
                    </span>
                  </span></label
                >
                <input
                  type="text"
                  name="duration"
                  class="form-control"
                  [(ngModel)]="newOffer.duration"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group w-50">
                <label
                  >{{ "GENERALS.COUNTRY" | translate }} *
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.COUNTRY-CODE" | translate }}
                    </span>
                  </span></label
                >

                <ng-select
                  class="customiz-select"
                  [items]="countries"
                  bindLabel="name"
                  bindValue="country_code"
                  placeholder="{{ 'GENERALS.SELECT-COUNTRY' | translate }}"
                  [(ngModel)]="newOffer.country"
                  (change)="countryChanged()"
                  notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                >
                </ng-select>
              </div>

              <div class="form-group w-50">
                <label
                  >{{ "GENERALS.CITY" | translate }} *
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description">
                      {{ "HELPERS.CITY" | translate }}
                    </span>
                  </span></label
                >

                <ng-select
                  class="customiz-select"
                  [items]="cities"
                  bindLabel="name"
                  bindValue="name"
                  placeholder="{{ 'GENERALS.SELECT-CITY' | translate }}"
                  [(ngModel)]="newOffer.city"
                  notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                  (search)="loadCities($event)"
                  (focus)="loadCities()"
                >
                </ng-select>
              </div>
            </div>

            <div class="row">
              <div class="form-group w-100">
                <label
                  >{{ "GENERALS.UPLOAD-PHOTO" | translate }} *
                  <span appHoverHelper class="input-helper"
                    ><i class="icon-help_comp"></i>
                    <span class="helper-description"
                      >{{ "GENERALS.USE-SQUARE-PHOTO" | translate }}
                    </span>
                  </span></label
                >

                <div class="upload-photo-wrapper">
                  <div class="uplaod-photo-box">
                    <img [src]="imgURL" *ngIf="imgURL" />
                    <span class="orange" *ngIf="message && !imgURL">{{
                      message
                    }}</span>
                  </div>
                  <label *ngIf="!imgURL" class="upload-files">
                    <div class="btn-small orange-bg">
                      <i class="icon-upload-cloud"></i>
                      {{ "GENERALS.ADD-PHOTO" | translate }}
                    </div>
                    <input
                      #file
                      type="file"
                      accept="image/*"
                      (change)="preview(file.files)"
                    />
                  </label>
                  <label *ngIf="imgURL" class="upload-files">
                    <div (click)="removeUploaded()" class="btn-small orange-bg">
                      <i class="icon-trash"></i>
                      {{ "GENERALS.REMOVE-PHOTO" | translate }}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end Selected category criteria -->

        <!-- characteristics -->
        <div class="characteristic-wrapper">
          <app-characteristic-form
            *ngIf="selectedCategory || edit"
            [formdata]="formdata"
          ></app-characteristic-form>
        </div>
        <!-- end characteristics -->

        <div class="white-box-row fl-r service-offer-footer">
          <ng-container *ngIf="(isCopyOffer || !id) && !isLoadingGeneral">
            <div (click)="saveDraft()" class="btn white-bg">
              <i class="icon-floppy"></i>
              {{ "GENERALS.SAVE-DRAFT" | translate }}
            </div>
            <div (click)="addOffer()" class="btn orange-bg">
              <i class="icon-plus-1"></i>
              {{ "GENERALS.ADD" | translate }}
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              !isCopyOffer &&
              id &&
              !isLoadingGeneral &&
              (newOffer.status_id !== 1 || newOffer.status_label !== 'Draft')
            "
          >
            <div (click)="updateOffer()" class="btn orange-bg">
              <i class="icon-plus-1"></i>
              {{ "GENERALS.UPDATE" | translate }}
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              !isLoadingGeneral &&
              !isCopyOffer &&
              (newOffer.status_id === 1 || newOffer.status_label === 'Draft')
            "
          >
            <div (click)="updateOffer('draft_update')" class="btn orange-bg">
              <i class="icon-plus-1"></i>
              {{ "GENERALS.UPDATE-DRAFT" | translate }}
            </div>

            <div (click)="updateOffer('draft_publish')" class="btn orange-bg">
              <i class="icon-floppy"></i>
              {{ "GENERALS.SAVE-PUBLISH" | translate }}
            </div>
          </ng-container>
        </div>
      </div>
      <!-- end right -->
    </div>
  </div>
</ng-container>

<!-- end content -->

<!-- add price modal -->
<app-add-price
  [currency_code]="newOffer.currency_code"
  [salePrice]="newOffer.sale_price"
  (saved)="addPrice($event)"
></app-add-price>
<!-- end add price modal -->
