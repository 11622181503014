let environment = {};
const stringToBoolean = (stringValue): boolean => {
  switch (`${stringValue}`.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
      return false;
    case null:
    default:
      return stringValue;
  }
};

environment = {
  production: stringToBoolean(process.env.NG_PRODUCTION),
  gateway_endpoint: process.env.NG_GATEWAY_ENDPOINT,
  client_id: process.env.NG_CLIENT_ID,
  client_secret: process.env.NG_CLIENT_SECRET,
  app_host: process.env.NG_APP_HOST,
  session_local_storage_key: process.env.NG_SESSION_LOCAL_STORAGE_KEY,
  lang_cookie_key: process.env.NG_LANG_COOKIE_KEY,
  cookieConsent: process.env.NG_COOKIECONSENT,
  marketplace_url: process.env.NG_MARKETPLACE_URL,
  marketplace: process.env.NG_MARKETPLACE,
  stripe_plans_url: process.env.NG_STRIPE_PLANS_URL,
  stripe_provider_table_url: process.env.NG_STRIPE_PROVIDER_TABLE_URL,
  stripe_client_table_url: process.env.NG_STRIPE_CLIENT_TABLE_URL,
  stripe_view_subscription_url: process.env.NG_STRIPE_VIEW_SUBSCRIPTION_URL,
  stripe_payments_url: process.env.NG_STRIPE_PAYMENTS_URL,
  role: process.env.NG_ROLE,
  provider_portal: process.env.NG_PROVIDER_PORTAL,
  domain: process.env.NG_DOMAIN,
  unit_id: process.env.NG_UNIT_ID,
  pusher_key: process.env.NG_PUSHER_KEY,
  wsHost: process.env.NG_WSHOST,
  wsPort: process.env.NG_WSPORT,
  google_tracking_id: process.env.NG_GOOGLE_TRACKING_ID,
  show_prerelease_features: stringToBoolean(
    process.env.NG_SHOW_PRERELEASE_FEATURES
  ),
  crm_endpoint: stringToBoolean(process.env.NG_CRM_ENDPOINT),
  realm_url: process.env.NG_REALM_URL ?? "http://localhost:8080/",
};
export const processEnvironment = Object.keys(environment).reduce(
  (result, key) => {
    if (environment[key] !== null && environment[key] !== undefined) {
      result[key] = environment[key];
    }
    return result;
  },
  {}
);
