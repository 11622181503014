import {
  HttpContextToken,
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthActions } from "@api/authentication";
import { AlertService } from "@modules/alert";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export const DISPLAY_API_ERRORS = new HttpContextToken<boolean>(() => true);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService, private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (!request.context.get(DISPLAY_API_ERRORS)) {
          return throwError(() => response);
        }
        if (response.status === 401) {
          this.store.dispatch(AuthActions.unauthenticated());
        }
        if (response.error?.message) {
          this.alertService.error(response.error.message);
        } else {
          this.alertService.errors(response?.error?.errors);
        }

        return throwError(() => response);
      })
    );
  }
}
