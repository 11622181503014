<div class="modal-form">
  <form [formGroup]="userFormGroup" class="field-grid">
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.FIRST-NAME" | translate }}</mat-label>
      <input matInput formControlName="first_name" name="first_name" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.LAST-NAME" | translate }}</mat-label>
      <input matInput formControlName="last_name" name="last_name" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.EMAIL" | translate }}</mat-label>
      <input matInput formControlName="email" name="email" type="email" />
    </mat-form-field>
    <mat-form-field class="phone-field w-full">
      <mat-label>
        {{ "LABEL.PHONE-NUMBER" | translate }}
      </mat-label>
      <ngx-mat-input-tel
        [enablePlaceholder]="true"
        [enableSearch]="true"
        formControlName="phone_number"
        name="phone_number"
        describedBy="phoneInput"
        [format]="'national'"
        [required]="true"
        #phone
        maxLength="20"
        (countryChanged)="onCountryChanged($event)"
      ></ngx-mat-input-tel>
      <mat-error formControlError="phone_number"></mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }}</mat-label>
      <input
        matInput
        formControlName="company_position"
        name="company_position"
      />
    </mat-form-field>

    <div *ngIf="userType !== 'expat'">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "LABEL.ROLE" | translate }}</mat-label>
        <mat-select formControlName="role_id" name="role_id">
          <mat-option *ngFor="let role of roles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
