import {
  Directive,
  HostBinding,
  HostListener,
  ElementRef,
} from "@angular/core";

@Directive({
  selector: "[toggle-calendar-input]",
  standalone: false,
})
export class ToggleCalendarInputDirective {
  constructor(private el: ElementRef) {}

  @HostBinding("class.calendar-input-target") isOpen = false;

  @HostListener("document:click", ["$event"])
  public onClick(event) {
    if (this.el.nativeElement.contains(event.target)) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = false;
    }
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.isOpen = false;
  }

  // @HostListener('click') toggleOpen($event) {
  //     this.isOpen = !this.isOpen;
  // }

  //  @HostListener('document:click', ['$event.target']) clickedOutside($event) {
  //      event.preventDefault();
  //      this.isOpen = false;
  //      console.log('CLICKED OUTSIDE');
  //   }
}
