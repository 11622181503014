<div
  class="tree-row-wrapper"
  *ngFor="let category of categories; trackBy: trackById"
>
  <div
    toggle-row
    class="toggle-row-action fl-l"
    *ngIf="category.children_count"
  >
    <i class="icon-plus-1"></i>
  </div>
  <div class="tree-row">
    <div class="tree-check fl-l">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? changeSelection(category) : null"
        [checked]="selection.isSelected(category)"
        [disableRipple]="true"
      >
      </mat-checkbox>
    </div>

    <div class="tree-label fl-l">
      <span>{{ category.label }}</span>
      <span *ngIf="category.children_count"
        >({{ category.children_count }})</span
      >
    </div>

    <div
      class="tree-row-details"
      *ngIf="
        category.children_count === 0 &&
        newCommission !== category.id &&
        selection.isSelected(category)
      "
      (click)="newCommission = category.id"
    >
      <div class="fl-l set-commission-btn">
        <button type="button" class="btn transparent-bg">
          {{ "LABEL.SET-COMMISSION" | translate }}
        </button>
      </div>
    </div>

    <div
      *ngIf="
        category.children_count === 0 &&
        $any(category).commissions &&
        $any(category).commissions.length
      "
    >
      <br />
      <div class="w-100 tree-row-details category-tree-row-periods">
        <ng-container>
          <ul>
            <li
              *ngFor="
                let commission of $any(category).commissions;
                let i = index
              "
            >
              <span class="fs-12 fl-l"
                ><i class="icon-right-dir"></i>

                <span *ngIf="commission.start_date !== commission.end_date">
                  Period<b>
                    {{ this.getDateFormatted(commission.start_date) }}/{{
                      this.getDateFormatted(commission.end_date)
                    }}</b
                  ></span
                >

                <span *ngIf="commission.start_date === commission.end_date">
                  From<b>
                    {{ this.getDateFormatted(commission.start_date) }}</b
                  ></span
                >
                with
              </span>
              <span class="fs-12 fl-l">
                <span class="orange m-0">{{ commission.commission }}</span>
                {{
                  commission?.commission_type_id?.toString() ===
                  providerCommissionEnum.percent.toString()
                    ? "%"
                    : "€"
                }}
                {{ "GENERALS.COMMISSION" | translate }}</span
              >
              <span
                *ngIf="!newCommission && !editCategoryCommission.id"
                class="fl-r commission-buttons"
              >
                <span
                  class="commission-buttons-items"
                  (click)="setEditOptions(category, i)"
                >
                  <i class="icon-edit"></i>
                </span>
                <span
                  class="commission-buttons-items"
                  (click)="onRemove(category, i)"
                >
                  <i class="icon-trash-empty"></i>
                </span>
              </span>

              <span
                *ngIf="
                  editCategoryCommission.id === category.id &&
                  editCategoryCommission.idx === i
                "
              >
                <br />
                <ng-container
                  *ngTemplateOutlet="
                    setCommission;
                    context: {
                      _commission: $any(category).commissions[i],
                      _category: category
                    }
                  "
                ></ng-container>
              </span>
              <br />
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
    <div *ngIf="newCommission === category.id">
      <br />
      <div class="tree-row-details category-tree-row-periods">
        <ng-container
          *ngTemplateOutlet="
            setCommission;
            context: {
              _commission: commission,
              _category: category
            }
          "
        ></ng-container>
      </div>
    </div>
  </div>
  <div class="toggle-tree" *ngIf="category.children_count">
    <app-add-commissions
      [level]="category.level + 1"
      [serviceCategories]="category.children"
      [parentIsSelected]="selection.isSelected(category)"
      (onselect)="onselect.emit($event)"
    ></app-add-commissions>
  </div>

  <div class="clear"></div>
</div>

<ng-template
  #setCommission
  let-_commission="_commission"
  let-_category="_category"
>
  <ng-container>
    <div
      [ngClass]="{
        'tree-row-details': newCommission,
        'tree-row-details-flex': true,
        'edit-tree-row-details': !newCommission
      }"
    >
      <div>
        <div class="row">
          <div class="set-commission-column fl-l fw-600">
            <div class="commission-column-head">
              {{ "GENERALS.COMMISSION-TYPE" | translate }}
            </div>
            <div class="commission-column-head">
              <div class="form-group add-commission-type-radio">
                <mat-radio-group
                  class="commission-type"
                  *ngFor="let type of commissionTypes"
                  [(ngModel)]="_commission.commission_type_id"
                >
                  <mat-radio-button [value]="type.id">
                    {{ type.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="set-commission-column fl-l fw-600">
            <div class="commission-column-head">
              {{ "GENERALS.COMMISSION" | translate }}
            </div>
            <div class="commission-input">
              <input
                type="text"
                class="edit-input fl-l"
                placeholder="{{ 'GENERALS.COMMISSION' | translate }}"
                name="commission"
                (click)="onEvent($event)"
                [(ngModel)]="_commission.commission"
              />
              <span
                *ngIf="
                  _commission?.commission_type_id?.toString() ===
                  providerCommissionEnum.fixed.toString()
                "
                class="add-commission-unit"
                >€</span
              >
              <span
                *ngIf="
                  _commission?.commission_type_id?.toString() ===
                  providerCommissionEnum.percent.toString()
                "
                class="add-commission-unit fw-700"
                >%</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="set-commission-column fl-l fw-600">
            <div class="commission-column-head">
              <div>{{ "GENERALS.SELECT-DATE-TYPE" | translate }}:</div>
            </div>
            <div class="commission-column-head">
              <div class="form-group add-commission-type-radio">
                <mat-radio-group
                  class="commission-type"
                  *ngFor="let type of dateTypes"
                  [(ngModel)]="dataType"
                >
                  <mat-radio-button
                    [value]="type.id"
                    (change)="changeCalendarType($event, _commission)"
                  >
                    {{ type.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="set-commission-column fl-l fw-600">
            <div class="commission-column-head">
              <div>{{ "GENERALS.SELECT-DATE" | translate }}:</div>
            </div>
            <div class="commission-column-body">
              <ng-container *ngIf="dataType === 'multipleMonths'">
                <app-date-range-calendar
                  class="calendar fl-l"
                  [dateRange]="
                    multipleMonthDate
                      ? multipleMonthDate
                      : _commission.dateRange
                  "
                  [isDisabled]="!dataType"
                  [minDate]="minDate"
                  (change)="setDate($event, _category, _commission)"
                  placeholder="{{ 'GENERALS.SELECT-DATE' | translate }}"
                ></app-date-range-calendar>
              </ng-container>

              <ng-container *ngIf="dataType === 'singleMonth'">
                <app-date-range-calendar
                  class="calendar fl-l"
                  [singleDate]="
                    singleMonthDate ? singleMonthDate : _commission.start_date
                  "
                  [isDisabled]="!dataType"
                  [minDate]="minDate"
                  (change)="setDate($event, _category, _commission)"
                  placeholder="{{ 'GENERALS.SELECT-DATE' | translate }}"
                ></app-date-range-calendar>
              </ng-container>
              <div class="commission-error-fields" *ngIf="intersectsWith">
                Selected period intersects with
                <br />
                {{ intersectsWith.start }}/{{ intersectsWith.end }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="save-commission-btn fs-16">
          <button
            type="button"
            class="btn transparent-bg"
            (click)="initEmpty()"
          >
            {{ "GENERALS.CANCEL" | translate }}
          </button>
          <button
            type="button"
            class="btn orange-bg"
            (click)="addCommission(_commission, _category)"
          >
            {{ "LABEL.SAVE-COMMISSION" | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
