<ng-container *ngIf="isLoading">
  <div class="d-flex loading-wrapper">
    <div class="w-profile">
      <app-loader-spinner-general
        [loadingType]="LoadingType.PROFILE"
      ></app-loader-spinner-general>
    </div>
    <div class="w-info">
      <div class="w-first-row">
        <div>
          <app-loader-spinner-general
            [repeat]="3"
            [loadingType]="LoadingType.INFO"
          ></app-loader-spinner-general>
        </div>
        <div>
          <app-loader-spinner-general
            [repeat]="7"
            [loadingType]="LoadingType.INFO"
          ></app-loader-spinner-general>
        </div>
      </div>
      <app-loader-spinner-general
        [repeat]="2"
        [loadingType]="LoadingType.INFO"
      ></app-loader-spinner-general>
    </div>
  </div>
</ng-container>

<div class="details-body-wrapper" *ngIf="!isLoading && entity">
  <!-- 2 col details -->
  <div class="details-2c">
    <!-- left -->
    <app-company-card
      [entity]="entity"
      [entityType]="entityType"
      [service]="service"
      (changeCompany)="getEntityDetails()"
    ></app-company-card>
    <!-- end left -->

    <!-- right -->
    <div class="details-2c-right" *ngIf="entity">
      <div class="first-row">
        <section class="white-box-wrapper w-50">
          <app-company-primary-account
            [primaryAccount]="primaryAccount"
          ></app-company-primary-account>
        </section>
        <section class="white-box-wrapper w-50">
          <app-company-info
            [entity]="entity"
            [entityType]="entityType"
            [customer]="customer"
            [primaryAccount]="primaryAccount"
          ></app-company-info>
        </section>
      </div>
      <section class="white-box-wrapper">
        <app-assign-plan
          [entityType]="$any(entityType)"
          [entityId]="entityId"
        ></app-assign-plan>
      </section>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>
