<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "LEFT-NAVIGATION.NAV6" | translate }}</div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <div class="btn white-bg" (click)="openModal('rfq')">
      <i class="icon-plus-1"></i> {{ "RFQS.ADD-RFQ" | translate }}
    </div>

    <div class="btn white-bg" (click)="openModal('bid')">
      <i class="icon-plus-1"></i> {{ "RFQS.ADD-BID" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper">
    <app-filters
      [showAdvanced]="false"
      [showCalendar]="true"
      [searchPlaceholder]="'GENERALS.SEARCH'"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="search_text = $event; navigateByUrl()"
      (filter)="filters = $event; navigateByUrl()"
      (date)="setFilteringDate($event); navigateByUrl()"
      [total]="total"
    ></app-filters>
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!-- elem on page -->
    <app-elements-on-page
      *ngIf="!isXs"
      [perPage]="perPage"
      (getItem)="perPage = $event; navigateByUrl()"
    ></app-elements-on-page>
    <!-- end elem on page -->
  </div>
  <!-- end right -->
  <div
    class="clear-btn btn white-bg fl-r"
    (click)="clearFilters(); navigateByUrl()"
  >
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="{{ active }}"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <th mat-sort-header="id">
              {{ "GENERALS.ID" | translate }}
            </th>
            <th mat-sort-header="is_bid">
              {{ "RFQS.REQUEST-TYPE" | translate }}
            </th>
            <th>{{ "GENERALS.COMPANY" | translate }}</th>
            <th>{{ "GENERALS.LOCATION" | translate }}</th>
            <th>{{ "RFQS.OFFERS" | translate }}</th>
            <th>{{ "RFQS.SENT-TO" | translate }}</th>
            <th>{{ "RFQS.AVAILABILITY" | translate }}</th>
            <th>{{ "RFQS.TIME-LEFT" | translate }}</th>
            <th mat-sort-header="status">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoadingGeneral">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoadingGeneral">
          <tr
            *ngFor="
              let rfq of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              <a [href]="'/rfqs-bids/' + rfq.id">
                {{ rfq.id }}
              </a>
            </td>
            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              {{ "RFQS." + rfq.request_type.toUpperCase() | translate }}
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              {{ rfq.company_name }}
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              {{ rfq.city }}, {{ rfq.country }}
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              {{ rfq.offers_request.length }}
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              {{ rfq.providers.length }}
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              {{ rfq.start_date | date : "dd/MM/yyyy" }} -
              {{ rfq.end_date | date : "dd/MM/yyyy" }}
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              {{ helper.timeLeft(rfq) }}
            </td>

            <td
              class="gray-pointer"
              (click)="navigateByUrl(filters.page, rfq.id)"
            >
              <div class="rfq-status-label-{{ rfq.status }}">
                {{ "STATUSES." + rfq.status_name | translate }}
              </div>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action with overlay -->
              <div
                toggle-action-table
                class="table-action-wrapper"
                *ngIf="
                  actions &&
                  rfq.status_name.toLowerCase() !== 'cancelled' &&
                  rfq.status_name.toLowerCase() !== 'closed' &&
                  rfq.status_name.toLowerCase() !== 'finalized'
                "
              >
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li
                      *ngFor="let action of actions[rfq.status]"
                      (click)="openConfirmAction(action, rfq)"
                    >
                      {{ action.text | translate }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check"></div>
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoadingGeneral">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let rfq of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check"></div>
          <div
            (click)="navigateByUrl(filters.page, rfq.id)"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ rfq.id }}</div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "RFQS.REQUEST-TYPE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ "RFQS." + rfq.request_type.toUpperCase() | translate }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.COMPANY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ rfq.company_name }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.LOCATION" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ rfq.city }}, {{ rfq.country }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "RFQS.OFFERS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ rfq.offers_request.length }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "RFQS.SENT-TO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ rfq.providers.length }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "RFQS.AVAILABILITY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ rfq.start_date | date : "dd/MM/yyyy" }} -
            {{ rfq.end_date | date : "dd/MM/yyyy" }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "RFQS.TIME-LEFT" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ helper.timeLeft(rfq) }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="rfq-status-label-{{ rfq.status }}">{{
              rfq.status_name
            }}</span>
          </div>
        </div>

        <div
          class="responsive-table-action"
          *ngIf="
            actions &&
            rfq.status_name.toLowerCase() !== 'cancelled' &&
            rfq.status_name.toLowerCase() !== 'closed' &&
            rfq.status_name.toLowerCase() !== 'finalized'
          "
        >
          <span
            *ngFor="let action of actions[rfq.status]"
            (click)="openConfirmAction(action.id, rfq)"
          >
            {{ action.text | translate }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

<app-add-rfqs-bids
  (onnext)="navigateByUrl(1)"
  [type]="type"
></app-add-rfqs-bids>
