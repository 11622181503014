import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ItemsListComponent } from "../dashboard/items-list/items-list.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-elements-on-page",
  templateUrl: "./elements-on-page.component.html",
  standalone: false,
})
export class ElementsOnPageComponent
  extends ItemsListComponent
  implements OnInit
{
  @Output() getItem: EventEmitter<any> = new EventEmitter();
  @Input()
  set perPage(value) {
    if (value) {
      this.per_page = typeof value === "string" ? parseInt(value, 10) : value;
    }
  }
  per_page;
  placeholder;
  _perPageValues;
  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.translate.get("GENERALS.RESULTS_PER_PAGE").subscribe((data) => {
      this.placeholder = data;
      this._perPageValues = this.perPageValues;
      this._perPageValues.map((val) => (val.label = this.placeholder));
    });
  }
}
