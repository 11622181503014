import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ExpatsService } from "src/app/_services/expats.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";

@Component({
  selector: "app-history",
  templateUrl: "./expat-activity.component.html",
  styleUrls: ["./expat-activity.component.css"],
})
export class ExpatActivityComponent implements OnInit {
  public id;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private expatService: ExpatsService
  ) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    this.expatService.getExpatById(this.id).subscribe((data) => {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForExpat(data.result, "history")
      );
    });
  }
}
