import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Moment } from "moment";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Subject,
  takeUntil,
} from "rxjs";
import { DateRangeInterface } from "src/app/_interfaces/date-range.interface";
import { AlertService } from "@modules/alert";

@Component({
  selector: "app-date-range-calendar",
  templateUrl: "./date-range-calendar.component.html",
  styleUrls: ["./date-range-calendar.component.scss"],
  standalone: false,
})
export class DateRangeCalendarComponent
  implements OnInit, OnChanges, OnDestroy
{
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() isDisabled: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string = "";
  @Input() multiple: boolean = false;
  @Input() singleDate: any = "";
  @Input() dateRange: DateRangeInterface;
  @Input() visibleInput: boolean = true;
  @Input() minDate: Date | Moment = null;
  @Input() maxDate: Date | Moment = null;

  private destroyed$ = new Subject<void>();
  dateChange = new Subject<{ inputValue: string; editDate: string }>();
  dateRangeMat: any = {};
  signleDateSub = new BehaviorSubject<moment.Moment>(this.singleDate);
  dateRangeSubStart = new BehaviorSubject<moment.Moment>(
    this.dateRangeMat.start_date
  );
  dateRangeSubEnd = new BehaviorSubject<moment.Moment>(
    this.dateRangeMat.end_date
  );

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnChanges(changes) {
    if (changes.dateRange?.currentValue === null) {
      this.dateRangeMat = {};
    } else {
      this.dateRangeMat = this.dateRange;
    }
  }

  ngOnInit() {
    if (this.singleDate) {
      this.singleDate = moment(this.singleDate).toDate();

      this.signleDateSub.next(this.singleDate);
    }

    if (this.dateRange) {
      this.dateRangeMat = this.dateRange;

      this.dateRangeSubStart.next(moment(this.dateRange.start_date));
      this.dateRangeSubEnd.next(moment(this.dateRange.end_date));
    }

    this.dateChange
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((change: { inputValue: string; editDate: string }) => {
        let momentDate: moment.Moment = null;

        if (moment(change.inputValue, "D/M/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "D/M/YYYY", true);
        }

        if (moment(change.inputValue, "D/MM/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "D/MM/YYYY", true);
        }

        if (moment(change.inputValue, "DD/M/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "DD/M/YYYY", true);
        }

        if (moment(change.inputValue, "DD/MM/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "DD/MM/YYYY", true);
        }

        if (momentDate) {
          switch (change.editDate) {
            case "single":
              this.signleDateSub.next(momentDate);
              break;
            case "start":
              this.dateRangeSubStart.next(momentDate);
              break;
            case "end":
              this.dateRangeSubEnd.next(momentDate);
              break;
          }
        }
      });

    this.dateRangeSubStart
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date: any) => {
        if (
          moment.isMoment(date) &&
          this.minDate &&
          !this.compareDates(moment(date).toDate(), this.minDate)
        ) {
          this.translateService
            .get("GENERALS.DATE-OUT-OF-RANGE")
            .subscribe((text: string) => {
              this.alertService.stringError(
                `${text} (${moment(this.minDate).format("DD/MM/YYYY")})`
              );
            });

          return;
        }

        if (date && moment.isMoment(date)) {
          this.onChangeStartDate(date.toDate());
        }
      });

    this.dateRangeSubEnd
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date: any) => {
        if (
          moment.isMoment(date) &&
          this.maxDate &&
          !this.compareDates(this.maxDate, moment(date).toDate())
        ) {
          this.translateService
            .get("GENERALS.DATE-OUT-OF-RANGE")
            .subscribe((text: string) => {
              this.alertService.stringError(
                `${text} (${moment(this.maxDate).format("DD/MM/YYYY")})`
              );
            });

          return;
        }

        if (date && moment.isMoment(date)) {
          this.onChangeEndDate(date.toDate());
        }
      });

    this.signleDateSub
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date: any) => {
        if (date && moment.isMoment(date)) {
          if (this.minDate) {
            if (!this.compareDates(moment(date).toDate(), this.minDate)) {
              this.translateService
                .get("GENERALS.DATE-OUT-OF-RANGE")
                .subscribe((text: string) => {
                  this.alertService.stringError(
                    `${text} (${moment(this.minDate).format("DD/MM/YYYY")})`
                  );
                });

              return;
            }
          }

          if (this.maxDate) {
            if (!this.compareDates(this.maxDate, moment(date).toDate())) {
              this.translateService
                .get("GENERALS.DATE-OUT-OF-RANGE")
                .subscribe((text: string) => {
                  this.alertService.stringError(
                    `${text} (${moment(this.maxDate).format("DD/MM/YYYY")})`
                  );
                });

              return;
            }
          }

          this.singleDate = date;
          this.onChangeSingleDate();
        }
      });
  }

  compareDates(newerDate: Date | Moment, olderDate: Date | Moment): boolean {
    if (moment(newerDate).year() > moment(olderDate).year()) {
      return true;
    }

    if (moment(newerDate).year() < moment(olderDate).year()) {
      return false;
    }

    if (moment(newerDate).month() > moment(olderDate).month()) {
      return true;
    }
    if (moment(newerDate).month() < moment(olderDate).month()) {
      return false;
    }

    if (moment(newerDate).date() > moment(olderDate).date()) {
      return true;
    }
    if (moment(newerDate).date() < moment(olderDate).date()) {
      return false;
    }

    return true;
  }

  onChangeStartDate(date: any): void {
    this.dateRangeMat.start_date = moment(date);
    this.emitChanges();
  }

  onChangeEndDate(date: any): void {
    this.dateRangeMat.end_date = moment(date);
    this.emitChanges();
  }

  onChangeSingleDate(): void {
    if (moment(this.singleDate).isValid()) {
      this.change.emit(this.singleDate);
    }
  }

  emitChanges(): void {
    if (this.dateRangeMat.start_date && this.dateRangeMat.end_date) {
      this.change.emit(this.dateRangeMat);
    }
  }

  validateInput(inputValue: string, editDate: string): void {
    if (inputValue) {
      this.dateChange.next({ inputValue, editDate });
    }
  }
}
